import React, { Component } from 'react'
import Popup from '../components/common/Popup';
import { INPUT_TYPE, DKInputForm, DKIcon, INPUT_VIEW_DIRECTION, DKLabel } from "deskera-ui-library"
import star from '../assets/star.png'
import favourite from '../assets/favourite.png'
import { isEmpty } from '../utilities/Common';
export default class SaveReport extends Component<any, any> {
    constructor(props: any) {
        super(props)
        this.state = {
            favourite: props.data?.favourite || false,
            name: props.data?.reportName || 'New Report',
            description: props.data?.reportDesc || 'New Report',
            canValidate: !isEmpty(props.data?.reportName) || false
        }
    }
    onFieldChange = (data: any) => {
        const [nameField, descField] = data;
        const name = nameField.value;
        this.setState((prevState: any) => ({ 
            name: name?.length <= 100 ? name : prevState.name, 
            description: descField.value, 
            canValidate: true 
        }));
    }
    getPopup = () => {
        return (
            <Popup
                popupWindowStyles={{ padding: 0, maxWidth: 500, maxHeight: "95%" }}
            >
                {this.renderBody()}
            </Popup>
        )
    }
    renderBody() {
        const direction = INPUT_VIEW_DIRECTION.VERTICAL;
        const fields = [
            {
                key: "name",
                required: true,
                title: `Report name`,
                type: INPUT_TYPE.TEXT,
                value: this.state.name
            },
            {
                key: "description",
                required: true,
                title: "Report description",
                type: INPUT_TYPE.LONG_TEXT,
                value: this.state.description,
            },
        ];
        return (
            <div
                className="column align-items-start parent-width p-l"
                style={{ height: "90%", overflowY: "visible" }}
            >
                <DKInputForm
                    className=""
                    direction={direction}
                    title="Save Report"
                    fields={fields}
                    autoFocus={true}
                    onCancel={() => this.props.onCancel()}
                    onSubmit={(data: any) => this.props.onSave(this.state)}
                    onChange={this.onFieldChange}
                />
                <div className="row">
                    <DKLabel text="Mark as favourite" />
                    <DKIcon className="cursor-hand ml-m"
                        src={this.state.favourite ? favourite : star}
                        onClick={() => this.setState({ favourite: !this.state.favourite })} />
                </div>
            </div>
        );
    }
    render() {

        return (
            <>{this.getPopup()}</>
        )
    }
}

import React, { Component } from 'react'
import { MODULES, MODULE_EVENT } from '../../constants/Constant';
import { DKLabel, DKButton, Toggle, showLoader, removeLoader } from 'deskera-ui-library'
import { getCapitalized } from '../../utilities/Common';
import '../../styles/Reports.css'

export default class UserPermission extends Component<any,any> {
    constructor(props:any) {
        super(props)
        this.state = {
            selectedSectionIndex: 0,
            actions: [],
            disabledActions: {},
            disableSaveButton: false,
          };
    }
    componentDidMount() {
        let allActions:any = this.getActions();
        let updatedActions = Object.keys(allActions).map((key) => ({
          key,
          title: allActions[key],
          isOn: !this.state?.disabledActions?.[
            Object.keys(MODULES)[this.state.selectedSectionIndex]
          ]?.includes(key),
        }));
        this.setState({ actions: updatedActions });
    }
    getHeader() {
        return (
          <div className="pt-s row justify-content-between align-items-start">
            <div>
              <DKLabel text="Permission settings" className="fw-m fs-m" />
              <DKLabel
                text="Enable or disable permissions for below sections and events."
                className="mt-xs text-gray"
              />
            </div>
            <div className="row width-auto">
              <DKButton
                title="Cancel"
                className="bg-gray1 mr-r"
                onClick={() => this.props?.onCancel()}
              />
              <DKButton
                title="Save"
                className="bg-button text-white"
                disabled={this.state.disableSaveButton}
                onClick={() => this.savePermission()}
              />
            </div>
          </div>
        );
      }
      getLeftPanel() {
        return (
          <div className=" z-index-1 " style={{ width: 150 }}>
            {Object.values(MODULES)?.map((obj, index) => {
              let title = obj.toLowerCase();
              return (
                <div
                  className="row justify-content-between  cursor-hand"
                  onClick={() => this.onModuleChange(index)}
                >
                  <DKLabel
                    text={getCapitalized(title)}
                    className={
                      "listPickerBG parent-width p-v-s " +
                      (index === this.state.selectedSectionIndex
                        ? "fw-m text-app "
                        : "")
                    }
                    style={{
                      backgroundColor:
                        index === this.state.selectedSectionIndex ? "#f4f4f6" : "",
                    }}
                  />
                  {index === this.state.selectedSectionIndex && (
                    <div
                      className="bg-button parent-height"
                      style={{ width: 2, height: 30 }}
                    />
                  )}
                </div>
              );
            })}
          </div>
        );
      }
      onModuleChange = (index:any) => {
        this.setState({ selectedSectionIndex: index }, () => {
          let allActions:any = this.getActions();
          let updatedActions = Object.keys(allActions).map((key) => ({
            key,
            title: allActions[key],
            isOn: !this.state?.disabledActions?.[
              Object.keys(MODULES)[this.state.selectedSectionIndex]
            ]?.includes(key),
          }));
          this.setState({ actions: updatedActions });
        });
      };

      getRightPanel() {
        return (
          <div className=" z-index-1 flex-1">
            {this.state.actions?.map((action:any, index:any) => {
              return (
                <div className="pt-m pl-l row justify-content-between">
                  <DKLabel text={action.title} />
                  <Toggle
                    color="bg-button"
                    isOn={action.isOn}
                    onChange={() =>
                      this.handleActionToggle(action.key, !action.isOn)
                    }
                  />
                </div>
              );
            })}
          </div>
        );
      }
      handleActionToggle = (key:any, toggle:any) => {
        let actionsToUpdate = [...this.state.actions];
        const index = actionsToUpdate.findIndex((action) => action.key === key);
        actionsToUpdate[index].isOn = toggle;
        this.setState({ actions: actionsToUpdate });
        const disableActions = actionsToUpdate
          .filter((action) => !action.isOn)
          ?.map((action) => action.key);
        let data = { ...this.state.disabledActions };
        data[
          Object.keys(MODULES)[this.state.selectedSectionIndex]
        ] = disableActions;
        this.setState({ disabledActions: data });
      };
    savePermission = () => {
          this.props?.onCancel()
      };
      getActions = () => {
        switch (Object.values(MODULES)[this.state.selectedSectionIndex]) {
          case MODULES.REPORT:
            return MODULE_EVENT.REPORT;
        }
      };
      getActionsName = (module:any) => {
        let title = module.toLowerCase();
        title = getCapitalized(title)
        let data = {
        REC_CREATE : `${title} created`,
        REC_UPDATE : `${title} updated`,
        REC_DELETE : `${title} deleted`,
        }
        return data;
      }
  render() {
    return (
        <div className=" transparent-background">
          <div className=" popup-window " style={{ maxWidth: "auto" }}>
            {this.getHeader()}
            <div className="mt-l mb-s flex-1 parent-width row align-items-start">
              {this.getLeftPanel()}
              <div
                className=" parent-height bg-gray2 column"
                style={{ width: 1, marginLeft: -2, height: 150 }}
              />
              {this.getRightPanel()}
            </div>
          </div>
        </div>
      );
  }
}

import ActivityManager from '../managers/ActivityManager';
import { INPUT_TYPE } from 'deskera-ui-library';
import {
  CAMPAIGN_TYPE,
  FILTER_LOGICAL_OPERATORS,
  FILTER_OPERATORS,
  INVITE_USER_STATUS
} from '../constants/Enum';
import { isEmptyObject, isValidDate } from '../utilities/Common';
import { COLUMN_CODE, TABLES } from '../constants/Constant';
import DBTableManager from '../managers/DBTableManager';
import { getFullName } from '../models/Tenant';

class TableDataParser {
  static parseTableData(data) {
    let tableData = {};
    tableData['id'] = data._id;
    tableData['name'] = data.name;
    tableData['columns'] = this.parseColumnData(data.columnsMetaData);
    return tableData;
  }
  static parseColumnData(data) {
    let columnsData = [];
    data.forEach((column) => {
      let data = { ...column };
      data['key'] = column.id;
      data['type'] = column.type.toLowerCase();
      data['options'] = column.options ? column.options : [];
      data['required'] = column.required ? column.required : false;
      data['width'] = column.width ? column.width : 200;
      columnsData.push(data);
    });
    return columnsData;
  }

  /** ************** FILTER PAYLOAD HELPERS ***************/

  /**
   * @description
   * This method modifies the filter condition based on DATE or COLUMN CODES
   * (for handling equalTo & greater than operations)
   */
  static updateFilterConditionForPayload(filterCondition, columns) {
    const updatedFilterConditions = [];
    const columnData = columns?.find(
      (column) => column.id === filterCondition?.colId
    );

    if (!isEmptyObject(filterCondition)) {
      filterCondition = { ...filterCondition };
      delete filterCondition.isExternal;
    }

    if (
      columnData?.type !== INPUT_TYPE.DATE ||
      !isValidDate(filterCondition.value)
    ) {
      if (ActivityManager.isActivityOverdueFilter(filterCondition)) {
        let overDueConditions = ActivityManager.getActivityReportQuery();
        updatedFilterConditions.push(...overDueConditions);
      } else if (!isEmptyObject(filterCondition)) {
        updatedFilterConditions.push(filterCondition);
      }

      return {
        conditions: updatedFilterConditions,
        logicalOperator: FILTER_LOGICAL_OPERATORS.AND
      };
    }

    const selectedDate = new Date(filterCondition.value);
    const nextDate = new Date(
      new Date(selectedDate).setDate(selectedDate.getDate() + 1)
    );
    if (filterCondition.opr === FILTER_OPERATORS.EQUAL) {
      updatedFilterConditions.push({
        colId: filterCondition.colId,
        value: selectedDate,
        opr: FILTER_OPERATORS.GREATER_THAN_OR_EQUAL
      });

      updatedFilterConditions.push({
        colId: filterCondition.colId,
        value: nextDate,
        opr: FILTER_OPERATORS.LESS_THAN
      });
    } else if (filterCondition.opr === FILTER_OPERATORS.GREATER_THAN) {
      updatedFilterConditions.push({
        colId: filterCondition.colId,
        value: nextDate,
        opr: FILTER_OPERATORS.GREATER_THAN_OR_EQUAL
      });
    } else {
      updatedFilterConditions.push(filterCondition);
    }

    return {
      conditions: updatedFilterConditions,
      logicalOperator: FILTER_LOGICAL_OPERATORS.AND
    };
  }

  /**
   * @description
   * This method creates groupings for filter payload, to apply logical operations on conditions.
   * External conditions (module level) are always processed with "AND" operators,
   * while GridLevel/additional Filters are processed with user selected operator.
   */
  static getModifiedFilterPayload(
    payload,
    columns,
    splitOnExternalConditions = true
  ) {
    const { logicalOperator, conditions } = payload;
    if (!conditions?.length)
      return { conditions: [], logicalOperator: logicalOperator };

    let filter1, filter2;

    if (splitOnExternalConditions) {
      filter1 = TableDataParser.getModifiedFilterPayload(
        {
          conditions: conditions.filter((condition) => condition.isExternal),
          logicalOperator: FILTER_LOGICAL_OPERATORS.AND
        },
        columns,
        false
      );
      filter2 = TableDataParser.getModifiedFilterPayload(
        {
          conditions: conditions.filter((condition) => !condition.isExternal),
          logicalOperator
        },
        columns,
        false
      );
    } else {
      filter1 = TableDataParser.updateFilterConditionForPayload(
        conditions[0],
        columns
      );
      filter2 =
        conditions?.length > 2
          ? TableDataParser.getModifiedFilterPayload(
              {
                conditions: conditions.slice(1),
                logicalOperator
              },
              columns,
              false
            )
          : TableDataParser.updateFilterConditionForPayload(
              conditions[1],
              columns
            );
    }

    return {
      filter1,
      filter2,
      logicalOperator: splitOnExternalConditions
        ? FILTER_LOGICAL_OPERATORS.AND
        : logicalOperator
    };
  }

  /** ************** SEGMENT COLUMN RENDERERS ***************/
  static checkAndReplaceSegment() {
    const contactSegmentCol = DBTableManager.getColumn(
      TABLES.CONTACT,
      COLUMN_CODE.CONTACT.SEGMENT
    );
    let segmentData = {
      type: contactSegmentCol.type,
      options: contactSegmentCol.options,
      name: contactSegmentCol.name
    };
    DBTableManager.updateColumn(COLUMN_CODE.FORMS.SEGMENT_ID, TABLES.FORM, {
      ...DBTableManager.getColumn(TABLES.FORM, COLUMN_CODE.FORMS.SEGMENT_ID),
      ...segmentData
    });
    DBTableManager.updateColumn(
      COLUMN_CODE.CAMPAIGN.SEGMENT_ID,
      TABLES.CAMPAIGN,
      {
        ...DBTableManager.getColumn(
          TABLES.CAMPAIGN,
          COLUMN_CODE.CAMPAIGN.SEGMENT_ID
        ),
        ...segmentData
      }
    );
  }

  /* ***************** CAMPAIGN GRID UTILS ***************** */
  static addDefaultCampaignTypeToRowData(row, table) {
    if (table !== TABLES.CAMPAIGN) return;

    const column_id = DBTableManager.getColumnId(
      table,
      COLUMN_CODE.CAMPAIGN.CAMPAIGN_TYPE
    );
    if (column_id && row?.cells && isEmptyObject(row.cells[column_id])) {
      row.cells[column_id] = [CAMPAIGN_TYPE.EMAIL];
    }
  }

  /***************** ACTIVITY GRID RENDERERS ****************/
  static checkAndReplaceActivityData(data, users) {
    const CONTACT_IDS = DBTableManager.getColumnId(
      TABLES.ACTIVITY,
      COLUMN_CODE.ACTIVITY.CONTACT_IDS
    );
    const ORGANIZATION_IDS = DBTableManager.getColumnId(
      TABLES.ACTIVITY,
      COLUMN_CODE.ACTIVITY.ORGANIZATION_IDS
    );
    const LINKED_TO = DBTableManager.getColumnId(
      TABLES.ACTIVITY,
      COLUMN_CODE.ACTIVITY.LINKED_TO
    );

    let contactOptions = [],
      organizationOptions = [],
      dealOptions = [],
      ownerOptions = [];

    data?.forEach((row) => {
      if (row.cells[CONTACT_IDS + '_detail']?.length >= 0) {
        contactOptions = [
          ...contactOptions,
          ...ActivityManager.contactData(row.cells[CONTACT_IDS + '_detail'])
        ];
      }
      if (row.cells[ORGANIZATION_IDS + '_detail']?.length >= 0) {
        organizationOptions = [
          ...organizationOptions,
          ...ActivityManager.contactData(
            row.cells[ORGANIZATION_IDS + '_detail']
          )
        ];
      }
      if (row.cells[LINKED_TO + '_detail']?.length >= 0) {
        dealOptions = [
          ...dealOptions,
          ...ActivityManager.dealData(row.cells[LINKED_TO + '_detail'])
        ];
      }
    });

    if (contactOptions.length >= 0) {
      contactOptions = [
        ...new Map(
          contactOptions.map((contact) => [contact.id, contact])
        ).values()
      ];
      let contactData = {
        options: contactOptions,
        type: INPUT_TYPE.SELECT
      };
      DBTableManager.updateColumn(
        COLUMN_CODE.ACTIVITY.CONTACT_IDS,
        TABLES.ACTIVITY,
        {
          ...DBTableManager.getColumn(
            TABLES.ACTIVITY,
            COLUMN_CODE.ACTIVITY.CONTACT_IDS
          ),
          ...contactData
        }
      );
    }

    if (organizationOptions.length >= 0) {
      organizationOptions = [
        ...new Map(
          organizationOptions.map((organization) => [
            organization.id,
            organization
          ])
        ).values()
      ];
      let organizationData = {
        options: organizationOptions,
        type: INPUT_TYPE.SELECT
      };
      DBTableManager.updateColumn(
        COLUMN_CODE.ACTIVITY.ORGANIZATION_IDS,
        TABLES.ACTIVITY,
        {
          ...DBTableManager.getColumn(
            TABLES.ACTIVITY,
            COLUMN_CODE.ACTIVITY.ORGANIZATION_IDS
          ),
          ...organizationData
        }
      );
    }

    if (dealOptions.length >= 0) {
      dealOptions = [
        ...new Map(dealOptions.map((deal) => [deal.id, deal])).values()
      ];
      let dealData = {
        options: dealOptions,
        type: INPUT_TYPE.SELECT
      };
      DBTableManager.updateColumn(
        COLUMN_CODE.ACTIVITY.LINKED_TO,
        TABLES.ACTIVITY,
        {
          ...DBTableManager.getColumn(
            TABLES.ACTIVITY,
            COLUMN_CODE.ACTIVITY.LINKED_TO
          ),
          ...dealData
        }
      );
    }

    if (ownerOptions.length === 0) {
      const ownerOptions = [];
      users.forEach((user, index) => {
        if (user.status === INVITE_USER_STATUS.JOINED) {
          ownerOptions.push({
            id: user.iamUserId,
            name: getFullName(user),
            color: `data-grid-badge-color-${10 - (index % 10)}`
          });
        }
      });
      let ownerData = {
        options: ownerOptions,
        type: INPUT_TYPE.SELECT
      };
      DBTableManager.updateColumn(
        COLUMN_CODE.ACTIVITY.OWNER_ID,
        TABLES.ACTIVITY,
        {
          ...DBTableManager.getColumn(
            TABLES.ACTIVITY,
            COLUMN_CODE.ACTIVITY.OWNER_ID
          ),
          ...ownerData,
          allowFilter: false,
          allowAddOption: false
        }
      );
    }
  }

  /** ************** DEAL GRID RENDERERS ********************/
  static putDataInOwnersColumn(userList) {
    const availableUsers =
      userList?.filter((user) => user.status === INVITE_USER_STATUS.JOINED) ||
      [];

    const ownerOptions = availableUsers.map((user, index) => ({
      id: user.iamUserId,
      name: getFullName(user),
      color: `data-grid-badge-color-${(index % 10) + 1}`
    }));

    const contactOwnerIdColumn = DBTableManager.getColumn(
      TABLES.CONTACT,
      COLUMN_CODE.CONTACT.OWNER_ID
    );
    DBTableManager.updateColumn(COLUMN_CODE.CONTACT.OWNER_ID, TABLES.CONTACT, {
      ...contactOwnerIdColumn,
      name: 'Owner',
      type: INPUT_TYPE.SELECT,
      options: ownerOptions,
      allowAddOption: false
      /* allowFilter: false */
    });
    DBTableManager.updateColumn(
      COLUMN_CODE.CONTACT.SUB_OWNER_ID,
      TABLES.CONTACT,
      {
        ...DBTableManager.getColumn(
          TABLES.CONTACT,
          COLUMN_CODE.CONTACT.SUB_OWNER_ID
        ),
        name: 'Sub Owner',
        type: INPUT_TYPE.MULTI_SELECT,
        options: ownerOptions,
        allowAddOption: false
      }
    );

    DBTableManager.updateColumn(COLUMN_CODE.ACCOUNT.OWNER_ID, TABLES.ACCOUNT, {
      ...DBTableManager.getColumn(TABLES.ACCOUNT, COLUMN_CODE.ACCOUNT.OWNER_ID),
      name: 'Owner',
      type: INPUT_TYPE.SELECT,
      options: ownerOptions,
      allowAddOption: false,
      allowFilter: false
    });
    DBTableManager.updateColumn(
      COLUMN_CODE.ACCOUNT.SUB_OWNER_ID,
      TABLES.ACCOUNT,
      {
        ...DBTableManager.getColumn(
          TABLES.ACCOUNT,
          COLUMN_CODE.ACCOUNT.SUB_OWNER_ID
        ),
        name: 'Sub Owner',
        type: INPUT_TYPE.MULTI_SELECT,
        options: ownerOptions,
        allowAddOption: false
      }
    );

    const dealOwnerIdColumn = DBTableManager.getColumn(
      TABLES.DEAL,
      COLUMN_CODE.DEAL.OWNER_ID
    );
    DBTableManager.updateColumn(COLUMN_CODE.DEAL.OWNER_ID, TABLES.DEAL, {
      ...dealOwnerIdColumn,
      name: 'Owner',
      type: INPUT_TYPE.SELECT,
      options: ownerOptions,
      allowAddOption: false,
      allowFilter: false
    });
    DBTableManager.updateColumn(COLUMN_CODE.DEAL.SUB_OWNER_ID, TABLES.DEAL, {
      ...DBTableManager.getColumn(TABLES.DEAL, COLUMN_CODE.DEAL.SUB_OWNER_ID),
      name: 'Sub Owner',
      type: INPUT_TYPE.MULTI_SELECT,
      options: ownerOptions,
      allowAddOption: false
    });

    const activityOwnerIdColumn = DBTableManager.getColumn(
      TABLES.ACTIVITY,
      COLUMN_CODE.ACTIVITY.OWNER_ID
    );
    DBTableManager.updateColumn(
      COLUMN_CODE.ACTIVITY.OWNER_ID,
      TABLES.ACTIVITY,
      {
        ...activityOwnerIdColumn,
        type: INPUT_TYPE.SELECT,
        options: ownerOptions,
        allowFilter: false,
        allowAddOption: false
      }
    );

    this.updateNoteColumn(TABLES.CONTACT, COLUMN_CODE.CONTACT.NOTE, {
      hidden: false
    });
    this.updateNoteColumn(TABLES.DEAL, COLUMN_CODE.DEAL.NOTE, {
      hidden: false
    });
  }

  static updateNoteColumn = (tableName, column, values) => {
    const noteColumn = DBTableManager.getColumn(tableName, column);
    DBTableManager.updateColumn(column, tableName, {
      ...noteColumn,
      ...values
    });
  };
}

export default TableDataParser;

import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import {
  DKButton,
  DKLabel,
  DKInput,
  DKSpinner,
  INPUT_VIEW_DIRECTION,
  INPUT_TYPE,
  showAlert
} from 'deskera-ui-library';
import AppIntegrationService from '../../services/appIntegration';
import Popup from '../common/Popup';

enum DATA_MAPPING_TYPE {
  TEXT = 'TEXT',
  DOUBLE = 'DOUBLE',
  DATE = 'DATE',
  INT = 'INT'
}

export default function CustomReportMapping(props: any) {
  const [columnMappingData, setColumnMappingData] = useState<any[]>(
    props.mappingData
  );
  const [isSaving, setIsSaving] = useState(false);

  const saveColumnMapping = () => {
    // For multiple files
    // const updatedMapping = columnMappingData.map((mapping: any) => ({
    //   id: mapping.id,
    //   columnMappings: mapping.columnMappings.map((data: any) => ({
    //     displayName: data.displayName,
    //     type: data.type
    //   }))
    // }));
    const fileId = columnMappingData[0]?.id;
    const updatedMapping = columnMappingData[0]?.columnMappings?.map(
      (data: any) => ({
        displayName: data?.displayName,
        type: data?.type
      })
    );
    // console.log('updatedMapping: ', updatedMapping);
    setIsSaving(true);
    AppIntegrationService.saveOneDriveFilesColumnMapping(
      fileId,
      updatedMapping
    ).then(
      (data: any) => {
        setIsSaving(false);
        removePopUp();
        props.onUpdate?.(data);
      },
      (err: any) => {
        setIsSaving(false);
        showAlert('Error!', 'Error while updating column types. Please note duplicate column names are not allowed.');
        console.error('Error updating column type mapping: ', err);
      }
    );
  };

  const updateColumnMappings = (
    id: number,
    displayName: string,
    type: string
  ) => {
    let columnMappingIndex = columnMappingData.findIndex(
      (data: any) => data.id === id
    );
    if (columnMappingIndex !== -1) {
      let columnMappingCopy = [
        ...columnMappingData[columnMappingIndex].columnMappings
      ];
      let columnIndex = columnMappingCopy.findIndex(
        (column: any) => column.displayName === displayName
      );
      if (columnIndex !== -1) {
        columnMappingCopy[columnIndex] = {
          ...columnMappingCopy[columnIndex],
          type: type
        };

        let updatedMappingData = [...columnMappingData];
        updatedMappingData[columnMappingIndex] = {
          ...updatedMappingData[columnMappingIndex],
          columnMappings: columnMappingCopy
        };

        setColumnMappingData(updatedMappingData);
      }
    }
  };

  const removePopUp = () => {
    const popupContainer = document.getElementById(props.popupId);
    popupContainer && ReactDOM.unmountComponentAtNode(popupContainer);
    popupContainer?.remove();
    props.onClose?.();
  };

  const dataMappingOptions = [
    { label: 'Date', value: DATA_MAPPING_TYPE.DATE },
    { label: 'Decimal Number', value: DATA_MAPPING_TYPE.DOUBLE },
    { label: 'Number', value: DATA_MAPPING_TYPE.INT },
    { label: 'Text', value: DATA_MAPPING_TYPE.TEXT }
  ];

  const getColumnInfo = (id: number) => {
    let columnMappings = columnMappingData.find(
      (data: any) => data.id === id
    )?.columnMappings;
    columnMappings = columnMappings.filter(
      (mapping: any) => mapping.allowToMap ?? true
    );
    return (
      <div className="column parent-width">
        <div className="row width-auto align-items-center justify-content-center p-v-xs">
          <DKLabel text="Column" className="fw-m" style={{ width: 200 }} />
          <DKLabel text="Type" className="fw-m" style={{ width: 100 }} />
        </div>
        {columnMappings.map((columnMapping: any) => {
          return (
            <div className="row width-auto align-items-center justify-content-center p-v-xs">
              <DKLabel
                text={columnMapping.displayName}
                style={{ width: 200 }}
              />
              <div style={{ width: 140 }}>
                <DKInput
                  title=""
                  readOnly={isSaving}
                  onChange={(obj: any) => {
                    updateColumnMappings(
                      id,
                      columnMapping.displayName,
                      obj.value
                    );
                  }}
                  value={dataMappingOptions.find(
                    (option: any) => columnMapping.type === option.value
                  )}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  type={INPUT_TYPE.DROPDOWN}
                  formatter={(obj: any) => {
                    return obj.label;
                  }}
                  style={{ width: 140 }}
                  className=""
                  dropdownConfig={{
                    title: '',
                    allowSearch: false,
                    searchableKey: 'label',
                    style: { minWidth: 150 },
                    className: 'shadow-m width-auto',
                    searchApiConfig: null,
                    data: dataMappingOptions,
                    renderer: (index: any, obj: any) => {
                      return <DKLabel text={`${obj.label}`} />;
                    }
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const getHeader = () => {
    return (
      <div
        className="row justify-content-between p-h-r p-v-s bg-gray1"
        style={{
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4
        }}
      >
        <div className="row width-auto">
          <DKLabel text="Map Column Types" className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className={`bg-white border-m mr-r`}
            onClick={() => removePopUp()}
          />
          <div
            className={`row border-radius-m ${
              isSaving ? ' bg-gray1 border-m pr-2' : ' bg-button text-white'
            }`}
          >
            <DKButton
              title={isSaving ? 'Saving' : 'Save'}
              onClick={() => saveColumnMapping()}
            />
            {isSaving && <DKSpinner iconClassName="ic-s" />}
          </div>
        </div>
      </div>
    );
  };

  const getBody = () => {
    return (
      <div className="column parent-width mt-r p-h-r">
        <DKLabel
          className="mb-l"
          text={`Please verify & confirm the data type for below columns of selected file${
            columnMappingData.length > 1 ? 's' : ''
          }: `}
        />
        {columnMappingData.map((data: any, index: number) => {
          return (
            <div className="column parent-width mb-s">
              <DKLabel text={data.fileName} className="fs-m fw-m mb-s" />
              <div className="bg-white border-m border-radius-s mb-r parent-width p-r border-box">
                {data.columnMappings?.length > 0 && getColumnInfo(data.id)}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Popup
      popupWindowStyles={{
        padding: 0,
        width: 400,
        maxWidth: '90vw',
        maxHeight: '90vh'
      }}
    >
      {getHeader()}
      <div className="parent-width column overflow-y-scroll">{getBody()}</div>
    </Popup>
  );
}

export const showCustomMapperPopup = (config: {
  mappingData: any[];
  onUpdate: (data: any) => void;
  onClose?: () => void;
}) => {
  const id = `custom-report-mapping-popup-${new Date().getTime()}`;
  let popupContainer = document.createElement('div');
  popupContainer.className = 'custom-report-mapping-popup app-font';
  popupContainer.setAttribute('id', id);
  ReactDOM.render(
    <CustomReportMapping
      popupId={id}
      mappingData={config.mappingData}
      onUpdate={config.onUpdate}
      onClose={config.onClose}
    />,
    document.body.appendChild(popupContainer)
  );
};

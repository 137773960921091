import React, { Component } from 'react';
import ApiConstants from '../constants/ApiConstant';
import { DKIcons, DKLabel, DKIcon, DKButton } from 'deskera-ui-library';
import { SETTING_CARD_IDS } from '../constants/Enum';
import RouteManager from '../managers/RouteManager';
import UserPermission from '../components/common/UserPermission';
import ic_one_drive from '../assets/appIntegration/ic_one_drive.svg';
import OneDriveButton from '../components/app/OneDriveButton';
import { PAGE_ROUTES } from '../constants/Constant';

export default class Settings extends Component<any, any> {
  settingOptions: any = [];
  constructor(props: any) {
    super(props);
    this.state = {
      showPermissionsPopup: false
    };
    this.settingOptions = [
      {
        title: 'My Profile',
        description:
          'See your profile details and explore our other products too',
        icon: DKIcons.ic_user,
        url: ApiConstants.PRODUCT_URL_GO
      },
      {
        cardId: SETTING_CARD_IDS.USER_PERMISSION,
        title: 'User Permissions',
        description: 'Set and manage user permissions.',
        icon: DKIcons.ic_user_2,
        buttonTitle: 'Edit Permissions',
        hidden: false,
        onClick: () => this.togglePermissionsPopup(true)
      },
      {
        title: 'Organization Settings',
        description: 'You can update or modify your organisation details here',
        icon: DKIcons.ic_home,
        url: ApiConstants.PRODUCT_URL_GO + 'settings'
      },
      {
        title: 'Subscription / Billing',
        description: 'Manage your subscription, change plan and upgrade here',
        icon: DKIcons.ic_cards,
        url: ApiConstants.PRODUCT_URL_GO + 'billing'
      },
      {
        title: 'Two-Factor Authentication (2FA)',
        description:
          "Increase your account's security by enabling Two-Factor Authentication (2FA)",
        icon: DKIcons.ic_key,
        url: ApiConstants.PRODUCT_URL_GO + 'security'
      },
      {
        title: 'Change Password',
        description: 'You can change or modify your password here',
        icon: DKIcons.ic_key_2,
        url: ApiConstants.PRODUCT_URL_GO + 'security'
      },
      {
        title: 'Connect cloud storage',
        description:
          'Connect your cloud storage accounts with Deskera Report Builder to add data sources.',
        icon: ic_one_drive,
        buttonTitle: 'Connect accounts',
        onClick: () => RouteManager.navigateToPage(PAGE_ROUTES.CONNECT_SETTINGS)
      }
    ];
  }
  componentDidMount() {
    RouteManager.setPresenter(this);
  }
  /////////////////////// User Permission ///////////////////////////////

  getUserPermissionsPopup = () => {
    return (
      <UserPermission onCancel={() => this.togglePermissionsPopup(false)} />
    );
  };
  togglePermissionsPopup = (shouldOpen?: boolean) => {
    this.setState({ showPermissionsPopup: shouldOpen });
  };
  ///////////////////////////////////////////////////////////////////////
  getAllCards() {
    let arr: any = [];
    this.settingOptions.forEach((obj: any) => {
      if (obj.hidden) return;

      arr.push(this.getCard(obj));
    });
    return arr;
  }
  getCard(setting: any) {
    return (
      <div
        className="bg-white border-radius-m border-m p-r column border-box shadow-s mobile-parent-width"
        style={{ minWidth: 250, width: '24%' }}
        onClick={() => {}}
      >
        <DKIcon src={setting.icon} className="" />
        <DKLabel text={setting.title} className="fw-m mt-m fs-m" />
        <DKLabel
          text={setting.description}
          className="text-dark-gray mt-xs mb-auto"
          style={{ height: 30 }}
        />
        {setting.buttonRenderer ? (
          <div className="row justify-content-end mt-auto">
            {setting.buttonRenderer()}
          </div>
        ) : (
          <DKButton
            title={setting.buttonTitle ? setting.buttonTitle : 'View Details'}
            className="align-self-end bg-gray1 border-m mt-xl"
            icon={DKIcons.ic_arrow_right2}
            onClick={() => {
              if (setting.url) {
                window.open(setting.url, '_blank');
              } else {
                setting.onClick();
              }
            }}
            isReverse={true}
          />
        )}
      </div>
    );
  }
  render() {
    return (
      <div className="parent-width p-v-l">
        <DKLabel className="fs-l fw-m" text="Settings" />
        <div
          className="row flex-wrap mt-l align-items-stretch"
          style={{ gap: 13 }}
        >
          {this.getAllCards()}
          {this.state.showPermissionsPopup && this.getUserPermissionsPopup()}
        </div>
      </div>
    );
  }
}

import { configureStore } from '@reduxjs/toolkit';
import tenantReducer from './slices/tenantSlice';
import tableReducer from './slices/tableSlice';
import reportReducer from './slices/reportSlice';
import recordReducer from './slices/recordSlice';
import tableMetaDataReducer from './slices/tableMetaDataSlice';
import appIntegrationReducer from './slices/appIntegrationSlice';

export const store = configureStore({
  reducer: {
    table: tableReducer,
    records: recordReducer,
    reports: reportReducer,
    tenant: tenantReducer,
    tableMetaData: tableMetaDataReducer,
    appIntegration: appIntegrationReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import React, { useState, useEffect } from 'react';
import { showToast, TOAST_TYPE, showLoader, removeLoader } from 'deskera-ui-library';
import {
  accountingMethod,
  comparisionDataOptions,
  dateFilterLogic,
  dateRangeOptions,
  getAmountBlockWithCurrency,
  getFinancialStartDate,
  getTimeStamp
} from '../helper/SharedParser';
import { DATE_FORMATS, REPORT_BASIS } from '../constants/Enum';
import { useAppSelector } from '../store/hooks';
import { getTenantInfo } from '../store/slices/tenantSlice';
import RouteManager from '../managers/RouteManager';
import { convertInTitleCase, isEmpty } from '../utilities/Common';
import { getDateStrFromDate, getFormattedDateString } from '../utilities/Date';

import PdfUtility from '../utilities/PDFUtility';
import AccountingReportBody from '../components/accounting/AccountingReportBody';
import CustomizePopup from '../components/accounting/CustomizePopup';
import AccountingService from '../services/reports/Accounting';
import {
  REPORT_HEADER_ITEMS,
  ReportHeaderState
} from '../models/AccountingReport';
import AccountingReportHeader from '../components/accounting/AccountingReportHeader';
import { getParsedDataForBLDetails } from '../helper/BalanceSheetParser';
import EmailComposerPopUp from '../components/common/EmailComposerPopup';

export default function BalanceSheetDetails(props: any) {
  const initialState = {
    Basis: { label: 'Accrual', value: REPORT_BASIS.ACCRUAL },
    StartDate: getFinancialStartDate(),
    EndDate: new Date(),
    ComparisonMode: { id: 1, vname: 'Off', custom: false },
    ColumnBy: null
  };
  const [reportFilterState, setReportFilterState] = useState<any>({
    ...initialState
  });
  const [showEmailPopUp, setShowEmailPopup] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [reportData, setReportData] = useState<any>(null);
  const [reportName, setReportName] = useState<any>('Balance Sheet Details');
  const [tenantName, setTenantName] = useState<any>('');
  const [pdfUrl, setPdfUrl] = useState<any>();
  const [lengthOfCols, setLengthOfCols] = useState<any>(0);
  const [passer, setPasser] = useState<any>();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [nameArray, setNameArray] = useState<any>();
  const [tableLoader, setTableLoader] = useState(false);
  const tenantInfo: any = useAppSelector(getTenantInfo());
  const [exportPayload, setExportPayload] = useState<any>();
  const [customFilter, setCustomFilter] = useState<any>();

  useEffect(() => {
    RouteManager.setPresenter({ props });
    const updatedState = reportFilterState;
    updatedState.CurrentDateRange = null;
    const payload = generatePayload(reportFilterState);
    setExportPayload(payload);
    fetchReportDetails(payload);
    return () => {
      // null
    };
  }, []);

  useEffect(() => {
    if (tenantInfo) {
      setTenantName(tenantInfo.name);
    }
  }, [tenantInfo]);

  useEffect(() => {
    if (passer && passer.rows) {
      let arr = getNames(passer.rows);
      setNameArray(arr);
    }
  }, [passer]);

  useEffect(() => {
    parsedDataForPNL();
  }, [reportData]);

  const getEmailPopUp = () => {
    return (
      <EmailComposerPopUp
        documentData={reportData}
        reportTitle={'Balance Sheet Details'}
        removeEmailPopUp={() => {
          setShowEmailPopup(false);
        }}
        pdfURL={pdfUrl}
        isMobile={false}
        printingCompleted={() => {
          setShowEmailPopup(false);
        }}
      />
    );
  };

  const getNames = (rows: any) => {
    if (rows.length === 0) {
      return [];
    }
    let nameArray: any = [];
    rows.forEach((row: any, index: number) => {
      if (row?.cells?.length > 0) {
        if (
          row.cells[0].title !== '' &&
          row.cells[0].title !== ' ' &&
          !isEmpty(row.cells[0].title)
        ) {
          nameArray.push(row.cells[0].title);
        }
      }

      if (row?.children.length > 0) {
        let childNameArray: any = getNames(row.children);
        if (childNameArray?.length > 0) {
          nameArray = [...nameArray, ...childNameArray];
        }
      }
    });

    return nameArray;
  };

  const replaceNameState = (rows: any, name: any, replace: any) => {
    if (rows.length === 0) {
      return;
    }
    rows.forEach((row: any, index: number) => {
      if (row?.cells?.length > 0) {
        if (row.cells[0].title === name) {
          row.cells[0].title = replace;
        }
      }

      if (row?.children.length > 0) {
        replaceNameState(row.children, name, replace);
      }
    });

    return rows;
  };

  const generatePayload = (reportFilterState: any) => {
    let payload: any = {
      basis: reportFilterState.Basis.value,
      startDate: getDateStrFromDate(
        reportFilterState.StartDate,
        DATE_FORMATS['DD-MM-YYYY']
      ),
      endDate: getDateStrFromDate(
        reportFilterState.EndDate,
        DATE_FORMATS['DD-MM-YYYY']
      )
    };
    if (reportFilterState.showCustomFilterPopup) {
      let customField: any = [];
      reportFilterState.showCustomFilterPopup?.data?.forEach((row: any) => {
        const modules = reportFilterState.showCustomFilterPopup
          .customFieldOptions
          ? reportFilterState.showCustomFilterPopup.customFieldOptions
              .filter((field: any) => field.key === row.key)
              .map((field: any) => {
                if (field.key === row.key) {
                  return field.module;
                }
                return '';
              })
          : [];

        let cf: any = {};
        if (Array.isArray(row.value)) {
          let val = '';
          reportFilterState.showCustomFilterPopup.options.forEach(
            (option: any) => {
              if (option.key === row.key) {
                option?.options.forEach((opt: any) => {
                  if (opt.id === row.value[0]) {
                    val = opt.value;
                  }
                });
              }
            }
          );

          cf.value = val;
          cf.code = row.key;
          cf.module = modules;
        } else {
          cf.value = row.value;
          cf.code = row.key;
          cf.module = modules;
        }
        if (cf.value !== '' && cf.module.length) customField.push(cf);
      });
      if (customField?.length) {
        payload = {
          ...payload,
          custom_field: JSON.stringify(customField)
        };
        setCustomFilter(encodeURI(JSON.stringify(customField)));
      } else {
        setCustomFilter([]);
      }
    }
    return payload;
  };

  //   const callPopup = (data: any) => {
  //     if (data?.rowData?.details) {
  //       const rowDetails = data.rowData.details;
  //       const docType = rowDetails['documentType'];
  //       const documentCode = rowDetails['documentCode'];
  //       const jeCode = rowDetails['jeCode'];

  //       if (documentCode && (data.cellIndex == 0 || data.cellIndex === 1)) {
  //         switch (docType) {
  //           case DOC_TYPE.INVOICE:
  //             loadInvoiceDetails(documentCode);
  //             break;
  //           case DOC_TYPE.QUOTE:
  //             loadQuoteDetails(documentCode);
  //             break;
  //           case DOC_TYPE.ORDER:
  //             loadOrderDetails(documentCode);
  //             break;
  //           case DOC_TYPE.BILL:
  //             loadBillDetails(documentCode);
  //             break;
  //           case DOC_TYPE.MAKE_PAYMENT:
  //           case DOC_TYPE.RECEIVE_PAYMENT:
  //             loadPaymentPopup(rowDetails);
  //             break;
  //           case DOC_TYPE.DEBIT_NOTE:
  //             loadDebitNotesDetails(documentCode);
  //             break;
  //           case DOC_TYPE.CREDIT_NOTE:
  //             loadCreditNotesDetails(documentCode);
  //             break;
  //           default:
  //             break;
  //         }
  //       }
  //       if (jeCode && (data.cellIndex === 2 || data.cellIndex === 1)) {
  //         // switch (docType) {
  //         //   case DOC_TYPE.NORMAL_JE:
  //         //     loadNormalJEDetails(jeCode);
  //         //     break;
  //         // }
  //         if (docType === DOC_TYPE.FUND_TRANSFER_JE) {
  //           loadFundTransferJEDetails(jeCode);
  //         } else if (docType !== DOC_TYPE.NORMAL_JE) {
  //           if (data.cellIndex === 2) {
  //             loadNormalJEDetails(jeCode);
  //           }
  //         } else {
  //           loadNormalJEDetails(jeCode);
  //         }
  //       }
  //     }
  //   };

  const exportDocumentBL = (format: string) => {
    AccountingService.getInstance()
      .exportBalanceSheet(format, {
        cmode: reportFilterState.ComparisonMode,
        basis: reportFilterState.Basis.value,
        presetInfo: reportFilterState.PresetInfo,
        filteredData: exportPayload,
        StartDate: getDateStrFromDate(
          reportFilterState.StartDate,
          DATE_FORMATS['DD-MM-YYYY']
        ),
        EndDate: getDateStrFromDate(
          reportFilterState.EndDate,
          DATE_FORMATS['DD-MM-YYYY']
        )
      })
      .then((res: any) => setIsExporting(false))
      .catch((err: any) => {
        showToast('Could Not Export Report', TOAST_TYPE.FAILURE);
      });
  };

  const parsedDataForPNL = () => {
    let style = {
      width: 170,
      textAlign: 'left',
      justifyContent: 'center',
      alignItems: 'center'
    };
    const columns = [
      {
        title: 'Document Code',
        style: {
          ...style,
          width: 190
        },
        type: 'bold-lines'
      },
      {
        title: 'Document Type',
        style,
        type: 'bold-lines'
      },
      {
        title: 'JE Code',
        style,
        type: 'bold-lines'
      },
      {
        title: 'Contact',
        style: {
          ...style,
          width: 190
        },
        type: 'bold-lines'
      },
      {
        title: 'Date',
        style: {
          ...style,
          width: 100
        },
        type: 'bold-lines'
      },
      {
        title: 'Amount',
        style: {
          ...style,
          textAlign: 'right'
        },
        type: 'bold-lines'
      },
      {
        title: 'Balance',
        style: {
          ...style,
          textAlign: 'right',
          width: 130
        },
        type: 'bold-lines'
      }
    ];
    let data = getParsedDataForBLDetails(
      reportData,
      reportFilterState,
      columns
    );

    if (data) {
      data['payload']['columns'] = columns;
      setPasser(data.payload);
      setLengthOfCols(data.length);
    }
  };

  const fetchReportDetails = (payload: any) => {
    if (!isEmpty(payload)) {
      setTableLoader(true);
      AccountingService.getInstance()
        .getBalanceSheetReport(payload)
        .then((res) => {
          let data: any = { ...res };
          setReportData({ ...data });
          setTableLoader(false);
        })
        .catch((err) => {
          showToast('Error loading report data', TOAST_TYPE.FAILURE);
          setTableLoader(false);
        });
    }
  };

  const runReport = (state: ReportHeaderState) => {
    const updatedState = reportFilterState;

    if (state.date.currentValue) {
      dateFilterLogic(state.date, updatedState);
    }

    if (state.accountingMethod.currentValue) {
      if (state.accountingMethod.currentValue === REPORT_BASIS.CASH) {
        accountingMethod(
          state.accountingMethod.currentValue,
          'Cash',
          updatedState
        );
      } else {
        accountingMethod(
          state.accountingMethod.currentValue,
          'Accural',
          updatedState
        );
      }
    }
    if (state.showCustomFilterPopup) {
      updatedState.showCustomFilterPopup = state.showCustomFilterPopup;
    }

    updatedState.CurrentDateRange = state.date.currentValue;
    const payload = generatePayload(updatedState);
    setReportFilterState({ ...updatedState });
    setExportPayload(payload);
    fetchReportDetails(payload);
  };

  const newHeader = () => {
    return (
      <AccountingReportHeader
        columnBy={{
          name: '',
          isHidden: true,
          type: REPORT_HEADER_ITEMS.COLUMN_BY,
          dataOptions: [],
          currentValue: reportFilterState.ColumnBy
        }}
        hasCustomField={true}
        hideToggle={true}
        toggle={(state: any) => {}}
        taxAgency={{
          name: '',
          isHidden: true,
          type: REPORT_HEADER_ITEMS.TAX_AGENCY,
          dataOptions: [],
          currentValue: null
        }}
        comparisionMode={{
          name: '',
          isHidden: true,
          type: REPORT_HEADER_ITEMS.COMPARISION_MODE,
          dataOptions: [],
          currentValue: reportFilterState.ComparisonMode
        }}
        accountingMethod={{
          name: '',
          isHidden: false,
          type: REPORT_HEADER_ITEMS.ACCOUNTING_METHOD,
          dataOptions: [...comparisionDataOptions],
          currentValue: null
        }}
        dateRange={{
          startDate: '',
          isHidden: false,
          endDate: '',
          dataOptions: [...dateRangeOptions],
          currentValue: reportFilterState.CurrentDateRange
        }}
        showDirect={false}
        runReport={(state) => {
          runReport(state);
        }}
        customize={{
          isHidden: false
        }}
        customizePopup={(state: any) => {
          setShowPopup(true);
        }}
        reportType={'Balance Sheet Details Report'}
        showFilter={false}
      />
    );
  };

  const replaceNames = (data: any) => {
    for (let keys in data) {
      if (keys !== 'reportName' && keys !== 'tenantName') {
        let rows = replaceNameState(passer.rows, keys, data[keys]);
        let updatedState = passer;
        updatedState.rows = rows;
        setPasser({ ...updatedState });
      } else {
        if (keys === 'reportName') {
          setReportName(data[keys]);
        } else {
          setTenantName(data[keys]);
        }
      }
    }
  };

  const documentCodeBlockStyle = (
    details: any,
    styles: any,
    detailsSupportingDocTypes: string[]
  ) => {
    let updatedStyle = { ...styles };
    if (!details.documentSequenceCode) {
      updatedStyle.textDecoration = 'none';
      updatedStyle.marginLeft = 30;
    } else {
      if (detailsSupportingDocTypes.includes(details.documentType)) {
        updatedStyle.marginLeft = 30;
      } else {
        updatedStyle.marginLeft = 30;
        updatedStyle.textDecoration = 'none';
        updatedStyle.cursor = 'auto';
      }
    }
    return updatedStyle;
  };

  const newReportBody = () => {
    return (
      <AccountingReportBody
        reportData={passer}
        reportName={reportName}
        filterDate={reportFilterState}
        tenantName={tenantName}
        templateName="template"
        footerText={getTimeStamp(reportFilterState.Basis.label)}
        onEmailClick={() => {
          if (!isLoading) {
            setIsLoading(true);
            showLoader("Generating PDF...");
            let pdf = new PdfUtility();
            const element = document.getElementById('template');
            pdf.getPDFUrlForEmail(element, 'Balance Sheet').then((res: any) => {
              setIsLoading(false);
              removeLoader();
              setShowEmailPopup(true);
              setPdfUrl(res);
            }).catch((err: any) => {
              removeLoader();
            });
          }
        }}
        onExportClick={() => {
          if (!isExporting) {
            setIsExporting(true);
            exportDocumentBL('XLS');
          }
        }}
        onPrintClick={() => {
          const element = document.getElementById('template');
          //add condition for landscape/potrait
          if (lengthOfCols >= 4) {
            PdfUtility.printLandscapeHtml(element, true);
          } else {
            PdfUtility.printLandscapeHtml(element, false);
          }
        }}
        onCellClick={(data) => {}}
        onRowExpand={async (data: any) => {
          let detailsData: any = [];
          if (isEmpty(data.rowData.totalValues)) {
            return;
          }

          data.rowData.cells.forEach((cell: any, index: any) => {
            if (index === 5 && data.rowData.children.length > 0) {
              if (
                isEmpty(data.rowData.totalValues.expanded) &&
                data.rowData.expanded
              ) {
                data.rowData.cells[index].title = ' ';
              } else if (
                isEmpty(data.rowData.totalValues.collapsed) &&
                data.rowData.collapsed
              ) {
                data.rowData.cells[index].title = ' ';
              } else {
                if (data.rowData.cells[index].title.indexOf('(') !== -1) {
                  data.rowData.cells[index].style = {
                    ...data.rowData.cells[index].style,
                    color: 'red'
                  };
                } else {
                  data.rowData.cells[index].style = {
                    ...data.rowData.cells[index].style,
                    color: 'black'
                  };
                }

                data.rowData.cells[index].title = data.rowData.expanded
                  ? data.rowData.totalValues.expanded[`Period_1`] !== undefined
                    ? getAmountBlockWithCurrency(
                        data.rowData.totalValues.expanded[`Period_1`]
                      )
                    : ' '
                  : data.rowData.totalValues.collapsed[`Period_1_withChild`] !==
                    undefined
                  ? getAmountBlockWithCurrency(
                      data.rowData.totalValues.collapsed[`Period_1_withChild`]
                    )
                  : getAmountBlockWithCurrency(
                      data.rowData.totalValues.expanded[`Period_1`]
                    );
              }

              if (
                data.rowData.cells[index].title === ' ' &&
                data.expanded === false &&
                data?.rowData?.totalValues?.details?.documentCode !==
                  'Opening Balance'
              ) {
                data.rowData.cells[index].title = data.rowData.totalValues
                  .expanded[`Period_1`]
                  ? getAmountBlockWithCurrency(
                      data.rowData.totalValues.expanded[`Period_1`]
                    )
                  : data.rowData.totalValues.collapsed[`Period_1_withChild`]
                  ? getAmountBlockWithCurrency(
                      data.rowData.totalValues.collapsed[`Period_1_withChild`]
                    )
                  : '';
              }
            }
          });
          let query = `?fromDate=${getDateStrFromDate(
            reportFilterState.StartDate,
            DATE_FORMATS['DD-MM-YYYY']
          )}&toDate=${getDateStrFromDate(
            reportFilterState.EndDate,
            DATE_FORMATS['DD-MM-YYYY']
          )}&account=${data.rowData.totalValues.accountCode}&customfield=${
            isEmpty(customFilter) ? [] : customFilter
          }`;

          if (reportFilterState.Basis.label === 'Accrual') {
            await AccountingService.getInstance()
              .getReportCashDetails(query)
              .then((res) => {
                detailsData = res;
              })
              .catch((err) => {
                showToast('Error loading report data', TOAST_TYPE.FAILURE);
              });
          } else {
            let query = `?fromDate=${getDateStrFromDate(
              reportFilterState.StartDate,
              DATE_FORMATS['DD-MM-YYYY']
            )}&toDate=${getDateStrFromDate(
              reportFilterState.EndDate,
              DATE_FORMATS['DD-MM-YYYY']
            )}&basis=${reportFilterState.Basis.value}&account=${
              data.rowData.totalValues.accountCode
            }&customfield=${isEmpty(customFilter) ? [] : customFilter}`;
            if (data.rowData.children.length > -1) {
              await AccountingService.getInstance()
                .getReportCashDetails(query)
                .then((res) => {
                  detailsData = res;
                })
                .catch((err) => {
                  showToast('Error loading report data', TOAST_TYPE.FAILURE);
                });
            }
          }
          if (data.rowData.expandedOnce === false) {
            data.rowData.expandedOnce = true;
            const detailsSupportingDocTypes: string[] = [
              //   DOC_TYPE.INVOICE,
              //   DOC_TYPE.BILL,
              //   DOC_TYPE.MAKE_PAYMENT,
              //   DOC_TYPE.RECEIVE_PAYMENT,
              //   DOC_TYPE.CREDIT_NOTE,
              //   DOC_TYPE.DEBIT_NOTE,
              //   DOC_TYPE.NORMAL_JE
            ];
            const style = {
              textDecoration: 'underline',
              cursor: 'pointer'
            };
            detailsData.reverse();
            detailsData.forEach((details: any, index: any) => {
              data.rowData.totalValues.details = details;
              const valueCell = [
                {
                  title: details.documentSequenceCode
                    ? details.documentSequenceCode
                    : '-',
                  style: documentCodeBlockStyle(
                    details,
                    style,
                    detailsSupportingDocTypes
                  ),
                  type: ''
                },
                {
                  title: details.documentType
                    ? convertInTitleCase(
                        details.documentType.replace(/_/g, ' ')
                      )
                    : '-',
                  style: detailsSupportingDocTypes.includes(
                    details.documentType
                  )
                    ? style
                    : {},
                  type: ''
                },
                {
                  title: details.jeCode ? details.jeCode : '-',
                  type: ''
                },
                {
                  title: details.contactName ? details.contactName : '-',
                  type: ''
                },
                {
                  title: details.date
                    ? getFormattedDateString(
                        details.date,
                        DATE_FORMATS['YYYY-MM-DD']
                      )
                    : '-',
                  type: ''
                },
                {
                  title: details.amount
                    ? getAmountBlockWithCurrency(Number(details.amount))
                    : '-',
                  type: '',
                  style: {
                    color: Number(details.amount) >= 0 ? 'black' : 'red'
                  }
                },
                {
                  title: details.balance
                    ? getAmountBlockWithCurrency(Number(details.balance))
                    : '-',
                  type: '',
                  style: {
                    color: Number(details.balance) >= 0 ? 'black' : 'red'
                  }
                }
              ];
              if (data?.rowData?.cells?.[0].title === 'Current Year Earnings') {
                if (details.documentCode !== 'Opening Balance') {
                  data.rowData.children.unshift({
                    cells: valueCell,
                    expanded: false,
                    children: [],
                    accountCode: '',
                    details: details
                  });
                }
              } else {
                data.rowData.children.unshift({
                  cells: valueCell,
                  expanded: false,
                  children: [],
                  accountCode: '',
                  details: details
                });
              }
            });
            setPasser({ ...passer });
          }
        }}
        showLoading={tableLoader}
      />
    );
  };

  return (
    <div className="column full-screen-width parent-width flex-1 text-13 bg-gray1 main-holder-padding padding-bottom-50">
      <div className="row align-items-center justify-content-between">
        {showEmailPopUp && getEmailPopUp()}
      </div>
      {newHeader()}

      <div className="column parent-width align-items-start p-h-s">
        {newReportBody()}
      </div>

      {showPopup && (
        <CustomizePopup
          populateFormData={nameArray}
          reportHeaders={[tenantName, reportName]}
          onCancel={() => {
            setShowPopup(false);
          }}
          onSave={(data: any) => {
            setShowPopup(false);
            replaceNames(data[0]);

            setReportName(data[1]);
            setTenantName(data[2]);
          }}
        />
      )}
    </div>
  );
}

import React, { Component } from 'react'
import RouteManager from '../../managers/RouteManager';
import { connect, ConnectedProps } from 'react-redux';
import { fetchReportsByPage } from '../../store/slices/reportSlice'
import ReportService from '../../services/reports/Report';
import ReportHolder from './ReportHolder';
import { debounce, isEmpty } from '../../utilities/Common';
import { APPS } from '../../constants/Constant';

class Starred extends Component<PropsFromRedux, any> {
    constructor(props: any) {
        super(props)
        this.state = {
            reports: [],
            appName: ''
        }

    }
    componentDidMount() {
        RouteManager.setPresenter(this);
        this.getAllReports();
    }
    getAllReports = (appName = '') => {
        let queryParams:any = {query: 'favourite=true'};
        if (!isEmpty(appName) || !isEmpty(this.state.appName)) {
            queryParams.appName = !isEmpty(appName) ? appName : this.state.appName;
        }
        if (!isEmpty(this.state.searchText)) {
            queryParams.search = this.state.searchText
        }
        if (!isEmpty(this.state.selectedUser)) {
            queryParams.query+= `,userId=${this.state.selectedUser?.userId}`
        }
        this.props.fetchReportsByPage(queryParams).then((response: any) => {
            this.setState({ reports: response?.payload?.content });
        })
    }
    onSearch = (searchText: any) => {
        this.setState({ searchText }, this.callDebounce)
    }
    callDebounce = debounce(() => this.getAllReports(), 300);
    render() {
        const apps = APPS.map(app => app.code);
        return (
            <ReportHolder
                headerName={'Starred Reports'}
                reports={this.state.reports}
                isStarredReport={true}
                onDelete={() => this.getAllReports()}
                onUpdate={() => this.getAllReports()}
                onAppSelection={(index: number) => { this.setState({ appName: index === 0 ? '' : apps[index] }, () => this.getAllReports(index === 0 ? '' : apps[index])) }}
                onOwnerSelection={(user:any)=> this.setState({selectedUser:user},()=>this.getAllReports())}
                onSearch={(searchText: any) => this.onSearch(searchText)}
            />
        )
    }
}
const mapStateToProps = (state: any) => ({});
const mapDispatchToProps = { fetchReportsByPage };
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Starred);

import React, { Component } from "react";

// import { DKIcons } from "../Constants/Icons";
// import DKIcon from "../DKIcon";
// import DKLabel from "../DKLabel";
// import DKSpinner from "../DKSpinner";
// import DKListPicker from "../DKListPicker";
import DKAddTenant from "./DKAddTenant";

import { DKIcon, DKIcons, DKLabel, DKSpinner, DKListPicker, DKLine } from "deskera-ui-library";
import { connect } from "react-redux";

class DKTenantController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showListPicker: false,
      showAddOrgPopup: false,
      allowTenantSelection:
        this.props.allowTenantSelection !== null &&
          this.props.allowTenantSelection !== undefined
          ? this.props.allowTenantSelection
          : true,
    };
  }

  setShowListPicker(visible) {
    if (this.state.allowTenantSelection) {
      this.setState({
        showListPicker: visible,
      });
    }
  }
  setShowAddOrgPopup(visible) {
    if (this.state.allowTenantSelection) {
      this.setState({
        showAddOrgPopup: visible,
      });
    }
  }

  render() {
    const reportCount = this.props.reports?.content?.length || 0;
    return (
      <>
        <div
          className="row cursor-hand border-radius-m p-h-m p-v-s justify-content-left"
          style={{
            pointerEvents: this.state.showListPicker ? "none" : "all",
          }}
          onClick={() => this.setShowListPicker(true)}
        >
          <div className="column  bg-white parent-width border-m border-radius-l p-m shadow-s-2 mt-xxl">
              <div className="row justify-content-between">
            {this.props.isMenuExpanded && (
                <DKLabel
                  text={this.props.selectedTenantDetails?.tenantName || '-'}
                  className="fw-m fs-m text-blue"
                />
                )}
                {this.state.allowTenantSelection &&
                  this.props.tenantList.length > 0 &&
                  this.props.isMenuExpanded ? (
                  <DKIcon
                    src={DKIcons.ic_arrow_down}
                    className="ic-xs"
                    style={{ opacity: 0.7 }}
                  />
                ) : (
                  this.props.isMenuExpanded &&
                  this.state.allowTenantSelection && (
                    <DKSpinner isWhite={true} iconClassName="ic-s-2" />
                  )
                )}
              </div>

            <DKLine className="m-v-s" />
            <DKLabel text={`${reportCount} Reports`} className="text-grays" />
          </div>
        </div>
        {this.state.showListPicker && this.props.tenantList?.length && (
          <div
            className="position-absolute z-index-5"
            style={{ top: 135, left: 13, width: 205 }}
          >
            <DKListPicker
              className="shadow-l border-radius-m"
              iconClassName="bg-gray1 border-m"
              title={this.props.tenantPickerTitle || "My Organizations"}
              data={this.props.tenantList.map((item) => item.tenantName)}
              onSelect={(index, value) => {
                this.setShowListPicker(false);
                this.props.onTenantSelect?.(this.props.tenantList[index]);
              }}
              onClose={() => this.setShowListPicker(false)}
              needIcon={true}
              button={
                this.props.allowAddTenant
                  ? {
                    title: this.props.tenantPickerButtonTitle || "+ Add Organization",
                    className: "bg-button text-white",
                    onClick:
                      this.props.onAddNewTenantTapped ||
                      (() => this.setShowAddOrgPopup(true)),
                  }
                  : null
              }
            />
          </div>
        )}
        {this.state.showAddOrgPopup ? (
          <DKAddTenant
            onCancel={() => {
              this.setShowAddOrgPopup(false);
            }}
            onSaveNewTenantTapped={(newTenantName) => {
              this.setShowAddOrgPopup(false);
              this.props.onSaveNewTenantTapped(newTenantName);
            }}
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  reports: state.reports.reports
});


export default connect(mapStateToProps, null)(DKTenantController);
import { DKButton, DKInput, DKLabel, showAlert } from 'deskera-ui-library';
import React, { useEffect, useState } from 'react';
import { isEmpty } from '../../utilities/Common';
import Popup from '../common/Popup';

const CustomizePopup = (props: any) => {
  const [popupState, setPopupState] = useState<any>();
  const [name, setName] = useState<any>(props.reportHeaders[1]);
  const [tenantName, setTenantName] = useState<any>(props.reportHeaders[0]);

  useEffect(() => {
    let obj: any = {};
    if (props.populateFormData) {
      props.populateFormData.forEach((row: any) => {
        obj[row] = row;
      });
    }

    setPopupState(obj);
  }, []);

  useEffect(() => {}, [popupState]);

  const validate = (popupState: any) => {
    let arr: any = [];
    for (let key in popupState) {
      if (arr.length && arr.includes(popupState[key])) {
        return false;
      } else {
        arr.push(popupState[key]);
      }
    }
    return true;
  };

  return (
    <Popup
      popupWindowStyles={{
        minWidth: 445,
        maxHeight: '90%',
        padding: 0,
        paddingBottom: 24,
        overflowY: 'auto'
      }}
    >
      <div className="row parent-width justify-content-between p-h-r p-v-s bg-gray1">
        <DKLabel
          text={'Customize Report'}
          className="parent-width  fw-b fs-m"
          style={{ minWidth: 280 }}
        />
        <div className="row width-auto parent-width">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={() => {
              props.onCancel();
            }}
          />
          <DKButton
            title={'Save'}
            className="bg-button text-white"
            onClick={() => {
              if (!validate(popupState)) {
                showAlert('Error', 'Names cannot be same');
              } else props.onSave([popupState, name, tenantName]);
              //props.onEdit(payload);
            }}
          />
        </div>
      </div>
      <div className="column p-l">
        <div className="row">
          <DKLabel
            text={'Report Header'}
            className="parent-width "
            style={{ minWidth: 200 }}
          />
          <DKInput
            placeholder={''}
            value={tenantName}
            canValidate={false}
            errorMessage={'Name cannot be empty'}
            onChange={(value: any) => {
              setTenantName(value);
            }}
          />
        </div>

        <div className="row parent-width">
          <DKLabel
            text={'Report Sub Header'}
            className="parent-width "
            style={{ minWidth: 200 }}
          />
          <div className="parent-width mt-m" style={{ minWidth: 200 }}>
            <DKInput
              placeholder={''}
              value={name}
              canValidate={false}
              errorMessage={'Name cannot be empty'}
              onChange={(value: any) => {
                setName(value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="column p-h-l pb-l">
        <div className="row  parent-width">
          <DKLabel
            text={'Account Name'}
            className="parent-width  fw-b"
            style={{ minWidth: 200 }}
          />
          <DKLabel
            text={'Customize Label'}
            className="parent-width  fw-b"
            style={{ minWidth: 200 }}
          />
        </div>
        {!isEmpty(popupState) &&
          props.populateFormData.map((row: any) => {
            return (
              <div className="row parent-width mt-m">
                <DKLabel
                  text={row}
                  className="parent-width "
                  style={{ minWidth: 200 }}
                />
                {popupState && !isEmpty(popupState[row]) && (
                  <div className="parent-width" style={{ minWidth: 200 }}>
                    <DKInput
                      placeholder={''}
                      value={popupState[row]}
                      canValidate={false}
                      errorMessage={'Name cannot be empty'}
                      onChange={(value: any) => {
                        let obj = popupState;
                        obj[row] = value;
                        if (isEmpty(value.trim())) {
                          showAlert('Error', 'Name Cannot be empty');
                        }
                        setPopupState(obj);
                      }}
                    />
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </Popup>
  );
};

export default CustomizePopup;

import { INPUT_TYPE } from 'deskera-ui-library';

export enum TRIAL_BALANCE_COLUMN_CODE {
  AC_CODE = 'code',
  AC_NAME = 'name',
  AC_GROUP = 'group',
  DEBIT = 'debitAmount',
  CREDIT = 'creditAmount'
}
export enum TRIAL_BALANCE_DETAIL_COLUMN_CODE {
  AC_CODE = 'accountCode',
  AC_NAME = 'accountName',
  AC_GROUP = 'accountGroup',
  DEBIT = 'debitAmount',
  CREDIT = 'creditAmount'
}
export enum TRIAL_BALANCE_DETAIL_COLUMN_NAME {
  'Account Code' = 'accountCode',
  'Account Name' = 'accountName',
  'Account Group' = 'accountGroup',
  'Debit' = 'debitAmount',
  'Credit' = 'creditAmount'
}

export const TrialBalanceColumnConfig: any[] = [
  {
    name: 'Account Code',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: null,
    required: false,
    width: 250,
    editable: false,
    columnCode: 'code',
    key: 'code',
    id: 'code',
    systemField: true,
    uiVisible: true
  },
  {
    name: 'Account Name',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: null,
    required: false,
    width: 300,
    editable: false,
    columnCode: 'name',
    key: 'name',
    id: 'name',
    systemField: true,
    uiVisible: true
  },
  {
    name: 'Account Group',
    type: INPUT_TYPE.TEXT,
    index: 2,
    options: null,
    required: false,
    width: 250,
    editable: false,
    columnCode: 'group',
    key: 'group',
    id: 'group',
    systemField: true,
    uiVisible: true
  },
  {
    name: 'Debit',
    type: INPUT_TYPE.NUMBER,
    index: 3,
    options: null,
    required: false,
    width: 250,
    editable: false,
    textAlign: 'right',
    columnCode: 'debitAmount',
    key: 'debitAmount',
    id: 'debitAmount',
    systemField: true,
    uiVisible: true
  },
  {
    name: 'Credit',
    type: INPUT_TYPE.NUMBER,
    index: 4,
    options: null,
    required: false,
    width: 250,
    editable: false,
    textAlign: 'right',
    columnCode: 'creditAmount',
    key: 'creditAmount',
    id: 'creditAmount',
    systemField: true,
    uiVisible: true
  }
];

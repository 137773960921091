import React, { Component } from 'react';
import { DKButton, DKLabel, showAlert } from 'deskera-ui-library';
import ApiConstants from '../../constants/ApiConstant';
import { isEmpty, isValidEmail } from '../../utilities/Common';
import AccountingService from '../../services/reports/Accounting';

export default class EmailComposerPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentData: this.props.documentData,
      emailAddress: '',
      messageString: '',
      loading: false,
      subject: '',
      pdfURL: this.props.pdfURL,
      isMobile: this.props.isMobile,
      reportTitle: this.props.reportTitle
    };
    setTimeout(() => {
      this.createMessage(this.props.reportTitle, this.props.documentData);
    }, 100);
  }

  componentWillReceiveProps(nextProp) {
    this.setState(
      {
        documentData: this.props.documentData,
        reportTitle: nextProp.reportTitle,
        pdfURL: nextProp.pdfURL,
        isMobile: nextProp.isMobile
      },
      () => {}
    );
  }

  render() {
    return (
      <div
        className="transparent-background"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      >
        <div
          className={'popup-window border-m border-radius-m'}
          style={{
            overflow: 'hidden',
            paddingBottom: 20,
            paddingTop: 25,
            borderRadius: 8
          }}
        >
          {this.getEmailPopup()}
        </div>
      </div>
    );
  }

  addTitleLabel(title) {
    return (
      <div className="row">
        <div className="column">
          <DKLabel className="fs-l fw-m" text={title} />
        </div>
      </div>
    );
  }
  getEmailPopup() {
    return (
      <div className="column parent-size bg-white ">
        {this.addTitleLabel('Share on Email')}
        <div style={{ height: 20 }}></div>
        {this.getTitleAndInputField(
          'email',
          'To',
          this.state.emailAddress,
          "Enter recipient's email address",
          (value) => {
            this.setState({
              emailAddress: value
            });
          }
        )}
        {this.getTitleAndInputField(
          'text',
          'Subject',
          this.state.subject,
          'Enter subject here',
          (value) => {
            this.setState({
              subject: value
            });
          }
        )}
        <div style={{ height: 20 }}></div>
        {this.state.loading ? (
          <div className="row-reverse parent-width justify-content-between">
            {this.getButton(
              ' Sending ... ',
              'bg-button text-white',
              'white',
              () => {}
            )}
          </div>
        ) : (
          <div className="row-reverse parent-width">
            {this.getButton(
              'Send',
              'bg-button text-white',
              'white',
              this.sendButtonTapped
            )}
            <div style={{ width: 12 }}></div>
            {this.getButton(
              'Cancel',
              'bg-gray2',
              'black',
              this.cancelButtonTapped
            )}
          </div>
        )}
      </div>
    );
  }

  getButton(title, backGroundColor, textColor, action) {
    return (
      <DKButton
        className={backGroundColor}
        color={textColor}
        title={title}
        onClick={action}
      />
    );
  }

  getTitleAndInputField(type, title, value, placeholder, onChange) {
    return (
      <div className="column" style={{ whiteSpace: 'nowrap', width: '100%' }}>
        <div
          className="row"
          style={{ whiteSpace: 'nowrap', alignItems: 'center' }}
        >
          <DKLabel className="fontStyleBold" text={title} />
          <div
            style={{
              width: '100%',
              padding: 10,
              marginRight: 10
            }}
          >
            <input
              className="fontStyle border-box fs-r"
              type={type}
              placeholder={placeholder}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              value={value}
              id="myInput"
              style={{ outline: 'none', width: '100%', border: 'none' }}
            />
          </div>
        </div>
        <div
          style={{
            width: '100%',
            height: 1,
            backgroundColor: 'rgb(230,230,230)',
            opacity: 1
          }}
        />
      </div>
    );
  }

  getArticle(type) {
    if (type.toLowerCase() === 'bill' || type.toLowerCase() === 'quotation') {
      return 'a';
    }
    return 'an';
  }

  createMessage(reportTitle, documentData) {
    if (reportTitle === undefined) {
      return;
    }
    // let documentURL = Utility.getSharableURL(reportTitle);
    let messageString =
      'Hi,' +
      '<br>' +
      '<br>' +
      'Please find the attached ' +
      reportTitle +
      ' report.' +
      '<br>' +
      '<br>' +
      '<< This amazing looking report was crafted with <a href="' +
      ApiConstants.TEMPLATE.DESKERA_URL +
      '">' +
      ' Deskera</a>. You can <a href="' +
      ApiConstants.TEMPLATE.LANDING_PAGE_URL +
      '">' +
      ' create one too for FREE!' +
      '</a> >> ';
    let subject = 'Report - ' + reportTitle;
    this.setState({
      messageString: messageString,
      subject: subject
    });
  }

  validateAndParseData(formData) {
    const requestData = new FormData();
    requestData.append('body', formData.body);
    let emails = formData.recipientEmail.split(',');
    for (let i = 0; i < emails.length; i++) {
      if (!isValidEmail(emails[i].trim())) {
        showAlert(
          'Validation failed',
          'Email address: ' + emails[i].trim() + ' is not valid.'
        );
        return false;
      }
      requestData.append('recipientEmail', emails[i].trim());
    }
    if (isEmpty(formData.subject)) {
      showAlert('Validation failed', 'Subject can not be empty.');
      return;
    }
    if (isEmpty(formData.subject)) {
      showAlert('Validation failed', 'Subject can not be empty.');
      return;
    }
    requestData.append('subject', formData.subject);
    requestData.append('attachmentName', formData.attachmentName);
    requestData.append('attachment', this.state.pdfURL);
    return requestData;
  }

  sendButtonTapped = () => {
    let fileName = this.state.reportTitle.replace(' ', '_').toLowerCase();
    let bodyData = {
      body: this.state.messageString,
      recipientEmail: this.state.emailAddress,
      subject: this.state.subject,
      attachmentName: fileName
    };

    let reqData = this.validateAndParseData(bodyData);

    if (reqData) {
      this.setState({
        loading: true
      });
      AccountingService.getInstance()
        .sendEmail(reqData)
        .then(
          (res) => {
            this.props.printingCompleted();
            this.setState({ loading: false });
          },
          (err) => {
            this.setState({ loading: false });
          }
        )
        .catch((err) => {
          this.setState({ loading: false });
        });
    }
  };

  emailSendWithStatue(success, message) {
    if (success) {
      this.setState({
        loading: false
      });
      this.props.removeEmailPopUp();
      alert(message);
    } else {
      alert(message);
      this.setState({
        loading: false
      });
    }
  }

  cancelButtonTapped = () => {
    this.props.removeEmailPopUp();
  };
}

import { PNL, PNL2, PNL3 } from '../constants/Constant';
import { store } from '../store/store';
import { isEmpty } from '../utilities/Common';
import { getDateFromString } from '../utilities/Date';
import {
  getAmountBlockWithCurrency,
  getParsedChildPNL,
  replaceLabelsForLiabilitiesAndAssets,
  getParsedSortedChildPNL,
  getParsedSortedChildPNLInBothCurrency,
  getParsedChildPNLDetails
} from './SharedParser';

const getTenantInfo = () => {
  return store.getState().tenant.tenantInfo;
};

export const getParsedDataForPNL = (
  reportData: any,
  reportFilterState: any,
  currency?: string
) => {
  const tenantInfo = getTenantInfo();

  if (isEmpty(reportData)) return;
  let width = 200;
  const len = Object.keys(reportData['periodName']).length;

  if (len <= 2) {
    width = 300;
  } else if (len <= 4) {
    width = 250;
  } else {
    width = 190;
  }

  let payload: any = {};
  payload['columns'] = [];
  payload['columns'].push({
    title: ' ',
    style: {
      width: width,

      textAlign: 'left',
      alignItems: 'center',
      justifyContent: 'center'
    },
    type: 'bold-lines'
  });

  // const ordered = Object.keys(reportData['periodName'])
  //   .sort()
  //   .reduce((obj: any, key) => {
  //     obj[key] = reportData['periodName'][key];
  //     return obj;
  //   }, {});

  // reportData['periodName'] = ordered;

  for (let key in reportData['periodName']) {
    const month = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    let dateArray = reportData['periodName'][key].split(' - ');
    let title =
      reportFilterState.ComparisonMode.id === 3
        ? ` ${
            month[
              new Date(
                getDateFromString(
                  dateArray[0].toString(),
                  tenantInfo?.dateFormat?.toUpperCase()
                )
              ).getMonth()
            ]
          } ${new Date(
            getDateFromString(
              dateArray[0].toString(),
              tenantInfo?.dateFormat?.toUpperCase()
            )
          ).getFullYear()}`
        : reportData['periodName'][key];
    if (
      !isEmpty(reportFilterState.Contact) ||
      !isEmpty(reportFilterState.Product)
    ) {
      payload['columns'].push({
        title: reportData['displayText'][key]
          ? reportData['displayText'][key]
          : ' ',
        style: {
          width: width,
          textAlign: 'right',
          justifyContent: 'center',
          alignItems: 'center'
        },
        type: 'bold-lines'
      });
    } else {
      payload['columns'].push({
        title: isEmpty(title) ? '' : title,
        style: {
          width: width,
          textAlign: 'right',
          justifyContent: 'center',
          alignItems: 'center'
        },
        type: 'bold-lines'
      });
    }
  }
  payload['rows'] = [];

  for (let key in reportData) {
    if (key === 'displayText' || key === 'groupNames') {
      continue;
    }
    let row: any = { expanded: true };

    row.cells = [];
    row.children = [];

    const text = key;
    const result = text.replace(/([A-Z])/g, ' $1');
    let finalText = result.charAt(0).toUpperCase() + result.slice(1);

    if (tenantInfo?.country === 'US') {
      finalText = replaceLabelsForLiabilitiesAndAssets(finalText);
    }

    let style: any = {
      fontWeight: 'normal',
      marginLeft: 0
    };
    let type = '';
    if (!PNL2[key] && key !== 'periodName') {
      //style.textAlign = 'left';
      if (PNL3[key]) {
        style = {
          ...style,
          fontWeight: '600'
        };
        type = 'bold-line';
      }

      row.cells.push({
        title: finalText,
        style: { ...style },
        type: type
      });
      let totalValues: any = { expanded: {}, collapsed: {} };
      let hasChild = true;

      if (Object.keys(reportData[key]).length === 0) {
        hasChild = false;
      }
      for (let period in reportData['periodName']) {
        if (PNL[key]) {
          totalValues.expanded[period] = undefined;
          totalValues.collapsed[`${period}_withChild`] =
            reportData[PNL[key]][`${period}`];
        }
        row = { ...row, totalValues: totalValues };

        let title = PNL[key]
          ? hasChild
            ? ' '
            : reportData[PNL[key]][period]
          : reportData[key][period];
        row.cells.push({
          title: PNL[key]
            ? hasChild
              ? ' '
              : getAmountBlockWithCurrency(
                  reportData[PNL[key]][period],
                  currency
                )
            : getAmountBlockWithCurrency(reportData[key][period], currency),
          style:
            !isNaN(title) && title < 0 ? { ...style, color: 'red' } : style,
          type: type
        });
      }
    }

    for (let child in reportData[key]) {
      let children: any = {
        expanded: true,
        cells: [],
        children: []
      };
      if (child.includes('Period_')) {
        continue;
      }
      let totalValues: any = {
        expanded: {},
        collapsed: {}
      };

      for (let keys in reportData['periodName']) {
        totalValues.expanded[keys] = reportData[key][child]['periodMap'][keys];
        totalValues.collapsed[`${keys}_withChild`] =
          reportData[key][child]['periodMap'][`${keys}_withChild`];
      }

      children = { ...children, totalValues: totalValues };
      children.cells.push({
        title: reportData[key][child]['name'],
        style: { style, paddingLeft: 6 }
      });
      for (let keys in reportData['periodName']) {
        if (Number(reportData[key][child]['periodMap'][`${keys}`]) < 0) {
          style = { ...style, color: 'red' };
        } else {
          style = { ...style, color: 'black' };
        }
        children.cells.push({
          title: !isNaN(reportData[key][child]['periodMap'][`${keys}`])
            ? getAmountBlockWithCurrency(
                reportData[key][child]['periodMap'][`${keys}`],
                currency
              )
            : ' ',
          style: { ...style }
        });
      }

      if (reportData[key][child]['itemDto'] !== null) {
        reportData[key][child]['itemDto'].forEach(
          (item: any, index: number) => {
            children.children.push(getParsedChildPNL(item, 15, reportData));
          }
        );
      }
      children.children = children.children.sort(function (a: any, b: any) {
        if (a.cells.length) {
          return a.cells[0]?.title?.localeCompare(b.cells[0]?.title);
        }
      });
      row.children.push(children);
    }

    row.children = row.children.sort(function (a: any, b: any) {
      if (a.cells.length) {
        return a.cells[0]?.title?.localeCompare(b.cells[0]?.title);
      }
    });
    // row = row.sort(function (a: any, b: any) {
    //   return a.name.localeCompare(b.name);
    // });

    if (key === 'income') {
      let children: any = {
        expanded: true,
        cells: [],
        children: []
      };
      children.cells.push({
        title: 'Total Income',
        style: {
          paddingLeft: 6
        },
        type: 'bold-line'
      });
      for (let keys in reportData['periodName']) {
        children.cells.push({
          title: getAmountBlockWithCurrency(
            reportData['totalIncome'][keys],
            currency
          ),

          type: 'bold-line'
        });
      }

      row.children.push(children);
    }
    if (key === 'costOfGoodsSold') {
      let children: any = {
        expanded: true,
        cells: [],
        children: []
      };

      children.cells.push({
        title: 'Total Cost of Goods Sold',
        style: {
          paddingLeft: 6
        },
        type: 'bold-line'
      });
      for (let keys in reportData['periodName']) {
        children.cells.push({
          title: getAmountBlockWithCurrency(
            reportData['totalCostOfGoodsSold'][keys],
            currency
          ),
          type: 'bold-line'
        });
      }

      row.children.push(children);
    }

    payload['rows'].push(row);
  }

  return { payload, length: len };
};

export const getSortedDataForPNL = (
  reportData: any,
  reportFilterState: any,
  direction: string,
  currency?: string
) => {
  const tenantInfo = getTenantInfo();

  if (isEmpty(reportData)) return;
  let width = 200;
  const len = Object.keys(reportData['periodName']).length;

  if (len <= 2) {
    width = 300;
  } else if (len <= 4) {
    width = 250;
  } else {
    width = 190;
  }

  let payload: any = {};
  payload['columns'] = [];
  payload['columns'].push({
    title: ' ',
    style: {
      width: width,

      textAlign: 'left',
      alignItems: 'center',
      justifyContent: 'center'
    },
    type: 'bold-lines'
  });

  const ordered = Object.keys(reportData['periodName'])
    .sort()
    .reduce((obj: any, key) => {
      obj[key] = reportData['periodName'][key];
      return obj;
    }, {});

  // reportData['periodName'] = ordered;

  for (let key in reportData['periodName']) {
    const month = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    let dateArray = reportData['periodName'][key].split(' - ');
    let title =
      reportFilterState.ComparisonMode.id === 3
        ? ` ${
            month[
              new Date(
                getDateFromString(
                  dateArray[0].toString(),
                  tenantInfo?.dateFormat?.toUpperCase()
                )
              ).getMonth()
            ]
          } ${new Date(
            getDateFromString(
              dateArray[0].toString(),
              tenantInfo?.dateFormat?.toUpperCase()
            )
          ).getFullYear()}`
        : reportData['periodName'][key];
    if (
      !isEmpty(reportFilterState.Contact) ||
      !isEmpty(reportFilterState.Product)
    ) {
      payload['columns'].push({
        title: reportData['displayText'][key]
          ? reportData['displayText'][key]
          : ' ',
        style: {
          width: width,
          textAlign: 'right',
          justifyContent: 'center',
          alignItems: 'center'
        },
        type: 'bold-lines'
      });
    } else {
      payload['columns'].push({
        title: isEmpty(title) ? '' : title,
        style: {
          width: width,
          textAlign: 'right',
          justifyContent: 'center',
          alignItems: 'center'
        },
        type: 'bold-lines'
      });
    }
  }
  payload['rows'] = [];

  for (let key in reportData) {
    if (key === 'displayText' || key === 'groupNames') {
      continue;
    }
    let row: any = { expanded: true };

    row.cells = [];
    row.children = [];

    const text = key;
    const result = text.replace(/([A-Z])/g, ' $1');
    let finalText = result.charAt(0).toUpperCase() + result.slice(1);

    if (tenantInfo?.country === 'US') {
      finalText = replaceLabelsForLiabilitiesAndAssets(finalText);
    }

    let style: any = {
      fontWeight: 'normal',
      marginLeft: 0
    };
    let type = '';
    if (!PNL2[key] && key !== 'periodName') {
      //style.textAlign = 'left';
      if (PNL3[key]) {
        style = {
          ...style,
          fontWeight: '600'
        };
        type = 'bold-line';
      }

      row.cells.push({
        title: finalText,
        style: { ...style },
        type: type
      });
      let totalValues: any = { expanded: {}, collapsed: {} };
      let hasChild = true;

      if (Object.keys(reportData[key]).length === 0) {
        hasChild = false;
      }
      for (let period in reportData['periodName']) {
        if (PNL[key]) {
          totalValues.expanded[period] = undefined;
          totalValues.collapsed[`${period}_withChild`] =
            reportData[PNL[key]][`${period}`];
        }
        row = { ...row, totalValues: totalValues };

        let title = PNL[key]
          ? hasChild
            ? ' '
            : reportData[PNL[key]][period]
          : reportData[key][period];
        row.cells.push({
          title: PNL[key]
            ? hasChild
              ? ' '
              : getAmountBlockWithCurrency(
                  reportData[PNL[key]][period],
                  currency
                )
            : getAmountBlockWithCurrency(reportData[key][period], currency),
          style:
            !isNaN(title) && title < 0
              ? { ...style, color: 'red' }
              : { ...style, color: 'black' },
          type: type,
          amount: PNL[key]
            ? reportData[PNL[key]][period]
            : reportData[key][period]
        });
      }
    }

    for (let child in reportData[key]) {
      let children: any = {
        expanded: true,
        cells: [],
        children: []
      };
      if (child.includes('Period_')) {
        continue;
      }
      let totalValues: any = {
        expanded: {},
        collapsed: {}
      };

      for (let keys in reportData['periodName']) {
        totalValues.expanded[keys] = reportData[key][child]['periodMap'][keys];
        totalValues.collapsed[`${keys}_withChild`] =
          reportData[key][child]['periodMap'][`${keys}_withChild`];
      }

      children = { ...children, totalValues: totalValues };
      children.cells.push({
        title: reportData[key][child]['name'],
        style: { style, paddingLeft: 6 }
      });
      for (let keys in reportData['periodName']) {
        if (Number(reportData[key][child]['periodMap'][`${keys}`]) < 0) {
          style = { ...style, color: 'red' };
        } else {
          style = { ...style, color: 'black' };
        }

        children.cells.push({
          title: !isNaN(reportData[key][child]['periodMap'][`${keys}`])
            ? getAmountBlockWithCurrency(
                reportData[key][child]['periodMap'][`${keys}`],
                currency
              )
            : ' ',
          style: { ...style },
          amount: !isNaN(
            reportData[key][child]['periodMap'][`${keys}_withChild`]
          )
            ? reportData[key][child]['periodMap'][`${keys}_withChild`]
            : reportData[key][child]['periodMap'][`${keys}`]
        });
      }

      if (reportData[key][child]['itemDto'] !== null) {
        reportData[key][child]['itemDto'].forEach(
          (item: any, index: number) => {
            children.children.push(
              getParsedSortedChildPNL(item, 15, reportData, direction)
            );
          }
        );
      }
      let newPayload: any = [];
      children.children.forEach((row: any) => {
        if (row.cells.length) {
          newPayload.push(row);
        }
      });
      if (direction === 'ASC') {
        children.children = newPayload.sort(
          (a: any, b: any) => a.cells[1].amount - b.cells[1].amount
        );
      } else {
        children.children = newPayload.sort(
          (a: any, b: any) => b.cells[1].amount - a.cells[1].amount
        );
      }

      row.children.push(children);
    }
    let newPayload: any = [];
    row.children.forEach((row: any) => {
      if (row.cells.length) {
        newPayload.push(row);
      }
    });
    if (direction === 'ASC') {
      row.children = newPayload.sort(
        (a: any, b: any) => a.cells[1].amount - b.cells[1].amount
      );
    } else {
      row.children = newPayload.sort(
        (a: any, b: any) => b.cells[1].amount - a.cells[1].amount
      );
    }

    // row = row.sort(function (a: any, b: any) {
    //   return a.name.localeCompare(b.name);
    // });

    if (key === 'income') {
      let children: any = {
        expanded: true,
        cells: [],
        children: []
      };
      children.cells.push({
        title: 'Total Income',
        style: {
          paddingLeft: 6
        },
        type: 'bold-line'
      });
      for (let keys in reportData['periodName']) {
        children.cells.push({
          title: getAmountBlockWithCurrency(
            reportData['totalIncome'][keys],
            currency
          ),

          type: 'bold-line'
        });
      }

      row.children.push(children);
    }
    if (key === 'costOfGoodsSold') {
      let children: any = {
        expanded: true,
        cells: [],
        children: []
      };

      children.cells.push({
        title: 'Total Cost of Goods Sold',
        style: {
          paddingLeft: 6
        },
        type: 'bold-line'
      });
      for (let keys in reportData['periodName']) {
        children.cells.push({
          title: getAmountBlockWithCurrency(
            reportData['totalCostOfGoodsSold'][keys],
            currency
          ),
          type: 'bold-line'
        });
      }

      row.children.push(children);
    }

    payload['rows'].push(row);
  }

  let newPayload: any = [];
  payload['rows'].forEach((row: any) => {
    if (row.cells.length) {
      newPayload.push(row);
    }
  });
  if (direction === 'ASC') {
    payload['rows'] = newPayload.sort(
      (a: any, b: any) => a.cells[1].amount - b.cells[1].amount
    );
  } else {
    payload['rows'] = newPayload.sort(
      (a: any, b: any) => b.cells[1].amount - a.cells[1].amount
    );
  }

  return { payload, length: len };
};

export const getParsedDataForPNLInBothCurrency = (
  reportData: any,
  reportFilterState: any,
  baseCurrency: string,
  primaryCurrency: string
) => {
  const tenantInfo = getTenantInfo();
  const getCurrency = (period: string) => {
    let col = parseInt(period.split('_')[1]);
    return col & 1 ? baseCurrency : primaryCurrency;
  };
  if (isEmpty(reportData)) return;
  let width = 200;
  const len = Object.keys(reportData['periodName']).length;

  if (len <= 2) {
    width = 300;
  } else if (len <= 4) {
    width = 250;
  } else {
    width = 190;
  }

  let payload: any = {};
  payload['columns'] = [];
  payload['columns'].push({
    title: ' ',
    style: {
      width: width,

      textAlign: 'left',
      alignItems: 'center',
      justifyContent: 'center'
    },
    type: 'bold-lines'
  });

  const ordered = Object.keys(reportData['periodName'])
    .sort()
    .reduce((obj: any, key) => {
      obj[key] = reportData['periodName'][key];
      return obj;
    }, {});

  // reportData['periodName'] = ordered;

  for (let key in reportData['periodName']) {
    const month = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    let dateArray = reportData['periodName'][key].split(' - ');
    let title =
      reportFilterState.ComparisonMode.id === 3
        ? ` ${
            month[
              new Date(
                getDateFromString(
                  dateArray[0].toString(),
                  tenantInfo.dateFormat.toUpperCase()
                )
              ).getMonth()
            ]
          } ${new Date(
            getDateFromString(
              dateArray[0].toString(),
              tenantInfo.dateFormat.toUpperCase()
            )
          ).getFullYear()}`
        : reportData['periodName'][key];
    if (
      !isEmpty(reportFilterState.Contact) ||
      !isEmpty(reportFilterState.Product)
    ) {
      payload['columns'].push({
        title: reportData['displayText'][key]
          ? reportData['displayText'][key]
          : ' ',
        style: {
          width: width,
          textAlign: 'right',
          justifyContent: 'center',
          alignItems: 'center'
        },
        type: 'bold-lines'
      });
    } else {
      payload['columns'].push({
        title: isEmpty(title) ? '' : title,
        style: {
          width: width,
          textAlign: 'right',
          justifyContent: 'center',
          alignItems: 'center'
        },
        type: 'bold-lines'
      });
    }
  }
  payload['rows'] = [];

  for (let key in reportData) {
    if (key === 'displayText' || key === 'groupNames') {
      continue;
    }
    let row: any = { expanded: true };

    row.cells = [];
    row.children = [];

    const text = key;
    const result = text.replace(/([A-Z])/g, ' $1');
    let finalText = result.charAt(0).toUpperCase() + result.slice(1);

    if (tenantInfo?.country === 'US') {
      finalText = replaceLabelsForLiabilitiesAndAssets(finalText);
    }

    let style: any = {
      fontWeight: 'normal',
      marginLeft: 0
    };
    let type = '';
    if (!PNL2[key] && key !== 'periodName') {
      //style.textAlign = 'left';
      if (PNL3[key]) {
        style = {
          ...style,
          fontWeight: '600'
        };
        type = 'bold-line';
      }

      row.cells.push({
        title: finalText,
        style: { ...style },
        type: type
      });
      let totalValues: any = { expanded: {}, collapsed: {} };
      let hasChild = true;

      if (Object.keys(reportData[key]).length === 0) {
        hasChild = false;
      }
      for (let period in reportData['periodName']) {
        if (PNL[key]) {
          totalValues.expanded[period] = undefined;
          totalValues.collapsed[`${period}_withChild`] =
            reportData[PNL[key]][`${period}`];
        }
        row = { ...row, totalValues: totalValues };

        let title = PNL[key]
          ? hasChild
            ? ' '
            : reportData[PNL[key]][period]
          : reportData[key][period];
        row.cells.push({
          title: PNL[key]
            ? hasChild
              ? ' '
              : getAmountBlockWithCurrency(
                  reportData[PNL[key]][period],
                  getCurrency(period)
                )
            : getAmountBlockWithCurrency(
                reportData[key][period],
                getCurrency(period)
              ),
          style:
            !isNaN(title) && title < 0 ? { ...style, color: 'red' } : style,
          type: type
        });
      }
    }

    for (let child in reportData[key]) {
      let children: any = {
        expanded: true,
        cells: [],
        children: []
      };
      if (child.includes('Period_')) {
        continue;
      }
      let totalValues: any = {
        expanded: {},
        collapsed: {}
      };

      for (let keys in reportData['periodName']) {
        totalValues.expanded[keys] = reportData[key][child]['periodMap'][keys];
        totalValues.collapsed[`${keys}_withChild`] =
          reportData[key][child]['periodMap'][`${keys}_withChild`];
      }

      children = { ...children, totalValues: totalValues };
      children.cells.push({
        title: reportData[key][child]['name'],
        style: { style, paddingLeft: 6 }
      });
      for (let keys in reportData['periodName']) {
        if (Number(reportData[key][child]['periodMap'][`${keys}`]) < 0) {
          style = { ...style, color: 'red' };
        } else {
          style = { ...style, color: 'black' };
        }
        children.cells.push({
          title: !isNaN(reportData[key][child]['periodMap'][`${keys}`])
            ? getAmountBlockWithCurrency(
                reportData[key][child]['periodMap'][`${keys}`],
                getCurrency(keys)
              )
            : ' ',
          style: { ...style }
        });
      }

      if (reportData[key][child]['itemDto'] !== null) {
        reportData[key][child]['itemDto'].forEach(
          (item: any, index: number) => {
            children.children.push(getParsedChildPNL(item, 15, reportData));
          }
        );
      }
      children.children = children.children.sort(function (a: any, b: any) {
        if (a.cells.length) {
          return a.cells[0]?.title?.localeCompare(b.cells[0]?.title);
        }
      });
      row.children.push(children);
    }

    row.children = row.children.sort(function (a: any, b: any) {
      if (a.cells.length) {
        return a.cells[0]?.title?.localeCompare(b.cells[0]?.title);
      }
    });
    // row = row.sort(function (a: any, b: any) {
    //   return a.name.localeCompare(b.name);
    // });

    if (key === 'income') {
      let children: any = {
        expanded: true,
        cells: [],
        children: []
      };
      children.cells.push({
        title: 'Total Income',
        style: {
          paddingLeft: 6
        },
        type: 'bold-line'
      });
      for (let keys in reportData['periodName']) {
        children.cells.push({
          title: getAmountBlockWithCurrency(
            reportData['totalIncome'][keys],
            getCurrency(keys)
          ),

          type: 'bold-line'
        });
      }

      row.children.push(children);
    }
    if (key === 'costOfGoodsSold') {
      let children: any = {
        expanded: true,
        cells: [],
        children: []
      };

      children.cells.push({
        title: 'Total Cost of Goods Sold',
        style: {
          paddingLeft: 6
        },
        type: 'bold-line'
      });
      for (let keys in reportData['periodName']) {
        children.cells.push({
          title: getAmountBlockWithCurrency(
            reportData['totalCostOfGoodsSold'][keys],
            getCurrency(keys)
          ),
          type: 'bold-line'
        });
      }

      row.children.push(children);
    }

    payload['rows'].push(row);
  }

  return { payload, length: len };
};

export const getSortedDataForPNLInBothCurrency = (
  reportData: any,
  reportFilterState: any,
  direction: string,
  baseCurrency: string,
  primaryCurrency: string
) => {
  const tenantInfo = getTenantInfo();

  const getCurrency = (period: string) => {
    let col = parseInt(period.split('_')[1]);
    return col & 1 ? baseCurrency : primaryCurrency;
  };
  if (isEmpty(reportData)) return;
  let width = 200;
  const len = Object.keys(reportData['periodName']).length;

  if (len <= 2) {
    width = 300;
  } else if (len <= 4) {
    width = 250;
  } else {
    width = 190;
  }

  let payload: any = {};
  payload['columns'] = [];
  payload['columns'].push({
    title: ' ',
    style: {
      width: width,

      textAlign: 'left',
      alignItems: 'center',
      justifyContent: 'center'
    },
    type: 'bold-lines'
  });

  const ordered = Object.keys(reportData['periodName'])
    .sort()
    .reduce((obj: any, key) => {
      obj[key] = reportData['periodName'][key];
      return obj;
    }, {});

  // reportData['periodName'] = ordered;

  for (let key in reportData['periodName']) {
    const month = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    let dateArray = reportData['periodName'][key].split(' - ');
    let title =
      reportFilterState.ComparisonMode.id === 3
        ? ` ${
            month[
              new Date(
                getDateFromString(
                  dateArray[0].toString(),
                  tenantInfo.dateFormat.toUpperCase()
                )
              ).getMonth()
            ]
          } ${new Date(
            getDateFromString(
              dateArray[0].toString(),
              tenantInfo.dateFormat.toUpperCase()
            )
          ).getFullYear()}`
        : reportData['periodName'][key];
    if (
      !isEmpty(reportFilterState.Contact) ||
      !isEmpty(reportFilterState.Product)
    ) {
      payload['columns'].push({
        title: reportData['displayText'][key]
          ? reportData['displayText'][key]
          : ' ',
        style: {
          width: width,
          textAlign: 'right',
          justifyContent: 'center',
          alignItems: 'center'
        },
        type: 'bold-lines'
      });
    } else {
      payload['columns'].push({
        title: isEmpty(title) ? '' : title,
        style: {
          width: width,
          textAlign: 'right',
          justifyContent: 'center',
          alignItems: 'center'
        },
        type: 'bold-lines'
      });
    }
  }
  payload['rows'] = [];

  for (let key in reportData) {
    if (key === 'displayText' || key === 'groupNames') {
      continue;
    }
    let row: any = { expanded: true };

    row.cells = [];
    row.children = [];

    const text = key;
    const result = text.replace(/([A-Z])/g, ' $1');
    let finalText = result.charAt(0).toUpperCase() + result.slice(1);

    if (tenantInfo?.country === 'US') {
      finalText = replaceLabelsForLiabilitiesAndAssets(finalText);
    }

    let style: any = {
      fontWeight: 'normal',
      marginLeft: 0
    };
    let type = '';
    if (!PNL2[key] && key !== 'periodName') {
      //style.textAlign = 'left';
      if (PNL3[key]) {
        style = {
          ...style,
          fontWeight: '600'
        };
        type = 'bold-line';
      }

      row.cells.push({
        title: finalText,
        style: { ...style },
        type: type
      });
      let totalValues: any = { expanded: {}, collapsed: {} };
      let hasChild = true;

      if (Object.keys(reportData[key]).length === 0) {
        hasChild = false;
      }
      for (let period in reportData['periodName']) {
        if (PNL[key]) {
          totalValues.expanded[period] = undefined;
          totalValues.collapsed[`${period}_withChild`] =
            reportData[PNL[key]][`${period}`];
        }
        row = { ...row, totalValues: totalValues };

        let title = PNL[key]
          ? hasChild
            ? ' '
            : reportData[PNL[key]][period]
          : reportData[key][period];
        row.cells.push({
          title: PNL[key]
            ? hasChild
              ? ' '
              : getAmountBlockWithCurrency(
                  reportData[PNL[key]][period],
                  getCurrency(period)
                )
            : getAmountBlockWithCurrency(
                reportData[key][period],
                getCurrency(period)
              ),
          style:
            !isNaN(title) && title < 0
              ? { ...style, color: 'red' }
              : { ...style, color: 'black' },
          type: type,
          amount: PNL[key]
            ? reportData[PNL[key]][period]
            : reportData[key][period]
        });
      }
    }

    for (let child in reportData[key]) {
      let children: any = {
        expanded: true,
        cells: [],
        children: []
      };
      if (child.includes('Period_')) {
        continue;
      }
      let totalValues: any = {
        expanded: {},
        collapsed: {}
      };

      for (let keys in reportData['periodName']) {
        totalValues.expanded[keys] = reportData[key][child]['periodMap'][keys];
        totalValues.collapsed[`${keys}_withChild`] =
          reportData[key][child]['periodMap'][`${keys}_withChild`];
      }

      children = { ...children, totalValues: totalValues };
      children.cells.push({
        title: reportData[key][child]['name'],
        style: { style, paddingLeft: 6 }
      });
      for (let keys in reportData['periodName']) {
        if (Number(reportData[key][child]['periodMap'][`${keys}`]) < 0) {
          style = { ...style, color: 'red' };
        } else {
          style = { ...style, color: 'black' };
        }

        children.cells.push({
          title: !isNaN(reportData[key][child]['periodMap'][`${keys}`])
            ? getAmountBlockWithCurrency(
                reportData[key][child]['periodMap'][`${keys}`],
                getCurrency(keys)
              )
            : ' ',
          style: { ...style },
          amount: !isNaN(
            reportData[key][child]['periodMap'][`${keys}_withChild`]
          )
            ? reportData[key][child]['periodMap'][`${keys}_withChild`]
            : reportData[key][child]['periodMap'][`${keys}`]
        });
      }

      if (reportData[key][child]['itemDto'] !== null) {
        reportData[key][child]['itemDto'].forEach(
          (item: any, index: number) => {
            children.children.push(
              getParsedSortedChildPNLInBothCurrency(
                item,
                15,
                reportData,
                direction,
                baseCurrency,
                primaryCurrency
              )
            );
          }
        );
      }
      let newPayload: any = [];
      children.children.forEach((row: any) => {
        if (row.cells.length) {
          newPayload.push(row);
        }
      });
      if (direction === 'ASC') {
        children.children = newPayload.sort(
          (a: any, b: any) => a.cells[1].amount - b.cells[1].amount
        );
      } else {
        children.children = newPayload.sort(
          (a: any, b: any) => b.cells[1].amount - a.cells[1].amount
        );
      }

      row.children.push(children);
    }
    let newPayload: any = [];
    row.children.forEach((row: any) => {
      if (row.cells.length) {
        newPayload.push(row);
      }
    });
    if (direction === 'ASC') {
      row.children = newPayload.sort(
        (a: any, b: any) => a.cells[1].amount - b.cells[1].amount
      );
    } else {
      row.children = newPayload.sort(
        (a: any, b: any) => b.cells[1].amount - a.cells[1].amount
      );
    }

    // row = row.sort(function (a: any, b: any) {
    //   return a.name.localeCompare(b.name);
    // });

    if (key === 'income') {
      let children: any = {
        expanded: true,
        cells: [],
        children: []
      };
      children.cells.push({
        title: 'Total Income',
        style: {
          paddingLeft: 6
        },
        type: 'bold-line'
      });
      for (let keys in reportData['periodName']) {
        children.cells.push({
          title: getAmountBlockWithCurrency(
            reportData['totalIncome'][keys],
            getCurrency(keys)
          ),

          type: 'bold-line'
        });
      }

      row.children.push(children);
    }
    if (key === 'costOfGoodsSold') {
      let children: any = {
        expanded: true,
        cells: [],
        children: []
      };

      children.cells.push({
        title: 'Total Cost of Goods Sold',
        style: {
          paddingLeft: 6
        },
        type: 'bold-line'
      });
      for (let keys in reportData['periodName']) {
        children.cells.push({
          title: getAmountBlockWithCurrency(
            reportData['totalCostOfGoodsSold'][keys],
            getCurrency(keys)
          ),
          type: 'bold-line'
        });
      }

      row.children.push(children);
    }

    payload['rows'].push(row);
  }

  let newPayload: any = [];
  payload['rows'].forEach((row: any) => {
    if (row.cells.length) {
      newPayload.push(row);
    }
  });
  if (direction === 'ASC') {
    payload['rows'] = newPayload.sort(
      (a: any, b: any) => a.cells[1].amount - b.cells[1].amount
    );
  } else {
    payload['rows'] = newPayload.sort(
      (a: any, b: any) => b.cells[1].amount - a.cells[1].amount
    );
  }

  return { payload, length: len };
};

export const getParsedDataForPNLDetails = (
  reportData: any,
  reportFilterState: any,
  columns: any[]
) => {
  if (isEmpty(reportData)) return;
  let width = 200;
  const len = Object.keys(reportData['periodName']).length;

  if (len <= 2) {
    width = 300;
  } else if (len <= 4) {
    width = 250;
  } else {
    width = 170;
  }

  let payload: any = {};
  payload['columns'] = [];
  payload['columns'].push({
    title: ' ',
    style: {
      width: width,

      textAlign: 'left',
      alignItems: 'center',
      justifyContent: 'center'
    },
    type: 'bold-lines'
  });
  const tenantInfo = getTenantInfo();
  for (let key in reportData['periodName']) {
    const month = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    let dateArray = reportData['periodName'][key].split(' - ');
    let title =
      reportFilterState?.ComparisonMode?.id === 3
        ? ` ${
            month[
              new Date(
                getDateFromString(
                  dateArray[0].toString(),
                  tenantInfo.dateFormat.toUpperCase()
                )
              ).getMonth()
            ]
          } ${new Date(
            getDateFromString(
              dateArray[0].toString(),
              tenantInfo.dateFormat.toUpperCase()
            )
          ).getFullYear()}`
        : reportData['periodName'][key];
    if (
      !isEmpty(reportFilterState.Contact) ||
      !isEmpty(reportFilterState.Product)
    ) {
      payload['columns'].push({
        title: reportData['displayText'][key]
          ? reportData['displayText'][key]
          : ' ',
        style: {
          width: width,
          textAlign: 'left',
          justifyContent: 'center',
          alignItems: 'center'
        },
        type: 'bold-lines'
      });
    } else {
      payload['columns'].push({
        title: isEmpty(title) ? '' : title,
        style: {
          width: width,
          textAlign: 'left',
          justifyContent: 'center',
          alignItems: 'center'
        },
        type: 'bold-lines'
      });
    }
  }
  payload['rows'] = [];

  // align amount to Amount column
  const alignAmountColumn = [];
  for (let i = 0; i < 4; i++) {
    alignAmountColumn.push({
      title: '',
      style: {
        paddingLeft: 6
      }
    });
  }

  for (let key in reportData) {
    if (key === 'displayText' || key === 'groupNames') {
      continue;
    }
    let row: any = { expanded: true, expandedOnce: false };

    row.cells = [];
    row.children = [];
    const text = key;
    const result = text.replace(/([A-Z])/g, ' $1');
    let finalText = result.charAt(0).toUpperCase() + result.slice(1);

    if (tenantInfo?.country === 'US') {
      finalText = replaceLabelsForLiabilitiesAndAssets(finalText);
    }

    let style: any = {
      fontWeight: 'normal',
      marginLeft: 0,
      textAlign: 'left'
    };
    let type = '';
    if (!PNL2[key] && key !== 'periodName') {
      if (PNL3[key]) {
        style = {
          ...style,
          fontWeight: '600',
          width: 1160
        };
        type = 'bold-line';
      }

      row.cells.push({
        title: finalText,
        style: { ...style },
        type: type
      });

      let totalValues: any = { expanded: {}, collapsed: {}, details: {} };
      let hasChild = true;

      if (Object.keys(reportData[key]).length === 0) {
        hasChild = false;
      }
      for (let period in reportData['periodName']) {
        if (PNL[key]) {
          totalValues.expanded[period] = undefined;
          totalValues.collapsed[`${period}_withChild`] =
            reportData[PNL[key]][`${period}`];
        }
        row = { ...row, totalValues: totalValues };
        row.cells.push(...alignAmountColumn);
        let amount = 0;
        if (
          reportData[PNL[key]] &&
          reportData[PNL[key]][period] &&
          !isNaN(reportData[PNL[key]][period])
        ) {
          amount = reportData[PNL[key]][period];
        }
        if (
          reportData[key] &&
          reportData[key][period] &&
          !isNaN(reportData[key][period])
        ) {
          amount = reportData[key][period];
        }
        let title = PNL[key]
          ? hasChild
            ? ' '
            : reportData[PNL[key]][period]
          : reportData[key][period];

        row.cells.push({
          title: PNL[key]
            ? hasChild
              ? ' '
              : getAmountBlockWithCurrency(reportData[PNL[key]][period])
            : getAmountBlockWithCurrency(reportData[key][period]),
          type: type,
          style:
            !isNaN(title) && title < 0 ? { color: 'red' } : { color: 'black' }
        });
      }
    }

    for (let child in reportData[key]) {
      let children: any = {
        expanded: false,
        cells: [],
        children: [],
        expandedOnce: false
      };
      if (child.includes('Period_')) {
        continue;
      }
      let totalValues: any = {
        expanded: {},
        collapsed: {},
        accountCode: reportData[key][child]['code'],
        details: {}
      };
      for (let keys in reportData['periodName']) {
        totalValues.expanded[keys] = reportData[key][child]['periodMap'][keys];
        totalValues.collapsed[`${keys}_withChild`] =
          reportData[key][child]['periodMap'][`${keys}_withChild`];
      }

      children = { ...children, totalValues: totalValues };
      children.cells.push({
        title: reportData[key][child]['name'],
        style: { style, paddingLeft: 10, textAlign: 'left' }
      });

      for (let i = 0; i < columns.length - 1; i++) {
        let hasChild = false;
        if (reportData[key][child]['itemDto'] !== null) {
          hasChild = true;
        }
        if (reportData[key][child]['itemDto']?.length === 0) {
          hasChild = false;
        }
        if (i === 4) {
          let title = hasChild
            ? totalValues.collapsed[`Period_1_withChild`]
            : totalValues.expanded[`Period_1`];
          children.cells.push({
            title: hasChild
              ? getAmountBlockWithCurrency(
                  totalValues.collapsed[`Period_1_withChild`]
                )
              : getAmountBlockWithCurrency(totalValues.expanded[`Period_1`]),
            style: {
              ...style,
              textAlign: 'right',
              color: !isNaN(title) && title < 0 ? 'red' : 'black'
            }
          });
        } else {
          children.cells.push({
            title: ' ',
            style: { ...style, textAlign: 'left' }
          });
        }
      }
      children.children.push({
        cells: [],
        children: [],
        expanded: false,
        expandedOnce: false
      });
      if (reportData[key][child]['itemDto'] !== null) {
        reportData[key][child]['itemDto'].forEach(
          (item: any, index: number) => {
            children.children.push(
              getParsedChildPNLDetails(item, 15, reportData)
            );
          }
        );
      }
      children.children = children.children.sort(function (a: any, b: any) {
        if (a.cells.length) {
          return a.cells[0]?.title?.localeCompare(b.cells[0]?.title);
        }
      });
      row.children.push(children);
    }
    row.children = row.children.sort(function (a: any, b: any) {
      if (a.cells.length) {
        return a.cells[0]?.title?.localeCompare(b.cells[0]?.title);
      }
    });

    if (key === 'income') {
      let children: any = {
        expanded: true,
        cells: [],
        children: []
      };
      children.cells.push({
        title: 'Total Income',
        style: {
          paddingLeft: 6,
          width: 1160
        },
        type: 'bold-line'
      });
      children.cells.push(...alignAmountColumn);
      for (let keys in reportData['totalIncome']) {
        children.cells.push({
          title: getAmountBlockWithCurrency(reportData['totalIncome'][keys]),
          style:
            !isNaN(reportData['totalIncome'][keys]) &&
            reportData['totalIncome'][keys] < 0
              ? { color: 'red' }
              : { color: 'black' },
          type: 'bold-line'
        });
      }

      row.children.push(children);
    }
    if (key === 'costOfGoodsSold') {
      let children: any = {
        expanded: true,
        cells: [],
        children: []
      };

      children.cells.push({
        title: 'Total Cost of Goods Sold',
        style: {
          paddingLeft: 6,
          width: 1160
        },
        type: 'bold-line'
      });
      children.cells.push(...alignAmountColumn);

      for (let keys in reportData['totalCostOfGoodsSold']) {
        children.cells.push({
          title: getAmountBlockWithCurrency(
            reportData['totalCostOfGoodsSold'][keys]
          ),
          type: 'bold-line',
          style:
            !isNaN(reportData['totalCostOfGoodsSold'][keys]) &&
            reportData['totalCostOfGoodsSold'][keys] < 0
              ? { color: 'red' }
              : { color: 'black' }
        });
      }

      row.children.push(children);
    }

    payload['rows'].push(row);
  }

  return { payload, length: len };
};

import React, { Component } from 'react';
import {
  DKLabel,
  DKDataGrid,
  getDateAsString,
  BarGraph,
  HorizontalBar,
  LineChart,
  PieChart,
  VerticalStackBar,
  HorizontalStackBar
} from 'deskera-ui-library';

import DBTableManager from '../../managers/DBTableManager';
import ChartDataParser from '../../managers/ChartDataParser';

import { APP_NAME, CHART_MIN_WIDTH, COLORS } from '../../constants/Constant';
import {
  CHART_TYPE,
  CHART_LAYOUT_TYPE,
  LOCAL_STORAGE_KEYS,
  DATE_FN_DATE_FORMATS
} from '../../constants/Enum';
import { getItemFromLocalStorage } from '../../utilities/LocalStorage';
import { isEmpty, isEmptyObject, shiftArrayElement, toCurrencyFormat } from '../../utilities/Common';

export default class ChartHolder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: this.props.chartData,
      inputData: this.props.inputData,
      additionalData: this.props.additionalData,
      dataSource: [
        {
          columns: this.props.chartData.columns,
          name: this.props.chartData.tableName
        }
      ],
      hasAmountColumn: this.dataTableHasAmountColumn(this.props.chartData.columns),
      tableHeaders: [],
      tableValues: [],
      barsData: [],
      stackBarData: [],
      linesData: [],
      pieChartData: []
    };
    this.chartRef = React.createRef(null);
  }

  componentDidMount() {
    this.processChartData();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      chartData: nextProps.chartData,
      dataSource: [
        {
          columns: nextProps.chartData.columns,
          name: nextProps.chartData.tableName
        }
      ],
      hasAmountColumn: nextProps?.chartData.columns && this.dataTableHasAmountColumn(nextProps.chartData.columns),
      tableHeaders: !isEmptyObject(this.props?.columns) ? this.props?.columns : []
    });

    if (nextProps.inputData !== null) {
      this.setState(
        {
          inputData: nextProps.inputData
        },
        this.processChartData
      );
    }
    if (nextProps.additionalData !== null) {
      this.setState({ additionalData: nextProps.additionalData });
    }
  }

  processChartData = () => {
    if (this.state.chartData.type.toUpperCase() === CHART_TYPE.TABLE) {
      this.getTableData();
    } else if (
      this.state.chartData.type.toUpperCase() === CHART_TYPE.BAR_VERTICAL ||
      this.state.chartData.type.toUpperCase() === CHART_TYPE.BAR_HORIZONTAL
    ) {
      this.setBarValues();
    } else if (
      this.state.chartData.type.toUpperCase() === CHART_TYPE.MULTI_BAR_VERTICAL ||
      this.state.chartData.type.toUpperCase() === CHART_TYPE.MULTI_BAR_HORIZONTAL
    ) {
      this.setStackBarValues();
    } else if (this.state.chartData.type.toUpperCase() === CHART_TYPE.LINE) {
      this.setLineValues();
    } else if (
      this.state.chartData.type.toUpperCase() === CHART_TYPE.PIE ||
      this.state.chartData.type.toUpperCase() === CHART_TYPE.DONUT
    ) {
      this.setPieValues();
    }
  };

  dataTableHasAmountColumn = (columns) => {
    columns = (columns || []);
    return columns.some(column => column.includes(['amount'])) || columns.some(column => column.includes(['revenue'])) || columns.some(column => column.includes(['profit'])) || (columns.some(column => column.includes(['total_cost'])) && !columns.some(column => column.includes(['no_'])));
  };

  getChartAppName = () => {
    let appName = this.state.chartData?.appName;

    if (!isEmpty(this.state.chartData?.dataTables)) {
      appName = this.state.chartData?.dataTables[0]?.appName;
    }

    return appName;
  }

  isCRMChart = () => {
    return [APP_NAME.CRM3, APP_NAME.CRM].includes(this.getChartAppName());
  }

  /* ************************* BAR REPORT UTILS ***************** */
  setBarValues() {
    let selectedColumns = this.state.dataSource
      ? this.state.dataSource[0].columns
      : [];
    let selectedTable = this.state.dataSource
      ? this.state.dataSource[0].name
      : '';
    let barsData = ChartDataParser.createDataArrayForBar(
      selectedColumns,
      selectedTable,
      this.state.inputData
    );
    this.setState({
      barsData: barsData
    });
  }

  setStackBarValues() {
    let selectedColumns = this.state.dataSource
      ? this.state.dataSource[0].columns
      : [];
    let selectedTable = this.state.dataSource
      ? this.state.dataSource[0].name
      : '';
    let stackBarData = ChartDataParser.createDataArrayForStackBar(
      selectedColumns,
      selectedTable,
      this.state.inputData
    );
    this.setState({
      stackBarData: stackBarData
    });
  }

  /* ************************* LINE REPORT UTILS ***************** */
  setLineValues() {
    let selectedColumns = this.state.dataSource
      ? this.state.dataSource[0].columns
      : [];
    let selectedTable = this.state.dataSource
      ? this.state.dataSource[0].name
      : '';

    let linesData = ChartDataParser.createDataArrayForLine(
      selectedColumns,
      selectedTable,
      this.state.inputData
    );

    this.setState({
      linesData: linesData
    });
  }

  /* ************************* PIE/DONUT REPORT UTILS ***************** */
  setPieValues() {
    let selectedColumns = this.state.dataSource
      ? this.state.dataSource[0].columns
      : [];
    let selectedTable = this.state.dataSource
      ? this.state.dataSource[0].name
      : '';

    this.setState({
      pieChartData: ChartDataParser.createDataArrayForPieChart(
        selectedColumns,
        selectedTable,
        this.state.inputData
      )
    });
  }

  /* ************************* TABLE REPORT UTILS ***************** */

  getColumnDataTypeFor(columnName) {
    let splitted = columnName.split('__');
    if (splitted.length === 2) {
      return DBTableManager.getColumnDataType(splitted[1], splitted[0]);
    }
    return;
  }

  getColumnDataFor(columnName) {
    let splitted = columnName.split('__');
    if (splitted.length === 2) {
      return DBTableManager.getColumnData(splitted[1], splitted[0]);
    }
    return null;
  }

  getTableData() {
    let data = [...this.state.inputData];
    data.forEach((element, index) => {
      let newElement = { ...element };
      try {
        Object.keys(element).forEach((key) => {
          if (
            this.getColumnDataTypeFor(key) === 'DATE' ||
            this.getColumnDataTypeFor(key) === 'DATETIME'
          ) {
            if (
              newElement[key] &&
              getItemFromLocalStorage(LOCAL_STORAGE_KEYS.DATE_FORMATE)
            ) {
              newElement[key] = getDateAsString(
                Date.parse(newElement[key]),
                getItemFromLocalStorage(LOCAL_STORAGE_KEYS.DATE_FORMATE)
              );
            }
          } else if (key.includes('amount') || key.includes('rate')) {
            const decimalScale =
              Number(
                getItemFromLocalStorage(LOCAL_STORAGE_KEYS.DECIMAL_SCALE)
              ) || 2;
            if (newElement[key] && decimalScale) {
              newElement[key] = Number(
                parseFloat(
                  newElement[key].toString().replaceAll(',', '')
                ).toFixed(decimalScale)
              ).toLocaleString('en', { minimumFractionDigits: decimalScale });
            }
          }
        });
      } catch (e) {
        // Do nothing show the data as it is
      }
      data[index] = newElement;
    });

    let firstElement = data[0];
    let headersMapping = [];
    if (firstElement === undefined) return;

    const savedColumnSettings = {};
    const columnSettingsList = this.props.chartData?.columnConfig || this.props.chartData?.settings?.columns;
    columnSettingsList?.forEach((column) => {
      savedColumnSettings[column.key] = column;
    });

    const columnWidth = Math.floor(400 / Object.entries(firstElement).length);
    for (const [key] of Object.entries(firstElement)) {
      let splitted = key.split('__');
      if (splitted.length === 2) {
        const columnData = this.getColumnDataFor(key);
        const type = (columnData?.data_type || 'STRING').toLowerCase();
        headersMapping.push({
          name:
            savedColumnSettings[key]?.name ||
            DBTableManager.getColumnDisplayName(splitted[1], splitted[0]),
          width:
            savedColumnSettings[key]?.width ||
            (columnWidth < 150 ? 150 : columnWidth),
          key: key,
          id: key,
          columnCode: splitted[1],
          tableName: splitted[0],
          type: type,
          systemField: true,
          textAlign: type === 'number' ? 'right' : 'left',
          allowColumnSort: (type === 'select' || columnData?.customField) ? false : true,
          editable:columnData?.customField ? false : true,
          renderer:
            columnData?.currency || key.includes('amount')
              ? ({ value }) => (
                  <DKLabel
                    className="text-align-right parent-width"
                    text={toCurrencyFormat(value, this.isCRMChart())}
                  />
                )
              : type === 'date'
              ? ({ value }) => value
              : null,
          options: (columnData?.possible_values || []).map((opt, index) => ({
            id: index,
            name: opt
          }))
        });
      }
    }

    if (columnSettingsList) {
      headersMapping.sort((header1, header2) => {
        const indexOfHeader1 = columnSettingsList.findIndex(
          (column) => column.id === header1.id
        );
        const indexOfHeader2 = columnSettingsList.findIndex(
          (column) => column.id === header2.id
        );
        if (indexOfHeader1 === -1 && indexOfHeader2 === -1) {
          return 0;
        } else if (indexOfHeader1 === -1) {
          return 1;
        } else if (indexOfHeader2 === -1) {
          return -1;
        } else {
          return indexOfHeader1 - indexOfHeader2;
        }
      });
    }
    this.setState({
      tableHeaders: headersMapping,
      tableValues: data
    });
  }

  /* ************************* RENDER UTILS ***************** */
  isRowLayout() {
    return this.props.layoutType === CHART_LAYOUT_TYPE.ROW;
  }

  isExpanded() {
    return this.state.chartData.isExpanded;
  }

  /* ************************* REPORT RENDERERS ***************** */
  getNoDataView() {
    return (
      <div className="parent-size column justify-content-center align-items-center">
        <DKLabel
          className="fw-m"
          text={this.state.chartData.isLoading ? 'Loading...' : 'No data'}
        />
        <DKLabel
          className="mt-s text-gray"
          text={
            this.state.chartData.isLoading
              ? 'Please wait, we are fetching the records.'
              : 'Select data source & parameters for desired result'
          }
        />
      </div>
    );
  }

  getChartNumericDisplayValueFormatter = (value) => {
    try {
      if (typeof value !== 'number' || !this.state.hasAmountColumn)
        return value;

      let newValue = value;
      const decimalScale =
        Number(getItemFromLocalStorage(LOCAL_STORAGE_KEYS.DECIMAL_SCALE)) || 2;

      newValue = Number(
        parseFloat(newValue.toString().replaceAll(',', '')).toFixed(
          decimalScale
        )
      ).toLocaleString('en', { minimumFractionDigits: decimalScale });

      newValue = this.state.hasAmountColumn
        ? toCurrencyFormat(newValue, this.isCRMChart())
        : newValue;

      return newValue;
    } catch (err) {
      console.log(err);
      return value;
    }
  };

  getChart() {
    if (this.state.chartData.type.toUpperCase() === CHART_TYPE.TABLE) {
      const width = this.chartRef.current?.clientWidth || CHART_MIN_WIDTH;
      return (
        <DKDataGrid
          columns={this.state.tableHeaders}
          rows={this.state.tableValues}
          updating={false}
          width={width || CHART_MIN_WIDTH}
          dateFormat={DATE_FN_DATE_FORMATS['DD-MM-YYYY']}
          needShadow={false}
          allowRowEdit={false}
          allowBulkOperation={false}
          allowColumnSort={true}
          allowColumnEdit={this.props.allowGridEdit}
          onSort={(data) => this.props.onSortApplied?.(data)}
          onColumnUpdate={() =>
            this.props.onGridColumnUpdate?.(this.state.tableHeaders)
          }
          onColumnShift={({ newIndex, oldIndex }) => {
            let newColumns = [...(this.state.tableHeaders || [])];
            newColumns = shiftArrayElement(newColumns, oldIndex, newIndex);
            if (this.props.onGridColumnUpdate) {
              this.props.onGridColumnUpdate(newColumns);
            } else {
              this.setState({ tableHeaders: newColumns });
            }
          }}
        />
      );
    } else if (this.state.chartData.type.toUpperCase() === CHART_TYPE.BAR_VERTICAL) {
      return (
        <BarGraph
          data={this.state.barsData}
          height={this.props.height ? this.props.height - 40 : 250}
          showLegend={true}
          tooltipValueFormatter={this.getChartNumericDisplayValueFormatter}
        />
      );
    } else if (this.state.chartData.type.toUpperCase() === CHART_TYPE.BAR_HORIZONTAL) {
      return (
        <HorizontalBar
          data={this.state.barsData}
          height={this.props.height || 250}
          showLegend={true}
          tooltipValueFormatter={this.getChartNumericDisplayValueFormatter}
        />
      );
    } else if (this.state.chartData.type.toUpperCase() === CHART_TYPE.MULTI_BAR_VERTICAL) {
      return (
        <VerticalStackBar
          data={this.state.stackBarData}
          height={this.props.height ? this.props.height - 40 : 250}
        />
      );
    } else if (this.state.chartData.type.toUpperCase() === CHART_TYPE.MULTI_BAR_HORIZONTAL) {
      return !this.state.stackBarData?.length ? this.getNoDataView() : (
        <HorizontalStackBar
          data={this.state.stackBarData}
          height={this.props.height || 250}
        />
      );
    } else if (this.state.chartData.type.toUpperCase() === CHART_TYPE.LINE) {
      return (
        <LineChart
          data={this.state.linesData}
          height={this.props.height ? this.props.height - 40 : 250}
          tooltipValueFormatter={this.getChartNumericDisplayValueFormatter}
        />
      );
    } else if (this.state.chartData.type.toUpperCase() === CHART_TYPE.DONUT) {
      return (
        <PieChart
          dataSet={this.state.pieChartData || []}
          chartConfig={{
            arcWidth: 0.7,
            size: (this.props.height || 230) - 50
          }}
          showLegend={true}
          displayValueFormatter={this.getChartNumericDisplayValueFormatter}
        />
      );
    } else if (this.state.chartData.type.toUpperCase() === CHART_TYPE.PIE) {
      return (
        <PieChart
          dataSet={this.state.pieChartData || []}
          chartConfig={{
            size: (this.props.height || 230) - 50
          }}
          showLegend={true}
          displayValueFormatter={this.getChartNumericDisplayValueFormatter}
        />
      );
    }
  }

  render() {
    return (
      <div
        className={
          'dk-report-chart-container column position-relative parent-width flex-1 justify-content-center align-items-center'
        }
        style={{
          height: '100%',
          minHeight: 150,
          maxHeight: '100%',
          minWidth: CHART_MIN_WIDTH,
          alignSelf: 'normal'
        }}
        ref={this.chartRef}
      >
        {this.state?.additionalData && (this.state.chartData.type.toUpperCase() === CHART_TYPE.DONUT || this.state.chartData.type.toUpperCase() === CHART_TYPE.PIE) &&
          <div className="row justify-content-between m-v-s">
          {this.state.additionalData?.map(field => {
              return (
                <>
                  {field.value !== null && <div className="column text-align-center align-items-center mr-m">
                    <div className="fw-b">{field.displayName}</div>
                    <div className="text-align-center text-gray fs-xl">{field?.currency ? toCurrencyFormat(field.value, this.isCRMChart()) : field.value || '-'}</div>
                  </div>}
                </>
              )
            })}
          </div>
        }
        {this.state.inputData &&
          this.state.inputData.length > 0 &&
          this.getChart()}
        {(this.state.inputData === null || this.state.inputData.length === 0) &&
          this.getNoDataView()}
      </div>
    );
  }
}

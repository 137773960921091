/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  DKIcon,
  DKIcons,
  DKButton,
  DKLabel,
  DKListPicker,
  Toggle,
  DKSpinner,
  showToast,
  TOAST_TYPE
} from 'deskera-ui-library';
import { useAppSelector } from '../store/hooks';
import { getTenantInfo } from '../store/slices/tenantSlice';
import { DATE_FORMATS, FINANCIAL_REPORT } from '../constants/Enum';
import { isEmpty } from '../utilities/Common';
import RouteManager from '../managers/RouteManager';
import { getLongAmoutBlockForGrid, sortGridData } from '../helper/SharedParser';
import {
  TRIAL_BALANCE_COLUMN_CODE,
  TrialBalanceColumnConfig
} from '../constants/TrialBalance';
import { getDateStrFromDate, getFormattedDateString } from '../utilities/Date';
import AccountingService from '../services/reports/Accounting';
import { PAGE_ROUTES } from '../constants/Constant';
import ic_no_data from '../assets/ic_no_data_3.png';
import ReportGridHolder from '../components/accounting/grid/ReportGridHolder';
import { TrialBalanceFilter } from '../components/accounting/TrialBalanceFilter';

export default function TrialBalance(props: any) {
  const [reportFilterState, setReportFilterState] = useState<any>();
  const tenantInfo = useAppSelector(getTenantInfo());
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState<any>(null);
  const [gridRowData, setGridRowData] = useState<any[]>([]);
  const [showExport, setShowExport] = useState(false);
  const defaultSortColumn = 'code';
  const defaultSortOrder = 'ASC';
  const [switchToMasterCurrency, setSwitchToMasterCurrency] = useState(false);
  const primaryCurrencyCode =
    tenantInfo?.additionalSettings?.MULTI_COMPANY?.primaryCurrencyCode;

  useEffect(() => {
    RouteManager.setPresenter({ props });
  }, []);

  useEffect(() => {
    if (!isEmpty(reportFilterState) && !isEmpty(tenantInfo)) {
      handleFilterChange();
    }
  }, [reportFilterState, switchToMasterCurrency]);

  useEffect(() => {
    populateGrid();
  }, [reportData]);

  const populateGrid = (
    sortByColumn = defaultSortColumn,
    sortDir = defaultSortOrder
  ) => {
    if (!isEmpty(reportData) && reportData.trialBalanceDetailDto.length) {
      const diffOpeningBalanceRow = populateDiffOpeningBalanceRow(
        reportData.diffOpeningBalance
      );
      const rowData = getRowData(sortByColumn, sortDir);

      const rowForTotal = getTotalRow();

      setGridRowData([diffOpeningBalanceRow, ...rowData, rowForTotal]);
    } else {
      setGridRowData([]);
    }
  };

  const getFormattedAmount = (amount: number) => {
    if (amount === 0) {
      return '';
    } else {
      return getLongAmoutBlockForGrid(getActiveCurrency(), amount + '');
    }
  };

  const getTotalRow = () => {
    let debitAmount = reportData.totalDebitAmount;
    let creditAmount = reportData.totalCreditAmount;
    if (debitAmount < creditAmount) {
      debitAmount = debitAmount + reportData.diffOpeningBalance;
    } else {
      creditAmount = creditAmount + reportData.diffOpeningBalance;
    }
    return {
      [TRIAL_BALANCE_COLUMN_CODE.AC_CODE]: '<b>Total</b>',
      [TRIAL_BALANCE_COLUMN_CODE.AC_NAME]: '',
      [TRIAL_BALANCE_COLUMN_CODE.AC_GROUP]: '',
      [TRIAL_BALANCE_COLUMN_CODE.DEBIT]: `<b>${getFormattedAmount(
        debitAmount
      )}</b>`,
      [TRIAL_BALANCE_COLUMN_CODE.CREDIT]: `<b>${getFormattedAmount(
        creditAmount
      )}</b>`
    };
  };

  const getRowData = (
    sortByColumn = defaultSortColumn,
    sortDir = defaultSortOrder
  ) => {
    let rowData: any[] = [];
    if (!isEmpty(reportData.trialBalanceDetailDto)) {
      let data = [...reportData.trialBalanceDetailDto];
      const columnType = TrialBalanceColumnConfig.find(
        (config: any) => config.key === sortByColumn
      ).type;

      data = sortGridData(data, columnType, sortByColumn, sortDir);

      data.forEach((row: any) => {
        rowData.push({
          [TRIAL_BALANCE_COLUMN_CODE.AC_CODE]:
            row[TRIAL_BALANCE_COLUMN_CODE.AC_CODE],
          [TRIAL_BALANCE_COLUMN_CODE.AC_NAME]:
            row[TRIAL_BALANCE_COLUMN_CODE.AC_NAME],
          [TRIAL_BALANCE_COLUMN_CODE.AC_GROUP]:
            row[TRIAL_BALANCE_COLUMN_CODE.AC_GROUP],
          [TRIAL_BALANCE_COLUMN_CODE.DEBIT]: getFormattedAmount(
            row[TRIAL_BALANCE_COLUMN_CODE.DEBIT]
          ),
          [TRIAL_BALANCE_COLUMN_CODE.CREDIT]: getFormattedAmount(
            row[TRIAL_BALANCE_COLUMN_CODE.CREDIT]
          )
        });
      });
    }
    return rowData;
  };

  const populateDiffOpeningBalanceRow = (amount: number) => {
    return {
      [TRIAL_BALANCE_COLUMN_CODE.AC_CODE]: '',
      [TRIAL_BALANCE_COLUMN_CODE.AC_NAME]: 'Difference in Opening balances',
      [TRIAL_BALANCE_COLUMN_CODE.AC_GROUP]: '',
      [TRIAL_BALANCE_COLUMN_CODE.DEBIT]:
        reportData.totalDebitAmount <= reportData.totalCreditAmount
          ? getFormattedAmount(amount)
          : '',
      [TRIAL_BALANCE_COLUMN_CODE.CREDIT]:
        reportData.totalDebitAmount > reportData.totalCreditAmount
          ? getFormattedAmount(amount)
          : ''
    };
  };

  const handleFilterChange = async () => {
    let queryString: string = `basis=${
      reportFilterState.Basis.value
    }&fromDate=${getFormattedDateString(
      tenantInfo.bookBeginningStartDate,
      DATE_FORMATS['YYYY-MM-DD'],
      DATE_FORMATS['DD-MM-YYYY']
    )}&toDate=${getDateStrFromDate(
      reportFilterState.EndDate,
      DATE_FORMATS['DD-MM-YYYY']
    )}&amountInPrimaryCurrency=${switchToMasterCurrency}&baseCurrency=${
      tenantInfo?.currency
    }&primaryCurrency=${primaryCurrencyCode}`;

    if (!isEmpty(reportFilterState.CustomField)) {
      let show = true;
      reportFilterState?.customFieldObject?.forEach((obj: any) => {
        if (obj.value === '' && obj.code === undefined) {
          show = false;
        }
      });

      if (show) {
        queryString += `&customfield=${encodeURI(
          reportFilterState.CustomField
        )}`;
      }
    }

    if (!isEmpty(queryString)) {
      try {
        setIsLoading(true);
        const data =
          await AccountingService.getInstance().getTrialBalanceReport(
            queryString
          );
        setReportData(data);
        setIsLoading(false);
      } catch (err: any) {
        console.error('Error loading report data: ', err);
        setIsLoading(false);
      }
    }
  };

  const exportDocument = (format: string) => {
    AccountingService.getInstance()
      .exportTrialBalance(format, {
        StartDate: getDateStrFromDate(
          reportFilterState.StartDate,
          DATE_FORMATS['DD-MM-YYYY']
        ),
        EndDate: getDateStrFromDate(
          reportFilterState.EndDate,
          DATE_FORMATS['DD-MM-YYYY']
        ),
        CustomField: reportFilterState.CustomField
      })
      .then((res: any) => setShowExport(false))
      .catch((err: any) => {
        showToast('Could Not Export Report', TOAST_TYPE.FAILURE);
      });
  };

  const showOptions = () => {
    return (
      <DKListPicker
        data={[`XLS`]}
        className="border shadow-m position-absolute z-index-3"
        style={{ width: 82, right: 0, top: 33 }}
        onSelect={(index: any, value: any) => {
          if (index === 0) {
            exportDocument('XLS');
          }
          if (index === 1) {
            exportDocument('PDF');
          }
        }}
        onClose={() => {
          setShowExport(false);
        }}
      />
    );
  };

  const getNoDataView = () => {
    return (
      <div className="column parent-size align-items-center justify-content-center position-absolute">
        <DKIcon src={ic_no_data} className="ic-l" style={{ opacity: 0.2 }} />
        <DKLabel
          className="fs-m fw-m mt-l"
          text={isLoading ? 'Loading' : 'No data found'}
        />
        <DKLabel
          className="text-dark-gray mt-s text-align-center"
          text={
            isLoading ? 'Please wait' : 'No data available for your selection.'
          }
        />
      </div>
    );
  };

  const onSort = ({ order, columnData }: any) => {
    populateGrid(columnData.key, order);
  };

  const getActiveCurrency = () =>
    switchToMasterCurrency ? primaryCurrencyCode : tenantInfo?.currency;

  return (
    <div
      className="column full-screen-width parent-width flex-1 text-13 text-align-left bg-gray1 main-holder-padding pb-xl"
      style={{ minHeight: '100%' }}
    >
      <div className="column parent-width align-items-center justify-content-between">
        <div className="row">
          <DKButton
            icon={DKIcons.ic_arrow_left}
            style={{
              paddingLeft: 0
            }}
            onClick={() => RouteManager.navigateToPage(PAGE_ROUTES.TEMPLATES)}
          />
          <DKLabel
            text={'Trial Balance'}
            style={{ fontSize: 16, fontWeight: 600 }}
          />
          {isLoading && <DKSpinner iconClassName="ic-s" className="ml-s" />}
        </div>
        <div className="row row-responsive align-items-start justify-content-start bg-white p-r border-radius-m m-v-r shadow-s-2 mobile-flex-gap-m">
          <TrialBalanceFilter
            reportType={FINANCIAL_REPORT.TRIAL_BALANCE}
            isDataLoading={isLoading}
            onFilterUpdated={(filters: any) => setReportFilterState(filters)}
          />
          <div className="position-relative" style={{ marginTop: 'auto' }}>
            <DKButton
              title={'Export'}
              icon={DKIcons.ic_export}
              className="border-m bg-gray1 ml-m mobile-m-0"
              style={{
                borderColor: 'rgb(235, 235, 235)',
                borderRadius: 4
              }}
              onClick={() => {
                setShowExport(true);
              }}
            />
            <div>{showExport && showOptions()}</div>
          </div>
          {!!primaryCurrencyCode && (
            <div className="column width-auto ml-3">
              <DKLabel
                text="Switch to Master Currency"
                className="mt-1 mb-2"
                style={{ color: 'gray', fontSize: 12 }}
              />
              <Toggle
                className="ml-5 box-content"
                isOn={switchToMasterCurrency}
                onChange={() => setSwitchToMasterCurrency((value) => !value)}
              />
            </div>
          )}
        </div>
      </div>

      <div className="column parent-width flex-1">
        <div className="column parent-width mb-s position-relative flex-1">
          <ReportGridHolder
            needColumnIcons={true}
            onSort={onSort}
            allowColumnSort={true}
            columnConfig={TrialBalanceColumnConfig}
            rows={gridRowData}
          />
          {reportData?.trialBalanceDetailDto?.length === 0 && getNoDataView()}
        </div>
      </div>
    </div>
  );
}

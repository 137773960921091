import { RootState } from '../store';
import {
  createAsyncThunk,
  createSlice,
  createSelector
} from '@reduxjs/toolkit';

import TenantService from '../../services/user/Tenant';

import { IUser, ICurrency, ITenant } from '../../models/Tenant';
import { CURRENCY_SYMBOLS } from '../../constants/Currencies';

export interface TenantState {
  tenantInfo: any;
  tenants: ITenant[];
  crmTenantSetting: { _id: string; currency: CURRENCY_SYMBOLS; } | null;
  users: IUser[];
  currencies: ICurrency[];
  loggedInUserInfo: any;
  loggedInUserPlanInfo: any;
  permissions: { userId?: number; apps?: any[] };
  userInfo: any;
}
const initialState: TenantState = {
  tenantInfo: null,
  crmTenantSetting: null,
  tenants: [],
  users: [],
  loggedInUserInfo: null,
  loggedInUserPlanInfo: null,
  currencies: [],
  userInfo: null,
  permissions: {}
};
const _tenantService = TenantService.getInstance();

export const fetchTenantInfo = createAsyncThunk(
  'tenants/fetchTenantInfo',
  async (data, thunkAPI) => {
    const response = await _tenantService.getTenantDetails();
    return response;
  }
);

export const fetchTenants = createAsyncThunk(
  'tenants/fetchTenants',
  async (data, thunkAPI) => {
    const response = await _tenantService.getAllTenants();
    return response;
  }
);

export const fetchUsers = createAsyncThunk(
  'tenants/fetchUsers',
  async (data, thunkAPI) => {
    const response = await _tenantService.getUsers();
    return response;
  }
);

export const fetchCurrencies = createAsyncThunk(
  'tenants/fetchCurrencies',
  async (data, thunkAPI) => {
    const response = await _tenantService.getCurrencyEnum();
    return response;
  }
);

export const fetchTenantPermissions = createAsyncThunk(
  'tenants/fetchTenantPermissions',
  async (data, thunkAPI) => {
    const response = await _tenantService.getTenantPermissions();
    return response;
  }
);

export const fetchCRMCurrencySettings = createAsyncThunk(
  'tenants/fetchCRMCurrencySettings',
  async (data, thunkAPI) => {
    const response = await _tenantService.getCRMCurrencySetting();
    return response;
  }
);

export const tenantsSlice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    setLoggedInUserInfo: (state, action) => {
      state.loggedInUserInfo = action.payload;
    },
    setLoggedInUserPlanInfo: (state, action) => {
      state.loggedInUserPlanInfo = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTenantInfo.fulfilled, (state, action: any) => {
      state.tenantInfo = action.payload;
    });
    builder.addCase(fetchTenants.fulfilled, (state, action: any) => {
      state.tenants = action.payload;
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      const res: any = action.payload;
      const filteredUsers = res.invites
        .filter(onlyUniqueUsers)
        .map((user: IUser) => {
          return { ...user, id: user.iamUserId || user.id };
        });

      state.users = filteredUsers;
    });
    builder.addCase(fetchCurrencies.fulfilled, (state, action: any) => {
      state.currencies = action.payload.content;
    });
    builder.addCase(fetchCurrencies.rejected, (state, action) => {
      state.currencies = [];
    });
    builder.addCase(fetchTenantPermissions.fulfilled, (state, action: any) => {
      state.permissions = action.payload;
    });
    builder.addCase(fetchCRMCurrencySettings.fulfilled, (state, action: any) => {
      state.crmTenantSetting = action.payload;
    });
  }
});

const selfSelector = (state: RootState) => state?.tenant;

/* ******************* TENANT SELECTORS ******************* */
export const getTenants = () =>
  createSelector(selfSelector, (state) => state.tenants);
export const getTenantInfo = () =>
  createSelector(selfSelector, (state) => state.tenantInfo);

/* ******************* USER SELECTORS ******************* */
const onlyUniqueUsers = (item: IUser, index: number, users: []) =>
  users.findIndex((obj: IUser) => obj.email === item.email) === index;

export const getUsers = () =>
  createSelector(selfSelector, (state) => state.users);

export const getUserByIamId = (iamUserId: number) =>
  createSelector(selfSelector, (state) =>
    state.users.find(
      (user: IUser) => user.iamUserId?.toString() === iamUserId?.toString()
    )
  );

export const getUserFullName = (user: IUser) =>
  `${user?.firstName} ${user?.lastName}`;

/* ******************* CURRENCY SELECTOR ******************* */
export const getCurrencies = () =>
  createSelector(selfSelector, (state) => state.currencies);
export const selectLoggedInUser = () =>
  createSelector(selfSelector, (state) => state.loggedInUserInfo);
export const selectLoggedInUserPlan = () =>
  createSelector(selfSelector, (state) => state.loggedInUserPlanInfo);
export const selectUserInfo = () =>
  createSelector(selfSelector, (state) => state.userInfo);
export const { setLoggedInUserInfo, setLoggedInUserPlanInfo, setUserInfo } =
  tenantsSlice.actions;
export default tenantsSlice.reducer;

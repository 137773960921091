import http from '../';
import ApiConstants from '../../constants/ApiConstant';
import { APP_NAME } from '../../constants/Constant';
import { FINANCIAL_REPORT } from '../../constants/Enum';
import { isEmpty } from '../../utilities/Common';
import { triggerDownload } from '../../utilities/Export';

class AccountingService {
  private static _reportInstance: AccountingService;

  public static getInstance(): AccountingService {
    if (!AccountingService._reportInstance) {
      AccountingService._reportInstance = new AccountingService();
    }
    return AccountingService._reportInstance;
  }

  /* **************** Accounting Reports ********************* */
  public getTaxAgency() {
    return http.get(`${ApiConstants.URL.REPORT_TAX_AGENCY}`);
  }

  public getComparisonModeOptions(type: FINANCIAL_REPORT): Promise<any> {
    return http.get(ApiConstants.URL.VIEW_OPTIONS(type));
  }

  public getCustomFieldByModules(appName: APP_NAME.ERP): Promise<any> {
    const payload = {
      application: appName,
      modules: [
        'ACCOUNT',
        'PRODUCT',
        'ORDER',
        'BILL',
        'QUOTATION',
        'INVOICE',
        'EXPENSE',
        'DEPOSIT',
        'CREDITNOTE',
        'DEBITNOTE',
        'JOURNAL'
      ]
    };
    return http.post(ApiConstants.URL.CUSTOM_FIELDS_BY_MODULES, payload);
  }

  public getPNLReport(payload: any): Promise<any> {
    return http.post(ApiConstants.URL.PNL, payload);
  }

  public getPNLReportInBothCurrency(payload: any): Promise<any> {
    return http.post(ApiConstants.URL.PNL_IN_BOTH_CURRENCY, payload);
  }

  public getReportCashDetails(query: string): Promise<any> {
    return http.get(ApiConstants.URL.REPORT_CASH_DETAILS + query);
  }

  public exportPNL(
    format: string,
    config: {
      cmode: any;
      basis: any;
      presetInfo: any;
      filteredData?: any;
      StartDate: string;
      EndDate: string;
    }
  ) {
    const { cmode, basis, presetInfo, filteredData, StartDate, EndDate } =
      config;
    let request: any = {
      startDate: StartDate,
      endDate: EndDate,
      basis: 'ACCRUAL',
      fileFormat: format
    };

    if (cmode.id === 3) {
      request = { vid: 3, vname: '6 Month', basis: basis, fileFormat: 'XLS' };
    }
    if (cmode.id === 2) {
      request = { vid: 2, vname: '3 Years', basis: basis, fileFormat: 'XLS' };
    }
    if (cmode.id === 4) {
      request = {
        vid: 4,
        vname: 'Quarterly',
        basis: basis,
        fileFormat: 'XLS'
      };
    }
    if (cmode.vname === '12 Month') {
      request = {
        vid: cmode.id,
        vname: '12 Month',
        basis: basis,
        fileFormat: 'XLS'
      };
    }
    if (presetInfo !== undefined && presetInfo !== null) {
      request = {
        periods: presetInfo.Periods,
        save: false,
        basis: basis,
        fileFormat: 'XLS'
      };
    }
    if (!isEmpty(filteredData?.contacts)) {
      request['contacts'] = filteredData?.contacts;
    }
    if (!isEmpty(filteredData?.products)) {
      request['products'] = filteredData?.products;
    }
    if (!isEmpty(filteredData?.custom_field)) {
      request['custom_field'] = filteredData?.custom_field;
    }

    let fileName =
      format === 'PDF' ? `PROFIT AND LOSS.pdf` : `PROFIT AND LOSS.xlsx`;
    const headers = {
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*'
    };
    const finalEndPoint = ApiConstants.URL.EXPORT_PNL + `-v2`;
    return http
      .post(`${finalEndPoint}`, request, {
        responseType: 'blob',
        headers: headers
      })
      .then(
        (response: any) => triggerDownload(response, fileName),
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  public getCustomizedReport(reportName: string): Promise<any> {
    return http.get(
      `${ApiConstants.URL.CUSTOMIZED_REPORT}?report=${reportName}`
    );
  }
  public postCustomizedReport(payload: any): Promise<any> {
    return http.post(`${ApiConstants.URL.CUSTOMIZED_REPORT}`, payload);
  }

  public getBalanceSheetReport(payload: any): Promise<any> {
    return http.post(ApiConstants.URL.BALANCE_SHEET, payload);
  }

  public getBalanceSheetReportInBothCurrency(payload: any): Promise<any> {
    return http.post(ApiConstants.URL.BALANCE_SHEET_IN_BOTH_CURRENCY, payload);
  }

  public exportBalanceSheet(
    format: string,
    config: {
      cmode: any;
      basis: string;
      presetInfo: any;
      filteredData?: any;
      StartDate: string;
      EndDate: string;
    }
  ) {
    const { cmode, basis, presetInfo, filteredData, StartDate, EndDate } =
      config;
    let request: any = {
      startDate: StartDate,
      endDate: EndDate,
      basis: 'ACCRUAL',
      fileFormat: format
    };

    if (cmode.id === 3) {
      request = {
        vid: cmode.id,
        vname: '6 Month',
        basis: basis,
        fileFormat: 'XLS'
      };
    }
    if (cmode.id === 6) {
      request = {
        vid: cmode.id,
        vname: '3 Years',
        basis: basis,
        fileFormat: 'XLS'
      };
    }
    if (cmode.id === 4) {
      request = {
        vid: cmode.id,
        vname: 'Quarterly',
        basis: basis,
        fileFormat: 'XLS'
      };
    }
    if (cmode.vname === '12 Month') {
      request = {
        vid: cmode.id,
        vname: '12 Month',
        basis: basis,
        fileFormat: 'XLS'
      };
    }

    if (presetInfo !== undefined && presetInfo !== null) {
      request = {
        periods: presetInfo.Periods,
        save: false,
        basis: basis,
        fileFormat: 'XLS'
      };
    }
    if (!isEmpty(filteredData?.contacts)) {
      request['contacts'] = filteredData?.contacts;
    }
    if (!isEmpty(filteredData?.products)) {
      request['products'] = filteredData?.products;
    }
    if (!isEmpty(filteredData?.custom_field)) {
      request['custom_field'] = filteredData?.custom_field;
    }

    let fileName =
      format === 'PDF' ? `Balance Sheet.pdf` : `Balance Sheet.xlsx`;
    const headers = {
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*'
    };
    const finalEndPoint = ApiConstants.URL.EXPORT_BALANCE_SHEET;
    return http
      .post(`${finalEndPoint}`, request, {
        responseType: 'blob',
        headers: headers
      })
      .then(
        (response: any) => triggerDownload(response, fileName),
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  public getTrialBalanceReport(queryParams: string): Promise<any> {
    return http.get(ApiConstants.URL.TRIAL_BALANCE + `?${queryParams}`);
  }

  public getTrialBalanceDetailReport(queryParams: string): Promise<any> {
    return http.get(ApiConstants.URL.TRIAL_BALANCE_DETAIL + `?${queryParams}`);
  }

  public exportTrialBalance(
    format: string,
    config: {
      CustomField?: any;
      StartDate: string;
      EndDate: string;
    }
  ) {
    const { CustomField, StartDate, EndDate } = config;
    const request1 = {
      fromDate: StartDate,
      toDate: EndDate,
      basis: 'ACCRUAL',
      fileFormat: format,
      customfield: CustomField
    };

    let fileName =
      format === 'PDF' ? `TRIAL BALANCE.pdf` : `TRIAL BALANCE.xlsx`;
    const headers = {
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*'
    };
    const finalEndPoint = ApiConstants.URL.EXPORT_TRIAL_BALANCE;
    return http
      .post(`${finalEndPoint}`, request1, {
        responseType: 'blob',
        headers: headers
      })
      .then(
        (response: any) => {
          triggerDownload(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  public deleteFilterViewOption(vid: number): Promise<any> {
    return http.delete(ApiConstants.URL.DELETE_VIEW(vid));
  }

  public getCashflowMap() {
    return http.get(ApiConstants.URL.FINANCIAL_CASHFLOW_MAP);
  }

  public updateMapping(payload: any) {
    return http
      .put(`${ApiConstants.URL.UPDATE_MAPPING}`, payload)
      .catch((err: any) => {
        console.error('Error while trying to update Mapping: ', err);
        return Promise.reject(err);
      });
  }

  public fetchAccounts() {
    return http.get(
      `${ApiConstants.URL.FETCH_ACCOUNTS}?query=search=&limit=400&status=ACTIVE`
    );
  }

  public getOBAccountByPage(params: any) {
    const queryString: string = `?query=&search=${params.SearchTerm}&limit=${params.Limit}&page=${params.Page}`;
    const finalEndpoint: string =
      ApiConstants.URL.FETCH_ACCOUNTS + queryString;
    return http.get(`${finalEndpoint}`);
  }

  public sendEmail(requestData: any) {
    return http
      .post(ApiConstants.URL.SEND_EMAIL_TEMPLATE_REPORT, requestData)
      .then(
        (res) => {
          console.log('Email Sent Successfully', res);
          return Promise.resolve(res);
        },
        (err) => {
          console.log('send Email Failed', err);
          return Promise.reject(err);
        }
      );
  }
}

export default AccountingService;

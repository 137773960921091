import http from '..';
import ApiConstants from '../../constants/ApiConstant';
import AuthManager from '../../managers/AuthManager';
import { showLoader, removeLoader } from 'deskera-ui-library';

export default class Auth {
  private static _authInstance: Auth;
  private static statusCallPromise: Promise<any> | null = null;

  public static getInstance(): Auth {
    if (!Auth._authInstance) {
      Auth._authInstance = new Auth();
    }
    return Auth._authInstance;
  }

  public clearStoredStatusCall() {
    Auth.statusCallPromise = null;
  }

  public async checkIfUserLoggedIn(
    needAlert = true,
    successCallback?: (res: any) => void
  ) {
    if (!Auth) return;

    /* For avoiding parallel status calls */
    if (Auth.statusCallPromise) {
      return Auth.statusCallPromise;
    }

    Auth.statusCallPromise = http
      .get(ApiConstants.AUTH.LOGIN_STATUS, {
        params: { skipInterceptor: true }
      })
      .then((response: any) => {
        Auth._authInstance.clearStoredStatusCall();

        if (response?.accessToken) {
          AuthManager.onReceiveToken(response.accessToken);
          successCallback?.(response);
          return Promise.resolve('Authenticated User');
        } else {
          AuthManager.gotoLoginPage(needAlert);
          return Promise.reject('Failed to authenticate user');
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    return Auth.statusCallPromise;
  }

  public async logOut() {
    showLoader('Logging out...');
    return http.get(ApiConstants.AUTH.LOG_OUT).finally(removeLoader);
  }

  public async switchTenant(params: any) {
    showLoader('Switching organisation...');
    return http
      .post(ApiConstants.AUTH.SWITCH_TENANT, params)
      .finally(removeLoader);
  }
  public checkUserSubscription = () => {
    return http.get(ApiConstants.SUBSCRIPTION.SALES)
    .then((data) => {
      AuthManager.setUserPlanDetails(data);
      if (AuthManager.isPlanActive()) {
        return Promise.resolve(data);
      } else {
        return Promise.reject(data);
      }
    })
    .catch((error) => {
      return Promise.reject("Failed to fetch user subscription");
    });
  }
  static fetchUsersInfo = (params: any = {}) => {
    return http.get(ApiConstants.TENANT.FETCH_USER_INFO, { params });
}
}

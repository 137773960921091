import { PAGE_ROUTES } from '../constants/Constant';
import { COMMON_EVENTS } from '../constants/Enum';
import { commonCustomEvent } from '../services/events';
import AppManager from './AppManager';

export default class RouteManager {
  static presenter: any = null;

  static setPresenter(presenter: any) {
    this.presenter = presenter;
  }

  static navigateToPage(pageRoute: string, param = '') {
    RouteManager.presenter.props.history.push(
      pageRoute + (param ? '?' + param : '')
    );
    AppManager.scrollToTop();
    commonCustomEvent.dispatch(COMMON_EVENTS.NAVIGATION_TRIGGERED, {});
  }

  static navigateToHome() {
    RouteManager.navigateToPage(PAGE_ROUTES.REPORT_DASHBOARD);
  }
}

import React, { useEffect, useState } from 'react';
import { DKButton, showLoader, removeLoader } from 'deskera-ui-library';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getQueryParam } from '../../utilities/Common';
import AppIntegrationService from '../../services/appIntegration';
import { fetchAppIntegrationMapping } from '../../store/slices/appIntegrationSlice';

interface DropBoxButtonProps {
  redirectURL?: string;
  needDisconnectButton?: boolean;
  buttonClassName?: string;
  styles?: { [key: string]: any };
  needDarkIcon?: boolean;
  onSuccess: (files: any[]) => void;
  onCancel: () => void;
  onError: (err: any) => void;
}

export default function DropBoxButton(props: DropBoxButtonProps) {
  const redirectUrl =
    props.redirectURL || `${window.location.origin}/settings/connect`;
  const login_url = `https://www.dropbox.com/oauth2/authorize?
client_id=${process.env.REACT_APP_DROPBOX_CLIENT_ID}&
redirect_uri=${redirectUrl}&
scope=account_info.read+files.metadata.write+files.metadata.read+files.content.write+files.content.read+sharing.write+sharing.read+file_requests.write+file_requests.read+contacts.write+contacts.read+profile+openid&
state=dropbox&
response_type=code&
token_access_type=offline&
prompt=login`;

  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const appIntegrationMapping = useAppSelector(
    (state) => state.appIntegration.connections
  );

  useEffect(() => {
    const authCode = getQueryParam('code');
    const state = getQueryParam('state');
    if (authCode && state === 'dropbox') {
      setLoading(true);
      const payload = {
        code: authCode,
        realmId: process.env.REACT_APP_DROPBOX_CLIENT_ID,
        state: state,
        appName: 'DROPBOX',
        redirectUrl: redirectUrl
      };
      AppIntegrationService.connectToApp(payload).then((data) => {
        dispatch(fetchAppIntegrationMapping());
      }).catch((err) => {
        console.error('Error while connecting app: ', err);
      }).finally(() => {
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* ************** HANDLERS *************** */
  const onDisconnectAccount = (dropBoxApp: any) => {
    showLoader('Disconnecting...');
    const payload = {
      app: dropBoxApp.appName,
      username: dropBoxApp.username
    };
    AppIntegrationService.disconnectApp(payload).then(
      (data) => {
        dispatch(fetchAppIntegrationMapping());
        removeLoader();
      },
      (err) => {
        console.error('Error while disconnecting app: ', err);
        removeLoader();
      }
    );
  };

  const triggerBrowseFileDialogue = () => {
    console.log(window);


    try {
      if (!(window as any)['Dropbox']) return;

      let odOptions = {
        clientId: process.env.REACT_APP_DROPBOX_CLIENT_ID,
        action: 'query',
        multiSelect: false,
        folderselect: false,
        // advanced: {
        //   endpointHint: 'api.dropbox.com'
        // },
        success: (files: any) => {
          if (props.onSuccess) {
            props.onSuccess(files);
          }
        },
        cancel: () => {
          if (props.onCancel) {
            props.onCancel();
          }
        },
        error: (error: any) => {
          console.error('Error loading picker: ', error);
          if (props.onError) {
            props.onError(error);
          }
        }
      };

      (window as any)['Dropbox'].choose(odOptions);
    } catch (err) {
      console.error('Error launching DropBox picker: ', err);
    }
  };

  const getConnectedAccountData = () => {
    try {
      const dropBoxAppList =
        appIntegrationMapping?.filter(
          (app: any) => app.appName === 'DROPBOX'
        ) || [];

      const dropBoxApp =
         dropBoxAppList.find((app) => app.connected) ||  dropBoxAppList[0];
      return  dropBoxApp;
    } catch (err) {
      return null;
    }
  };

  /* ************** RENDERERS *************** */
  const getDropBoxBtn = () => {
    const dropBoxApp = getConnectedAccountData();
    return ! dropBoxApp?.['connected'] ? (
      <DKButton
        title={loading ? "Connecting..." : "Connect to Dropbox"}
        className={
          props.buttonClassName || 'border-m bg-button text-white border-blue'
        }
        style={props.styles || {}}
        onClick={() => {
          window.location.href = login_url;
        }}
      />
    ) : props.needDisconnectButton ? (
      <DKButton
        title="Disconnect Dropbox"
        className="border-m bg-button text-white border-blue"
        onClick={() => onDisconnectAccount(dropBoxApp)}
      />
    ) : (
      <DKButton
        title="Browse Dropbox"
        className={
          props.buttonClassName || 'border-m bg-button text-white border-blue'
        }
        style={props.styles || {}}
        onClick={() => triggerBrowseFileDialogue()}
      />
    );
  };

  return getDropBoxBtn();
}

import { COLORS } from '../constants/Constant';
import { isEmpty } from '../utilities/Common';

export default class ChartDataParser {
  static commonDataParser(selectedColumns, selectedTableName, dataArray) {
    if (dataArray === null || dataArray.length === 0) {
      return [];
    }
    let result = [];
    for (let i = 0; i < dataArray.length; i++) {
      let record = dataArray[i];
      let chartItem = {
        title: '',
        color: COLORS[i],
        value: 0
      };
      // name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
      for (const [key, value] of Object.entries(record)) {
        let splitted = key.split('__');
        if (splitted.length === 2) {
          chartItem[splitted[1]] = value;
        }

        if (typeof value === 'string' && (key.includes('name') || isEmpty(chartItem.title))) {
          chartItem['title'] = value;
        }

        chartItem.value = Number(value) || chartItem.value;
      }

      if (isEmpty(chartItem.title)) {
        chartItem.title = 'record ' + (i + 1);
      }
      
      result.push(chartItem);
    }
    return result;
  }

  static createDataArrayForBar(selectedColumns, selectedTableName, dataArray) {
    return ChartDataParser.commonDataParser(
      selectedColumns,
      selectedTableName,
      dataArray
    );
  }

  static createDataArrayForLine(selectedColumns, selectedTableName, dataArray) {
    if (dataArray === null || dataArray.length === 0) {
      return [];
    }
    const lines = [];
    const lineTitleToPointsMap = {};
    const recordTitles = [];
    for (let i = 0; i < dataArray.length; i++) {
      let record = dataArray[i];
      recordTitles.push('');
      /** 
       * for record: {name: 'Page A', uv: 4000, pv: 2400, amt: 2400} 
       * 
       * 3 lines will be plotted:
       * uv, pv, amt
       * with titles in data points as "name"
      */
      Object.entries(record).forEach(([key, value]) => {
        if (isEmpty(value)) return;

        if (!isNaN(Number(value))) {
          let lineTitle = key;
          let splitted = key.split('__');
          if (splitted.length === 2) {
            lineTitle = splitted[1];
          }

          lineTitleToPointsMap[lineTitle] =
            lineTitleToPointsMap[lineTitle] || {};
          lineTitleToPointsMap[lineTitle][i] = Number(value);
        } else if (typeof value === 'string' && (key.includes('name') || isEmpty(recordTitles[i]))) {
          recordTitles[i] = value;
        }
      });

      if (isEmpty(recordTitles[i])) {
        recordTitles[i] = `record ${i + 1}`;
      }
    }

    Object.keys(lineTitleToPointsMap).forEach((lineTitle, lineIndex) => {
      const pointValueMap = lineTitleToPointsMap[lineTitle];
      const points = [];

      recordTitles.forEach((recordTitle, index) => {
        points.push({
          title: recordTitle,
          value: pointValueMap[index] ?? 0
        });
      });

      if (lineIndex < 6) {
        lines.push({
          title: lineTitle,
          color: COLORS[lineIndex],
          points: points
        });
      }
    });

    return lines;
  }

  static createDataArrayForPieChart(columnData, selectedTable, dataArray) {
    return ChartDataParser.commonDataParser(
      columnData,
      selectedTable,
      dataArray
    );
  }

  static createDataArrayForStackBar(
    selectedColumns,
    selectedTableName,
    dataArray
  ) {
    if (dataArray === null || dataArray.length === 0) {
      return [];
    }
    let result = [];
    for (let i = 0; i < dataArray.length; i++) {
      let record = dataArray[i];
      let chartItem = {
        title: '',
        stackBars: [],
        total: 0
      };

      let colorIndex = 0;
      for (const [key, value] of Object.entries(record)) {
        let splitted = key.split('__');
        if (splitted.length === 2 && typeof value === 'number') {
          chartItem.stackBars.push({
            title: splitted[1],
            value: value,
            color: COLORS[colorIndex++]
          });

          chartItem.total += value;
        }

        if (typeof value === 'string' && (key.includes('name') || isEmpty(chartItem.title))) {
          chartItem['title'] = value;
        }
      }

      if (isEmpty(chartItem.title)) {
        chartItem.title = 'record ' + (i + 1);
      }

      result.push(chartItem);
    }
    return result;
  }
}

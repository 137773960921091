/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  DKIcons,
  DKButton,
  DKLabel,
  DKFilterPopup,
  DKDateRangePicker,
  DKRadioButton,
  INPUT_TYPE,
  DKInput,
  Toggle
} from 'deskera-ui-library';
import RouteManager from '../../managers/RouteManager';

import {
  initialReportHeaderState,
  ReportHeaderProps,
  ReportHeaderState,
  REPORT_HEADER_ITEMS
} from '../../models/AccountingReport';
import {
  ACCOUNTING_METHOD,
  CUSTOM_FIELD_TYPE,
  DATE_FORMATS,
  FINANCIAL_REPORT,
  SWITCH_CURRENCY_OPTIONS
} from '../../constants/Enum';
import { APP_NAME, PAGE_ROUTES } from '../../constants/Constant';
import { deepCloneObject, isEmpty } from '../../utilities/Common';
import { getDateFromString, getDateStrFromDate } from '../../utilities/Date';
import MappingPopup from './MappingPopup';
import AddCustomComparisonMode from './AddCustomComparisonMode';
import { getTenantInfo, getUsers } from '../../store/slices/tenantSlice';
import { useAppSelector } from '../../store/hooks';
import AccountingService from '../../services/reports/Accounting';
import ReportFilter from './ReportFilter';
import { CustomFieldOption } from './TrialBalanceFilter';
import { isViewportMobile } from '../../utilities/ViewportSize';

const AccountingReportHeader: React.FC<ReportHeaderProps> = (props) => {
  const valueStyle = {
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 26,
    marginTop: 3,
    backgroundColor: 'rgb(240, 240, 243)'
  };

  const titleStyle = { color: 'gray', fontSize: 12 };
  const inputWidth = 160;
  const [reportHeaderState, setReportHeaderState] = useState<ReportHeaderState>(
    deepCloneObject(initialReportHeaderState)
  );
  const [filters, setFilters] = useState<any[]>([]);
  const [customFieldsOptions, setCustomFieldsOptions] = useState<any>(null);
  const [searchText, setSearchText] = useState<any>('');
  const [taxAgencyOptions, setTaxAgencyOptions] = useState<any>([]);
  const [showMapping, setShowMapping] = useState<boolean>(false);

  const [showAddCustomModeBlock, setShowAddCustomModeBlock] = useState(false);
  const tenantInfo = useAppSelector(getTenantInfo());
  const usersListInfo = useAppSelector(getUsers());

  const getSwitchCurrencyOptions = () => {
    let switchCurrencyOptions = [
      {
        title: `Base Currency (${tenantInfo?.currency})`,
        value: SWITCH_CURRENCY_OPTIONS.BASE_CURRENCY
      },
      {
        title: `Primary Currency (${tenantInfo?.additionalSettings?.MULTI_COMPANY?.primaryCurrencyCode})`,
        value: SWITCH_CURRENCY_OPTIONS.PRIMARY_CURRENCY
      },
      {
        title: `Both`,
        value: SWITCH_CURRENCY_OPTIONS.BOTH
      }
    ];
    return switchCurrencyOptions;
  };

  useEffect(() => {
    //callGetCustomFields();

    const updatedState = reportHeaderState;
    updatedState.columnBy.isHidden = props.columnBy.isHidden;
    updatedState.comparisionMode.isHidden = props.comparisionMode.isHidden;
    updatedState.accountingMethod.isHidden = props.accountingMethod.isHidden;
    updatedState.direct.hidden = !props.showDirect;
    updatedState.searchBar.hidden = !props.searchBar;
    updatedState.date.isHidden = props.dateRange.isHidden;
    if (props.taxAgency)
      updatedState.taxAgency.isHidden = props.taxAgency.isHidden;
    if (props.hasCustomField) {
      updatedState.hasCustomField = props.hasCustomField;
    }
    if (!props.columnBy.isHidden) {
      updatedState.columnBy.dataOptions = [...props.columnBy.dataOptions];
      updatedState.columnBy.currentValue = props.columnBy.currentValue;
    }

    if (!props.comparisionMode.isHidden) {
      updatedState.comparisionMode.dataOptions =
        props.comparisionMode.dataOptions;
      updatedState.comparisionMode.currentValue =
        props.comparisionMode.currentValue;
    }

    if (props?.customize?.isHidden === false) {
      updatedState.customize.isHidden = false;
    }
    if (props?.hideToggle === false) {
      updatedState.hideToggle = false;
    }
    if (props?.hasMapping) {
      updatedState.hasMapping = props.hasMapping;
    }

    if (!props.dateRange.isHidden) {
      updatedState.date.dataOptions = props.dateRange.dataOptions;
      updatedState.date.currentValue = props.dateRange.currentValue;
      if (props.dateRange.currentValue) {
        setStartAndEndDate(updatedState, updatedState.date.currentValue);
      }
    }

    if (
      !!updatedState.switchCurrency &&
      props.switchCurrency?.isHidden === false
    ) {
      updatedState.switchCurrency.dataOptions = getSwitchCurrencyOptions();
      updatedState.switchCurrency.currentValue =
        props.switchCurrency?.currentValue ||
        updatedState.switchCurrency.dataOptions[0];
    }

    updatedState.showFilterPopup.hidden = !props.showFilter;
    setReportHeaderState({ ...updatedState });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    const fetchTaxAgency = async () => {
      await AccountingService.getInstance()
        .getTaxAgency()
        .then((res: any) => {
          let options: any = [];
          res.forEach((row: any) => {
            options.push({ label: row.name, value: row.code });
          });

          setTaxAgencyOptions(options);
        })
        .catch((err: any) => {
          console.error(err);
        });
    };
    loadCustomFieldsByModules();
    fetchTaxAgency();
  }, []);

  const loadCustomFieldsByModules = async () => {
    try {
      const customFields =
        await AccountingService.getInstance().getCustomFieldByModules(
          APP_NAME.ERP
        );
      createCustomFieldOptions(customFields);
    } catch (err: any) {
      console.error('Error loading custom fields by module: ', err);
    }
  };

  const createCustomFieldOptions = (fields: any) => {
    let options: CustomFieldOption[] = [];
    Object.keys(fields).forEach((key) => {
      fields[key].forEach((field: any) => {
        options.push({
          id: field.code,
          type: field.fieldType.toLowerCase(),
          name: field.label,
          allowFilter: true,
          columnCode: field.code,
          key: field.code,
          required: true,
          module: field.module,
          options: field.attributes
        });
      });
    });

    setCustomFieldsOptions(options);
  };

  const getUniqueCustomFieldOptions = () => {
    let setObj = new Set<any>(); // create key value pair from array of array

    let results: any = [...(customFieldsOptions || [])]?.reduce((acc, item) => {
      if (!setObj.has(item.id)) {
        setObj.add(item.id);

        acc.push(item);
      }
      return acc;
    }, []); //converting back to array from mapobject

    results = results.map((res: any) => {
      const usersList: any[] = usersListInfo;
      if (res.type === INPUT_TYPE.DROPDOWN) {
        res.type = INPUT_TYPE.SELECT;
        res.options &&
          res.options.forEach((options: any) => {
            options['name'] = options['value'];
          });
      }
      if (res.type === CUSTOM_FIELD_TYPE.USER.toLowerCase()) {
        res = {
          ...res,
          type: INPUT_TYPE.SELECT,
          options: usersList.length
            ? usersList.map((user: any) => {
                let name = user.firstName?.trim();
                if (user.lastName?.trim()) {
                  name += ` ${user.lastName?.trim()}`;
                }

                return {
                  id: user.iamUserId?.toString(),
                  name: name,
                  value: user.iamUserId?.toString()
                };
              })
            : []
        };
      }
      return res;
    });

    return results;
  };

  const showHideCalendar = (value: boolean) => {
    const updatedState = reportHeaderState;
    updatedState.showCalendar = value;
    setReportHeaderState({ ...updatedState });
  };

  const showHideFilter = (value: boolean) => {
    const updatedState = reportHeaderState;
    updatedState.showFilterPopup.show = value;
    updatedState.showCustomFilterPopup.show = false;
    setReportHeaderState({ ...updatedState });
  };

  const showHideCustomFilter = (value: boolean) => {
    const updatedState = reportHeaderState;
    updatedState.showCustomFilterPopup.show = value;
    updatedState.showFilterPopup.show = false;
    setReportHeaderState({ ...updatedState });
  };

  const onReportFilterChange = (data: any) => {
    let updatedState = reportHeaderState;
    updatedState.showFilterPopup.data = data;
    updatedState.isFiltersApplied = false;
    if (
      data.customer.length > 0 ||
      data.product.length > 0 ||
      data.vendor.length > 0
    ) {
      updatedState.isFiltersApplied = true;
    }
    updatedState.showFilterPopup.show = false;
    setReportHeaderState({ ...updatedState });
  };

  const formUpdated = (value: any, type: REPORT_HEADER_ITEMS) => {
    const updatedState = reportHeaderState;
    switch (type) {
      case REPORT_HEADER_ITEMS.ACCOUNTING_METHOD:
        updatedState.accountingMethod.currentValue = value;
        break;
      case REPORT_HEADER_ITEMS.COLUMN_BY:
        updatedState.columnBy.currentValue = value;
        if (value.id === 10 || value.id === 9) {
          const monthDate = updatedState.date.dataOptions.find(
            (x: any) => x.id === 3
          );
          const comparisionMode = updatedState.comparisionMode.dataOptions.find(
            (y: any) => y.id === 1
          );
          if (monthDate) {
            updatedState.date.currentValue = monthDate;
            setStartAndEndDate(updatedState, monthDate);
          }

          if (comparisionMode) {
            updatedState.comparisionMode.currentValue = comparisionMode;
          }
        } else if (value.id === 3 || value.id === 4 || value.id === 2) {
          const comparisionMode = updatedState.comparisionMode.dataOptions.find(
            (y: any) => y.id === value.id
          );
          if (comparisionMode) {
            updatedState.comparisionMode.currentValue = comparisionMode;
          }
        } else {
          const comparisionMode = updatedState.comparisionMode.dataOptions.find(
            (y: any) => y.id === 1
          );

          if (comparisionMode) {
            updatedState.comparisionMode.currentValue = comparisionMode;
          }
        }
        break;
      case REPORT_HEADER_ITEMS.COMPARISION_MODE:
        updatedState.comparisionMode.currentValue = value;
        if (value.id === 2 || value.id === 3 || value.id === 4) {
          const columnBy = updatedState.columnBy.dataOptions.find(
            (x) => x.id === value.id
          );
          if (columnBy) {
            updatedState.columnBy.currentValue = columnBy;
          }
        } else if (value.id === 0) {
          setShowAddCustomModeBlock(true);
        }
        break;
      case REPORT_HEADER_ITEMS.DATE_RANGE:
        if (value.id === 8) {
          updatedState.showCalendar = true;
        }
        updatedState.date.currentValue = value;
        setStartAndEndDate(updatedState, value);
        break;
      case REPORT_HEADER_ITEMS.DIRECT:
        updatedState.direct.value = value;
        break;

      case REPORT_HEADER_ITEMS.TAX_AGENCY:
        if (updatedState.taxAgency !== undefined)
          updatedState.taxAgency.currentValue = value;

        break;
      case REPORT_HEADER_ITEMS.SWITCH_CURRENCY:
        if (updatedState.switchCurrency !== undefined)
          updatedState.switchCurrency.currentValue = value;
        break;
      default:
        break;
    }
    setReportHeaderState({ ...updatedState });
  };

  const setStartAndEndDate = (updatedState: ReportHeaderState, value: any) => {
    let days: any = getLastDays(value.id);
    let startDate = days[0];
    let endDate = days[1];
    updatedState.date.startDate = startDate
      ? getDateStrFromDate(startDate, DATE_FORMATS['DD-MM-YYYY'])
      : getDateStrFromDate(new Date(), DATE_FORMATS['DD-MM-YYYY']);
    updatedState.date.endDate = endDate
      ? getDateStrFromDate(endDate, DATE_FORMATS['DD-MM-YYYY'])
      : getDateStrFromDate(new Date(), DATE_FORMATS['DD-MM-YYYY']);
    updatedState.comparisionMode.currentValue =
      updatedState.comparisionMode.dataOptions.find(
        (mode: any) => mode?.id === 1
      );
  };

  const getLastDays = (id: any) => {
    let curr = new Date();
    let firstDay = new Date(curr.setDate(curr.getDate() - curr.getDay()));
    let lastDay = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6));

    if (id === 0) {
      firstDay = new Date(new Date().getFullYear(), 0, 1);
      lastDay = new Date();
      firstDay = new Date(firstDay.setFullYear(firstDay.getFullYear() - 10));
    }
    if (id === 1) {
      firstDay = new Date();
      lastDay = new Date();
    }
    if (id === 3) {
      curr = new Date();
      firstDay = new Date(curr.getFullYear(), curr.getMonth(), 1);
      lastDay = new Date(curr.getFullYear(), curr.getMonth() + 1, 0);
    }
    if (id === 4) {
      firstDay = new Date(new Date().getFullYear(), 0, 1);
      lastDay = new Date(new Date().getFullYear(), 11, 31);
    }
    if (id === 5) {
      firstDay = new Date(firstDay.setDate(firstDay.getDate() - 7));
      lastDay = new Date(lastDay.setDate(lastDay.getDate() - 7));
    }
    if (id === 6) {
      curr = new Date();
      firstDay = new Date(curr.getFullYear(), curr.getMonth(), 1);
      lastDay = new Date(curr.getFullYear(), curr.getMonth() + 1, 0);

      firstDay = new Date(firstDay.setMonth(firstDay.getMonth() - 1));
      lastDay = new Date(lastDay.setMonth(lastDay.getMonth() - 1));
    }
    if (id === 7) {
      firstDay = new Date(new Date().getFullYear(), 0, 1);
      lastDay = new Date(new Date().getFullYear(), 11, 31);
      firstDay = new Date(firstDay.setFullYear(firstDay.getFullYear() - 1));
      lastDay = new Date(lastDay.setFullYear(lastDay.getFullYear() - 1));
    }
    if (id === 8) {
    }
    if (id === 9) {
      return getCurrentPreviousQuarter('current');
    }
    if (id === 10) {
      return getCurrentPreviousQuarter('previous');
    }
    if (id === 11) {
      let today = new Date();
      let priorDate = new Date(new Date().setDate(today.getDate() - 30));
      firstDay = priorDate;
      lastDay = new Date();
    }
    if (id === 12) {
      let today = new Date();
      let priorDate = new Date(new Date().setDate(today.getDate() - 60));
      firstDay = priorDate;
      lastDay = new Date();
    }
    if (id === 13) {
      let today = new Date();
      let priorDate = new Date(new Date().setDate(today.getDate() - 90));
      firstDay = priorDate;
      lastDay = new Date();
    }
    if (id === 14) {
      let today = new Date();
      let priorDate = new Date(new Date().setDate(today.getDate() - 365));
      firstDay = priorDate;
      lastDay = new Date();
    }
    if (id === 15) {
      firstDay = new Date(firstDay.setDate(firstDay.getDate() + 7));
      lastDay = new Date(lastDay.setDate(lastDay.getDate() + 7));
    }
    if (id === 16) {
      curr = new Date();
      firstDay = new Date(curr.getFullYear(), curr.getMonth(), 1);
      lastDay = new Date(curr.getFullYear(), curr.getMonth() + 1, 0);

      firstDay = new Date(firstDay.setMonth(firstDay.getMonth() + 1));
      lastDay = new Date(lastDay.setMonth(lastDay.getMonth() + 1));
    }
    if (id === 17) {
      var today = new Date(),
        quarter = Math.floor(today.getMonth() / 3),
        startDate,
        endDate;
      startDate = new Date(today.getFullYear(), quarter * 3 + 3, 1);
      endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0);
      firstDay = startDate;
      lastDay = endDate;
    }
    if (id === 18) {
      firstDay = new Date(new Date().getFullYear(), 0, 1);
      lastDay = new Date(new Date().getFullYear(), 11, 31);
      firstDay = new Date(firstDay.setFullYear(firstDay.getFullYear() + 1));
      lastDay = new Date(lastDay.setFullYear(lastDay.getFullYear() + 1));
    }
    if (id === 19) {
      firstDay = new Date();
      lastDay = new Date(lastDay.setDate(new Date().getDate() + 28));
    }

    return [firstDay, lastDay];
  };

  const getCurrentPreviousQuarter = (value: any) => {
    var today = new Date(),
      quarter = Math.floor(today.getMonth() / 3),
      startDate,
      endDate;

    switch (value) {
      case 'previous':
        startDate = new Date(today.getFullYear(), quarter * 3 - 3, 1);
        endDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth() + 3,
          0
        );
        break;
      default:
        startDate = new Date(today.getFullYear(), quarter * 3, 1);
        endDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth() + 3,
          0
        );
        break;
    }

    return [startDate, endDate];
  };

  const handleNewPresetInfo = (presetInfo: any) => {
    const updatedState = reportHeaderState;
    updatedState.presetInfo = presetInfo;
    setReportHeaderState({ ...updatedState });
  };

  const updateDateRange = (startDate: Date, endDate: Date) => {
    if (startDate && endDate) {
      const updatedState = reportHeaderState;
      updatedState.date.startDate = getDateStrFromDate(
        startDate,
        DATE_FORMATS['DD-MM-YYYY']
      );
      updatedState.date.endDate = getDateStrFromDate(
        endDate,
        DATE_FORMATS['DD-MM-YYYY']
      );
      updatedState.showCalendar = false;
      setReportHeaderState({ ...updatedState });
    }
  };

  const getDateRangePicker = () => {
    return (
      <div
        className={`position-absolute bg-white border-m border-radius-s shadow-s z-index-3`}
        style={{
          top: 20,
          left: -200
        }}
      >
        <DKDateRangePicker
          className="border shadow "
          onClose={() => {
            showHideCalendar(false);
          }}
          color={'rgb(56, 79, 190)'}
          startDate={getDateFromString(
            reportHeaderState.date.startDate,
            DATE_FORMATS['DD-MM-YYYY']
          )}
          selectedStartDate={getDateFromString(
            reportHeaderState.date.startDate,
            DATE_FORMATS['DD-MM-YYYY']
          )}
          selectedEndDate={getDateFromString(
            reportHeaderState.date.endDate,
            DATE_FORMATS['DD-MM-YYYY']
          )}
          onSelectDateRange={(startDate: Date, endDate: Date) => {
            updateDateRange(startDate, endDate);
          }}
        />
      </div>
    );
  };

  const navigateToReport = () => {
    if (props.mrpReport) {
      //   RouteManager.navigateToPage(PAGE_ROUTES.MRP_REPORTS_LIST);
    } else {
      RouteManager.navigateToPage(PAGE_ROUTES.TEMPLATES);
    }
  };

  return (
    <div className="column border-box parent-width p-h-xs z-index-2">
      <div className="row ">
        <DKButton
          icon={DKIcons.ic_arrow_left}
          className="mr-ss mt-1"
          onClick={() => navigateToReport()}
          style={{
            paddingLeft: 0
          }}
        />
        <DKLabel text={props.reportType} className="fw-m fs-m mt-s mb-xs" />
      </div>

      <div
        className="row row-responsive justify-content-between flex-wrap mt-m bg-white p-l border-radius-s border-m "
        style={{ gap: 8 }}
      >
        <div className='row width-auto flex-wrap align-items-stretch' style={{ gap: 16 }}>
        {!reportHeaderState.columnBy.isHidden && (
          <div style={{ width: inputWidth }}>
            <DKInput
              type={INPUT_TYPE.DROPDOWN}
              required={false}
              direction="Vertical"
              title="Display columns by"
              titleStyle={titleStyle}
              valueStyle={valueStyle}
              value={reportHeaderState.columnBy.currentValue}
              displayKey="title"
              onChange={(value: any) =>
                formUpdated(value, REPORT_HEADER_ITEMS.COLUMN_BY)
              }
              dropdownConfig={{
                className: '',
                style: {},
                allowSearch: false,
                searchableKey: null,
                data: reportHeaderState.columnBy.dataOptions,
                renderer: (index: any, obj: any) => {
                  return <DKLabel text={obj.title} />;
                },
                onSelect: (index: any, value: any) => {}
              }}
            />
          </div>
        )}

        {!reportHeaderState.comparisionMode.isHidden && (
          <div style={{ width: inputWidth }}>
            <DKInput
              type={INPUT_TYPE.DROPDOWN}
              required={false}
              direction="Vertical"
              title="Compare period"
              titleStyle={titleStyle}
              valueStyle={valueStyle}
              value={reportHeaderState.comparisionMode.currentValue}
              displayKey="title"
              formatter={(obj: any) => {
                return obj.vname;
              }}
              onChange={(value: any) => {
                formUpdated(value, REPORT_HEADER_ITEMS.COMPARISION_MODE);
              }}
              dropdownConfig={{
                className: '',
                style: {},
                allowSearch: false,
                searchableKey: 'vname',
                data: reportHeaderState.comparisionMode.dataOptions,
                renderer: (index: any, obj: any) => {
                  return <DKLabel text={obj.vname} />;
                },
                onSelect: (index: any, value: any) => {}
              }}
            />
          </div>
        )}

        {!reportHeaderState.date.isHidden && (
          <div
            className="position-relative"
            style={{ width: props.removeValueStyle ? '350px' : inputWidth }}
          >
            <DKInput
              type={INPUT_TYPE.DROPDOWN}
              required={false}
              direction="Vertical"
              title="Report period"
              titleStyle={titleStyle}
              valueStyle={props.removeValueStyle ? null : valueStyle}
              formatter={(obj: any) => {
                return obj.title;
              }}
              value={reportHeaderState.date.currentValue}
              displayKey="title"
              onChange={(value: any) => {
                formUpdated(value, REPORT_HEADER_ITEMS.DATE_RANGE);
              }}
              dropdownConfig={{
                className: '',
                style: {},
                allowSearch: false,
                searchableKey: null,
                data: reportHeaderState.date.dataOptions,
                renderer: (index: any, obj: any) => {
                  return <DKLabel text={obj.title} />;
                },
                onSelect: (index: any, value: any) => {}
              }}
            />
            {reportHeaderState.showCalendar && getDateRangePicker()}
          </div>
        )}

        {!reportHeaderState.taxAgency.isHidden && (
          <div className="position-relative" style={{ width: inputWidth }}>
            <DKInput
              type={INPUT_TYPE.DROPDOWN}
              required={false}
              direction="Vertical"
              title="Tax Agency"
              titleStyle={titleStyle}
              valueStyle={valueStyle}
              formatter={(obj: any) => {
                return obj.label;
              }}
              value={reportHeaderState.taxAgency?.currentValue}
              displayKey="title"
              onChange={(value: any) => {
                formUpdated(value, REPORT_HEADER_ITEMS.TAX_AGENCY);
              }}
              dropdownConfig={{
                className: '',
                style: {},
                allowSearch: false,
                searchableKey: null,
                data: taxAgencyOptions,
                renderer: (index: any, obj: any) => {
                  return <DKLabel text={obj.label} />;
                },
                onSelect: (index: any, value: any) => {}
              }}
            />
          </div>
        )}

        {!reportHeaderState.searchBar.hidden && (
          <div style={{ width: '350px' }} className="mr-r">
            <DKInput
              type={INPUT_TYPE.TEXT}
              required={false}
              direction="Vertical"
              title="Search by: Product name, code, serial/batch, WO code"
              titleStyle={titleStyle}
              //   valueStyle={searchValueStyle}
              value={searchText}
              displayKey="title"
              onChange={(value: any) => {
                // formUpdated(value, REPORT_HEADER_ITEMS.DATE_RANGE);
                setSearchText(searchText?.toLowerCase());
              }}
            />
          </div>
        )}

        {!reportHeaderState.direct.hidden && (
          <div>
            <DKLabel style={titleStyle} text={'Direct'} className="mb-r" />
            <Toggle
              onChange={() => {
                formUpdated(
                  !reportHeaderState.direct.value,
                  REPORT_HEADER_ITEMS.DIRECT
                );
              }}
              isOn={reportHeaderState.direct.value}
            />
          </div>
        )}

        {!reportHeaderState.accountingMethod.isHidden && (
          <div className="column">
            <DKLabel
              style={titleStyle}
              text="Accounting Method"
              className="mb-r"
            />
            <div className="row width-auto mb-xs mt-xs">
              <DKRadioButton
                title="Accrual"
                color="bg-blue"
                className="mr-r"
                isSelected={
                  reportHeaderState.accountingMethod.currentValue ===
                  ACCOUNTING_METHOD.ACCRUAL
                }
                onClick={() => {
                  formUpdated(
                    ACCOUNTING_METHOD.ACCRUAL,
                    REPORT_HEADER_ITEMS.ACCOUNTING_METHOD
                  );
                }}
              />
              <DKRadioButton
                title="Cash"
                color="bg-blue"
                isSelected={
                  reportHeaderState.accountingMethod.currentValue ===
                  ACCOUNTING_METHOD.CASH
                }
                onClick={() => {
                  formUpdated(
                    ACCOUNTING_METHOD.CASH,
                    REPORT_HEADER_ITEMS.ACCOUNTING_METHOD
                  );
                }}
              />
            </div>
          </div>
        )}
        {!reportHeaderState.hideToggle && (
          <div className="column width-auto">
            <DKLabel
              text="Show non zero"
              className=" mb-s "
              style={{ color: 'gray', fontSize: 12 }}
            />
            <Toggle
              className="mt-xs"
              isOn={reportHeaderState.toggle}
              onChange={() => {
                let newState: any = {
                  ...reportHeaderState,
                  toggle: !reportHeaderState.toggle
                };
                setReportHeaderState(newState);
                props.toggle(newState);
              }}
            />
          </div>
        )}
        {props.switchCurrency?.isHidden === false &&
          !!tenantInfo?.additionalSettings?.MULTI_COMPANY && (
            <div
              className="position-relative"
              style={{ width: props.removeValueStyle ? '350px' : inputWidth }}
            >
              <DKInput
                type={INPUT_TYPE.DROPDOWN}
                required={false}
                direction="Vertical"
                title="Switch Currency"
                titleStyle={titleStyle}
                valueStyle={props.removeValueStyle ? null : valueStyle}
                formatter={(obj: any) => {
                  return obj.title;
                }}
                value={reportHeaderState.switchCurrency?.currentValue}
                displayKey="title"
                onChange={(value: any) => {
                  formUpdated(value, REPORT_HEADER_ITEMS.SWITCH_CURRENCY);
                }}
                dropdownConfig={{
                  className: '',
                  style: {},
                  allowSearch: false,
                  searchableKey: null,
                  data: reportHeaderState.switchCurrency?.dataOptions,
                  renderer: (index: any, obj: any) => {
                    return <DKLabel text={obj.title} />;
                  },
                  onSelect: (index: any, value: any) => {}
                }}
              />
            </div>
          )}
        {reportHeaderState.hasCustomField && !isEmpty(getUniqueCustomFieldOptions()) && (
          <div className="column">
            <DKLabel
              text="Custom Filter"
              className="mb-s"
              style={{ color: 'gray', fontSize: 12 }}
            />
            <div className="position-relative">
              <DKButton
                icon={DKIcons.ic_filter}
                className="report-filter-popup-btn"
                onClick={() => {
                  showHideCustomFilter(true);
                }}
              />
              {reportHeaderState.showCustomFilterPopup.data?.length > 0 && (
                <div
                  className="position-absolute bg-red"
                  style={{
                    width: 5,
                    height: 5,
                    top: -3,
                    right: 5,
                    borderRadius: 3
                  }}
                ></div>
              )}
              {reportHeaderState.showCustomFilterPopup.show &&
                customFieldsOptions && (
                  <div
                    style={{
                      width: 480,
                      position: 'absolute',
                      top: -20,
                      left: -200,
                      zIndex: 1
                    }}
                  >
                    <DKFilterPopup
                      headerTitle="Custom Field Filters"
                      filterData={[...filters]}
                      headers={getUniqueCustomFieldOptions()}
                      onSubmit={(data: any) => {
                        setFilters(data);
                        setReportHeaderState({
                          ...reportHeaderState,
                          showCustomFilterPopup: {
                            ...reportHeaderState.showCustomFilterPopup,
                            show: false,
                            data: data,
                            options: getUniqueCustomFieldOptions(),
                            customFieldOptions: customFieldsOptions
                          }
                        });
                      }}
                      onCancel={() => {
                        setReportHeaderState({
                          ...reportHeaderState,
                          showCustomFilterPopup: {
                            ...reportHeaderState.showCustomFilterPopup,
                            show: false
                          }
                        });
                      }}
                    />
                  </div>
                )}
            </div>
          </div>
        )}

        {reportHeaderState.hasMapping && (
          <DKButton
            icon={DKIcons.ic_settings}
            className="cursor-pointer mt-2"
            onClick={() => {
              setShowMapping(true);
            }}
          />
        )}
        

        {!reportHeaderState.showFilterPopup.hidden && (
          <div className="column">
            <DKLabel style={titleStyle} text="Filter" className="mb-s" />
            <div className="position-relative">
              <DKButton
                className="report-filter-popup-btn"
                icon={DKIcons.ic_filter}
                onClick={() => showHideFilter(true)}
              />
              {reportHeaderState.isFiltersApplied && (
                <div
                  className="position-absolute bg-red"
                  style={{
                    width: 5,
                    height: 5,
                    top: 5,
                    right: 5,
                    borderRadius: 3
                  }}
                ></div>
              )}
              {reportHeaderState.showFilterPopup.show && (
                <div
                  style={{
                    width: 400,
                    maxWidth: '80vw',
                    position: 'absolute',
                    top: isViewportMobile() ? '-5vw' : -34,
                    left: isViewportMobile() ? '-5vw' : -200,
                    zIndex: 1
                  }}
                >
                  <ReportFilter
                    appliedFilter={reportHeaderState.showFilterPopup.data}
                    applyFilter={(data: any) => onReportFilterChange(data)}
                    onClose={() => {
                      const updatedState = reportHeaderState;
                      updatedState.showFilterPopup.show = false;
                      setReportHeaderState({ ...updatedState });
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        </div>
        <div
          className="bg-gray2 display-only-web flex-shrink-0"
          style={{ width: 1, height: '100%', minHeight: 100 }}
        />
        <div className="column width-auto" style={{ gap: 8 }}>
          {!reportHeaderState.customize.isHidden && (
            <DKButton
              title="Customize"
              className="bg-gray2 fw-m"
              onClick={() => {
                //props.runReport(reportHeaderState);
                props.customizePopup && props.customizePopup(reportHeaderState);
              }}
            />
          )}
          <DKButton
            title="Run Report"
            className="bg-button text-white fw-m"
            onClick={() => {
              setReportHeaderState({ ...reportHeaderState, toggle: false });
              props.runReport(reportHeaderState);
            }}
          />
        </div>
      </div>

      {showMapping && (
        <MappingPopup
          onCancel={() => {
            setShowMapping(false);
          }}
          onSave={() => {}}
        />
      )}

      {showAddCustomModeBlock && (
        <AddCustomComparisonMode
          reportType={FINANCIAL_REPORT.PNL}
          onCustomComparisonModeChange={(presetInfo: any) => {
            handleNewPresetInfo(presetInfo);
            setShowAddCustomModeBlock(false);
          }}
          onAddCustomComparisonModeClose={() => {
            setShowAddCustomModeBlock(false);
          }}
        />
      )}
    </div>
  );
};
export default AccountingReportHeader;

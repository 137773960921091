import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import printJS from 'print-js';

export default class PdfUtility {
  static async generateCanvas(element: HTMLElement) {
    if (element !== null) {
      return await html2canvas(element, {
        allowTaint: true,
        scale: 0.5
      }).then((canvas: any) => {
        return canvas;
      });
    }
  }

  generatePdf(
    imgData: any,
    canvasWidth: number,
    canvasHeight: number,
    documentNumber: string,
    isGenerateUrl = false,
    isLandscape = false
  ) {
    let pdf = new jsPDF(isLandscape ? 'landscape' : 'p', 'px', 'a4', true);

    let width = pdf.internal.pageSize.getWidth();
    let height = pdf.internal.pageSize.getHeight();

    let widthRatio = width / canvasWidth;
    let heightRatio = height / canvasHeight;

    let ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

    pdf.addImage(
      imgData,
      'JPG',
      0,
      0,
      canvasWidth * ratio,
      canvasHeight * ratio,
      '',
      undefined,
      undefined
    );
    if (isGenerateUrl) {
      return pdf.output('blob');
    } else {
      pdf.save(
        documentNumber.length > 0 ? documentNumber + '.pdf' : 'Document.pdf'
      );
    }
  }

  async getPDFUrl(element: any, documentNumber: string) {
    if (element !== null) {
      //1125 x 793
      return await html2canvas(element, {
        allowTaint: true
      }).then((canvas: any) => {
        // document.body.appendChild(canvas);  // if you want see your screenshot in body.
        const imgData = canvas.toDataURL('image/jpg');
        return this.generatePdf(
          imgData,
          canvas.width,
          canvas.height,
          documentNumber,
          false
        );
      });
    }
  }

  static printLandscapeHtml(element: any, isLandscape = false) {
    try {
      if (element === null) {
        return;
      }
      let clone = element.cloneNode(true);
      clone.childNodes[1].style.maxWidth = 'none';
      clone.childNodes[1].id = 'tmp-report';

      const finalClone = document.body.appendChild(clone.cloneNode(true));

      finalClone.style.width = 'auto';
      let tmpWidth =
        (document.getElementById('tmp-report')?.getBoundingClientRect().width ||
          200) + 'px';

      finalClone.style.width = tmpWidth;
      finalClone.id = 'new-temp';
      let pdf = finalClone.getBoundingClientRect();
      html2canvas(finalClone, {
        allowTaint: true, //DOMException: Failed to execute 'toDataURL' on 'HTMLCanvasElement': Tainted canvases may not be exported.
        useCORS: true
      }).then((canvas: any) => {
        const imgData = canvas.toDataURL('image/jpg');
        var img = new Image();
        img.src = imgData;
        img.height = pdf.height;
        img.width = pdf.width;
        setTimeout(function () {
          printJS({
            printable: img.src,
            type: 'image',
            header: '',
            // TODO: pass landscape based on selected report
            style: `@page { size: A4 ${isLandscape ? 'landscape' : ''} }`
          });
          // pri.focus();
          // pri.print();
        }, 500);
        clone.remove();
        finalClone.remove();
      });
    } catch (err) {
      console.error('Error printing report', err);
    }
  }

  async getPDFUrlForEmailLandscape(
    element: any,
    documentNumber: string,
    isLandscape = false
  ) {
    if (element !== null) {
      //1125 x 793
      let clone = element.cloneNode(true);

      clone.childNodes[1].style.maxWidth = 'none';
      clone.childNodes[1].id = 'tmp-report';

      const finalClone = document.body.appendChild(clone.cloneNode(true));

      finalClone.style.width = 'auto';
      let tmpWidth =
        (document.getElementById('tmp-report')?.getBoundingClientRect().width ||
          200) + 'px';

      finalClone.style.width = tmpWidth;
      finalClone.id = 'new-temp';
      return await html2canvas(isLandscape ? finalClone : element, {
        allowTaint: true
      }).then((canvas: any) => {
        // document.body.appendChild(canvas);  // if you want see your screenshot in body.
        const imgData = canvas.toDataURL('image/jpg');
        clone.remove();
        finalClone.remove();
        return this.generatePdf(
          imgData,
          canvas.width,
          canvas.height,
          documentNumber,
          true,
          isLandscape
        );
      });
    }
  }

  async getPDFUrlForEmail(element: any, documentNumber: string) {
    if (element !== null) {
      //1125 x 793
      return await html2canvas(element, {
        allowTaint: true
      }).then((canvas: any) => {
        // document.body.appendChild(canvas);  // if you want see your screenshot in body.
        const imgData = canvas.toDataURL('image/jpg');
        return this.generatePdf(
          imgData,
          canvas.width,
          canvas.height,
          documentNumber,
          true
        );
      });
    }
  }
}

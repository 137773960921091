import ApiConstants from '../constants/ApiConstant';
import http from './index';

class AppIntegrationService {
  static getAuthURL(): Promise<any> {
    return http.get(ApiConstants.URL.APP_INTEGRATION.GET_AUTH_URL);
  }
  static connectToApp(payload: any): Promise<any> {
    return http.post(ApiConstants.URL.APP_INTEGRATION.CONNECT, payload);
  }
  static disconnectApp(payload: any): Promise<any> {
    const finalUrl = `${ApiConstants.URL.APP_INTEGRATION.DISCONNECT}?app=${payload.app}&username=${payload.username}`;
    return http.post(finalUrl);
  }
  static getAppIntegrationMapping(): Promise<any> {
    return http.get(ApiConstants.URL.APP_INTEGRATION.MAPPING);
  }

  /* ******************* ONE DRIVE FILES ****************** */
  static getOneDriveFileList(): Promise<any[]> {
    return http.get(
      ApiConstants.URL.APP_INTEGRATION.GET_ONE_DRIVE_FILES + '&limit=100&page=0'
    );
  }

  static saveOneDriveFiles(filesData: any): Promise<any> {
    return http.post(
      ApiConstants.URL.APP_INTEGRATION.SAVE_ONE_DRIVE_FILES,
      filesData
    );
  }

  static saveOneDriveFilesColumnMapping(
    fileId: number,
    mappingData: any
  ): Promise<any> {
    return http.put(
      ApiConstants.URL.APP_INTEGRATION.UPDATE_ONE_DRIVE_FILES_COLUMN_MAPPING(
        fileId
      ),
      mappingData,
      { params: { skipInterceptor: true } }
    );
  }
  /* ******************* DROP BOX FILES ****************** */
  static getDropBoxFileList(): Promise<any[]> {
    return http.get(
      ApiConstants.URL.APP_INTEGRATION.GET_DROP_BOX_FILES + '&limit=100&page=0'
    );
  }

  static saveDropBoxFiles(filesData: any): Promise<any> {
    return http.post(
      ApiConstants.URL.APP_INTEGRATION.SAVE_DROP_BOX_FILES,
      filesData
    );
  }

  static saveDropBoxFilesColumnMapping(
    fileId: number,
    mappingData: any
  ): Promise<any> {
    return http.put(
      ApiConstants.URL.APP_INTEGRATION.UPDATE_ONE_DRIVE_FILES_COLUMN_MAPPING(
        fileId
      ),
      mappingData,
      { params: { skipInterceptor: true } }
    );
  }
}

export default AppIntegrationService;

export class ReportManager {
    private static _instance = new ReportManager();
    private selectedReport = null;
    public static geInstance() {
        if (!this._instance) {
            this._instance = new ReportManager();
        }
        return this._instance;
    }
    public setSelectedReport(data: any) {
        this.selectedReport = data;
    }
    public getSelectedReport(): any {
        return this.selectedReport;
    }
}
import React, { Component } from 'react';
import { DKLabel } from 'deskera-ui-library';

/*
PROPS
- data {style, title}
*/

class ReportTableColumn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      data: nextProps.data
    });
  }
  render() {
    return (
      <div style={this.state.data.style}>
        <DKLabel
          className="p-v-xs"
          style={{
            fontWeight:
              this.props.data.type === 'bold' ||
              this.props.data.type === 'bold-line' ||
              this.props.data.type === 'bold-lines'
                ? '600'
                : 'normal',
            borderTop:
              this.props.data.type === 'bold-line' ||
              this.props.data.type === 'bold-lines'
                ? '1px solid lightGray'
                : 'none',
            borderBottom:
              this.props.data.type === 'bold-lines'
                ? '1px solid lightGray'
                : 'none',
            textAlign: this.state.data.style.textAlign
          }}
          text={this.state.data.title}
        />
      </div>
    );
  }
}

export default ReportTableColumn;

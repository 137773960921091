import React, { useState, useEffect } from 'react';
import { showToast, TOAST_TYPE, showLoader, removeLoader } from 'deskera-ui-library';
import CustomizePopup from '../components/accounting/CustomizePopup';
import {
  DATE_FORMATS,
  FINANCIAL_REPORT,
  REPORT_BASIS,
  SWITCH_CURRENCY_OPTIONS
} from '../constants/Enum';
import {
  accountingMethod,
  columnLogic,
  comparisionDataOptions,
  comparisionLog,
  dateFilterLogic,
  dateRangeOptions,
  getAmountBlockWithCurrency,
  getFinancialStartDate,
  getTimeStamp,
  makepayload
} from '../helper/SharedParser';
import { useAppSelector } from '../store/hooks';
import { getTenantInfo } from '../store/slices/tenantSlice';
import { useLocation } from 'react-router';
import RouteManager from '../managers/RouteManager';
import { deepCloneObject, isEmpty } from '../utilities/Common';
import AccountingService from '../services/reports/Accounting';
import {
  getParsedDataForBL,
  getParsedDataForBLInBothCurrency,
  getParsedSortedDataForBL,
  getParsedSortedDataForBLInBothCurrency
} from '../helper/BalanceSheetParser';
import {
  REPORT_HEADER_ITEMS,
  ReportHeaderState
} from '../models/AccountingReport';
import AccountingReportHeader from '../components/accounting/AccountingReportHeader';
import AccountingReportBody from '../components/accounting/AccountingReportBody';
import PdfUtility from '../utilities/PDFUtility';
import { getDateStrFromDate } from '../utilities/Date';
import EmailComposerPopUp from '../components/common/EmailComposerPopup';

interface LocationDetails {
  reportDetails: any;
}
export default function BalanceSheet(props: any) {
  const initialState = {
    Basis: { label: 'Accrual', value: REPORT_BASIS.ACCRUAL },
    StartDate: getFinancialStartDate(),
    EndDate: new Date(),
    ComparisonMode: { id: 1, vname: 'Off', custom: false },
    ColumnBy: null,
    SwitchCurrency: null
  };
  const [reportFilterState, setReportFilterState] = useState<any>({
    ...initialState
  });
  const tenantInfo = useAppSelector(getTenantInfo());
  const [showEmailPopUp, setShowEmailPopup] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customizeData, setCustomizeData] = useState<any>();
  const [toggled, setToggled] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState(false);
  const [reportData, setReportData] = useState<any>(null);
  const [pdfUrl, setPdfUrl] = useState<any>();
  const [lengthOfCols, setLengthOfCols] = useState<any>(0);
  const [comparisionModes, setComparisionModes] = useState([
    { id: 0, vname: 'Customize', custom: false }
  ]);
  const [passer, setPasser] = useState<any>();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [nameArray, setNameArray] = useState<any>();
  const [reportName, setReportName] = useState<any>('Balance Sheet');
  const [tenantName, setTenantName] = useState<any>('');
  const location = useLocation<LocationDetails>();
  const reportDetails = location.state?.reportDetails;
  const [columnByData, setColumnByData] = useState<any>();
  const [tableLoader, setTableLoader] = useState(false);
  const [exportPayload, setExportPayload] = useState<any>();
  const primaryCurrencyCode =
    tenantInfo?.additionalSettings?.MULTI_COMPANY?.primaryCurrencyCode;

  useEffect(() => {
    RouteManager.setPresenter({ props });
    const columnByData = [
      { id: 1, title: 'Total Only', vname: 'Off', custom: false },
      { id: 9, title: 'Days', vname: 'days', custom: false },
      { id: 10, title: 'Weeks', vname: 'weeks', custom: false },
      { id: 3, title: 'Months', vname: '6 Month', custom: false },
      {
        id: 4,
        title: 'Quarters',
        vname: 'Quarterly',
        custom: false
      },
      { id: 2, title: 'Years', vname: '3 Years', custom: false },
      { id: 5, title: 'Contact', vname: '3 Years', custom: false },
      { id: 6, title: 'Product', vname: '3 Years', custom: false }
    ];

    if (!isEmpty(tenantInfo)) {
      if (tenantInfo?.additionalSettings?.LOCATION?.trackLocation) {
        columnByData.push({
          id: 7,
          title: 'Location',
          vname: '3 Years',
          custom: false
        });
      }
      if (tenantInfo?.additionalSettings?.CLASS?.trackClasses) {
        columnByData.push({
          id: 8,
          title: 'Class',
          vname: 'class',
          custom: false
        });
      }

      // api calls
      if (reportDetails) {
        loadViewOptions(false);
      } else {
        loadViewOptions(true);
      }

      const payload = makepayload(reportFilterState);
      setExportPayload(payload);
      setColumnByData(columnByData);
      fetchReportDetails(payload);
    }
    return () => {
      // null
    };
  }, []);

  useEffect(() => {
    if (passer && passer.rows) {
      let arr = getNames(passer.rows);
      setNameArray(arr);
    }
  }, [passer]);

  useEffect(() => {
    if (tenantInfo) {
      setTenantName(tenantInfo.name);
    }
  }, [tenantInfo]);

  useEffect(() => {
    if (customizeData && customizeData.customViewData) {
      replaceNames(customizeData.customViewData);
    }
  }, [customizeData]);

  useEffect(() => {
    const updatedState = reportFilterState;
    if (reportDetails && comparisionModes.length > 1) {
      if (reportDetails.timePeriod === 12) {
        updatedState.ComparisonMode = comparisionModes[1];
        updatedState.ColumnBy = {
          id: 2,
          title: 'Years',
          vname: '3 Years',
          custom: false
        };
      }
      if (reportDetails.timePeriod === 1) {
        updatedState.ComparisonMode = comparisionModes[2];
        updatedState.ColumnBy = {
          id: 3,
          title: 'Months',
          vname: '6 Month',
          custom: false
        };
      }
      if (reportDetails.timePeriod === 4) {
        updatedState.ComparisonMode = comparisionModes[3];
        updatedState.ColumnBy = {
          id: 4,
          title: 'Quarters',
          vname: 'Quarterly',
          custom: false
        };
      }
      const payload = makepayload(updatedState);
      setExportPayload(payload);
      setReportFilterState({ ...updatedState });
      fetchReportDetails(payload);
    }
  }, [comparisionModes]);

  useEffect(() => {
    parsedDataForBL();
  }, [reportData]);

  const loadViewOptions = async (resetOption = false) => {
    if (props.reportType === FINANCIAL_REPORT.TRIAL_BALANCE) {
      return;
    }
    try {
      const options =
        await AccountingService.getInstance().getComparisonModeOptions(
          FINANCIAL_REPORT.PNL
        );
      setComparisionModes((prevState: any) => [
        ...options,
        { id: 0, vname: 'Customize', custom: false }
      ]);
      if (reportFilterState.PresetInfo?.PresetName) {
        setReportFilterState((prevState: any) => {
          return {
            ...prevState,
            PresetInfo: {
              ...reportFilterState.PresetInfo,
              PresetName: undefined,
              SaveAsPreset: false
            }
          };
        });
      } else if (resetOption) {
        setReportFilterState((prevState: any) => {
          return {
            ...prevState,
            ComparisonMode: options.filter(
              (option: any) => option.vname === 'Off'
            )[0]
          };
        });
      }
    } catch (err: any) {
      console.error('Error loading view options: ', err);
    }
  };

  const getEmailPopUp = () => {
    return (
      <EmailComposerPopUp
        documentData={reportData}
        reportTitle={'Balance Sheet'}
        removeEmailPopUp={() => {
          setShowEmailPopup(false);
        }}
        pdfURL={pdfUrl}
        isMobile={false}
        printingCompleted={() => {
          setShowEmailPopup(false);
        }}
      />
    );
  };

  const setZero = (rows: any) => {
    let newRow = deepCloneObject(rows);
    let indexesToRemove: any[] = [];

    rows.forEach((row: any, index: any) => {
      let toDelete = true;
      row.cells.forEach((cell: any, indexx: any) => {
        if (
          (cell.title === ' ' && indexx !== 0) ||
          (Number(cell.title.substring(1)) !== 0 && indexx !== 0)
        ) {
          toDelete = false;
          //return;
        }
      });
      rows?.children?.forEach((child: any) => {
        newRow.children = setZero(child);
      });
      if (toDelete === true && row.cells.length > 0) {
        // newRow.splice(index, 1);
        indexesToRemove.push(index);
      }
    });
    for (var i = indexesToRemove.length - 1; i >= 0; i--)
      newRow.splice(indexesToRemove[i], 1);

    return newRow;
  };

  const toggleZero = (state: any) => {
    if (state.toggle === false) {
      // setPasser(oldPasser);
      return;
    }

    const rows = setZero(passer.rows);

    let updatedState = passer;
    updatedState.rows = rows;
    setPasser({ ...updatedState });
  };

  const getNames = (rows: any) => {
    if (rows.length === 0) {
      return [];
    }
    let nameArray: any = [];
    rows.forEach((row: any, index: number) => {
      if (row?.cells?.length > 0) {
        if (
          row.cells[0].title !== '' &&
          row.cells[0].title !== ' ' &&
          !isEmpty(row.cells[0].title)
        ) {
          nameArray.push(row.cells[0].title);
        }
      }

      if (row?.children.length > 0) {
        let childNameArray: any = getNames(row.children);
        if (childNameArray?.length > 0) {
          nameArray = [...nameArray, ...childNameArray];
        }
      }
    });

    return nameArray;
  };

  const replaceNameState = (rows: any, name: any, replace: any) => {
    if (rows.length === 0) {
      return;
    }
    let nameArray: any = [];
    rows.forEach((row: any, index: number) => {
      if (row?.cells?.length > 0) {
        if (row.cells[0].title === name) {
          row.cells[0].title = replace;
        }
      }

      if (row?.children.length > 0) {
        replaceNameState(row.children, name, replace);
      }
    });

    return rows;
  };

  const exportDocumentBL = (format: string) => {
    AccountingService.getInstance()
      .exportBalanceSheet(format, {
        cmode: reportFilterState.ComparisonMode,
        basis: reportFilterState.Basis.value,
        presetInfo: reportFilterState.PresetInfo,
        filteredData: exportPayload,
        StartDate: getDateStrFromDate(
          reportFilterState.StartDate,
          DATE_FORMATS['DD-MM-YYYY']
        ),
        EndDate: getDateStrFromDate(
          reportFilterState.EndDate,
          DATE_FORMATS['DD-MM-YYYY']
        )
      })
      .then((res: any) => setIsExporting(false))
      .catch((err: any) => {
        showToast('Could Not Export Report', TOAST_TYPE.FAILURE);
      });
  };

  const parsedDataForBL = () => {
    const data =
      reportFilterState?.SwitchCurrency?.value === SWITCH_CURRENCY_OPTIONS.BOTH
        ? getParsedDataForBLInBothCurrency(
            reportData,
            reportFilterState,
            tenantInfo?.currency,
            primaryCurrencyCode
          )
        : getParsedDataForBL(
            reportData,
            reportFilterState,
            getActiveCurrency()
          );

    if (data) {
      setPasser(data.payload);
      fetchCustomziedReportDetails();

      setLengthOfCols(data.length);
    }
  };

  const parsedSortedDataForBL = (direction: any) => {
    const data =
      reportFilterState?.SwitchCurrency?.value === SWITCH_CURRENCY_OPTIONS.BOTH
        ? getParsedSortedDataForBLInBothCurrency(
            reportData,
            reportFilterState,
            direction,
            tenantInfo?.currency,
            primaryCurrencyCode
          )
        : getParsedSortedDataForBL(
            reportData,
            reportFilterState,
            direction,
            getActiveCurrency()
          );

    if (data) {
      setPasser(data.payload);
      fetchCustomziedReportDetails();

      setLengthOfCols(data.length);
    }
  };

  const fetchCustomziedReportDetails = () => {
    AccountingService.getInstance()
      .getCustomizedReport('BALANCE_SHEET')
      .then((res) => {
        let data: any = { ...res };
        setCustomizeData(data[0]);
      })
      .catch((err) => {});
  };
  const postNames = (data: any) => {
    let data1: any = data[0];
    data1['reportName'] = data[1];
    data1['tenantName'] = data[2];
    let payload: any = {
      customViewData: data1,
      report: 'BALANCE_SHEET'
    };
    AccountingService.getInstance()
      .postCustomizedReport(payload)
      .then((res) => {})
      .catch((err) => {});
  };

  const runReport = (state: ReportHeaderState) => {
    const updatedState = reportFilterState;
    if (state.columnBy.currentValue) {
      columnLogic(state.columnBy.currentValue, updatedState, comparisionModes);
    }

    if (state.comparisionMode.currentValue) {
      comparisionLog(state.comparisionMode.currentValue, updatedState);
    }

    if (state.date.currentValue) {
      dateFilterLogic(state.date, updatedState);
    }

    if (state.accountingMethod.currentValue) {
      if (state.accountingMethod.currentValue.value === REPORT_BASIS.CASH) {
        accountingMethod(
          state.accountingMethod.currentValue,
          'Cash',
          updatedState
        );
      } else {
        accountingMethod(
          state.accountingMethod.currentValue,
          'Accural',
          updatedState
        );
      }
    }

    // filter
    let contact: any = [];
    let product: any = [];

    if (state?.showFilterPopup?.data) {
      state.showFilterPopup.data.product.forEach((prod: any) => {
        product.push(prod.id);
      });
      state.showFilterPopup.data.customer.forEach((customer: any) => {
        contact.push(customer.id);
      });
      state.showFilterPopup.data.vendor.forEach((vendor: any) => {
        contact.push(vendor.id);
      });

      updatedState.Contacts = contact;
      updatedState.Products = product;
    }

    // presetInfo
    if (state.presetInfo) {
      updatedState.PresetInfo = state.presetInfo;
      updatedState.Basis = state.presetInfo.PresetName
        ? { label: 'Accrual', value: REPORT_BASIS.ACCRUAL }
        : updatedState.Basis;
    }
    if (state.showCustomFilterPopup) {
      updatedState.showCustomFilterPopup = state.showCustomFilterPopup;
    }

    updatedState.ColumnBy = state.columnBy.currentValue;
    updatedState.ComparisonMode = state.comparisionMode.currentValue;
    updatedState.CurrentDateRange = state.date.currentValue;
    updatedState.SwitchCurrency = state.switchCurrency?.currentValue;

    const payload = makepayload(updatedState);
    setExportPayload(payload);
    setReportFilterState({ ...updatedState });
    switch (updatedState.SwitchCurrency?.value) {
      case SWITCH_CURRENCY_OPTIONS.PRIMARY_CURRENCY:
        fetchReportDetails(payload, true);
        break;
      case SWITCH_CURRENCY_OPTIONS.BASE_CURRENCY:
        fetchReportDetails(payload);
        break;
      case SWITCH_CURRENCY_OPTIONS.BOTH:
        fetchReportDetailsInBothCurrency(payload);
        break;
      default:
        fetchReportDetails(payload);
    }
  };

  const fetchReportDetails = (
    payload: any,
    switchToPrimaryCurrency: boolean = false
  ) => {
    if (!isEmpty(payload)) {
      setTableLoader(true);
      let updatedPayload = { ...payload };
      updatedPayload.amountInPrimaryCurrency = switchToPrimaryCurrency;
      updatedPayload.baseCurrency = tenantInfo?.currency;
      updatedPayload.primaryCurrency = primaryCurrencyCode;
      AccountingService.getInstance()
        .getBalanceSheetReport(updatedPayload)
        .then((res) => {
          let data: any = { ...res };
          setReportData({ ...data });
          setTableLoader(false);
        })
        .catch((err) => {
          showToast('Error loading report data', TOAST_TYPE.FAILURE);
          setTableLoader(false);
        });
    }
  };

  const fetchReportDetailsInBothCurrency = (payload: any) => {
    if (!isEmpty(payload)) {
      setTableLoader(true);
      let updatedPayload = { ...payload };
      updatedPayload.amountInPrimaryCurrency = true;
      updatedPayload.baseCurrency = tenantInfo?.currency;
      updatedPayload.primaryCurrency = primaryCurrencyCode;

      AccountingService.getInstance()
        .getBalanceSheetReportInBothCurrency(updatedPayload)
        .then((res: any) => {
          let data: any = { ...res };
          setReportData({ ...data });
          setTableLoader(false);
        })
        .catch((err: any) => {
          showToast('Error loading report data', TOAST_TYPE.FAILURE);
          setTableLoader(false);
        });
    }
  };

  const newHeader = () => {
    return (
      <AccountingReportHeader
        columnBy={{
          name: '',
          isHidden: false,
          type: REPORT_HEADER_ITEMS.COLUMN_BY,
          dataOptions: columnByData ? columnByData : [],
          currentValue: reportFilterState.ColumnBy
        }}
        hasCustomField={true}
        taxAgency={{
          name: '',
          isHidden: true,
          type: REPORT_HEADER_ITEMS.TAX_AGENCY,
          dataOptions: [],
          currentValue: null
        }}
        comparisionMode={{
          name: '',
          isHidden: false,
          type: REPORT_HEADER_ITEMS.COMPARISION_MODE,
          dataOptions: [...comparisionModes],
          currentValue: reportFilterState.ComparisonMode
        }}
        accountingMethod={{
          name: '',
          isHidden: false,
          type: REPORT_HEADER_ITEMS.ACCOUNTING_METHOD,
          dataOptions: [...comparisionDataOptions],
          currentValue: null
        }}
        hideToggle={false}
        toggle={(state: any) => {
          setToggled(state.toggle);
          if (state.toggle === false) {
            runReport(state);
          } else toggleZero(state);
        }}
        switchCurrency={{
          name: '',
          isHidden: false,
          type: REPORT_HEADER_ITEMS.SWITCH_CURRENCY,
          dataOptions: [],
          currentValue: reportFilterState.SwitchCurrency
        }}
        dateRange={{
          startDate: '',
          isHidden: false,
          endDate: '',
          dataOptions: [...dateRangeOptions],
          currentValue: reportFilterState.CurrentDateRange
        }}
        showDirect={false}
        runReport={(state) => {
          runReport(state);
        }}
        customize={{
          isHidden: false
        }}
        customizePopup={(state: any) => {
          setShowPopup(true);
        }}
        reportType={'Balance Sheet Report'}
        showFilter={true}
      />
    );
  };

  const replaceNames = (data: any) => {
    for (let keys in data) {
      if (keys !== 'reportName' && keys !== 'tenantName') {
        let rows = replaceNameState(passer.rows, keys, data[keys]);
        let updatedState = passer;
        updatedState.rows = rows;
        setPasser({ ...updatedState });
      } else {
        if (keys === 'reportName') {
          setReportName(data[keys]);
        } else {
          setTenantName(data[keys]);
        }
      }
    }
  };

  const newReportBody = () => {
    return (
      <AccountingReportBody
        reportData={passer}
        reportName={reportName}
        tenantName={tenantName}
        showSort={true}
        onSortClick={(direction: any) => {
          parsedSortedDataForBL(direction);
        }}
        footerText={getTimeStamp('Cash')}
        templateName="template"
        onEmailClick={() => {
          if (!isLoading) {
            setIsLoading(true);
            showLoader("Generating PDF...");
            let pdf = new PdfUtility();
            const element = document.getElementById('template');
            //Check for column size to update landscape
            pdf
              .getPDFUrlForEmailLandscape(
                element,
                'Balance Sheet',
                lengthOfCols >= 4 ? true : false
              )
              .then((res: any) => {
                setIsLoading(false);
                removeLoader();
                setShowEmailPopup(true);
                setPdfUrl(res);
              }).catch(err => {
                removeLoader();
              });
          }
        }}
        onCellClick={() => {}}
        onExportClick={() => {
          if (!isExporting) {
            setIsExporting(true);
            exportDocumentBL('XLS');
          }
        }}
        onPrintClick={() => {
          const element = document.getElementById('template');
          //add condition for landscape/potrait
          if (lengthOfCols >= 4) {
            PdfUtility.printLandscapeHtml(element, true);
          } else {
            PdfUtility.printLandscapeHtml(element, false);
          }
        }}
        onRowExpand={(data: any) => {
          if (data.rowData.totalValues) {
            data.rowData.cells.forEach((cell: any, index: any) => {
              if (index !== 0 && data.rowData.children.length > 0) {
                if (
                  isEmpty(data.rowData.totalValues.expanded) &&
                  data.rowData.expanded
                ) {
                  data.rowData.cells[index].title = ' ';
                } else if (
                  isEmpty(data.rowData.totalValues.collapsed) &&
                  data.rowData.collapsed
                ) {
                  data.rowData.cells[index].title = ' ';
                } else {
                  data.rowData.cells[index].title = data.rowData.expanded
                    ? data.rowData.totalValues.expanded[`Period_${index}`] !==
                      undefined
                      ? getAmountBlockWithCurrency(
                          data.rowData.totalValues.expanded[`Period_${index}`],
                          getActiveCurrency()
                        )
                      : ' '
                    : data.rowData.totalValues.collapsed[
                        `Period_${index}_withChild`
                      ] !== undefined
                    ? getAmountBlockWithCurrency(
                        data.rowData.totalValues.collapsed[
                          `Period_${index}_withChild`
                        ],
                        getActiveCurrency()
                      )
                    : ' ';

                  if (data.rowData.cells[index].title.indexOf('(') !== -1) {
                    data.rowData.cells[index].style = {
                      ...data.rowData.cells[index].style,
                      color: 'red'
                    };
                  } else {
                    data.rowData.cells[index].style = {
                      ...data.rowData.cells[index].style,
                      color: 'black'
                    };
                  }
                }
              }
            });
          }
        }}
        showLoading={tableLoader}
      />
    );
  };

  const getActiveCurrency = () =>
    reportFilterState.SwitchCurrency?.value ===
    SWITCH_CURRENCY_OPTIONS.PRIMARY_CURRENCY
      ? primaryCurrencyCode
      : tenantInfo?.currency;

  return (
    <div className="column full-screen-width parent-width text-13 flex-1 bg-gray1 main-holder-padding padding-bottom-50">
      <div className="row align-items-center justify-content-between">
        {showEmailPopUp && getEmailPopUp()}
      </div>
      {newHeader()}

      <div className="column parent-width align-items-start p-h-s">
        {newReportBody()}
      </div>
      {showPopup && (
        <CustomizePopup
          populateFormData={nameArray}
          reportHeaders={[tenantName, reportName]}
          onCancel={() => {
            setShowPopup(false);
          }}
          onSave={(data: any) => {
            setShowPopup(false);
            replaceNames(data[0]);
            postNames(data);
            setReportName(data[1]);
            setTenantName(data[2]);
          }}
        />
      )}
    </div>
  );
}

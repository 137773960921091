import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AppIntegrationService from '../../services/appIntegration';

export interface AppIntegrationState {
  connections: any[];
}

const initialState: AppIntegrationState = {
  connections: []
};

export const fetchAppIntegrationMapping = createAsyncThunk(
  'fetchAppIntegrationMapping',
  async () => {
    try {
      const response = await AppIntegrationService.getAppIntegrationMapping();
      return response;
    } catch (err) {
      console.log('err fetchAppIntegrationMapping', err);
    }
  }
);

export const appIntegrationSlice = createSlice({
  name: 'appIntegration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAppIntegrationMapping.fulfilled, (state, action) => {
      state.connections = action.payload?.content || [];
    });
  }
});

export default appIntegrationSlice.reducer;

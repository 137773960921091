import { TEMPLATE } from '../constants/Enum';

export class AccountingTemplates {
  static getTemplateData(templateName: TEMPLATE) {
    switch (templateName) {
      case TEMPLATE.TRIAL_BALANCE:
        return getTrialBalanceTemplate();
      case TEMPLATE.PROFIT_AND_LOSS:
        return getProfitAndLossTemplate();
      case TEMPLATE.BALANCE_SHEET:
        return getBalanceSheetTemplate();
      default:
        return [];
    }
  }
}

export function getTrialBalanceTemplate() {
  return [
    {
      id: 0,
      type: 'trialBalance',
      tableName: 'sales_invoice_report',
      columns: [
        'id',
        'document_code',
        'contact_name',
        'currency',
        'total_amount',
        'due_amount',
        'status'
      ],
      sortBy: {
        column: 'id',
        direction: 'DEFAULT',
        table: 'sales_invoice_report'
      },
      filters: [],
      isExpanded: true
    }
  ];
}

export function getProfitAndLossTemplate() {
  return [
    {
      id: 0,
      type: 'pnl',
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate: new Date(),
      tableName: 'sales_invoice_report',
      columns: [
        'id',
        'document_code',
        'contact_name',
        'currency',
        'total_amount',
        'due_amount',
        'status'
      ],
      sortBy: {
        column: 'id',
        direction: 'DEFAULT',
        table: 'sales_invoice_report'
      },
      filters: [],
      isExpanded: true
    }
  ];
}

export function getBalanceSheetTemplate() {
  return [
    {
      id: 0,
      type: 'balanceSheet',
      tableName: 'sales_invoice_report',
      columns: [
        'id',
        'document_code',
        'contact_name',
        'currency',
        'total_amount',
        'due_amount',
        'status'
      ],
      sortBy: {
        column: 'id',
        direction: 'DEFAULT',
        table: 'sales_invoice_report'
      },
      filters: [],
      isExpanded: true
    }
  ];
}

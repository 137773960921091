/* *************** App Enums ***************** */
export enum COMMON_EVENTS {
  SUBSCRIPTION_CHECK = 'onAuthorizationCheckStateChanged',
  NAVIGATION_TRIGGERED = 'onNavigationTriggered',
  ERROR_OCCURRED = 'onErrorOccurred'
}

export enum APPLICATION_ENUM {
  ALL = 'All',
  ERP = 'ERP',
  CRM = 'CRM',
  CRM3 = 'CRM3'
}

export const LOCAL_STORAGE_KEYS = {
  DATE_FORMATE: 'dateFormat',
  GRID_DATE_FORMAT: 'gridDateFormat',
  DECIMAL_SCALE: 'decimalScale',
  BOOK_BEGINNING_DATE: 'bookBeginningStartDate',
  IS_SIDE_BAR_EXPANDED: 'isSideBarExpanded',
  SELECTED_PIPELINE: 'selectedPipeline'
};

/* *************** Report Enums ***************** */
export enum REPORT_TYPE {
  PNL = 'pnl',
  BALANCE_SHEET = 'balanceSheet',
  TRIAL_BALANCE = 'trialBalance',
  PROFIT_AND_LOSS_BY_YEAR = 'PROFIT_AND_LOSS_BY_YEAR',
  PROFIT_AND_LOSS_BY_MONTH = 'PROFIT_AND_LOSS_BY_MONTH',
  PROFIT_AND_LOSS_BY_QUARTER = 'PROFIT_AND_LOSS_BY_QUARTER',
  BALANCE_SHEET_BY_YEAR = 'BALANCE_SHEET_BY_YEAR',
  BALANCE_SHEET_BY_MONTH = 'BALANCE_SHEET_BY_MONTH',
  BALANCE_SHEET_BY_QUARTER = 'BALANCE_SHEET_BY_QUARTER'
}

export enum TEMPLATE_GROUP {
  ERP_REPORTS = 'ERP Reports',
  ACCOUNTING_REPORTS = 'Accounting Reports',
  CRM_REPORTS = 'CRM Reports'
}

export enum TEMPLATE {
  ACTIVITY_BY_CONTACT = 'Activity by Contact',
  TICKET_BY_CONTACT = 'Ticket by Contact',
  QUOTATION_LIST = 'Quotation List',
  INVOICE_LIST = 'Invoice List',
  ORDER_LIST = 'Order List',
  BILL_LIST = 'Bill List',
  CREDIT_NOTE_LIST = 'Credit Note List',
  DEBIT_NOTE_LIST = 'Debit Note List',
  RECEIPT_LIST = 'Receipt List',
  PAYMENT_LIST = 'Payment List',
  BATCH_SERIAL_LEDGER = 'Batch Serial Ledger',
  STOCK_MOVEMENT = 'Stock Movement',
  PROFIT_AND_LOSS = 'Profit And Loss',
  PROFIT_AND_LOSS_BY_MONTH = 'Profit And Loss By Month',
  PROFIT_AND_LOSS_BY_QUARTER = 'Profit And Loss By Quarter',
  PROFIT_AND_LOSS_BY_YEAR = 'Profit And Loss By Year',
  PROFIT_AND_LOSS_DETAILS = 'Profit And Loss Details',
  BALANCE_SHEET = 'Balance Sheet',
  BALANCE_SHEET_BY_MONTH = 'Balance Sheet By Month',
  BALANCE_SHEET_BY_QUARTER = 'Balance Sheet By Quarter',
  BALANCE_SHEET_BY_YEAR = 'Balance Sheet By Year',
  BALANCE_SHEET_DETAILS = 'Balance Sheet Details',
  TRIAL_BALANCE = 'Trial Balance',
  TRIAL_BALANCE_DETAILS = 'Trial Balance Details',
  CONTACT_LIST = 'Contact List',
  DEAL_LIST = 'Deal List',
  ACTIVITY_LIST = 'Activity List',
  OVERDUE_ACTIVITY = 'Overdue Activity',
  DEAL_CONVERSION_REPORT = 'Deal Conversion Report',
  CONTACT_WITH_DEALS = 'Contact with Deals',
  DEAL_WON_CONTACT = 'Deal Won Contact List',
  CAMPAIGN_STATUS = 'Campaign Status Report',
  UNSUBSCRIBED_REPORT = 'Unsubscribed Report',
  AUTOMATION_REPORT = 'Automation Report',
  WON_DEAL_WITH_CONTACT = 'Won Deals With Contact',
  CONTACT_WITH_TICKET = 'Contact With Ticket',
  PIPELINE_STAGE_REPORT = 'Pipeline Stage Report',
  ACTIVITY_REPORT = 'Contact With Activity',
  INVENTORY_DASHBOARD = 'Inventory Dashbord'
}

/* *************** Chart Enums ***************** */
export enum OLD_CHART_TYPES {
  'Table' = 'TABLE',
  'Bar Vertical Graph' = 'BAR_VERTICAL',
  'Multi Vertical Bar Graph' = 'MULTI_BAR_VERTICAL',
  'Bar Horizontal Graph' = 'BAR_HORIZONTAL',
  'Multi Bar Horizontal Graph' = 'MULTI_BAR_HORIZONTAL',
  'Line Chart' = 'LINE',
  'Pie Chart' = 'PIE',
  'DONUT' = 'DONUT'
}
export enum CHART_TYPE {
  TABLE = 'TABLE',
  BAR_VERTICAL = 'BAR_VERTICAL',
  MULTI_BAR_VERTICAL = 'MULTI_BAR_VERTICAL',
  BAR_HORIZONTAL = 'BAR_HORIZONTAL',
  MULTI_BAR_HORIZONTAL = 'MULTI_BAR_HORIZONTAL',
  LINE = 'LINE',
  PIE = 'PIE',
  DONUT = 'DONUT'
}

export enum TEMPLATE_CHART_TYPE {
  PNL = 'pnl',
  BALANCE_SHEET = 'balanceSheet',
  TRIAL_BALANCE = 'trialBalance'
}

export enum CHART_LAYOUT_TYPE {
  THUMBNAIL = 'Thumbnail',
  ROW = 'Row'
}

export enum CHARTS_ACTION {
  EXPAND = 'EXPAND',
  DELETE = 'DELETE',
  MOVE_LEFT = 'MOVE_LEFT',
  MOVE_RIGHT = 'MOVE_RIGHT'
}

export enum COLUMN_LAYOUT {
  ONE = '1',
  TWO = '2',
  THREE = '3'
}

export enum SORT_OPTIONS {
  DEFAULT = 'ASC',
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum PREFIXES {
  CELLS = 'cells.'
}
/* ******************** MISC ****************** */
export enum DATE_FORMATS {
  'D MMM YYYY' = 'D MMM YYYY',
  'DD-MM-YYYY' = 'DD-MM-YYYY',
  'DD-MMM-YYYY' = 'DD-MMM-YYYY',
  'DD/MM/YYYY' = 'DD/MM/YYYY',
  'MM-DD-YYYY' = 'MM-DD-YYYY',
  'MM/DD/YYYY' = 'MM/DD/YYYY',
  'YYYY-MM-DD' = 'YYYY-MM-DD'
}

export enum DATE_FN_DATE_FORMATS {
  'D MMM YYYY' = 'd MMM yyyy',
  'dd-mm-yyyy' = 'dd-MM-yyyy',
  'DD-MM-YYYY' = 'dd-MM-yyyy',
  'DD/MM/YYYY' = 'dd/MM/yyyy',
  'DD-MMM-YYYY' = 'dd-MMM-yyyy',
  'MM-DD-YYYY' = 'MM-dd-yyyy',
  'MM/DD/YYYY' = 'MM/dd/yyyy',
  'YYYY-MM-DD' = 'yyyy-MM-dd'
}

export enum DATE_FORMATS_GRID {
  'D MMM YYYY' = 'd MMM yyyy',
  'dd-mm-yyyy' = 'dd-MM-yyyy',
  'DD-MM-YYYY' = 'dd-MM-yyyy',
  'DD/MM/YYYY' = 'dd/MM/yyyy',
  'DD-MMM-YYYY' = 'dd MMM yyyy',
  'dd-MMM-yyyy' = 'dd MMM yyyy',
  'MM-DD-YYYY' = 'MM-dd-yyyy',
  'MM/DD/YYYY' = 'MM/dd/yyyy',
  'YYYY-MM-DD' = 'yyyy-MM-dd',
  /* ---------OTHERS----------- */
  'DDMMYYYY' = 'ddMMyyyy',
  'DDMMYYYY HH:mm' = 'ddMMyyyy HH:mm',
  'DD/MM/YYYY HH:mm' = 'dd/MM/yyyy HH:mm',
  'DD-MM-YYYY HH:mm' = 'dd-MM-yyyy HH:mm',
  'MMDDYYYY' = 'MMddyyyy',
  'MM/DD/YYYY HH:mm' = 'MM/dd/yyyy HH:mm',
  'DD MMMM YYYY' = 'dd MMMM yyyy',
  'D MMMM YYYY' = 'd MMMM yyyy',
  'MMM DD, YYYY' = 'MMM dd, yyyy',
  'MMM DD, YYYY HH:mm' = 'MMM dd, yyyy HH:mm',
  'EEE, DD MMM YYYY' = 'EEE, dd MMM yyyy',
  'EEE, MMM DD YYYY, HH:mm' = 'EEE, MMM dd yyyy, HH:mm',
  'EEE, MMM DD YYYY, HH:mm aa' = 'EEE, MMM dd yyyy, HH:mm aa'
}

export enum supportedFileTypes {
  csv = 'csv',
  xlsx = 'xlsx'
}
export enum SETTING_CARD_IDS {
  USER_PERMISSION = 'user_permission',
  TEAM_PERMISSION = 'team_permission'
}

export enum AUTOMATION_LOG_NODE_TYPE {
  CREATE_DEAL = 'CREATE_DEAL',
  CREATE_CONTACT = 'CREATE_CONTACT',
  UPDATE_DEAL = 'UPDATE_DEAL',
  UPDATE_CONTACT = 'UPDATE_CONTACT',
  SEND_EMAIL = 'SEND_EMAIL',
  SPLIT = 'SPLIT',
  WAIT_FOR_TIME = 'WAIT_FOR_TIME',
  // SEND_SMS_MESSAGE = "SEND_SMS_MESSAGE",
  SEND_WHATSAPP_MESSAGE = 'SEND_WHATSAPP_MESSAGE',
  ASSIGN_OWNER = 'ASSIGN_OWNER',
  END_WORKFLOW = 'END_WORKFLOW',
  CAMPAIGN_PUBLISHED = 'CAMPAIGN_PUBLISHED',
  FORM_SUBMITTED = 'FORM_SUBMITTED',
  CONTACT_ADDED = 'CONTACT_ADDED',
  CONTACT_UPDATED = 'CONTACT_UPDATED',
  FACEBOOK_LEAD_ADDED = 'FACEBOOK_LEAD_ADDED',
  DEAL_UPDATED = 'DEAL_UPDATED',
  DEAL_STAGE_CHANGED = 'DEAL_STAGE_CHANGED',
  ACTIVITY_UPDATED = 'ACTIVITY_UPDATED',
  CRM_DATE_ALERT_TRIGGER = 'CRM_DATE_ALERT_TRIGGER',
  BOOKS_CREATE_QUOTATION = 'BOOKS_CREATE_QUOTATION'
}
export const AUTOMATION_ACTIONS_TITLE: any = {
  SEND_EMAIL: 'Send an e-mail',
  CREATE_CONTACT: 'Create a contact',
  UPDATE_CONTACT: 'Update a contact',
  CREATE_DEAL: 'Create a deal',
  UPDATE_DEAL: 'Update a deal',
  SPLIT: 'Split Path',
  WAIT_FOR_TIME: 'Wait for (time)',
  // SEND_SMS_MESSAGE : "Send a text message",
  SEND_WHATSAPP_MESSAGE: 'Send a whatsapp message',
  // CRM_DATE_ALERT_TRIGGER : "Date alert trigger",
  ASSIGN_OWNER: 'Assign owner'
};
export const AUTOMATION_TRIGGER_TITLE: any = {
  CAMPAIGN_PUBLISHED: 'Campaign is published',
  FORM_SUBMITTED: 'Form submitted',
  CONTACT_ADDED: 'Contact added',
  CONTACT_UPDATED: 'Contact field changed',
  // FACEBOOK_LEAD_ADDED : "Facebook lead added",
  DEAL_UPDATED: 'Deal field changed',
  DEAL_STAGE_CHANGED: 'Deal stage changed',
  ACTIVITY_UPDATED: 'Activity field changed',
  CRM_DATE_ALERT_TRIGGER: 'Date alert trigger',
  BOOKS_CREATE_QUOTATION: 'Quotation added'
};
export enum DATE_RNGE_FILTER_OPTION {
  ALL_DATA = 'All Data',
  THIS_WEEK = 'This Week',
  THIS_MONTH = 'This Month',
  THIS_QUARTER = 'This Quarter',
  THIS_YEAR = 'This Year',
  CUSTOM_DATE = 'Custom Date'
}
export enum FILTER_OPERATORS {
  EQUAL = 'eq',
  NOT_EQUAL = 'neq',
  GREATER_THAN = 'gt',
  GREATER_THAN_OR_EQUAL = 'gte',
  GREATER_EQUAL = 'gteq' /* only used in automation for dates */,
  LESS_THAN = 'lt',
  LESS_THAN_OR_EQUAL = 'lte',
  CONTAINS = 'c',
  IN = 'in'
}
export const ACTIVITY_STATUS_CODE = {
  COMPLETED: 1,
  PENDING: 2,
  OVERDUE: 3
};
export enum FILTER_LOGICAL_OPERATORS {
  AND = 'and',
  OR = 'or'
}
export enum CAMPAIGN_TYPE {
  EMAIL = 1,
  WHATSAPP = 2
}
export enum CONTACT_DETAIL_TAB {
  EMAIL = 'contactEmail',
  TICKET = 'tickets',
  DEAL = 'deals',
  ACTIVITY = 'activities',
  QUOTES = 'quotes',
  INVOICES = 'invoices',
  NOTES = 'notes',
  ATTACHMENTS = 'Attachments',
  LOGS = 'logs'
}
export const DEAL_STATUS_CODE = {
  DEFAULT: 0,
  WON: 1,
  LOST: 2
};
export enum QUERY_PARAM_KEY {
  CONTACT_DETAIL_TAB = 'activeTab'
}
export const PRODUCTS = {
  AIO: 'aio',
  ERP: 'erp',
  CRM: 'crm',
  CRM_PLUS: 'crm_plus',
  PEOPLE: 'people',
  WEBSITE_BUILDER: 'website builder',
  SHOP: 'shop',
  REPORT_BUILDER: 'report_builder',
  DOCUMENT_BUILDER: 'document builder',
  DOCUMENT_SCANNER: 'document scanner',
  ORGANISATION: 'organisation',
  CONSOLE: 'console',
  WORKFLOW_ENGINE: 'workflow_engine',
  ATTENDANCE: 'attendance',
  EXPENSE: 'expense',
  CHAT: 'chat',
  EM: 'em', //Expense
  DS: 'ds', //Shop
  EL: 'el' //Leave Management
};
export enum INVITE_USER_STATUS {
  SENT = 'SENT',
  JOINED = 'JOINED'
}
export enum CUSTOM_FIELD_TYPE {
  CURRENCY = 'CURRENCY',
  MULTI_SELECT = 'MULTISELECT',
  COUNTRY = 'COUNTRY',
  BOOLEAN = 'BOOLEAN',
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  DATE = 'DATE',
  DROPDOWN = 'DROPDOWN',
  USER = 'USER'
}

export enum FILTER_DATA_TYPE {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  MULTI_SELECT = 'MULTISELECT',
  SELECT = 'SELECT',
  DROPDOWN = 'DROPDOWN',
  DATE = 'DATE',
  DURATION = 'DURATION'
}

/* ******************** ACCOUNTING REPORTS ****************** */
export enum ACCOUNTING_METHOD {
  ACCRUAL = 'ACCRUAL',
  CASH = 'CASH'
}

export enum REPORT_BASIS {
  ACCRUAL = 'ACCRUAL',
  CASH = 'CASH'
}

export enum FINANCIAL_REPORT {
  PNL = 'PNL',
  BALANCE_SHEET = 'BALANCE_SHEET',
  TRIAL_BALANCE = 'TRIAL_BALANCE'
}

export enum INPUT_VIEW_DIRECTION {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL'
}

export enum STATUS_TYPE {
  ACTIVE = 'ACTIVE',
  active = 'active',
  Active = 'Active',
  INACTIVE = 'INACTIVE',
  inactive = 'inactive',
  Inactive = 'Inactive',
  OPEN = 'OPEN'
}

export enum SWITCH_CURRENCY_OPTIONS {
  BASE_CURRENCY = 'BASE_CURRENCY',
  PRIMARY_CURRENCY = 'PRIMARY_CURRENCY',
  BOTH = 'BOTH'
}

/* ****************** FILTER ENUMS ********************** */
export enum REPORT_FILTER_LIST {
  CUSTOMER = 'CUSTOMER',
  VENDOR = 'VENDOR',
  CLASS = 'CLASS',
  PRODUCT = 'PRODUCT',
  ACCOUNT = 'ACCOUNT'
}

export enum DropdownSelectionType {
  SINGLE = 'single',
  MULTIPLE = 'multiple'
}

export enum FORM_ELEMENTS {
  DROPDOWN = 'dropdown',
  INPUT = 'input',
  SEARCHABLE = 'searchable',
  TOGGLE = 'toggle',
  DATE = 'date'
}

export const COUNTRY_CODES = {
  US: 'US',
  IN: 'IN',
  ID: 'ID',
  SG: 'SG',
  PH: 'PH',
  AE: 'AE', //Used for UAE
  MY: 'MY',
  AU: 'AU',
  NL: 'NL',
  BE: 'BE',
  CA: 'CA',
  NZ: 'NZ',
  UK: 'UK',
  DE: 'DE',
  SA: 'SA',
  IL: 'IL'
};

export const COUNTRY_SPECIFIC_URL_CODE = {
  IN: '/in',
  SG: '/sg',
  US: '/us',
  INDONESIA: '/id',
  MALAYSIA: '/my',
  UAE: '/ae',
  PHILIPPINES: '/ph',
  CANADA: '/ca'
};

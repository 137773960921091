import {
  DKButton,
  DKCheckMark,
  DKIcon,
  DKIcons,
  DKInput,
  DKLabel,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  showToast,
  TOAST_TYPE,
  showAlert
} from 'deskera-ui-library';
import React, { useState } from 'react';
import { DATE_FORMATS, FINANCIAL_REPORT } from '../../constants/Enum';
import { getDateFromString, getDateStrFromDate } from '../../utilities/Date';
import { isEmpty } from '../../utilities/Common';
import Popup from '../common/Popup';

interface Period {
  fromDate: string;
  toDate: string;
}
interface IState {
  SaveAsPreset: boolean;
  Periods: Period[];
  PresetName?: string;
}

interface IProps {
  reportType: FINANCIAL_REPORT;
  onCustomComparisonModeChange: (presetInfo: any) => void;
  onAddCustomComparisonModeClose: () => void;
}

const PeriodName: any = {
  0: 'First Period',
  1: 'Second Period',
  2: 'Third Period',
  3: 'Fourth Period',
  4: 'Fifth Period'
};

export default function AddCustomComparisonMode(props: IProps) {
  const [state, setState] = useState<IState>({
    PresetName: '',
    SaveAsPreset: false,
    Periods: [
      {
        fromDate: getDateStrFromDate(new Date(), DATE_FORMATS['DD-MM-YYYY']),
        toDate: getDateStrFromDate(new Date(), DATE_FORMATS['DD-MM-YYYY'])
      },
      {
        fromDate: getDateStrFromDate(new Date(), DATE_FORMATS['DD-MM-YYYY']),
        toDate: getDateStrFromDate(new Date(), DATE_FORMATS['DD-MM-YYYY'])
      }
    ]
  });

  const validateAndSave = () => {
    const datesStringArr = state.Periods.map(
      (period) => period.fromDate + '-' + period.toDate
    );
    let validDates: any = true;
    state.Periods.forEach((period: any) => {
      let from = period.fromDate;
      let to = period.toDate;
      from = getDateFromString(from, DATE_FORMATS['DD-MM-YYYY']);
      to = getDateFromString(to, DATE_FORMATS['DD-MM-YYYY']);
      if (from > to) {
        validDates = false;
      }
    });

    let rangesAreSame = false;
    for (let i = 0; i < datesStringArr.length - 1; i++) {
      if (rangesAreSame) break;
      for (let j = i + 1; j < datesStringArr.length; j++) {
        if (datesStringArr[i] === datesStringArr[j]) {
          rangesAreSame = true;
          break;
        }
      }
    }
    if (!validDates) {
      showAlert('Warning', 'From Date should be smaller than To Date');
    } else if (rangesAreSame) {
      showAlert(
        'Warning',
        'Date Periods are same, Please provide different date periods.'
      );
    } else if (state.SaveAsPreset && isEmpty(state.PresetName)) {
      showToast('Preset name is required.', TOAST_TYPE.FAILURE);
    } else {
      props.onCustomComparisonModeChange(state);
    }
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel text="Apply Custom View" className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title="Cancel"
            className="bg-white border-m mr-r"
            onClick={() => props.onAddCustomComparisonModeClose()}
          />
          <DKButton
            title={'Save'}
            className="bg-button text-white"
            onClick={() => validateAndSave()}
          />
        </div>
      </div>
    );
  };

  const addNewPeriod = () => {
    if (state.Periods.length < 5) {
      setState((prevState) => {
        return {
          ...prevState,
          Periods: [
            ...state.Periods,
            {
              fromDate: getDateStrFromDate(
                new Date(),
                DATE_FORMATS['DD-MM-YYYY']
              ),
              toDate: getDateStrFromDate(new Date(), DATE_FORMATS['DD-MM-YYYY'])
            }
          ]
        };
      });
    }
  };

  const removePeriod = (index: number) => {
    let periods = [...state.Periods];
    periods.splice(index, 1);
    setState((prevState) => {
      return {
        ...prevState,
        Periods: periods
      };
    });
  };

  const updatePeriod = (index: number, key: string, value: Date) => {
    let periods = [...state.Periods];
    let periodToModify = periods[index];
    periodToModify = {
      ...periodToModify,
      [key]: getDateStrFromDate(value, DATE_FORMATS['DD-MM-YYYY'])
    };
    periods[index] = periodToModify;

    setState((prevState) => {
      return {
        ...prevState,
        Periods: periods
      };
    });
  };

  const getPeriod = (period: Period, index: number) => {
    return (
      <div className="column parent-width mb-r">
        <div className="row parent-width text-gray fw-b">
          {PeriodName[index]}
        </div>
        <div className="row parent-width mt-r">
          <DKInput
            value={getDateFromString(
              period.fromDate,
              DATE_FORMATS['DD-MM-YYYY']
            )}
            className={'mr-l mb-s'}
            title="From"
            canValidate={false}
            type={INPUT_TYPE.DATE}
            onChange={(value: Date) => updatePeriod(index, 'fromDate', value)}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={false}
            readOnly={false}
          />
          <DKInput
            value={getDateFromString(period.toDate, DATE_FORMATS['DD-MM-YYYY'])}
            className={'mb-s'}
            title="To"
            canValidate={false}
            type={INPUT_TYPE.DATE}
            onChange={(value: Date) => updatePeriod(index, 'toDate', value)}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={false}
            readOnly={false}
            datePickerConfig={{
              isDateRange: false
            }}
          />
          {state.Periods.length > 2 && (
            <DKIcon
              src={DKIcons.ic_delete}
              className="ic-s ml-s mt-xl opacity-50 cursor-pointer"
              onClick={() => removePeriod(index)}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <Popup
      popupWindowStyles={{
        maxWidth: '90vw',
        width: 500,
        maxHeight: '90vh',
        padding: 0,
        overflowY: 'visible'
      }}
    >
      {getHeader()}
      <div className="parent-width parent-height p-h-r p-v-l overflow-y-auto border-box">
        <div className="column parent-width parent-height align-items-start overflow-y-visible">
          {state &&
            state.Periods.map((period, index) => {
              return getPeriod(period, index);
            })}
          <DKButton
            title={`+ Add another period to compare`}
            disabled={state.Periods.length === 5}
            onClick={() => addNewPeriod()}
            className={`${
              state.Periods.length === 5 ? 'text-gray' : 'text-blue'
            } fw-m p-v-r mb-r`}
            style={{ zIndex: 1, paddingLeft: 0 }}
          />
          <div className="row parent-width align-items-start">
            <div className="row parent-width">
              <DKCheckMark
                title="Save this as preset view"
                isSelected={state.SaveAsPreset}
                onClick={() => {
                  setState((prevState) => {
                    return {
                      ...prevState,
                      SaveAsPreset: !state.SaveAsPreset,
                      PresetName: ''
                    };
                  });
                }}
              />
            </div>
            <div className="row parent-width pb-xl" style={{ marginRight: 20 }}>
              {state.SaveAsPreset && (
                <DKInput
                  title=""
                  placeholder="Preset name"
                  value={state.PresetName}
                  className=""
                  type={INPUT_TYPE.TEXT}
                  direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
                  readOnly={false}
                  required={true}
                  canValidate={!state.PresetName}
                  errorMessage={
                    state.PresetName ? '' : 'Preset name is required'
                  }
                  onChange={(value: string) => {
                    setState((prevState) => {
                      return {
                        ...prevState,
                        PresetName: value
                      };
                    });
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Popup>
  );
}

export interface ITenant {
  id: number;
  userId: number;
  tenantId: number;
  tenantName: string;
  countryLookUpCode: string;
  logoUrl: string | null;
  memberCount: number;
  roleGroups: string[];
  isDemoOrg: boolean;
  enableSwitch: any;
  dateFormat: string;
  userFirstName: string;
  userLastName: string;
  userContact: any;
}

export interface IUser {
  createdAt: string;
  _id: string;
  id?: string;
  userId: number;
  firstName: string;
  lastName: string;
  fullName?: string;
  email: string;
  iamUserId: number;
  roleGroup: string;
  userName: string;
  status: string;
  crm3RoleId?: string;
  teamDesignation?: string;
}

export interface ICurrency {
  currencyName: string;
  currencyCode: string;
  currencySymbol: string;
}

export const getFullName = (user: IUser) =>
  `${user?.firstName || '...'} ${user?.lastName || ''}`;

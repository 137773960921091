import ApiConstants from '../constants/ApiConstant';
import http from './index';
import ITable from '../services/reports/Table';

class Table {
  static getAllViews(): Promise<ITable[]> {
    return http.get(ApiConstants.URL.TABLE.GET_ALL).then(
      (res) => {
        return Promise.resolve(res.data);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }
  static getTable(tableId: string) {
    return http
      .get(`${ApiConstants.URL.TABLE.GET(tableId)}`)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  static getRecordsByPage(params: any, data: any, tableId: string) {
    if (!params) params = { pageNo: 1, pageSize: 20 };
    if (!tableId) {
      return Promise.reject('Table Id is required');
    }
    return http
      .post(`${ApiConstants.URL.TABLE.GET_RECORD_BY_PAGE(tableId)}`, data, {
        params: { ...params, _allowRetry: true }
      })
      .then(
        (response) => {
          response.data = response.data.map((record: any) => {
            return {
              ...record,
              cells: {
                ...record.cells,
                createdBy: record.createdBy,
                id: record._id
              }
            };
          });
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

export default Table;

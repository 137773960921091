import { TRIAL_BALANCE1 } from '../constants/Constant';
import { isEmpty } from '../utilities/Common';
import { getAmountBlockWithCurrency } from './SharedParser';

export const getParsedDataForTrialBalanceDetails = (
  reportData: any,
  reportFilterState: any,
  columns: any[]
) => {
  if (isEmpty(reportData)) return;
  let width = 200;
  let newReportData = reportData['trialBalanceDetailDto'];

  let payload: any = {};
  payload['columns'] = [];
  payload['columns'].push({
    title: ' ',
    style: {
      width: width
    },
    type: 'bold-lines'
  });

  payload['rows'] = [];

  for (let key in newReportData) {
    let row: any = { expanded: true, expandedOnce: false };

    row.cells = [];
    row.children = [];
    const text = newReportData[key]['accountCode'];
    const result = text.replace(/([A-Z])/g, ' $1');
    let finalText = result.charAt(0).toUpperCase() + result.slice(1);

    let style: any = {
      fontWeight: 'normal',
      marginLeft: 0
    };
    let type = '';
    if (!TRIAL_BALANCE1[key] && key !== 'periodName') {
      row.cells.push({
        title: finalText,
        style: { ...style },
        type: type
      });
      let totalValues: any = { expanded: {}, collapsed: {}, details: {} };
      let hasChild = true;

      if (isEmpty(newReportData[key]['itemDto'])) {
        hasChild = false;
      }

      for (let i = 0; i < columns.length - 1; i++) {
        if (
          columns[i + 1].id === 'debitAmount' ||
          columns[i + 1].id === 'creditAmount'
        ) {
          totalValues.expanded[columns[i + 1].id] = newReportData[key][
            `${columns[i + 1].id}`
          ]
            ? newReportData[key][`${columns[i + 1].id}`]
            : 0.0;
          totalValues.collapsed[`${columns[i + 1].id}WithChilds`] =
            newReportData[key][`${columns[i + 1].id}WithChilds`];
        }
        row = { ...row, totalValues: totalValues };

        row.cells.push({
          title:
            columns[i + 1].id === 'debitAmount' ||
            columns[i + 1].id === 'creditAmount'
              ? getAmountBlockWithCurrency(
                  newReportData[key][columns[i + 1].id]
                )
              : newReportData[key][columns[i + 1].id],
          style: { ...style },
          type: type
        });
      }
    }

    for (let element in newReportData[key]['itemDto']) {
      let child = newReportData[key]['itemDto'][element];
      let children: any = {
        expanded: false,
        cells: [],
        children: [],
        expandedOnce: false
      };

      let totalValues: any = {
        expanded: {},
        collapsed: {},
        accountCode: newReportData[key]['accountCode'],
        details: {}
      };

      children = { ...children, totalValues: totalValues };
      children.cells.push({
        title: child?.accountCode,
        style: { style, marginLeft: 20 }
      });

      for (let i = 0; i < columns.length - 1; i++) {
        if (
          columns[i + 1].id === 'debitAmount' ||
          columns[i + 1].id === 'creditAmount'
        ) {
          totalValues.expanded[columns[i + 1].id] = child[columns[i + 1].id]
            ? child[columns[i + 1].id]
            : 0.0;
          totalValues.collapsed[`${columns[i + 1].id}WithChilds`] =
            child[`${columns[i + 1].id}WithChilds`];
        }
        children = { ...children, totalValues: totalValues };
        let val = columns[i].title;
        children.cells.push({
          title:
            columns[i + 1].id === 'debitAmount' ||
            columns[i + 1].id === 'creditAmount'
              ? getAmountBlockWithCurrency(
                  child[`${columns[i + 1].id}WithChilds`]
                )
              : child[columns[i + 1].id],
          style: { ...style }
        });
      }
      if (!isEmpty(child['itemDto'])) {
        children.children.push({
          cells: [],
          children: [],
          expanded: false,
          expandedOnce: false
        });
        if (child['itemDto'] !== null) {
          for (let item in child['itemDto']) {
            children.children.push(
              getParsedChildTB(
                child['itemDto'][item],
                25,
                newReportData,
                columns
              )
            );
          }
          children.children = children.children.sort(function (a: any, b: any) {
            if (a.cells.length) {
              return a.cells[0]?.title?.localeCompare(b.cells[0]?.title);
            }
          });
        }
      }
      row.children.push(children);
    }
    row.children = row.children.sort(function (a: any, b: any) {
      if (a.cells.length) {
        return a.cells[0]?.title?.localeCompare(b.cells[0]?.title);
      }
    });

    if (key === '0') {
      let row1: any = { expanded: true, expandedOnce: false };
      row1.cells = [];
      row1.cells.push({
        title: 'Difference in Opening balances',
        style: { ...style, fontWeight: '600' }
      });

      for (let i = 0; i < columns.length - 1; i++) {
        let title = '';

        if (reportData.totalDebitAmount <= reportData.totalCreditAmount) {
          if (columns[i + 1].id === 'debitAmount') {
            title = getAmountBlockWithCurrency(
              reportData['diffOpeningBalance']
            );
          }
        } else {
          if (columns[i + 1].id === 'creditAmount') {
            title = getAmountBlockWithCurrency(
              reportData['diffOpeningBalance']
            );
          }
        }

        row1.cells.push({
          title: title,
          style: { ...style, fontWeight: '600' }
        });
      }
      payload.rows.push(row1);
    }

    payload['rows'].push(row);
    if (isEmpty(newReportData[key]['itemDto'])) {
      continue;
    }
  }

  let style = {
    fontWeight: '600',
    borderTop: '1px solid lightGray',
    marginTop: '15px'
  };

  let row1: any = { expanded: true, expandedOnce: false };
  row1.cells = [];
  row1.cells.push({
    title: 'Total',
    style: style
  });
  for (let i = 0; i < columns.length - 1; i++) {
    let title = '';

    if (reportData.totalDebitAmount <= reportData.totalCreditAmount) {
      if (columns[i + 1].id === 'debitAmount') {
        title = getAmountBlockWithCurrency(
          reportData['diffOpeningBalance'] + reportData['totalDebitAmount']
        );
      } else if (columns[i + 1].id === 'creditAmount') {
        title = getAmountBlockWithCurrency(reportData['totalCreditAmount']);
      }
    } else {
      if (columns[i + 1].id === 'debitAmount') {
        title = getAmountBlockWithCurrency(reportData['totalDebitAmount']);
      } else if (columns[i + 1].id === 'creditAmount') {
        title = getAmountBlockWithCurrency(
          reportData['diffOpeningBalance'] + reportData['totalCreditAmount']
        );
      }
    }
    row1.cells.push({
      title: title,
      style: { ...style }
    });
  }
  payload.rows.push(row1);
  return { payload, length: 5 };
};

const getParsedChildTB = (
  obj: any,
  margin: number,
  reportData: any,
  columns: any[]
) => {
  let totalValues: any = { expanded: {}, collapsed: {} };

  let row: any = { expanded: true, totalValues: totalValues };
  row.cells = [];
  row.children = [];
  const text = obj.accountCode;
  row.cells.push({
    title: text,
    style: {
      fontWeight: 'normal',
      marginLeft: obj['itemDto'] !== null ? margin : margin + 15
    }
  });
  let style: any = {
    fontWeight: 'normal',
    marginLeft: 0
  };

  for (let i = 0; i < columns.length - 1; i++) {
    if (
      columns[i + 1].id === 'debitAmount' ||
      columns[i + 1].id === 'creditAmount'
    ) {
      totalValues.expanded[columns[i + 1].id] = obj[`${columns[i + 1].id}`]
        ? obj[`${columns[i + 1].id}`]
        : 0.0;
      totalValues.collapsed[`${columns[i + 1].id}WithChilds`] =
        obj[`${columns[i + 1].id}WithChilds`];
    }
    row = { ...row, totalValues: totalValues };
    row.cells.push({
      title:
        columns[i + 1].id === 'debitAmount' ||
        columns[i + 1].id === 'creditAmount'
          ? getAmountBlockWithCurrency(obj[columns[i + 1].id])
          : obj[columns[i + 1].id],
      style: { ...style }
    });
  }

  if (obj['itemDto'] !== null) {
    for (let item in obj['itemDto']) {
      row.children.push(
        getParsedChildTB(obj['itemDto'][item], margin + 7, reportData, columns)
      );
    }
  }
  row.children = row.children.sort(function (a: any, b: any) {
    if (a.cells.length) {
      return a.cells[0]?.title?.localeCompare(b.cells[0]?.title);
    }
  });

  return row;
};

import React from 'react';
import { DKLabel, DKButton } from 'deskera-ui-library';

import { COMMON_EVENTS } from '../constants/Enum';
import { commonCustomEvent } from '../services/events';

class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidMount() {
    commonCustomEvent.on(COMMON_EVENTS.ERROR_OCCURRED, this.onErrorOccurred);
    commonCustomEvent.on(
      COMMON_EVENTS.NAVIGATION_TRIGGERED,
      this.onNavigationTriggered
    );
  }

  componentDidCatch(error: Error, errorInfo: any) {
    /* Report error to analytics */
  }

  componentWillUnmount() {
    commonCustomEvent.remove(
      COMMON_EVENTS.ERROR_OCCURRED,
      this.onErrorOccurred
    );
    commonCustomEvent.remove(
      COMMON_EVENTS.NAVIGATION_TRIGGERED,
      this.onNavigationTriggered
    );
  }

  onNavigationTriggered = () => {
    this.setState({ hasError: false });
  };
  onErrorOccurred = () => {
    this.setState({ hasError: true });
  };
  render() {
    return this.state.hasError ? (
      <div
        className="column bg-white border-m border-radius-s shadow-s width-auto p-r"
        style={{
          margin: 'auto',
          width: 370,
          maxWidth: '90%',
          minWidth: 300
        }}
      >
        <DKLabel text={'Oops!, something went wrong.'} className="row fw-m" />
        <DKLabel
          text={
            'It looks like we are having some issue loading this page. Try reloading the page.'
          }
          className="row mt-l"
        />
        <DKButton
          title="Reload Page"
          className="bg-transparent text-app mt-l fw-m align-self-end"
          onClick={() => window.location.reload()}
        />
      </div>
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  DKInput,
  INPUT_VIEW_DIRECTION,
  INPUT_TYPE,
  DKLabel,
  DKButton
} from 'deskera-ui-library';
import {
  MulitSelectDropdown,
  ReactSelectOptionsType
} from '../../models/Filter';
import {
  DropdownSelectionType,
  FORM_ELEMENTS,
  REPORT_FILTER_LIST
} from '../../constants/Enum';
import { deepCloneObject } from '../../utilities/Common';
import ProductService, {
  ProductAPIConfig
} from '../../services/reports/Product';
import AccountingService from '../../services/reports/Accounting';
import ContactService from '../../services/reports/Contact';

interface ReportFilterState {
  customer: MulitSelectDropdown;
  vendor: MulitSelectDropdown;
  product: MulitSelectDropdown;
  class: MulitSelectDropdown;
  account: MulitSelectDropdown;
}

interface PayloadItem {
  name: string;
  id: string;
}

export interface ReportFilterPayload {
  customer: PayloadItem[];
  vendor: PayloadItem[];
  product: PayloadItem[];
  account: PayloadItem[];
}

interface ReportFilterProps {
  appliedFilter: ReportFilterPayload | null;
  applyFilter: (payload: any) => void;
  onClose: () => void;
}
const ReportFilter: React.FC<ReportFilterProps> = (props) => {
  const initialState: ReportFilterState = {
    customer: {
      key: '',
      hidden: true,
      value: [],
      selectionType: DropdownSelectionType.MULTIPLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    vendor: {
      key: '',
      hidden: true,
      value: [],
      selectionType: DropdownSelectionType.MULTIPLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    product: {
      key: '',
      hidden: true,
      value: [],
      selectionType: DropdownSelectionType.MULTIPLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    class: {
      key: '',
      hidden: true,
      value: [],
      selectionType: DropdownSelectionType.MULTIPLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    account: {
      key: '',
      hidden: true,
      value: [],
      selectionType: DropdownSelectionType.MULTIPLE,
      type: FORM_ELEMENTS.DROPDOWN
    }
  };
  type ReactOptionType = ReactSelectOptionsType<string, string>;
  const [state, setState] = useState<ReportFilterState>(
    deepCloneObject(initialState)
  );

  useEffect(() => {
    fetchData();
  }, []);

  const optionsSelected = (options: number[], type: REPORT_FILTER_LIST) => {
    const updatedState = state;
    switch (type) {
      case REPORT_FILTER_LIST.CUSTOMER:
        updatedState.customer.value = options;
        break;
      case REPORT_FILTER_LIST.VENDOR:
        updatedState.vendor.value = options;
        break;
      case REPORT_FILTER_LIST.PRODUCT:
        updatedState.product.value = options;
        break;
      case REPORT_FILTER_LIST.CLASS:
        updatedState.class.value = options;
        break;
      case REPORT_FILTER_LIST.ACCOUNT:
        updatedState.account.value = options;
        break;
      default:
        break;
    }
    setState({ ...updatedState });
  };

  const fetchData = () => {
    const updatedState = state;

    ContactService.getContactsByPage({
      Page: 0,
      Limit: 500,
      IncludeOpeningAmounts: false,
      IncludeOweAmounts: false,
      Query: 'status=active'
    })
      .then((res: any) => {
        const response: any = res;
        const content: any[] = response['content'] || [];
        const selectedOptions: number[] = [];
        const options: ReactOptionType[] = content.map((item: any, index) => {
          if (props.appliedFilter) {
            const isSelectedOption = props.appliedFilter.customer.find(
              (cus) => cus.id === item.code
            );
            if (isSelectedOption) {
              selectedOptions.push(index);
            }
          }
          return {
            value: item.code,
            label: item.name
          };
        });

        updatedState.customer.options = options;
        updatedState.customer.value = selectedOptions;
        setState({ ...updatedState });
      })
      .catch((err: any) => {});

    const productConfig: ProductAPIConfig = {
      ...ProductService.apiConfig,
      Page: 0,
      Limit: 500,
      Query: 'isVariant=false',
      SearchTerm: ''
    };

    ProductService.apiConfig = productConfig;

    ProductService.getProductsByPage()
      .then((res) => {
        const response: any = res;
        const content: any[] = response['content'] || [];
        const selectedOptions: number[] = [];
        const options: ReactOptionType[] = content.map((item: any, index) => {
          if (props.appliedFilter) {
            const isSelectedOption = props.appliedFilter.product.find(
              (prod) => prod.id === item.productId
            );
            if (isSelectedOption) {
              selectedOptions.push(index);
            }
          }
          return {
            value: item.productId,
            label: item.name
          };
        });

        updatedState.product.options = options;
        updatedState.product.value = selectedOptions;
        setState({ ...updatedState });
      })
      .catch((err) => {});

    AccountingService.getInstance()
      .getOBAccountByPage({
        Page: 0,
        Limit: 500,
        SearchTerm: ''
      })
      .then((res: any) => {
        const response: any = res;
        const content: any[] = response['content'] || [];
        const selectedOptions: number[] = [];
        const options: ReactOptionType[] = content.map((item: any, index) => {
          if (props.appliedFilter) {
            const isSelectedOption = props.appliedFilter.account.find(
              (acc) => acc.id === item.accountCode
            );
            if (isSelectedOption) {
              selectedOptions.push(index);
            }
          }
          return {
            value: item.accountCode,
            label: item.name
          };
        });

        updatedState.account.options = options;

        setState({ ...updatedState });
      })
      .catch((err: any) => {});

    ContactService.getContactsByPage({
      Page: 0,
      Limit: 500,
      IncludeOpeningAmounts: false,
      IncludeOweAmounts: false,
      Query: 'vendor=true'
    })
      .then((res: any) => {
        const response: any = res;
        const content: any[] = response['content'] || [];
        const selectedOptions: number[] = [];
        const options: ReactOptionType[] = content.map((item: any, index) => {
          if (props.appliedFilter) {
            const isSelectedOption = props.appliedFilter.vendor.find(
              (ven) => ven.id === item.code
            );
            if (isSelectedOption) {
              selectedOptions.push(index);
            }
          }
          return {
            value: item.code,
            label: item.name
          };
        });

        updatedState.vendor.options = options;
        updatedState.vendor.value = selectedOptions;
        setState({ ...updatedState });
      })
      .catch((err: any) => {});
  };

  const applyFilter = () => {
    const updatedState = state;
    const accountSelected: PayloadItem[] = [];
    const customerSelected: PayloadItem[] = [];
    const vendorSelected: PayloadItem[] = [];
    const productSelected: PayloadItem[] = [];

    if (updatedState.account.value.length > 0) {
      updatedState.account.value.forEach((item) => {
        if (updatedState.account.options) {
          accountSelected.push({
            id: updatedState.account.options[item].value,
            name: updatedState.account.options[item].label
          });
        }
      });
    }

    if (updatedState.customer.value.length > 0) {
      updatedState.customer.value.forEach((item) => {
        if (updatedState.customer.options) {
          customerSelected.push({
            id: updatedState.customer.options[item].value,
            name: updatedState.customer.options[item].label
          });
        }
      });
    }

    if (updatedState.vendor.value.length > 0) {
      updatedState.vendor.value.forEach((item) => {
        if (updatedState.vendor.options) {
          vendorSelected.push({
            id: updatedState.vendor.options[item].value,
            name: updatedState.vendor.options[item].label
          });
        }
      });
    }

    if (updatedState.product.value.length > 0) {
      updatedState.product.value.forEach((item) => {
        if (updatedState.product.options) {
          productSelected.push({
            id: updatedState.product.options[item].value,
            name: updatedState.product.options[item].label
          });
        }
      });
    }

    const payload: ReportFilterPayload = {
      account: accountSelected,
      customer: customerSelected,
      vendor: vendorSelected,
      product: productSelected
    };

    if (props.applyFilter) {
      props.applyFilter(payload);
    }
  };

  const multiSelectDropdown = (
    value: number[],
    title: string,
    dropdownName: REPORT_FILTER_LIST,
    options: ReactOptionType[] | undefined = [],
    searchableKey: string
  ) => {
    return (
      <div className="row">
        <div style={{ width: 120 }}>
          <DKLabel text={title} />
        </div>
        <DKInput
          className={''}
          value={value}
          displayKey="label"
          title=""
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          type={INPUT_TYPE.DROPDOWN}
          required={false}
          canValidate={true}
          onChange={(value: number[]) => {
            optionsSelected(value, dropdownName);
          }}
          dropdownConfig={{
            className: '',
            title: title,
            style: {},
            allowSearch: true,
            searchableKey: 'label',
            multiSelect: true,
            selectedIndexes: [],
            checkMarkColor: 'bg-blue',
            data: options,
            renderer: (index: number, obj: any) => {
              return <DKLabel text={obj.label} />;
            },
            onSelect: (index: number, value: any) => {},
            onClear: () => {}
          }}
        />
      </div>
    );
  };

  return (
    <div
      id="report-filter-popup-id"
      className="bg-white p-l border-radius-s shadow-l border-m parent-width p-h-l p-v-l"
    >
      <div className="row mb-l justify-content-between">
        <DKLabel className="column fs-m fw-m mb-m" text="Report Filters" />
        <div className="row width-auto">
          <DKButton
            title="Cancel"
            className="border-m mr-s"
            onClick={() => props.onClose()}
          />
          <DKButton
            title="Apply"
            className="bg-button text-white mr-ss"
            onClick={() => {
              applyFilter();
            }}
          />
        </div>
      </div>
      {/* <div className="mb-m">
        {multiSelectDropdown(
          state.account.value,
          'Account',
          REPORT_FILTER_LIST.ACCOUNT,
          state.account.options,
          'label'
        )}
      </div> */}
      <div className="mb-m">
        {multiSelectDropdown(
          state.customer.value,
          'Customer',
          REPORT_FILTER_LIST.CUSTOMER,
          state.customer.options,
          'name'
        )}
      </div>
      {/* <div className="mb-m">
        {multiSelectDropdown(
          state.vendor.value,
          'Vendor',
          REPORT_FILTER_LIST.VENDOR,
          state.vendor.options,
          ''
        )}
      </div> */}
      <div className="mb-m">
        {multiSelectDropdown(
          state.product.value,
          'Product',
          REPORT_FILTER_LIST.PRODUCT,
          state.product.options,
          ''
        )}
      </div>
    </div>
  );
};

export default ReportFilter;

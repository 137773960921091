import ApiConstants from '../constants/ApiConstant';
import { removeLoader, showAlert } from 'deskera-ui-library';
import { isMobileAppWebView } from '../utilities/ViewportSize';
import AuthService from '../services/user/Auth';
import { DEFAULT_TRIAL_PLAN_DAYS } from '../constants/Constant';

export const parseJWTToken = (token: string) => {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export default class AuthManager {
  static userLoggedIn = false;
  static didSessionExpired = false;
  static token = null;
  static userPlanDetails: any = null;

  static setUserLogInStatus(status: boolean) {
    AuthManager.userLoggedIn = status;
  }

  static isUserLoggedIn() {
    return AuthManager.userLoggedIn && !AuthManager.didSessionExpired;
  }

  static setSessionExpiryStatus(status: boolean) {
    AuthManager.didSessionExpired = status;
  }

  static isSessionExpired() {
    return AuthManager.didSessionExpired;
  }

  static onReceiveToken(accessToken: string) {
    const token = parseJWTToken(accessToken);
    AuthManager.token = token;
    AuthManager.setUserLogInStatus(true);
  }

  static gotoLoginPage(needAlert?: boolean) {
    removeLoader();

    if (needAlert && !isMobileAppWebView() && !AuthManager.isSessionExpired()) {
      showAlert(
        'Session expired!',
        'Your session is expired, please login and try again.',
        [
          {
            title: 'Ok',
            className: 'bg-button text-white fw-m',
            onClick: () => {
              window.open(
                ApiConstants.AUTH.LOGIN_REDIRECT + window.location.href,
                '_self'
              );
            }
          }
        ]
      );

      AuthManager.setSessionExpiryStatus(true);
    } else {
      window.open(
        ApiConstants.AUTH.LOGIN_REDIRECT + window.location.href,
        '_self'
      );
    }
  }

  static async logout() {
    try {
      await AuthService.getInstance().logOut();
      window.open(
        ApiConstants.AUTH.LOGIN_REDIRECT + window.location.href,
        '_self'
      );
    } catch (err) {
      showAlert('Failed to log out!', 'Please refresh & try again');
    }
  }

  static showLogoutConfirmation() {
    let buttons = [
      {
        title: 'Cancel',
        className: 'bg-gray2 border-m ',
        onClick: () => { }
      },
      {
        title: 'Log out',
        className: 'bg-red text-white ml-r',
        onClick: AuthManager.logout
      }
    ];

    showAlert('Log out', 'Are you sure want to log out the session?', buttons);
  }
  static setUserPlanDetails(planDetails: any) {
    this.userPlanDetails = planDetails;
  }
  static isTrialPlanActive() {
    return this.userPlanDetails?.InTrial && !this.userPlanDetails?.TrialOver;
  }
  static isTrialPlanAvailable() {
    return !this.userPlanDetails?.InTrial && !this.userPlanDetails?.TrialOver;
  }
  static getTrialDays(inTrialMode = false) {
    /* Trial Days for an unsubscribed user */
    let trialDays = this.userPlanDetails?.TrialDays ?? DEFAULT_TRIAL_PLAN_DAYS;

    /* Pending trial days */
    try {
      if (this.userPlanDetails && inTrialMode) {
        const trialEndTime = new Date(this.userPlanDetails.TrialEndDate).getTime();
        const currentTime = new Date().getTime();

        trialDays = Math.ceil((trialEndTime - currentTime) / (1000 * 60 * 60 * 24));
        trialDays = trialDays < 0 ? 0 : trialDays;
      }
    } catch (err) {
      trialDays = 0;
    }

    return trialDays;
  }
  static isPlanActive() {
    return (this.userPlanDetails?.Subscribed && this.getUserPlanName()) || this.isTrialPlanActive();
  }
  static getUserPlanName() {
    return this.userPlanDetails?.PlanName;
  }
  static getProductPermissionAlert(isOwner?: boolean) {
    let buttons = isOwner ? [
      {
        title: 'Do it Later',
        className: 'bg-gray2 border-m',
        onClick: () => { }
		  }, {
        title: 'Go to settings',
        className: 'bg-button text-white ml-m',
        onClick: () => window.open(ApiConstants.PRODUCT_URL_GO, '__self')
		  }
		] : undefined;

    if (typeof isOwner === "undefined") {
      buttons = [
        {
          title: 'Go to settings',
          className: 'bg-button text-white ml-m',
          onClick: () => window.open(ApiConstants.PRODUCT_URL_GO, '__self')
        }
      ];
      isOwner = true;
    }

    const isProfessionalPlan = AuthManager.getUserPlanName() === 'Professional';

    let heading = `Upgrade now!`;
    let message = `This feature is not available in your current plan. Please ${
        isOwner ? '' : 'ask org owner to '
    }upgrade to CRM-Professional plan.`;

    if (isProfessionalPlan) {
        heading = `Permission Denied!`;
        message = `You don't have permission to access report builder, please ${
        isOwner ? 
        'go to user settings or ask your org owner, for providing access to Deskera Report Builder.' : 
        'ask org owner to provide access.'
        }`;
    }

    showAlert(heading, message, buttons);
  }
}
export const parseAuthToken = (token: any) => {
  return {
    imID: token.iamUserId,
    iamUserId: token.iamUserId,
    id: token.userId,
    tenantID: token.tenantId,
    name: token.name,
    email: token.email,
    phone: token.phone_number,
    complianceEnabled: token.complianceEnabled,
    countryCode: token.countryCode,
    taxResidency: token.taxResidency,
    website: token.website,
    tenantName: token.website,
    country: token.taxResidency,
    currency: token.currency,
    isOrgSet: token.isOrgSet,
    emailVerified: token.emailVerified,
  }
};

import React, { Component, useEffect, useState } from 'react';
import {
  DKIcon,
  DKIcons,
  DKLabel,
  DKListPicker2,
  showAlert
} from 'deskera-ui-library';
import { useAppSelector } from '../../store/hooks';
import {
  getUserFullName,
  getUsers,
  fetchUsers
} from '../../store/slices/tenantSlice';
import {
  INVITE_USER_STATUS,
  NO_USER_VIEW_ALERT
} from '../../constants/Constant';
import { IUser } from '../../models/Tenant';
import { isEmptyObject } from '../../utilities/Common';
import { connect, ConnectedProps } from 'react-redux';
import { store } from '../../store/store';

const OwnerFilterButton = ({
  selectedOwner,
  onChangeOwner,
  className = '',
  additionalFilters = []
}: any) => {
  const users = useAppSelector(getUsers());
  const [needOwnerPicker, setNeedOwnerPicker] = useState(false);

  useEffect(() => {
    if (isEmptyObject(users)) {
      store.dispatch(fetchUsers()).then((response: any) => {});
    }
  }, []);

  const getUserList = () => {
    const availableUsers: IUser[] =
      users?.filter((user: any) => user.status === INVITE_USER_STATUS.JOINED) ||
      [];
    availableUsers.unshift(...additionalFilters);
    availableUsers.unshift({ firstName: 'All', lastName: '' } as IUser);
    return availableUsers.map((user) => ({
      ...user,
      fullName: getUserFullName(user)
    }));
  };

  const handleOwnerSelection = (newSelectedOwner: IUser | null) => {
    if (newSelectedOwner?.iamUserId !== selectedOwner?.iamUserId) {
      onChangeOwner(newSelectedOwner);
    }
    toggleOwnerPicker(false);
  };

  const toggleOwnerPicker = (shouldOpen?: boolean) => {
    if (!users?.length && shouldOpen) {
      showAlert('No users found', NO_USER_VIEW_ALERT);
      return;
    }
    setNeedOwnerPicker(shouldOpen ?? !needOwnerPicker);
  };

  return (
    <div className={`position-relative ${className}`}>
      <div
        className="row width-auto border-m bg-gray1 border-radius-m p-h-m cursor-hand"
        style={{
          paddingTop: 8,
          paddingBottom: 8,
          height: '100%'
        }}
        onClick={() => toggleOwnerPicker(true)}
      >
        <DKLabel
          text="Created by:"
          className="fw-m"
          style={{ whiteSpace: 'nowrap' }}
        />
        <DKLabel
          text={`${selectedOwner ? getUserFullName(selectedOwner) : 'All'}`}
          className="p-h-s"
          style={{
            maxWidth: 150,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        />
        <DKIcon src={DKIcons.ic_arrow_down2} className="ic-s" />
      </div>
      {needOwnerPicker && (
        <DKListPicker2
          className="position-absolute border-m z-index-3 shadow-m"
          style={{
            top: 0,
            right: 0,
            width: '100%',
            minWidth: 150,
            maxWidth: 200
          }}
          allowSearch={true}
          searchableKey={'fullName'}
          data={getUserList()}
          renderer={(index: number, user: IUser) => (
            <div className="text-align-left">{user.fullName}</div>
          )}
          onSelect={(index: number, user: IUser) => {
            const newSelectedUser = isEmptyObject(user.iamUserId?.toString())
              ? null
              : user;
            handleOwnerSelection(newSelectedUser);
          }}
          onClose={() => setTimeout(() => toggleOwnerPicker(false), 10)}
        />
      )}
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    users: state.tenant.users
  };
};
const mapDispatchToProps = { fetchUsers };
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(OwnerFilterButton);

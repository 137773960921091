import { APP_SCROLL_CONTAINER_ID } from '../constants/Constant';
import AuthManager from './AuthManager';
import { showAlert } from 'deskera-ui-library';

export default class AppManager {
  static scrollToTop() {
    const appContainer = document.getElementById(APP_SCROLL_CONTAINER_ID);
    if (!appContainer) return;

    appContainer.scrollTop = 0;
  }

  static reloadApp() {
    if (typeof window.history.pushState != 'undefined') {
      var obj = { Page: 'new', Url: '/' };
      window.history.pushState(obj, obj.Page, obj.Url);
    }

    window.location.reload();
  }
  static showLogoutConfirmation() {
    let buttons = [
      {
        title: "Cancel",
        className: "bg-gray2 border-m ",
        onClick: () => {},
      },
      {
        title: "Log out",
        className: "bg-red text-white ml-r",
        onClick: () => {
          AuthManager.logout();
        },
      },
    ];
    showAlert("Log out", "Are you sure want to log out the session?", buttons);
  }
}

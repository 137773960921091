import React, { useEffect, useState } from 'react';
import {
  DKLabel,
  showAlert,
  DKDataGrid,
  showLoader,
  DKSpinner,
  removeLoader
} from 'deskera-ui-library';
import { compare, CopilotManager } from '../managers/CopilotManager';
import RouteManager from '../managers/RouteManager';
import httpClient from '../services/index';
import { COPILOT } from '../constants/ApiConstant';
import SideBarService from '../services/common/Sidebar';
import { LOCAL_STORAGE_KEYS } from '../constants/Enum';
import { getItemFromLocalStorage } from '../utilities/LocalStorage';
import { isEmptyObject } from '../utilities/Common';
import { useParams } from 'react-router-dom';

const CopilotReport = (props: any) => {
  const [columns, setColumns] = useState<any[] | null>(null);
  const [rows, setRows] = useState<any[] | null>(null);
  const [filteredRows, setFilterRows] = useState<any[] | null>(null);
  const [pageInfo, setPageInfo] = useState({
    pageNo: 1,
    sortDir: 'ASC',
    sort: null,
    totalPages: null,
    limit: 15
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const { id } = useParams<any>();

  useEffect(() => {
    RouteManager.setPresenter({ props });
    return () => {
      CopilotManager.setData(null);
    };
  }, []);

  useEffect(() => {
    const data = CopilotManager.getData();
    const fileURL = data?.content?.attachments?.[0]?.fileUrl;
    if (id) {
      if (fileURL) {
        showLoader('Loading Reports Data...');
        setLoading(true);
        readCSV(fileURL);
      } else {
        showInvalidAlert();
      }
    }
  }, [id]);

  useEffect(() => {
    updateFilterRows();
  }, [pageInfo,search]);

  useEffect(() => {
    updateFilterRows();
  }, [rows]);

  const updateFilterRows = () => {
    let copyOfRows = [...(rows ?? [])];
    if(search){
      copyOfRows = copyOfRows.filter((rows:any) => Object.values(rows)?.join('')?.toLowerCase()?.includes(search?.toLowerCase()));
    }
    const sort = pageInfo.sort;
    if (sort != null) {
      if (pageInfo.sortDir === 'ASC') {
        copyOfRows = copyOfRows?.sort((a, b) => compare(a[sort], b[sort]));
      } else {
        copyOfRows = copyOfRows?.sort((a, b) => compare(b[sort], a[sort]));
      }
    }
    const placeOfLastRecord = pageInfo.pageNo * pageInfo.limit;
    copyOfRows =
      copyOfRows?.slice(
        placeOfLastRecord - pageInfo.limit,
        placeOfLastRecord
      ) ?? [];

    setFilterRows(copyOfRows);
  };

  const readCSV = (url: string) => {
    httpClient
      .post(COPILOT.READ_CSV, {
        url
      })
      .then((response: any) => {
        let dataArray = response.split(/\r?\n/);
        try {
          if (Array.isArray(dataArray)) {
            const headers = dataArray?.[0]?.split(',');
            const dataRows: string[] = dataArray?.slice(1, dataArray.length);
            const columnData: any[] = [];
            const rowData: any[] = [];
            headers?.forEach((header: string) => {
              header = header?.replaceAll('"', '');
              const key = header?.toLowerCase().replaceAll(' ', '_');
              columnData.push({
                name: header,
                type: 'text',
                index: 1,
                options: null,
                required: false,
                width: 200,
                editable: false,
                allowSort: true,
                hidden: false,
                uiVisible: true,
                systemField: true,
                columnCode: key,
                key: key
              });
            });
            dataRows?.forEach((row: string) => {
              const splitRow = row?.split(',');
              const newRow: any = {};
              columnData.forEach((column: any, index: number) => {
                newRow[column.key] = splitRow[index]?.replaceAll('"', '');
              });
              rowData.push(newRow);
            });
            setColumns(columnData);
            setRows(rowData);
            setLoading(false);
          } else {
            showInvalidAlert();
          }
          removeLoader();
        } catch (error) {
          removeLoader();
          showInvalidAlert();
          console.error(error);
        }
      })
      .catch((error: any) => {
        removeLoader();
        showInvalidAlert();
        console.error(error);
      });
  };
  const showInvalidAlert = () => {
    setLoading(false);
    showAlert('Error!', 'Data is not valid, Please try again!', [
      {
        title: 'Ok',
        className: 'bg-button text-white',
        onClick: () => {
          RouteManager.navigateToHome();
        }
      }
    ]);
  };
  const hasData = !isEmptyObject(rows) && !isEmptyObject(columns);
  return (
    <>
      {/* <div className="row row-responsive mobile-flex-wrap mobile-flex-gap-s justify-content-between bg-white p-h-xxl p-v-l">
        <DKLabel text="Copilot Report" className="fs-l fw-m" />
      </div> */}
      {!hasData && (
        <div className="column parent-size p-h-xxl pt-xs align-items-center justify-content-center">
          <DKSpinner
            iconClassName="ic-s-2"
            className="ml-m"
            title="Fetching Report..."
          />
        </div>
      )}
      {hasData && (
        <div className="column parent-width mb-s position-relative flex-1">
          <DKDataGrid
            title={`Copilot Report`}
            tableName={'Copilot Report'}
            metaDataTableName={'Copilot Report'}
            buttons={null}
            showHeader={true}
            allowSearch={true}
            allowRowAdd={false}
            allowRowEdit={false}
            allowColumnAdd={false}
            allowColumnEdit={false}
            allowColumnDelete={false}
            allowColumnShift={false}
            allowBulkOperation={true}
            allowFilter={false}
            width={SideBarService.getContainerWidth()}
            currentPage={pageInfo.pageNo}
            columns={columns}
            rows={filteredRows}
            needTrailingColumn={true}
            dateFormat={getItemFromLocalStorage(
              LOCAL_STORAGE_KEYS.GRID_DATE_FORMAT
            )}
            totalPageCount={Math.ceil((rows?.length ?? 0) / pageInfo.limit)}
            onSort={({ order, columnData }: any) => {
              setPageInfo((prePageInfo) => ({
                ...prePageInfo,
                sortDir: order,
                sort: columnData.key
              }));
            }}
            onPageChange={(pageNo: number) => {
              setPageInfo((prePageInfo) => ({ ...prePageInfo, pageNo }));
            }}
            onSearch={(searchText: string) => {
              setSearch(searchText);
            }}
          />
        </div>
      )}
    </>
  );
};

export default CopilotReport;

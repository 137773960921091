import React, { Component } from "react";
// import { INPUT_TYPE, INPUT_VIEW_DIRECTION } from "../Constants/Enum";
// import { getCapitalized } from "../Utility/DKUtility";

// import DKButton from "../DKButton";
// import DKLabel from "../DKLabel";
// import DKInput from "../DKInput";

import {DKInput, DKLabel, DKButton, getCapitalized, INPUT_TYPE, INPUT_VIEW_DIRECTION} from "deskera-ui-library";

class DKAddTenant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newOrgName: "",
      saveOrgTapped: false,
    };
  }
  setNewOrgName(name) {
    this.setState({ newOrgName: name });
  }

  setSaveOrgTapped(flag) {
    this.setState({ saveOrgTapped: flag });
  }

  saveButtonTapped = () => {
    this.setSaveOrgTapped(true);

    if (this.state.newOrgName?.trim()) {
      this.props.onSaveNewTenantTapped(getCapitalized(this.state.newOrgName));
    }
  };

  cancelTapped = () => {
    this.setNewOrgName("");
    this.props.onCancel();
  };

  componentDidMount() {
    document.addEventListener("keydown", this.bindEnter);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.bindEnter);
  }
  bindEnter = (evt) => {
    evt = evt || window.event;
    if (evt.key === "Enter") {
      this.saveButtonTapped();
    }
  };

  render() {
    return (
      <div className="transparent-background">
        <div className="popup-window" style={{ maxWidth: 400 }}>
          <div className="row justify-content-between">
            <DKLabel text="Add organisation" className="fw-m fs-l" />
            <div>
              <div className="row">
                <DKButton
                  title="Cancel"
                  className="bg-gray1 border-m fw-m"
                  onClick={this.cancelTapped}
                />
                <DKButton
                  title="Add"
                  className="bg-button ml-r text-white fw-m"
                  onClick={this.saveButtonTapped}
                />
              </div>
            </div>
          </div>

          <div className="column mt-r parent-width">
            <div className="row" style={{ alignItems: "flex-start" }}>
              <DKInput
                autoFocus={true}
                type={INPUT_TYPE.TEXT}
                className="parent-width"
                title="Company name"
                required
                value={this.state.newOrgName}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                onChange={(text) => this.setNewOrgName(text)}
                canValidate={this.state.saveOrgTapped}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DKAddTenant;

export class CopilotManager {
  static dataFromChat = null;
  static setData = (data: any) => {
    this.dataFromChat = data;
  };

  static getData(): any {
    return this.dataFromChat;
  }
}
export function compare(a: any, b: any) {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}
import React, { useEffect } from 'react';
import { DKIcon, DKLabel } from 'deskera-ui-library';
import ic_onedrive from '../assets/appIntegration/ic_onedrive.png';
import ic_dropbox from '../assets/appIntegration/ic_dropbox.png';
import OneDriveButton from '../components/app/OneDriveButton';
import RouteManager from '../managers/RouteManager';
import { useAppSelector } from '../store/hooks';
import DropBoxButton from '../components/app/DropBoxButton';

export default function ConnectAccounts(props: any) {
  const appIntegrationMapping = useAppSelector(
    (state) => state.appIntegration.connections
  );

  useEffect(() => {
    RouteManager.setPresenter({ props });
  }, []);

  const connectedOneDriveAccount = getConnectedOneDriveAccountData();
  const connectedDropBoxAccount = getConnectedDropBoxAccountData();

  const cards = [
    {
      title: 'Connect OneDrive',
      description: connectedOneDriveAccount && connectedOneDriveAccount?.connected
        ? `Connected account: ${connectedOneDriveAccount.username}`
        : `Connect your OneDrive account to Deskera Report Builder`,
      icon: ic_onedrive,
      buttonRenderer: () => (
        <OneDriveButton
          needDisconnectButton={true}
          onSuccess={(files: any[]) => {}}
          onCancel={() => {}}
          onError={(err) => {}}
        />
      )
    },
    {
      title: 'Connect DropBox',
      description: connectedDropBoxAccount && connectedDropBoxAccount?.connected
        ? `Connected account: ${connectedDropBoxAccount.username}`
        : `Connect your DropBox account to Deskera Report Builder`,
      icon: ic_dropbox,
      buttonRenderer: () => (
        <DropBoxButton
          needDisconnectButton={true}
          onSuccess={(files: any[]) => {}}
          onCancel={() => {}}
          onError={(err) => {}}
        />
      )
    }
  ];

  function getConnectedOneDriveAccountData() {
    try {
      const oneDriveAppList =
        appIntegrationMapping?.filter(
          (app: any) => app.appName === 'ONEDRIVE'
        ) || [];

      const oneDriveApp =
        oneDriveAppList.find((app) => app.connected) || oneDriveAppList[0];
      return oneDriveApp;
    } catch (err) {
      return null;
    }
  }
  function getConnectedDropBoxAccountData() {
    try {
      const dropBoxAppList =
        appIntegrationMapping?.filter(
          (app: any) => app.appName === 'DROPBOX'
        ) || [];

      const dropBoxApp =
        dropBoxAppList.find((app) => app.connected) || dropBoxAppList[0];
      return dropBoxApp;
    } catch (err) {
      return null;
    }
  }

  const getCard = (setting: any) => {
    return (
      <div
        className="bg-white border-radius-m border-m p-r column border-box shadow-s mobile-parent-width"
        style={{ minWidth: 250, width: '24%' }}
        onClick={() => {}}
      >
        <DKIcon src={setting.icon} className="" />
        <DKLabel text={setting.title} className="fw-m mt-r fs-m" />
        <DKLabel
          text={setting.description}
          className="text-dark-gray mt-xs mb-auto"
          style={{ height: 30 }}
        />
        <div className="row justify-content-end mt-auto pt-r">
          {setting.buttonRenderer()}
        </div>
      </div>
    );
  };

  const getAllCards = () => {
    let arr: any = [];
    cards.forEach((obj: any) => {
      if (obj.hidden) return;

      arr.push(getCard(obj));
    });
    return arr;
  };

  return (
    <div className="parent-width p-v-l">
      <DKLabel className="fs-l fw-m" text="Connect cloud storage" />
      <div
        className="row flex-wrap mt-l align-items-stretch"
        style={{ gap: 13 }}
      >
        {getAllCards()}
      </div>
    </div>
  );
}

export enum CURRENCY_SYMBOLS {
  ZWD = '$',
  ZMW = 'ZK',
  ZMK = 'ZK',
  ZAR = 'R',
  YER = '﷼',
  XPF = '₣',
  XOF = 'CFA',
  XCD = '$',
  XAF = 'FCFA',
  WST = 'WS$',
  VUV = 'VT',
  VND = '₫',
  VEF = 'Bs',
  UZS = 'лв',
  UYU = '$U',
  USD = '$',
  UGX = 'USh',
  UAH = '₴',
  TZS = 'TSh',
  TWD = 'NT$',
  TTD = 'TT$',
  TRY = '₺',
  TOP = 'T$',
  TND = 'د.ت',
  TMT = 'T',
  TMM = 'T',
  TJS = 'ЅM',
  THB = '฿',
  SZL = 'E',
  SYP = '£',
  STD = 'Db',
  SSP = '£',
  SRD = '$',
  SOS = 'S',
  SLL = 'Le',
  SKK = 'Sk',
  SHP = '£',
  SGD = '$',
  SEK = 'kr',
  SDG = 'SD',
  SCR = '₨',
  SBD = 'Si$',
  SAR = '﷼',
  RWF = 'FRw',
  RUB = '₽',
  RSD = 'Дин.',
  RON = 'lei',
  QAR = '﷼',
  PYG = '₲',
  PLN = 'zł',
  PKR = '₨',
  PHP = '₱',
  PGK = 'K',
  PEN = 'S/',
  PAB = 'B/.',
  OMR = '﷼',
  NZD = '$',
  NPR = '₨',
  NOK = 'kr',
  NIO = 'C$',
  NGN = '₦',
  NAD = '$',
  MZN = 'MT',
  MYR = 'RM',
  MXN = '$',
  MWK = 'MK',
  MVR = 'Rf',
  MUR = '₨',
  MTL = '₤',
  MRO = 'UM',
  MOP = 'MOP$',
  MNT = '₮',
  MMK = 'K',
  MKD = 'ден',
  MGA = 'Ar',
  MDL = 'L',
  MAD = 'DH',
  LYD = 'ل.د',
  LVL = 'Ls',
  LTL = 'Lt',
  LSL = 'L',
  LRD = '$',
  LKR = '₨',
  LBP = '£',
  LAK = '₭',
  KZT = 'лв',
  KYD = '$',
  KWD = 'د.ك',
  KRW = '₩',
  KPW = '₩',
  KMF = 'CF',
  KHR = '៛',
  KGS = 'лв',
  KES = 'KSh,',
  JPY = '¥',
  JOD = 'د.ا',
  JMD = 'J$',
  ISK = 'kr',
  IRR = '﷼',
  IQD = 'ع.د',
  INR = '₹',
  ILS = '₪',
  IDR = 'Rp',
  HUF = 'Ft',
  HTG = 'G',
  HRK = 'kn',
  HNL = 'L',
  HKD = '$',
  GYD = 'GY$',
  GTQ = 'Q',
  GNF = 'FG',
  GMD = 'D',
  GIP = '£',
  GHS = '₵',
  GHC = 'GH₵',
  GEL = 'ლ',
  GBP = '£',
  FKP = '£',
  FJD = 'FJ$',
  EUR = '€',
  ETB = 'ብር',
  ERN = 'ናቕፋ',
  EGP = '£',
  EEK = 'EEK',
  DZD = 'دج',
  DOP = 'RD$',
  DKK = 'kr.',
  DJF = 'Fdj',
  CZK = 'Kč',
  CYP = '£',
  CVE = '$',
  CUC = '$',
  CRC = '₡',
  COP = '$',
  CNY = '¥',
  CLP = '$',
  CHF = 'CHF',
  CDF = 'FC',
  CAD = '$',
  BZD = 'BZ$',
  BYR = 'Br',
  BWP = 'P',
  BTN = 'Nu.',
  BSD = '$',
  BRL = 'R$',
  BOB = 'b$',
  BND = '$',
  BMD = '$',
  BIF = 'FBu',
  BHD = '.د.ب',
  BGN = 'лв',
  BDT = '৳',
  BBD = '$',
  BAM = 'KM',
  AZN = '₼',
  AWG = 'Afl',
  AUD = '$',
  ARS = '$',
  AOA = 'Kz',
  ANG = 'Naf',
  AMD = 'դր',
  ALL = 'Lek',
  AFN = '؋',
  AED = 'د.إ'
}

import { ACCOUNTING_METHOD } from '../constants/Enum';

export enum REPORT_HEADER_ITEMS {
  COLUMN_BY = 'columnBy',
  COMPARISION_MODE = 'comparisionMode',
  DATE_RANGE = 'dateRange',
  ACCOUNTING_METHOD = 'accountingMethod',
  FILTER = 'filter',
  SHOW_CALENDAR = 'showCalendar',
  DIRECT = 'direct',
  TAX_AGENCY = 'taxAgency',
  SWITCH_CURRENCY = 'switchCurrency'
}

interface ReportHeaderItem {
  name: string;
  isHidden: boolean;
  type: REPORT_HEADER_ITEMS;
  dataOptions: any[];
  currentValue: any;
}

interface ReportHeaderDate {
  startDate: string;
  endDate: string;
  isHidden: boolean;
  dataOptions: any[];
  currentValue: any;
}

export interface ReportHeaderProps {
  reportType: string;
  columnBy: ReportHeaderItem;
  comparisionMode: ReportHeaderItem;
  dateRange: ReportHeaderDate;
  taxAgency?: ReportHeaderItem;
  accountingMethod: ReportHeaderItem;
  showDirect: boolean;
  runReport: (state: ReportHeaderState) => void;

  showFilter: boolean;
  customize?: any;
  customizePopup?: (state: any) => void;
  toggle: (state: any) => void;
  hideToggle: boolean;
  customFieldFilter?: (state: any) => void;
  hasCustomField?: boolean;
  hasMapping?: boolean;
  mrpReport?: boolean;
  searchBar?: boolean;
  searchPlaceholder?: string;
  removeValueStyle?: boolean;
  switchCurrency?: ReportHeaderItem;
}

interface PayloadItem {
  name: string;
  id: string;
}

export interface ReportFilterPayload {
  customer: PayloadItem[];
  vendor: PayloadItem[];
  product: PayloadItem[];
  account: PayloadItem[];
}

export interface ReportHeaderState {
  showCalendar: boolean;
  date: ReportHeaderDate;
  taxAgency: ReportHeaderItem;
  searchBar: { hidden: boolean };
  columnBy: ReportHeaderItem;
  customFieldOptionsForModule?: any;
  comparisionMode: ReportHeaderItem;
  accountingMethod: ReportHeaderItem;
  direct: { hidden: boolean; value: boolean };
  showFilterPopup: {
    hidden: boolean;
    show: boolean;
    data: ReportFilterPayload | null;
  };
  showCustomFilterPopup: {
    hidden: boolean;
    show: boolean;
    data: any;
    options?: any;
    customFieldOptions?: any;
  };
  presetInfo: any;
  isFiltersApplied: boolean;
  customize: any;
  toggle: any;
  hideToggle: boolean;
  hasCustomField: boolean;
  hasMapping?: boolean;
  switchCurrency?: ReportHeaderItem;
}

export const initialReportHeaderState: ReportHeaderState = {
  showCalendar: false,
  toggle: false,
  hasCustomField: false,
  hasMapping: false,
  hideToggle: true,
  searchBar: { hidden: true },
  date: {
    startDate: '',
    endDate: '',
    isHidden: false,
    dataOptions: [],
    currentValue: null
  },
  columnBy: {
    name: '',
    isHidden: false,
    type: REPORT_HEADER_ITEMS.COLUMN_BY,
    dataOptions: [],
    currentValue: null
  },
  comparisionMode: {
    name: '',
    isHidden: false,
    type: REPORT_HEADER_ITEMS.COMPARISION_MODE,
    dataOptions: [],
    currentValue: null
  },
  accountingMethod: {
    name: '',
    isHidden: false,
    type: REPORT_HEADER_ITEMS.ACCOUNTING_METHOD,
    dataOptions: [],
    currentValue: ACCOUNTING_METHOD.ACCRUAL
  },
  taxAgency: {
    name: '',
    isHidden: false,
    type: REPORT_HEADER_ITEMS.TAX_AGENCY,
    dataOptions: [],
    currentValue: null
  },
  showFilterPopup: {
    hidden: false,
    show: false,
    data: null
  },
  showCustomFilterPopup: {
    hidden: false,
    show: false,
    data: null,
    customFieldOptions: null
  },

  customize: {
    isHidden: true
  },
  direct: { hidden: false, value: true },
  presetInfo: null,
  isFiltersApplied: false
};

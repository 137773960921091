import { createSlice } from '@reduxjs/toolkit';
import { FILTER_LOGICAL_OPERATORS } from '../../constants/Enum';
import { ITableFilterCondition } from '../../services/reports/Table';
export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_SEARCH_QUERY = '';

/* *************** Types ****************** */
export interface TableMetaData {
  filter: {
    logicalOperator: FILTER_LOGICAL_OPERATORS;
    conditions: ITableFilterCondition[];
  };
  page: number;
  searchQuery: string;
}
export interface TableMetaDataState {
  activity: TableMetaData;
  overdueActivity: TableMetaData;
}

/* *************** INITIAL STATES ****************** */
const initialFilter: any = {
  logicalOperator: FILTER_LOGICAL_OPERATORS.AND,
  conditions: []
};
const initialMetaData: TableMetaData = {
  filter: initialFilter,
  page: DEFAULT_PAGE_NUMBER,
  searchQuery: ''
};
const initialState: TableMetaDataState = {
  activity: initialMetaData,
  overdueActivity: initialMetaData
};

/* *************** REDUCER ****************** */
export const tableMetaDataSlice = createSlice({
  name: 'tableMetaData',
  initialState,
  reducers: {
    updateMetaData: (state: any, action) => {
      state[action.payload.tableName] = action.payload.metaData;
    },
    patchFilterCondition: (state: any, action) => {
      const filterData =
        state[action.payload.tableName].filter || initialFilter;
      const updatedConditions = [...(filterData?.conditions || [])];
      const existingIndex = updatedConditions.findIndex(
        (condition) => condition.key === action.payload.condition.key
      );
      if (existingIndex === -1) {
        updatedConditions.push(action.payload.condition);
      } else {
        updatedConditions[existingIndex] = action.payload.condition;
      }
      filterData.conditions = updatedConditions;

      state[action.payload.tableName].filter = filterData;
    },
    removeFilterCondition: (state: any, action) => {
      const filterData =
        state[action.payload.tableName].filter || initialFilter;
      const updatedConditions = [...(filterData?.conditions || [])];
      const existingIndex = updatedConditions.findIndex(
        (condition) => condition.key === action.payload.key
      );
      if (existingIndex !== -1) {
        updatedConditions.splice(existingIndex, 1);
      }
      filterData.conditions = updatedConditions;

      state[action.payload.tableName].filter = filterData;
    },
    updateFilter: (state: any, action) => {
      state[action.payload.tableName].filter =
        action.payload.filter || initialFilter;
    },
    updatePage: (state: any, action) => {
      state[action.payload.tableName].page =
        action.payload.page || DEFAULT_PAGE_NUMBER;
    },
    updateSearchQuery: (state: any, action) => {
      state[action.payload.tableName].searchQuery =
        action.payload.searchQuery || DEFAULT_SEARCH_QUERY;
    }
  }
});
export const {
  updateMetaData,
  updateFilter,
  removeFilterCondition,
  patchFilterCondition,
  updatePage,
  updateSearchQuery
} = tableMetaDataSlice.actions;

export const selectTableMeta = (state: any, tableName: string) =>
  state.tableMetaData[tableName];

export default tableMetaDataSlice.reducer;

import ApiConstants from '../../constants/ApiConstant';
import httpClient from '..';
import { APP_NAME } from '../../constants/Constant';
import { INPUT_TYPE } from 'deskera-ui-library';
export interface ITable {
  _id?: string;
  name?: string;
  columnsMetaData?: IColumn[];
  default?: boolean;
}
export interface IColumn {
  id?: string;
  key?: string;
  name?: string;
  type?: IColumnType;
  index?: number;
  options?: IColumnOption[];
  required?: boolean;
  width?: number;
  editable?: boolean;
  systemField?: boolean;
  columnCode?: string;
  hidden?: boolean;
  uiVisible?: boolean;
  datasource?: IColumnDataSource;
  formatter?: (data: any) => any;
  renderer?: (data: any) => any;
  disableWebhook?: boolean;
  dropdownConfig?: any;
  tableName?: string;
  allowAddOption?: boolean;
  allowFilter?: boolean;
  allowDataSourceEdit?: boolean;
  refTable?: IRefTable;
  formatType?: string;
}
export type IColumnType = 'ref';

export interface IColumnOption {
  id?: string | number;
  name?: string;
  color?: string; //css class ex: bg-gray
}

export interface IRefTable {
  _id: string;
  objectType: String;
}

export type IColumnDataSource = {
  source?: string;
  target?: string;
  filter?: any;
  valueKey?: string;
};

export interface ITableFilterCondition {
  key: string;
  condition: string;
  value: filterCondition;
  isExternal?: boolean;
}
export type filterCondition =
  | 'is'
  | 'isnt'
  | 'in'
  | 'nin'
  | 'c'
  | 'nc'
  | 'sw'
  | 'ew'
  | 'eq'
  | 'neq'
  | 'emp'
  | 'nemp'
  | 'lt'
  | 'gt'
  | 'lte'
  | 'gte';

export interface IFilterCondition {
  colId: string;
  opr: string;
  value: filterCondition;
  isExternal?: boolean;
}

export interface ITableFilter {
  logicalOperator: 'or' | 'and';
  conditions: IFilterCondition[];
}
export type AddRecordButtonConfig = {
  title: string;
  icon?: string;
  className?: string;
  style?: any;
  onClick?: () => void;
};
class TableService {
  private static _tableInstance: TableService;

  public static getInstance(): TableService {
    if (!TableService._tableInstance) {
      TableService._tableInstance = new TableService();
    }
    return TableService._tableInstance;
  }

  /* ************** Fetch Report Data API's ************** */
  public async fetchReportTablesByAppName(data: any) {
    return httpClient.get(ApiConstants.TABLE.LIST, { params: data });
  }
}

export default TableService;

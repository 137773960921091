import { showAlert } from 'deskera-ui-library';

let resolveCallback: any;

const useConfirm = () => {
  const onConfirm = () => {
    resolveCallback(true);
  };

  const onCancel = () => {
    resolveCallback(false);
  };

  const confirm = (
    text: string,
    title?: string,
    confirmBtnLabel?: string,
    cancelBtnLabel?: string,
    confirmBtnClass?: string
  ) => {
    let buttons = [
      {
        title: cancelBtnLabel ? cancelBtnLabel : `Cancel`,
        className: 'bg-gray2 border-m mt-r',
        onClick: () => {
          onCancel();
        }
      },
      {
        title: confirmBtnLabel ? confirmBtnLabel : `Delete`,
        className: confirmBtnClass ?? 'bg-red text-white ml-r mt-r',
        onClick: () => {
          onConfirm();
        }
      }
    ];
    const confirmTitle = title ? title : ``;
    showAlert(
      confirmTitle,
      text ??
        'Deleting this record will delete it permanently, you will not be able to restore it.',
      buttons
    );
    return new Promise((res, rej) => {
      resolveCallback = res;
    });
  };

  return { confirm, onConfirm, onCancel };
};

export default useConfirm;

import {
  createAsyncThunk,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';
import ReportService from '../../services/reports/Report';
import { RootState } from '../store';
import { IReport } from '../../models/Report';
import { TABLES } from '../../constants/Constant';

export interface ReportState {
  activityByContact: any;
  ticketByContact: any;
  reports: any;
  selectedReport: IReport | null;
  data: any;
  records: any;
}
const initialState: ReportState = {
  activityByContact: [],
  ticketByContact: [],
  reports: {},
  selectedReport: null,
  data: {},
  records: []
};

const _reportService = ReportService.getInstance();

export const fetchReportsByPage = createAsyncThunk(
  'reports/fetchReportsByPage',
  async (data: any = {}) => {
    const response = await _reportService.fetchAllReports(data);
    return response;
  }
);

/* ************* STANDARD REPORT APIS **************** */
export const fetchActivityByContact = createAsyncThunk(
  'reports/fetchActivityByContact',
  async (data: any) => {
    let response = await _reportService.fetchActivityByContact(data?.params);
    return response;
  }
);
export const fetchTicketByContact = createAsyncThunk(
  'reports/fetchTicketByContact',
  async (data: any) => {
    let response = await _reportService.fetchTicketByContact(data?.params);
    return response;
  }
);
export const fetchAutomationLogs = createAsyncThunk(
  'record/fetchAutomationLogs',
  async (data: any, isTrigger) => {
    let response = await _reportService.fetchAutomationLogs(data?.params);
    return response;
  }
);
export const reportSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchActivityByContact.fulfilled, (state, action) => {
      state.activityByContact = action.payload;
    });
    builder
      .addCase(fetchTicketByContact.fulfilled, (state, action) => {
        state.ticketByContact = action.payload;
      })
      .addCase(fetchReportsByPage.fulfilled, (state, action) => {
        state.reports = action.payload;
      });
    builder.addCase(fetchAutomationLogs.fulfilled, (state, action) => {
      state.data[TABLES.AUTOMATION_LOG] = action.payload;
    });
  }
});

export const getRecordsForActivityByContact = (state: RootState) =>
  state.reports.activityByContact;
export const getRecordsForTicketByContact = (state: RootState) =>
  state.reports.ticketByContact;
const selfSelector = (state: RootState) => state;
export const recordsByTableNameSelector = (tableName: string) =>
  createSelector(
    selfSelector,
    (state: RootState) => state.reports.data[tableName]
  );
export default reportSlice.reducer;

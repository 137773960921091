import {
  BALANCE_SHEET1,
  BALANCE_SHEET2,
  BALANCE_SHEET3,
  BALANCE_SHEET_LABEL,
  BALANCE_SHEET_STYLE,
  BALANCE_SHEET_TOTAL
} from '../constants/Constant';
import { store } from '../store/store';
import { isEmpty } from '../utilities/Common';
import { getDateFromString } from '../utilities/Date';
import {
  getAmountBlockWithCurrency,
  getParsedChildPNL,
  getParsedChildPNLDetails,
  getParsedChildPNLInBothCurrency,
  getParsedSortedChildPNL,
  getParsedSortedChildPNLInBothCurrency,
  replaceLabelsForLiabilitiesAndAssets
} from './SharedParser';

const getTenantInfo = () => {
  return store.getState().tenant.tenantInfo;
};

export const getParsedDataForBL = (
  reportData: any,
  reportFilterState: any,
  currency?: string
) => {
  const tenantInfo = getTenantInfo();

  if (isEmpty(reportData)) return;
  let openingBalanceAsset: any = {};
  let openingBalanceLiability: any = {};
  let totalAsset: any = {};
  let totalLiability: any = {};
  let showAsset = true;
  let showAssetObj: any = {};
  for (let period in reportData['periodName']) {
    totalAsset[period] = reportData['assets']['totalAsset'][period];
    totalLiability[period] =
      reportData['liabilities']['totalLiabilities'][period];

    const diff = Math.abs(totalAsset[period] - totalLiability[period]);

    if (totalAsset[period] < totalLiability[period]) {
      openingBalanceAsset[period] = diff;
      totalAsset[period] += diff;
      showAssetObj[period] = true;
      showAsset = true;
    } else {
      openingBalanceLiability[period] = diff;
      totalLiability[period] += diff;
      showAssetObj[period] = false;
      showAsset = false;
    }
  }
  let newReportData: any = {
    assets: {},
    bank: reportData['assets']['bank'],
    totalBank: reportData['assets']['totalBank'],
    totalCash: reportData['assets']['totalCash'],
    totalCurrentAsset: reportData['assets']['totalCurrentAsset'],
    totalNonCurrentAsset: reportData['assets']['totalNonCurrentAsset'],
    totalOtherCurrentAssets: reportData['assets']['totalOtherCurrentAssets'],
    totalEquity: reportData['liabilities']['totalEquity'],
    totalCurrentLiabilities:
      reportData['liabilities']['totalCurrentLiabilities'],
    totalNonCurrentLiabilities:
      reportData['liabilities']['totalNonCurrentLiabilities'],
    totalOtherCurrentLiabilities:
      reportData['liabilities']['totalOtherCurrentLiabilities'],
    cash: reportData['assets']['cash'],
    currentAsset: reportData['assets']['currentAsset'],
    nonCurrentAsset: reportData['assets']['nonCurrentAsset'],
    otherCurrentAssets: reportData['assets']['otherCurrentAssets'],
    longTermAssets: reportData['assets']['longTermAssets'],
    totalLongTermAssets: reportData['assets']['totalLongTermAssets'],
    accountsReceivable: reportData['assets']['accountsReceivable'],
    totalAccountsReceivable: reportData['assets']['totalAccountsReceivable'],
    fixedAssets: reportData['assets']['fixedAssets'],
    totalFixedAssets: reportData['assets']['totalFixedAssets'],
    otherAssets: reportData['assets']['otherAssets'],
    totalOtherAssets: reportData['assets']['totalOtherAssets'],
    ppe: reportData['assets']['ppe'],
    totalPpe: reportData['assets']['totalPpe'],
    intangibles: reportData['assets']['intangibles'],
    totalIntangibles: reportData['assets']['totalIntangibles'],
    otherLongTermAsset: reportData['assets']['otherLongTermAsset'],
    totalOtherLongTermAsset: reportData['assets']['totalOtherLongTermAsset'],
    diffOpeningBalanceAsset: openingBalanceAsset,
    totalAsset: totalAsset,
    liabilities: {},
    accountsPayable: reportData['liabilities']['accountsPayable'],
    totalAccountsPayable: reportData['liabilities']['totalAccountsPayable'],
    currentLiabilities: reportData['liabilities']['currentLiabilities'],
    nonCurrentLiabilities: reportData['liabilities']['nonCurrentLiabilities'],
    otherCurrentLiabilities:
      reportData['liabilities']['otherCurrentLiabilities'],
    longTermLiabilities: reportData['liabilities']['longTermLiabilities'],
    totalLongTermLiabilities:
      reportData['liabilities']['totalLongTermLiabilities'],
    equity: reportData['liabilities']['equity'],
    diffOpeningBalanceLiability: openingBalanceLiability,
    totalLiabilities: totalLiability,
    periodName: reportData['periodName']
  };
  let width = 200;
  const len = Object.keys(newReportData['periodName']).length;

  if (len <= 2) {
    width = 300;
  } else if (len <= 4) {
    width = 250;
  } else {
    width = 170;
  }

  let payload: any = {};
  payload['columns'] = [];
  payload['columns'].push({
    title: ' ',
    style: {
      width: width,

      textAlign: 'left'
    },
    type: 'bold-lines'
  });

  for (let key in newReportData['periodName']) {
    const month = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    let dateArray = reportData['periodName'][key].split(' - ');
    let title =
      reportFilterState.ComparisonMode.id === 3
        ? ` ${
            month[
              new Date(
                getDateFromString(
                  dateArray[0].toString(),
                  tenantInfo?.dateFormat.toUpperCase()
                )
              )?.getMonth()
            ]
          } ${new Date(
            getDateFromString(
              dateArray[0].toString(),
              tenantInfo?.dateFormat.toUpperCase()
            )
          )?.getFullYear()}`
        : newReportData['periodName'][key];
    if (
      !isEmpty(reportFilterState.Contact) ||
      !isEmpty(reportFilterState.Product)
    ) {
      payload['columns'].push({
        title: reportData['displayText'][key]
          ? reportData['displayText'][key]
          : ' ',
        style: {
          width: width,
          textAlign: 'right',
          justifyContent: 'center',
          alignItems: 'center'
        },
        type: 'bold-lines'
      });
    } else {
      payload['columns'].push({
        title: title,
        style: {
          width: width,
          textAlign: 'right',
          type: 'bold-lines'
        },
        type: 'bold-lines'
      });
    }
  }
  payload['rows'] = [];

  for (let key in newReportData) {
    let row: any = { expanded: true };
    row.cells = [];
    row.children = [];
    const text = key;
    const result = text.replace(/([A-Z])/g, ' $1');
    let finalText = result.charAt(0).toUpperCase() + result.slice(1);
    if (finalText === 'Accounts Receivable') {
      finalText = 'Accounts Receivable (A/R)';
    }
    if (finalText === 'Accounts Payable') {
      finalText = 'Accounts Payable (A/P)';
    }

    if (tenantInfo?.country === 'US') {
      finalText = replaceLabelsForLiabilitiesAndAssets(finalText);
    }
    if (
      (finalText === 'Diff Opening Balance Asset' && showAsset === false) ||
      (finalText === 'Diff Opening Balance Liability' && showAsset)
    ) {
      continue;
    }
    if (
      (finalText === 'Diff Opening Balance Asset' && showAsset) ||
      (finalText === 'Diff Opening Balance Liability' && !showAsset)
    ) {
      finalText = 'Diff Opening Balance';
    }

    let style: any = {
      fontWeight: 'normal',
      marginLeft: 0
    };
    if (BALANCE_SHEET2[key] && key !== 'periodName') {
      //style.textAlign = 'left';
      if (BALANCE_SHEET3[key]) {
        style = {
          ...style
        };
      }

      row.cells.push({
        title: finalText,
        style: {
          ...style,
          borderTop: BALANCE_SHEET_LABEL[key] ? 'none' : '1px solid lightGray',
          marginTop: BALANCE_SHEET_STYLE[key] ? '15px' : '0px',
          fontWeight: BALANCE_SHEET1[key]
            ? '600'
            : BALANCE_SHEET_LABEL[key]
            ? '700'
            : 'normal'
        }
      });

      let totalValues: any = { expanded: {}, collapsed: {} };
      for (let period in newReportData['periodName']) {
        if (BALANCE_SHEET_TOTAL[key]) {
          totalValues.expanded[period] = undefined;
          totalValues.collapsed[`${period}_withChild`] =
            newReportData[BALANCE_SHEET_TOTAL[key]][period];
        }
        row = { ...row, totalValues: totalValues };
        //style.textAlign = 'right';
        let hasChild = true;
        if (Object.keys(newReportData[key]).length === 0) {
          hasChild = false;
        }
        let title = BALANCE_SHEET_TOTAL[key]
          ? hasChild
            ? ' '
            : newReportData[BALANCE_SHEET_TOTAL[key]][period]
          : BALANCE_SHEET1[key]
          ? newReportData[BALANCE_SHEET1[key]][period]
          : BALANCE_SHEET_LABEL[key]
          ? ' '
          : newReportData[key][period];

        row.cells.push({
          title: BALANCE_SHEET_TOTAL[key]
            ? hasChild
              ? ' '
              : getAmountBlockWithCurrency(
                  newReportData[BALANCE_SHEET_TOTAL[key]][period] || 0,
                  currency
                )
            : BALANCE_SHEET1[key]
            ? getAmountBlockWithCurrency(
                newReportData[BALANCE_SHEET1[key]][period] || 0,
                currency
              )
            : BALANCE_SHEET_LABEL[key]
            ? ' '
            : getAmountBlockWithCurrency(
                newReportData[key][period] || 0,
                currency
              ),
          style: {
            ...style,
            borderTop: BALANCE_SHEET_LABEL[key]
              ? 'none'
              : '1px solid lightGray',
            fontWeight: BALANCE_SHEET1[key] ? '600' : 'normal',
            color: !isNaN(title) && title < 0 ? 'red' : 'black'
          }
        });
      }
    }

    for (let child in newReportData[key]) {
      if (child.includes('Period_')) {
        continue;
      }
      let children: any = {
        expanded: true,
        cells: [],
        children: []
      };
      let totalValues: any = {
        expanded: {},
        collapsed: {}
      };

      for (let keys in newReportData['periodName']) {
        totalValues.expanded[keys] =
          newReportData[key][child]['periodMap'][keys];
        totalValues.collapsed[`${keys}_withChild`] =
          newReportData[key][child]['periodMap'][`${keys}_withChild`];
      }
      children = { ...children, totalValues: totalValues };

      children.cells.push({
        title: newReportData[key][child]['name'],
        style: { style, paddingLeft: 6 }
      });

      for (let keys in newReportData['periodName']) {
        children.cells.push({
          title: !isNaN(newReportData[key][child]['periodMap'][`${keys}`])
            ? getAmountBlockWithCurrency(
                newReportData[key][child]['periodMap'][keys],
                currency
              )
            : ' ',
          style:
            !isNaN(newReportData[key][child]['periodMap'][`${keys}`]) &&
            newReportData[key][child]['periodMap'][`${keys}`] >= 0
              ? style
              : { ...style, color: 'red' }
        });
      }

      if (newReportData[key][child]['itemDto'] !== null) {
        newReportData[key][child]['itemDto'].forEach(
          (item: any, index: number) => {
            children.children.push(
              getParsedChildPNL(item, 15, reportData, currency)
            );
          }
        );
      }
      children.children = children.children.sort(function (a: any, b: any) {
        if (a.cells.length) {
          return a.cells[0]?.title?.localeCompare(b.cells[0]?.title);
        }
      });

      row.children.push(children);
    }

    row.children = row.children.sort(function (a: any, b: any) {
      if (a.cells.length) {
        return a.cells[0]?.title?.localeCompare(b.cells[0]?.title);
      }
    });
    payload['rows'].push(row);
  }

  return { payload, length: len };
};

export const getParsedSortedDataForBL = (
  reportData: any,
  reportFilterState: any,
  direction: string,
  currency?: string
) => {
  const tenantInfo = getTenantInfo();

  if (isEmpty(reportData)) return;
  let openingBalanceAsset: any = {};
  let openingBalanceLiability: any = {};
  let totalAsset: any = {};
  let totalLiability: any = {};
  let showAsset = true;
  let showAssetObj: any = {};

  for (let period in reportData['periodName']) {
    totalAsset[period] = reportData['assets']['totalAsset'][period];
    totalLiability[period] =
      reportData['liabilities']['totalLiabilities'][period];

    const diff = Math.abs(totalAsset[period] - totalLiability[period]);

    if (totalAsset[period] < totalLiability[period]) {
      openingBalanceAsset[period] = diff;
      totalAsset[period] += diff;
      showAsset = true;
      showAssetObj[period] = true;
    } else {
      openingBalanceLiability[period] = diff;
      totalLiability[period] += diff;
      showAsset = false;
      showAssetObj[period] = false;
    }
  }
  let newReportData: any = {
    assets: {},
    bank: reportData['assets']['bank'],
    totalBank: reportData['assets']['totalBank'],
    totalCash: reportData['assets']['totalCash'],
    totalCurrentAsset: reportData['assets']['totalCurrentAsset'],
    totalNonCurrentAsset: reportData['assets']['totalNonCurrentAsset'],
    totalOtherCurrentAssets: reportData['assets']['totalOtherCurrentAssets'],
    totalEquity: reportData['liabilities']['totalEquity'],
    totalCurrentLiabilities:
      reportData['liabilities']['totalCurrentLiabilities'],
    totalNonCurrentLiabilities:
      reportData['liabilities']['totalNonCurrentLiabilities'],
    totalOtherCurrentLiabilities:
      reportData['liabilities']['totalOtherCurrentLiabilities'],
    cash: reportData['assets']['cash'],
    currentAsset: reportData['assets']['currentAsset'],
    nonCurrentAsset: reportData['assets']['nonCurrentAsset'],
    otherCurrentAssets: reportData['assets']['otherCurrentAssets'],
    longTermAssets: reportData['assets']['longTermAssets'],
    totalLongTermAssets: reportData['assets']['totalLongTermAssets'],
    accountsReceivable: reportData['assets']['accountsReceivable'],
    totalAccountsReceivable: reportData['assets']['totalAccountsReceivable'],
    fixedAssets: reportData['assets']['fixedAssets'],
    totalFixedAssets: reportData['assets']['totalFixedAssets'],
    otherAssets: reportData['assets']['otherAssets'],
    totalOtherAssets: reportData['assets']['totalOtherAssets'],
    ppe: reportData['assets']['ppe'],
    totalPpe: reportData['assets']['totalPpe'],
    intangibles: reportData['assets']['intangibles'],
    totalIntangibles: reportData['assets']['totalIntangibles'],
    otherLongTermAsset: reportData['assets']['otherLongTermAsset'],
    totalOtherLongTermAsset: reportData['assets']['totalOtherLongTermAsset'],
    diffOpeningBalanceAsset: openingBalanceAsset,
    totalAsset: totalAsset,
    liabilities: {},
    accountsPayable: reportData['liabilities']['accountsPayable'],
    totalAccountsPayable: reportData['liabilities']['totalAccountsPayable'],
    currentLiabilities: reportData['liabilities']['currentLiabilities'],
    nonCurrentLiabilities: reportData['liabilities']['nonCurrentLiabilities'],
    otherCurrentLiabilities:
      reportData['liabilities']['otherCurrentLiabilities'],
    equity: reportData['liabilities']['equity'],
    longTermLiabilities: reportData['liabilities']['longTermLiabilities'],
    totalLongTermLiabilities:
      reportData['liabilities']['totalLongTermLiabilities'],
    diffOpeningBalanceLiability: openingBalanceLiability,
    totalLiabilities: totalLiability,
    periodName: reportData['periodName']
  };
  let width = 200;
  const len = Object.keys(newReportData['periodName']).length;

  if (len <= 2) {
    width = 300;
  } else if (len <= 4) {
    width = 250;
  } else {
    width = 170;
  }

  let payload: any = {};
  payload['columns'] = [];
  payload['columns'].push({
    title: ' ',
    style: {
      width: width,

      textAlign: 'left'
    },
    type: 'bold-lines'
  });

  for (let key in newReportData['periodName']) {
    const month = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    let dateArray = reportData['periodName'][key].split(' - ');
    let title =
      reportFilterState.ComparisonMode.id === 3
        ? ` ${
            month[
              new Date(
                getDateFromString(
                  dateArray[0].toString(),
                  tenantInfo?.dateFormat.toUpperCase()
                )
              )?.getMonth()
            ]
          } ${new Date(
            getDateFromString(
              dateArray[0].toString(),
              tenantInfo?.dateFormat.toUpperCase()
            )
          )?.getFullYear()}`
        : newReportData['periodName'][key];
    if (
      !isEmpty(reportFilterState.Contact) ||
      !isEmpty(reportFilterState.Product)
    ) {
      payload['columns'].push({
        title: reportData['displayText'][key]
          ? reportData['displayText'][key]
          : ' ',
        style: {
          width: width,
          textAlign: 'right',
          justifyContent: 'center',
          alignItems: 'center'
        },
        type: 'bold-lines'
      });
    } else {
      payload['columns'].push({
        title: title,
        style: {
          width: width,
          textAlign: 'right',
          type: 'bold-lines'
        },
        type: 'bold-lines'
      });
    }
  }
  payload['rows'] = [];
  for (let key in newReportData) {
    let row: any = { expanded: true };
    row.cells = [];
    row.children = [];
    const text = key;
    const result = text.replace(/([A-Z])/g, ' $1');
    let finalText = result.charAt(0).toUpperCase() + result.slice(1);
    if (finalText === 'Accounts Receivable') {
      finalText = 'Accounts Receivable (A/R)';
    }
    if (finalText === 'Accounts Payable') {
      finalText = 'Accounts Payable (A/P)';
    }

    if (tenantInfo?.country === 'US') {
      finalText = replaceLabelsForLiabilitiesAndAssets(finalText);
    }
    if (
      (finalText === 'Diff Opening Balance Asset' && showAsset === false) ||
      (finalText === 'Diff Opening Balance Liability' && showAsset)
    ) {
      continue;
    }
    if (
      (finalText === 'Diff Opening Balance Asset' && showAsset) ||
      (finalText === 'Diff Opening Balance Liability' && !showAsset)
    ) {
      finalText = 'Diff Opening Balance';
    }

    let style: any = {
      fontWeight: 'normal',
      marginLeft: 0
    };
    if (BALANCE_SHEET2[key] && key !== 'periodName') {
      //style.textAlign = 'left';
      if (BALANCE_SHEET3[key]) {
        style = {
          ...style
        };
      }

      row.cells.push({
        title: finalText,
        style: {
          ...style,
          borderTop: BALANCE_SHEET_LABEL[key] ? 'none' : '1px solid lightGray',
          marginTop: BALANCE_SHEET_STYLE[key] ? '15px' : '0px',
          fontWeight: BALANCE_SHEET1[key]
            ? '600'
            : BALANCE_SHEET_LABEL[key]
            ? '700'
            : 'normal'
        }
      });

      let totalValues: any = { expanded: {}, collapsed: {} };
      for (let period in newReportData['periodName']) {
        if (BALANCE_SHEET_TOTAL[key]) {
          totalValues.expanded[period] = undefined;
          totalValues.collapsed[`${period}_withChild`] =
            newReportData[BALANCE_SHEET_TOTAL[key]][period];
        }
        row = { ...row, totalValues: totalValues };
        //style.textAlign = 'right';
        let hasChild = true;
        if (Object.keys(newReportData[key]).length === 0) {
          hasChild = false;
        }
        let title = BALANCE_SHEET_TOTAL[key]
          ? hasChild
            ? ' '
            : newReportData[BALANCE_SHEET_TOTAL[key]][period]
          : BALANCE_SHEET1[key]
          ? newReportData[BALANCE_SHEET1[key]][period]
          : BALANCE_SHEET_LABEL[key]
          ? ' '
          : newReportData[key][period];

        row.cells.push({
          title: BALANCE_SHEET_TOTAL[key]
            ? hasChild
              ? ' '
              : getAmountBlockWithCurrency(
                  newReportData[BALANCE_SHEET_TOTAL[key]][period] || 0,
                  currency
                )
            : BALANCE_SHEET1[key]
            ? getAmountBlockWithCurrency(
                newReportData[BALANCE_SHEET1[key]][period] || 0,
                currency
              )
            : BALANCE_SHEET_LABEL[key]
            ? ' '
            : getAmountBlockWithCurrency(
                newReportData[key][period] || 0,
                currency
              ),
          style: {
            ...style,
            borderTop: BALANCE_SHEET_LABEL[key]
              ? 'none'
              : '1px solid lightGray',
            fontWeight: BALANCE_SHEET1[key] ? '600' : 'normal',
            color: !isNaN(title) && title < 0 ? 'red' : 'black'
          },
          amount: BALANCE_SHEET_TOTAL[key]
            ? !isNaN(
                newReportData[BALANCE_SHEET_TOTAL[key]][`${period}__withChild`]
              )
              ? newReportData[BALANCE_SHEET_TOTAL[key]][`${period}__withChild`]
              : newReportData[BALANCE_SHEET_TOTAL[key]][`${period}`]
            : BALANCE_SHEET1[key]
            ? newReportData[BALANCE_SHEET1[key]][period]
            : newReportData[key][period]
        });
      }
    }

    for (let child in newReportData[key]) {
      if (child.includes('Period_')) {
        continue;
      }
      let children: any = {
        expanded: true,
        cells: [],
        children: []
      };
      let totalValues: any = {
        expanded: {},
        collapsed: {}
      };

      for (let keys in newReportData['periodName']) {
        totalValues.expanded[keys] =
          newReportData[key][child]['periodMap'][keys];
        totalValues.collapsed[`${keys}_withChild`] =
          newReportData[key][child]['periodMap'][`${keys}_withChild`];
      }
      children = { ...children, totalValues: totalValues };

      children.cells.push({
        title: newReportData[key][child]['name'],
        style: { style, paddingLeft: 6 }
      });

      for (let keys in newReportData['periodName']) {
        children.cells.push({
          title: !isNaN(newReportData[key][child]['periodMap'][`${keys}`])
            ? getAmountBlockWithCurrency(
                newReportData[key][child]['periodMap'][keys],
                currency
              )
            : ' ',
          style:
            !isNaN(newReportData[key][child]['periodMap'][`${keys}`]) &&
            newReportData[key][child]['periodMap'][`${keys}`] >= 0
              ? style
              : { ...style, color: 'red' },
          amount: !isNaN(
            newReportData[key][child]['periodMap'][`${keys}_withChild`]
          )
            ? newReportData[key][child]['periodMap'][`${keys}_withChild`]
            : newReportData[key][child]['periodMap'][`${keys}`]
        });
      }

      if (newReportData[key][child]['itemDto'] !== null) {
        newReportData[key][child]['itemDto'].forEach(
          (item: any, index: number) => {
            children.children.push(
              getParsedSortedChildPNL(item, 15, reportData, direction, currency)
            );
          }
        );
      }
      let newPayload: any = [];
      children.children.forEach((row: any) => {
        if (row.cells.length) {
          newPayload.push(row);
        }
      });
      if (direction === 'ASC') {
        children.children = newPayload.sort(
          (a: any, b: any) => a.cells[1].amount - b.cells[1].amount
        );
      } else {
        children.children = newPayload.sort(
          (a: any, b: any) => b.cells[1].amount - a.cells[1].amount
        );
      }

      row.children.push(children);
    }

    let newPayload: any = [];
    row.children.forEach((row: any) => {
      if (row.cells.length) {
        newPayload.push(row);
      }
    });
    if (direction === 'ASC') {
      row.children = newPayload.sort(
        (a: any, b: any) => a.cells[1].amount - b.cells[1].amount
      );
    } else {
      row.children = newPayload.sort(
        (a: any, b: any) => b.cells[1].amount - a.cells[1].amount
      );
    }

    payload['rows'].push(row);
  }
  let newPayload: any = [];
  payload['rows'].forEach((row: any) => {
    if (row.cells.length) {
      newPayload.push(row);
    }
  });
  if (direction === 'ASC') {
    payload['rows'] = newPayload.sort(
      (a: any, b: any) => a.cells[1].amount - b.cells[1].amount
    );
  } else {
    payload['rows'] = newPayload.sort(
      (a: any, b: any) => b.cells[1].amount - a.cells[1].amount
    );
  }

  return { payload, length: len };
};

export const getParsedDataForBLInBothCurrency = (
  reportData: any,
  reportFilterState: any,
  baseCurrency: string,
  primaryCurrency: string
) => {
  const tenantInfo = getTenantInfo();

  const getCurrency = (period: string) => {
    let col = parseInt(period.split('_')[1]);
    return col & 1 ? baseCurrency : primaryCurrency;
  };
  if (isEmpty(reportData)) return;
  let openingBalanceAsset: any = {};
  let openingBalanceLiability: any = {};
  let totalAsset: any = {};
  let totalLiability: any = {};
  let showAsset = true;
  let showAssetObj: any = {};
  for (let period in reportData['periodName']) {
    totalAsset[period] = reportData['assets']['totalAsset'][period];
    totalLiability[period] =
      reportData['liabilities']['totalLiabilities'][period];

    const diff = Math.abs(totalAsset[period] - totalLiability[period]);

    if (totalAsset[period] < totalLiability[period]) {
      openingBalanceAsset[period] = diff;
      totalAsset[period] += diff;
      showAssetObj[period] = true;
      showAsset = true;
    } else {
      openingBalanceLiability[period] = diff;
      totalLiability[period] += diff;
      showAssetObj[period] = false;
      showAsset = false;
    }
  }
  let newReportData: any = {
    assets: {},
    bank: reportData['assets']['bank'],
    totalBank: reportData['assets']['totalBank'],
    totalCash: reportData['assets']['totalCash'],
    totalCurrentAsset: reportData['assets']['totalCurrentAsset'],
    totalNonCurrentAsset: reportData['assets']['totalNonCurrentAsset'],
    totalOtherCurrentAssets: reportData['assets']['totalOtherCurrentAssets'],
    totalEquity: reportData['liabilities']['totalEquity'],
    totalCurrentLiabilities:
      reportData['liabilities']['totalCurrentLiabilities'],
    totalNonCurrentLiabilities:
      reportData['liabilities']['totalNonCurrentLiabilities'],
    totalOtherCurrentLiabilities:
      reportData['liabilities']['totalOtherCurrentLiabilities'],
    cash: reportData['assets']['cash'],
    currentAsset: reportData['assets']['currentAsset'],
    nonCurrentAsset: reportData['assets']['nonCurrentAsset'],
    otherCurrentAssets: reportData['assets']['otherCurrentAssets'],
    longTermAssets: reportData['assets']['longTermAssets'],
    totalLongTermAssets: reportData['assets']['totalLongTermAssets'],
    accountsReceivable: reportData['assets']['accountsReceivable'],
    totalAccountsReceivable: reportData['assets']['totalAccountsReceivable'],
    fixedAssets: reportData['assets']['fixedAssets'],
    totalFixedAssets: reportData['assets']['totalFixedAssets'],
    otherAssets: reportData['assets']['otherAssets'],
    totalOtherAssets: reportData['assets']['totalOtherAssets'],
    ppe: reportData['assets']['ppe'],
    totalPpe: reportData['assets']['totalPpe'],
    intangibles: reportData['assets']['intangibles'],
    totalIntangibles: reportData['assets']['totalIntangibles'],
    otherLongTermAsset: reportData['assets']['otherLongTermAsset'],
    totalOtherLongTermAsset: reportData['assets']['totalOtherLongTermAsset'],
    diffOpeningBalanceAsset: openingBalanceAsset,
    totalAsset: totalAsset,
    liabilities: {},
    accountsPayable: reportData['liabilities']['accountsPayable'],
    totalAccountsPayable: reportData['liabilities']['totalAccountsPayable'],
    currentLiabilities: reportData['liabilities']['currentLiabilities'],
    nonCurrentLiabilities: reportData['liabilities']['nonCurrentLiabilities'],
    otherCurrentLiabilities:
      reportData['liabilities']['otherCurrentLiabilities'],
    longTermLiabilities: reportData['liabilities']['longTermLiabilities'],
    totalLongTermLiabilities:
      reportData['liabilities']['totalLongTermLiabilities'],
    equity: reportData['liabilities']['equity'],
    diffOpeningBalanceLiability: openingBalanceLiability,
    totalLiabilities: totalLiability,
    periodName: reportData['periodName']
  };
  let width = 200;
  const len = Object.keys(newReportData['periodName']).length;

  if (len <= 2) {
    width = 300;
  } else if (len <= 4) {
    width = 250;
  } else {
    width = 170;
  }

  let payload: any = {};
  payload['columns'] = [];
  payload['columns'].push({
    title: ' ',
    style: {
      width: width,

      textAlign: 'left'
    },
    type: 'bold-lines'
  });

  for (let key in newReportData['periodName']) {
    const month = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    let dateArray = reportData['periodName'][key].split(' - ');
    let title =
      reportFilterState.ComparisonMode.id === 3
        ? ` ${
            month[
              new Date(
                getDateFromString(
                  dateArray[0].toString(),
                  tenantInfo?.dateFormat.toUpperCase()
                )
              )?.getMonth()
            ]
          } ${new Date(
            getDateFromString(
              dateArray[0].toString(),
              tenantInfo?.dateFormat.toUpperCase()
            )
          )?.getFullYear()}`
        : newReportData['periodName'][key];
    if (
      !isEmpty(reportFilterState.Contact) ||
      !isEmpty(reportFilterState.Product)
    ) {
      payload['columns'].push({
        title: reportData['displayText'][key]
          ? reportData['displayText'][key]
          : ' ',
        style: {
          width: width,
          textAlign: 'right',
          justifyContent: 'center',
          alignItems: 'center'
        },
        type: 'bold-lines'
      });
    } else {
      payload['columns'].push({
        title: title,
        style: {
          width: width,
          textAlign: 'right',
          type: 'bold-lines'
        },
        type: 'bold-lines'
      });
    }
  }
  payload['rows'] = [];

  for (let key in newReportData) {
    let row: any = { expanded: true };
    row.cells = [];
    row.children = [];
    const text = key;
    const result = text.replace(/([A-Z])/g, ' $1');
    let finalText = result.charAt(0).toUpperCase() + result.slice(1);
    if (finalText === 'Accounts Receivable') {
      finalText = 'Accounts Receivable (A/R)';
    }
    if (finalText === 'Accounts Payable') {
      finalText = 'Accounts Payable (A/P)';
    }

    if (tenantInfo?.country === 'US') {
      finalText = replaceLabelsForLiabilitiesAndAssets(finalText);
    }
    if (
      (finalText === 'Diff Opening Balance Asset' && showAsset === false) ||
      (finalText === 'Diff Opening Balance Liability' && showAsset)
    ) {
      continue;
    }
    if (
      (finalText === 'Diff Opening Balance Asset' && showAsset) ||
      (finalText === 'Diff Opening Balance Liability' && !showAsset)
    ) {
      finalText = 'Diff Opening Balance';
    }

    let style: any = {
      fontWeight: 'normal',
      marginLeft: 0
    };
    if (BALANCE_SHEET2[key] && key !== 'periodName') {
      //style.textAlign = 'left';
      if (BALANCE_SHEET3[key]) {
        style = {
          ...style
        };
      }

      row.cells.push({
        title: finalText,
        style: {
          ...style,
          borderTop: BALANCE_SHEET_LABEL[key] ? 'none' : '1px solid lightGray',
          marginTop: BALANCE_SHEET_STYLE[key] ? '15px' : '0px',
          fontWeight: BALANCE_SHEET1[key]
            ? '600'
            : BALANCE_SHEET_LABEL[key]
            ? '700'
            : 'normal'
        }
      });

      let totalValues: any = { expanded: {}, collapsed: {} };
      for (let period in newReportData['periodName']) {
        if (BALANCE_SHEET_TOTAL[key]) {
          totalValues.expanded[period] = undefined;
          totalValues.collapsed[`${period}_withChild`] =
            newReportData[BALANCE_SHEET_TOTAL[key]][period];
        }
        row = { ...row, totalValues: totalValues };
        //style.textAlign = 'right';
        let hasChild = true;
        if (Object.keys(newReportData[key]).length === 0) {
          hasChild = false;
        }
        let title = BALANCE_SHEET_TOTAL[key]
          ? hasChild
            ? ' '
            : newReportData[BALANCE_SHEET_TOTAL[key]][period]
          : BALANCE_SHEET1[key]
          ? newReportData[BALANCE_SHEET1[key]][period]
          : BALANCE_SHEET_LABEL[key]
          ? ' '
          : newReportData[key][period];

        row.cells.push({
          title: BALANCE_SHEET_TOTAL[key]
            ? hasChild
              ? ' '
              : getAmountBlockWithCurrency(
                  newReportData[BALANCE_SHEET_TOTAL[key]][period] || 0,
                  getCurrency(period)
                )
            : BALANCE_SHEET1[key]
            ? getAmountBlockWithCurrency(
                newReportData[BALANCE_SHEET1[key]][period] || 0,
                getCurrency(period)
              )
            : BALANCE_SHEET_LABEL[key]
            ? ' '
            : getAmountBlockWithCurrency(
                newReportData[key][period] || 0,
                getCurrency(period)
              ),
          style: {
            ...style,
            borderTop: BALANCE_SHEET_LABEL[key]
              ? 'none'
              : '1px solid lightGray',
            fontWeight: BALANCE_SHEET1[key] ? '600' : 'normal',
            color: !isNaN(title) && title < 0 ? 'red' : 'black'
          }
        });
      }
    }

    for (let child in newReportData[key]) {
      if (child.includes('Period_')) {
        continue;
      }
      let children: any = {
        expanded: true,
        cells: [],
        children: []
      };
      let totalValues: any = {
        expanded: {},
        collapsed: {}
      };

      for (let keys in newReportData['periodName']) {
        totalValues.expanded[keys] =
          newReportData[key][child]['periodMap'][keys];
        totalValues.collapsed[`${keys}_withChild`] =
          newReportData[key][child]['periodMap'][`${keys}_withChild`];
      }
      children = { ...children, totalValues: totalValues };

      children.cells.push({
        title: newReportData[key][child]['name'],
        style: { style, paddingLeft: 6 }
      });

      for (let keys in newReportData['periodName']) {
        children.cells.push({
          title: !isNaN(newReportData[key][child]['periodMap'][`${keys}`])
            ? getAmountBlockWithCurrency(
                newReportData[key][child]['periodMap'][keys],
                getCurrency(keys)
              )
            : ' ',
          style:
            !isNaN(newReportData[key][child]['periodMap'][`${keys}`]) &&
            newReportData[key][child]['periodMap'][`${keys}`] >= 0
              ? style
              : { ...style, color: 'red' }
        });
      }

      if (newReportData[key][child]['itemDto'] !== null) {
        newReportData[key][child]['itemDto'].forEach(
          (item: any, index: number) => {
            children.children.push(
              getParsedChildPNLInBothCurrency(
                item,
                15,
                reportData,
                baseCurrency,
                primaryCurrency
              )
            );
          }
        );
      }
      children.children = children.children.sort(function (a: any, b: any) {
        if (a.cells.length) {
          return a.cells[0]?.title?.localeCompare(b.cells[0]?.title);
        }
      });

      row.children.push(children);
    }

    row.children = row.children.sort(function (a: any, b: any) {
      if (a.cells.length) {
        return a.cells[0]?.title?.localeCompare(b.cells[0]?.title);
      }
    });
    payload['rows'].push(row);
  }

  return { payload, length: len };
};

export const getParsedSortedDataForBLInBothCurrency = (
  reportData: any,
  reportFilterState: any,
  direction: string,
  baseCurrency: string,
  primaryCurrency: string
) => {
  const tenantInfo = getTenantInfo();

  const getCurrency = (period: string) => {
    let col = parseInt(period.split('_')[1]);
    return col & 1 ? baseCurrency : primaryCurrency;
  };
  if (isEmpty(reportData)) return;
  let openingBalanceAsset: any = {};
  let openingBalanceLiability: any = {};
  let totalAsset: any = {};
  let totalLiability: any = {};
  let showAsset = true;
  let showAssetObj: any = {};

  for (let period in reportData['periodName']) {
    totalAsset[period] = reportData['assets']['totalAsset'][period];
    totalLiability[period] =
      reportData['liabilities']['totalLiabilities'][period];

    const diff = Math.abs(totalAsset[period] - totalLiability[period]);

    if (totalAsset[period] < totalLiability[period]) {
      openingBalanceAsset[period] = diff;
      totalAsset[period] += diff;
      showAsset = true;
      showAssetObj[period] = true;
    } else {
      openingBalanceLiability[period] = diff;
      totalLiability[period] += diff;
      showAsset = false;
      showAssetObj[period] = false;
    }
  }
  let newReportData: any = {
    assets: {},
    bank: reportData['assets']['bank'],
    totalBank: reportData['assets']['totalBank'],
    totalCash: reportData['assets']['totalCash'],
    totalCurrentAsset: reportData['assets']['totalCurrentAsset'],
    totalNonCurrentAsset: reportData['assets']['totalNonCurrentAsset'],
    totalOtherCurrentAssets: reportData['assets']['totalOtherCurrentAssets'],
    totalEquity: reportData['liabilities']['totalEquity'],
    totalCurrentLiabilities:
      reportData['liabilities']['totalCurrentLiabilities'],
    totalNonCurrentLiabilities:
      reportData['liabilities']['totalNonCurrentLiabilities'],
    totalOtherCurrentLiabilities:
      reportData['liabilities']['totalOtherCurrentLiabilities'],
    cash: reportData['assets']['cash'],
    currentAsset: reportData['assets']['currentAsset'],
    nonCurrentAsset: reportData['assets']['nonCurrentAsset'],
    otherCurrentAssets: reportData['assets']['otherCurrentAssets'],
    longTermAssets: reportData['assets']['longTermAssets'],
    totalLongTermAssets: reportData['assets']['totalLongTermAssets'],
    accountsReceivable: reportData['assets']['accountsReceivable'],
    totalAccountsReceivable: reportData['assets']['totalAccountsReceivable'],
    fixedAssets: reportData['assets']['fixedAssets'],
    totalFixedAssets: reportData['assets']['totalFixedAssets'],
    otherAssets: reportData['assets']['otherAssets'],
    totalOtherAssets: reportData['assets']['totalOtherAssets'],
    ppe: reportData['assets']['ppe'],
    totalPpe: reportData['assets']['totalPpe'],
    intangibles: reportData['assets']['intangibles'],
    totalIntangibles: reportData['assets']['totalIntangibles'],
    otherLongTermAsset: reportData['assets']['otherLongTermAsset'],
    totalOtherLongTermAsset: reportData['assets']['totalOtherLongTermAsset'],
    diffOpeningBalanceAsset: openingBalanceAsset,
    totalAsset: totalAsset,
    liabilities: {},
    accountsPayable: reportData['liabilities']['accountsPayable'],
    totalAccountsPayable: reportData['liabilities']['totalAccountsPayable'],
    currentLiabilities: reportData['liabilities']['currentLiabilities'],
    nonCurrentLiabilities: reportData['liabilities']['nonCurrentLiabilities'],
    otherCurrentLiabilities:
      reportData['liabilities']['otherCurrentLiabilities'],
    equity: reportData['liabilities']['equity'],
    longTermLiabilities: reportData['liabilities']['longTermLiabilities'],
    totalLongTermLiabilities:
      reportData['liabilities']['totalLongTermLiabilities'],
    diffOpeningBalanceLiability: openingBalanceLiability,
    totalLiabilities: totalLiability,
    periodName: reportData['periodName']
  };
  let width = 200;
  const len = Object.keys(newReportData['periodName']).length;

  if (len <= 2) {
    width = 300;
  } else if (len <= 4) {
    width = 250;
  } else {
    width = 170;
  }

  let payload: any = {};
  payload['columns'] = [];
  payload['columns'].push({
    title: ' ',
    style: {
      width: width,

      textAlign: 'left'
    },
    type: 'bold-lines'
  });

  for (let key in newReportData['periodName']) {
    const month = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    let dateArray = reportData['periodName'][key].split(' - ');
    let title =
      reportFilterState.ComparisonMode.id === 3
        ? ` ${
            month[
              new Date(
                getDateFromString(
                  dateArray[0].toString(),
                  tenantInfo?.dateFormat.toUpperCase()
                )
              )?.getMonth()
            ]
          } ${new Date(
            getDateFromString(
              dateArray[0].toString(),
              tenantInfo?.dateFormat.toUpperCase()
            )
          )?.getFullYear()}`
        : newReportData['periodName'][key];
    if (
      !isEmpty(reportFilterState.Contact) ||
      !isEmpty(reportFilterState.Product)
    ) {
      payload['columns'].push({
        title: reportData['displayText'][key]
          ? reportData['displayText'][key]
          : ' ',
        style: {
          width: width,
          textAlign: 'right',
          justifyContent: 'center',
          alignItems: 'center'
        },
        type: 'bold-lines'
      });
    } else {
      payload['columns'].push({
        title: title,
        style: {
          width: width,
          textAlign: 'right',
          type: 'bold-lines'
        },
        type: 'bold-lines'
      });
    }
  }
  payload['rows'] = [];
  for (let key in newReportData) {
    let row: any = { expanded: true };
    row.cells = [];
    row.children = [];
    const text = key;
    const result = text.replace(/([A-Z])/g, ' $1');
    let finalText = result.charAt(0).toUpperCase() + result.slice(1);
    if (finalText === 'Accounts Receivable') {
      finalText = 'Accounts Receivable (A/R)';
    }
    if (finalText === 'Accounts Payable') {
      finalText = 'Accounts Payable (A/P)';
    }

    if (tenantInfo?.country === 'US') {
      finalText = replaceLabelsForLiabilitiesAndAssets(finalText);
    }
    if (
      (finalText === 'Diff Opening Balance Asset' && showAsset === false) ||
      (finalText === 'Diff Opening Balance Liability' && showAsset)
    ) {
      continue;
    }
    if (
      (finalText === 'Diff Opening Balance Asset' && showAsset) ||
      (finalText === 'Diff Opening Balance Liability' && !showAsset)
    ) {
      finalText = 'Diff Opening Balance';
    }

    let style: any = {
      fontWeight: 'normal',
      marginLeft: 0
    };
    if (BALANCE_SHEET2[key] && key !== 'periodName') {
      //style.textAlign = 'left';
      if (BALANCE_SHEET3[key]) {
        style = {
          ...style
        };
      }

      row.cells.push({
        title: finalText,
        style: {
          ...style,
          borderTop: BALANCE_SHEET_LABEL[key] ? 'none' : '1px solid lightGray',
          marginTop: BALANCE_SHEET_STYLE[key] ? '15px' : '0px',
          fontWeight: BALANCE_SHEET1[key]
            ? '600'
            : BALANCE_SHEET_LABEL[key]
            ? '700'
            : 'normal'
        }
      });

      let totalValues: any = { expanded: {}, collapsed: {} };
      for (let period in newReportData['periodName']) {
        if (BALANCE_SHEET_TOTAL[key]) {
          totalValues.expanded[period] = undefined;
          totalValues.collapsed[`${period}_withChild`] =
            newReportData[BALANCE_SHEET_TOTAL[key]][period];
        }
        row = { ...row, totalValues: totalValues };
        //style.textAlign = 'right';
        let hasChild = true;
        if (Object.keys(newReportData[key]).length === 0) {
          hasChild = false;
        }
        let title = BALANCE_SHEET_TOTAL[key]
          ? hasChild
            ? ' '
            : newReportData[BALANCE_SHEET_TOTAL[key]][period]
          : BALANCE_SHEET1[key]
          ? newReportData[BALANCE_SHEET1[key]][period]
          : BALANCE_SHEET_LABEL[key]
          ? ' '
          : newReportData[key][period];

        row.cells.push({
          title: BALANCE_SHEET_TOTAL[key]
            ? hasChild
              ? ' '
              : getAmountBlockWithCurrency(
                  newReportData[BALANCE_SHEET_TOTAL[key]][period] || 0,
                  getCurrency(period)
                )
            : BALANCE_SHEET1[key]
            ? getAmountBlockWithCurrency(
                newReportData[BALANCE_SHEET1[key]][period] || 0,
                getCurrency(period)
              )
            : BALANCE_SHEET_LABEL[key]
            ? ' '
            : getAmountBlockWithCurrency(
                newReportData[key][period] || 0,
                getCurrency(period)
              ),
          style: {
            ...style,
            borderTop: BALANCE_SHEET_LABEL[key]
              ? 'none'
              : '1px solid lightGray',
            fontWeight: BALANCE_SHEET1[key] ? '600' : 'normal',
            color: !isNaN(title) && title < 0 ? 'red' : 'black'
          },
          amount: BALANCE_SHEET_TOTAL[key]
            ? !isNaN(
                newReportData[BALANCE_SHEET_TOTAL[key]][`${period}__withChild`]
              )
              ? newReportData[BALANCE_SHEET_TOTAL[key]][`${period}__withChild`]
              : newReportData[BALANCE_SHEET_TOTAL[key]][`${period}`]
            : BALANCE_SHEET1[key]
            ? newReportData[BALANCE_SHEET1[key]][period]
            : newReportData[key][period]
        });
      }
    }

    for (let child in newReportData[key]) {
      if (child.includes('Period_')) {
        continue;
      }
      let children: any = {
        expanded: true,
        cells: [],
        children: []
      };
      let totalValues: any = {
        expanded: {},
        collapsed: {}
      };

      for (let keys in newReportData['periodName']) {
        totalValues.expanded[keys] =
          newReportData[key][child]['periodMap'][keys];
        totalValues.collapsed[`${keys}_withChild`] =
          newReportData[key][child]['periodMap'][`${keys}_withChild`];
      }
      children = { ...children, totalValues: totalValues };

      children.cells.push({
        title: newReportData[key][child]['name'],
        style: { style, paddingLeft: 6 }
      });

      for (let keys in newReportData['periodName']) {
        children.cells.push({
          title: !isNaN(newReportData[key][child]['periodMap'][`${keys}`])
            ? getAmountBlockWithCurrency(
                newReportData[key][child]['periodMap'][keys],
                getCurrency(keys)
              )
            : ' ',
          style:
            !isNaN(newReportData[key][child]['periodMap'][`${keys}`]) &&
            newReportData[key][child]['periodMap'][`${keys}`] >= 0
              ? style
              : { ...style, color: 'red' },
          amount: !isNaN(
            newReportData[key][child]['periodMap'][`${keys}_withChild`]
          )
            ? newReportData[key][child]['periodMap'][`${keys}_withChild`]
            : newReportData[key][child]['periodMap'][`${keys}`]
        });
      }

      if (newReportData[key][child]['itemDto'] !== null) {
        newReportData[key][child]['itemDto'].forEach(
          (item: any, index: number) => {
            children.children.push(
              getParsedSortedChildPNLInBothCurrency(
                item,
                15,
                reportData,
                direction,
                baseCurrency,
                primaryCurrency
              )
            );
          }
        );
      }
      let newPayload: any = [];
      children.children.forEach((row: any) => {
        if (row.cells.length) {
          newPayload.push(row);
        }
      });
      if (direction === 'ASC') {
        children.children = newPayload.sort(
          (a: any, b: any) => a.cells[1].amount - b.cells[1].amount
        );
      } else {
        children.children = newPayload.sort(
          (a: any, b: any) => b.cells[1].amount - a.cells[1].amount
        );
      }

      row.children.push(children);
    }

    let newPayload: any = [];
    row.children.forEach((row: any) => {
      if (row.cells.length) {
        newPayload.push(row);
      }
    });
    if (direction === 'ASC') {
      row.children = newPayload.sort(
        (a: any, b: any) => a.cells[1].amount - b.cells[1].amount
      );
    } else {
      row.children = newPayload.sort(
        (a: any, b: any) => b.cells[1].amount - a.cells[1].amount
      );
    }

    payload['rows'].push(row);
  }
  let newPayload: any = [];
  payload['rows'].forEach((row: any) => {
    if (row.cells.length) {
      newPayload.push(row);
    }
  });
  if (direction === 'ASC') {
    payload['rows'] = newPayload.sort(
      (a: any, b: any) => a.cells[1].amount - b.cells[1].amount
    );
  } else {
    payload['rows'] = newPayload.sort(
      (a: any, b: any) => b.cells[1].amount - a.cells[1].amount
    );
  }

  return { payload, length: len };
};

export const getParsedDataForBLDetails = (
  reportData: any,
  reportFilterState: any,
  columns: any[]
) => {
  if (isEmpty(reportData)) return;
  let openingBalanceAsset: any = {};
  let openingBalanceLiability: any = {};
  let totalAsset: any = {};
  let totalLiability: any = {};
  let showAsset = true;
  for (let period in reportData['periodName']) {
    totalAsset[period] = reportData['assets']['totalAsset'][period];
    totalLiability[period] =
      reportData['liabilities']['totalLiabilities'][period];

    const diff = Math.abs(totalAsset[period] - totalLiability[period]);

    if (totalAsset[period] < totalLiability[period]) {
      openingBalanceAsset[period] = diff;
      totalAsset[period] += diff;
      showAsset = true;
    } else {
      openingBalanceLiability[period] = diff;
      totalLiability[period] += diff;
      showAsset = false;
    }
  }

  let newReportData: any = {
    assets: {},
    bank: reportData['assets']['bank'],
    totalBank: reportData['assets']['totalBank'],
    totalCash: reportData['assets']['totalCash'],
    totalCurrentAsset: reportData['assets']['totalCurrentAsset'],
    totalNonCurrentAsset: reportData['assets']['totalNonCurrentAsset'],
    totalOtherCurrentAssets: reportData['assets']['totalOtherCurrentAssets'],
    totalEquity: reportData['liabilities']['totalEquity'],
    totalCurrentLiabilities:
      reportData['liabilities']['totalCurrentLiabilities'],
    totalNonCurrentLiabilities:
      reportData['liabilities']['totalNonCurrentLiabilities'],
    totalOtherCurrentLiabilities:
      reportData['liabilities']['totalOtherCurrentLiabilities'],
    cash: reportData['assets']['cash'],
    currentAsset: reportData['assets']['currentAsset'],
    nonCurrentAsset: reportData['assets']['nonCurrentAsset'],
    otherCurrentAssets: reportData['assets']['otherCurrentAssets'],
    diffOpeningBalanceAsset: openingBalanceAsset,
    totalAsset: totalAsset,

    longTermAssets: reportData['assets']['longTermAssets'],
    totalLongTermAssets: reportData['assets']['totalLongTermAssets'],
    accountsReceivable: reportData['assets']['accountsReceivable'],
    totalAccountsReceivable: reportData['assets']['totalAccountsReceivable'],
    fixedAssets: reportData['assets']['fixedAssets'],
    totalFixedAssets: reportData['assets']['totalFixedAssets'],
    otherAssets: reportData['assets']['otherAssets'],
    totalOtherAssets: reportData['assets']['totalOtherAssets'],
    ppe: reportData['assets']['ppe'],
    totalPpe: reportData['assets']['totalPpe'],
    intangibles: reportData['assets']['intangibles'],
    totalIntangibles: reportData['assets']['totalIntangibles'],
    otherLongTermAsset: reportData['assets']['otherLongTermAsset'],
    totalOtherLongTermAsset: reportData['assets']['totalOtherLongTermAsset'],

    liabilities: {},
    accountsPayable: reportData['liabilities']['accountsPayable'],
    totalAccountsPayable: reportData['liabilities']['totalAccountsPayable'],

    currentLiabilities: reportData['liabilities']['currentLiabilities'],
    nonCurrentLiabilities: reportData['liabilities']['nonCurrentLiabilities'],
    otherCurrentLiabilities:
      reportData['liabilities']['otherCurrentLiabilities'],
    longTermLiabilities: reportData['liabilities']['longTermLiabilities'],
    totalLongTermLiabilities:
      reportData['liabilities']['totalLongTermLiabilities'],
    equity: reportData['liabilities']['equity'],
    diffOpeningBalanceLiability: openingBalanceLiability,
    totalLiabilities: totalLiability,

    periodName: reportData['periodName']
  };
  let width = 200;
  const len = Object.keys(newReportData['periodName']).length;

  if (len <= 2) {
    width = 300;
  } else if (len <= 4) {
    width = 250;
  } else {
    width = 170;
  }

  let payload: any = {};
  payload['columns'] = [];
  payload['columns'].push({
    title: ' ',
    style: {
      width: width,

      textAlign: 'left'
    },
    type: 'bold-lines'
  });
  const tenantInfo = getTenantInfo();
  for (let key in newReportData['periodName']) {
    const month = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    let dateArray = reportData['periodName'][key].split(' - ');
    let title =
      reportFilterState.ComparisonMode.id === 3
        ? ` ${
            month[
              new Date(
                getDateFromString(
                  dateArray[0].toString(),
                  tenantInfo.dateFormat.toUpperCase()
                )
              ).getMonth()
            ]
          } ${new Date(
            getDateFromString(
              dateArray[0].toString(),
              tenantInfo.dateFormat.toUpperCase()
            )
          ).getFullYear()}`
        : newReportData['periodName'][key];
  }
  payload['columns'] = columns;

  payload['rows'] = [];

  const alignAmountColumn = [];
  for (let i = 0; i < 4; i++) {
    alignAmountColumn.push({
      title: '',
      style: {
        paddingLeft: 6
      }
    });
  }

  for (let key in newReportData) {
    let row: any = { expanded: true, expandedOnce: false };
    row.cells = [];
    row.children = [];
    const text = key;
    const result = text.replace(/([A-Z])/g, ' $1');
    let finalText = result.charAt(0).toUpperCase() + result.slice(1);
    if (finalText === 'Accounts Receivable') {
      finalText = 'Accounts Receivable (A/R)';
    }
    if (finalText === 'Accounts Payable') {
      finalText = 'Accounts Payable (A/P)';
    }
    if (tenantInfo?.country === 'US') {
      finalText = replaceLabelsForLiabilitiesAndAssets(finalText);
    }

    if (
      (finalText === 'Diff Opening Balance Asset' && showAsset === false) ||
      (finalText === 'Diff Opening Balance Liability' && showAsset)
    ) {
      continue;
    }
    if (
      (finalText === 'Diff Opening Balance Asset' && showAsset) ||
      (finalText === 'Diff Opening Balance Liability' && !showAsset)
    ) {
      finalText = 'Diff Opening Balance';
    }

    let style: any = {
      fontWeight: 'normal',
      marginLeft: 0
    };
    if (BALANCE_SHEET2[key] && key !== 'periodName') {
      //style.textAlign = 'left';
      if (BALANCE_SHEET3[key]) {
        style = {
          ...style
        };
      }

      row.cells.push({
        title: finalText,
        style: {
          ...style,
          borderBottom: BALANCE_SHEET_LABEL[key] ? 'none' : 'none',
          marginTop: BALANCE_SHEET_STYLE[key] ? '15px' : '0px',
          fontWeight: BALANCE_SHEET1[key]
            ? '600'
            : BALANCE_SHEET_LABEL[key]
            ? '700'
            : 'normal'
        }
      });
      row.cells.push(...alignAmountColumn);

      let totalValues: any = { expanded: {}, collapsed: {} };
      for (let period in newReportData['periodName']) {
        if (BALANCE_SHEET_TOTAL[key]) {
          totalValues.expanded[period] = undefined;
          totalValues.collapsed[`${period}_withChild`] =
            newReportData[BALANCE_SHEET_TOTAL[key]][period];
        }
        row = { ...row, totalValues: totalValues };
        //style.textAlign = 'right';
        let hasChild = true;

        if (Object.keys(newReportData[key]).length === 0) {
          hasChild = false;
        }
        let title = BALANCE_SHEET_TOTAL[key]
          ? hasChild
            ? ' '
            : newReportData[BALANCE_SHEET_TOTAL[key]][period]
          : BALANCE_SHEET1[key]
          ? newReportData[BALANCE_SHEET1[key]][period]
          : BALANCE_SHEET_LABEL[key]
          ? ' '
          : newReportData[key][period];

        row.cells.push({
          title: BALANCE_SHEET_TOTAL[key]
            ? hasChild
              ? ' '
              : getAmountBlockWithCurrency(
                  newReportData[BALANCE_SHEET_TOTAL[key]][period]
                )
            : BALANCE_SHEET1[key]
            ? getAmountBlockWithCurrency(
                newReportData[BALANCE_SHEET1[key]][period]
              )
            : BALANCE_SHEET_LABEL[key]
            ? ' '
            : getAmountBlockWithCurrency(newReportData[key][period]),
          style: {
            ...style,
            borderTop: BALANCE_SHEET_LABEL[key] ? 'none' : 'none',
            fontWeight: BALANCE_SHEET1[key] ? '600' : 'normal',

            color: !isNaN(title) && title < 0 ? 'red' : 'black'
          }
        });
      }
      row.cells.push({
        title: ' ',
        style: {
          ...style,
          borderTop: BALANCE_SHEET_LABEL[key] ? 'none' : 'none',
          fontWeight: BALANCE_SHEET1[key] ? '600' : 'normal'
        }
      });
    }

    for (let child in newReportData[key]) {
      if (child.includes('Period_')) {
        continue;
      }
      let children: any = {
        expanded: false,
        cells: [],
        children: [],
        expandedOnce: false
      };
      let totalValues: any = {
        expanded: {},
        collapsed: {},
        accountCode: newReportData[key][child]['code']
      };

      for (let keys in newReportData['periodName']) {
        totalValues.expanded[keys] =
          newReportData[key][child]['periodMap'][keys];
        totalValues.collapsed[`${keys}_withChild`] =
          newReportData[key][child]['periodMap'][`${keys}_withChild`];
      }
      children = { ...children, totalValues: totalValues };

      children.cells.push({
        title: newReportData[key][child]['name'],
        style: { style, paddingLeft: 6 }
      });
      // for (let i = 0; i < columns.length - 2; i++) {
      //   children.cells.push({
      //     title: ' ',
      //     style: { ...style, textAlign: 'left' }
      //   });
      // }
      // for (let keys in newReportData['periodName']) {
      //   children.cells.push({
      //     title: ' ',
      //     style: style
      //   });
      // }
      // children.cells.push({
      //   title: ' ',
      //   style: {
      //     ...style,
      //     borderTop: BALANCE_SHEET_LABEL[key] ? 'none' : 'none',
      //     fontWeight: BALANCE_SHEET1[key] ? '600' : 'normal'
      //   }
      // });
      // children.cells.push(...alignAmountColumn);
      for (let i = 0; i < columns.length - 1; i++) {
        let hasChild = false;
        if (newReportData[key][child]['itemDto'] !== null) {
          hasChild = true;
        }
        if (newReportData[key][child]['itemDto']?.length === 0) {
          hasChild = false;
        }
        if (i === 4) {
          let title = hasChild
            ? totalValues.collapsed[`Period_1_withChild`]
            : totalValues.expanded[`Period_1`];
          children.cells.push({
            title: hasChild
              ? getAmountBlockWithCurrency(
                  totalValues.collapsed[`Period_1_withChild`]
                )
              : getAmountBlockWithCurrency(totalValues.expanded[`Period_1`]),
            style: {
              ...style,
              textAlign: 'right',
              color: !isNaN(title) && title < 0 ? 'red' : 'black'
            }
          });
        } else {
          children.cells.push({
            title: ' ',
            style: { ...style, textAlign: 'left' }
          });
        }
      }
      children.children.push({
        cells: [],
        children: [],
        expanded: false,
        expandedOnce: false
      });

      if (newReportData[key][child]['itemDto'] !== null) {
        newReportData[key][child]['itemDto'].forEach(
          (item: any, index: number) => {
            children.children.push(
              getParsedChildPNLDetails(item, 15, reportData)
            );
          }
        );
      }
      // children.children = children.children.sort(function (a: any, b: any) {
      //   return a.cells[0].title.localeCompare(b.cells[0].title);
      // });
      children.children = children.children.sort(function (a: any, b: any) {
        if (a.cells.length) {
          return a.cells[0]?.title?.localeCompare(b.cells[0]?.title);
        }
      });
      row.children.push(children);
    }
    row.children = row.children.sort(function (a: any, b: any) {
      if (a.cells.length) {
        return a.cells[0]?.title?.localeCompare(b.cells[0]?.title);
      }
    });

    payload['rows'].push(row);
  }

  return { payload, length: len };
};

import { INPUT_TYPE } from 'deskera-ui-library';
import { differenceInYears } from 'date-fns';
import { CURRENCY_SYMBOLS } from '../constants/Currencies';
import { DATE_FORMATS } from '../constants/Enum';
import { store } from '../store/store';
import { formatNumberToDecimal, isEmpty } from '../utilities/Common';
import { getDateFromString, getDateStrFromDate } from '../utilities/Date';

export const getTenantInfo = () => {
  return store.getState().tenant.tenantInfo;
};

export function getFinancialStartDate() {
  const tenantInfo = getTenantInfo();

  if (!tenantInfo) return new Date();

  const currenctDate = new Date();
  let fromDate = new Date(tenantInfo.financialStartDate);
  let numberOfYears = differenceInYears(currenctDate, fromDate);
  fromDate.setFullYear(fromDate.getFullYear() + numberOfYears);
  return fromDate;
}

export const replaceLabelsForLiabilitiesAndAssets = (label: string) => {
  // Rename asset label as per requirements
  if (label === 'Non Current Asset') {
    label = 'Long Term Asset';
  }
  if (label === 'Non Current Liabilities') {
    label = 'Long Term Liabilities';
  }

  return label;
};

export const getAmountBlockWithCurrency = (
  amount: number,
  currency?: string
) => {
  let tenantInfo = store.getState().tenant.tenantInfo;
  const currencySymbol =
    CURRENCY_SYMBOLS[(currency ? currency : tenantInfo.currency) as never];

  if (amount >= 0) {
    return `${currencySymbol} ${formatNumberToDecimal(Math.abs(amount))}`;
  } else {
    return `(${currencySymbol} ${formatNumberToDecimal(Math.abs(amount))})`;
  }
};

export const getLongAmoutBlockForGrid = (
  code: string,
  amount: string,
  extraClass?: string
) => {
  let tenantInfo = store.getState().tenant.tenantInfo;
  const currencySymbol =
    CURRENCY_SYMBOLS[(code ? code : tenantInfo.currency) as never] || '';

  const html = `<div class="${+amount < 0 ? ' text-red' : ''} ${
    extraClass ? extraClass : 'text-right'
  }">${currencySymbol}${
    +amount < 0
      ? '(' + formatNumberToDecimal(Math.abs(+amount)) + ')'
      : formatNumberToDecimal(+amount)
  }</div>`;
  return html;
};

export const getParsedChildPNLDetails = (
  obj: any,
  margin: number,
  reportData: any
) => {
  let totalValues: any = {
    expanded: {},
    collapsed: {},
    accountCode: obj['code'],
    details: {}
  };
  for (const period in reportData['periodName']) {
    totalValues.expanded[period] = obj['periodMap'][period];
    totalValues.collapsed[`${period}_withChild`] =
      obj['periodMap'][`${period}_withChild`];
  }
  let row: any = {
    expanded: false,
    totalValues: totalValues,
    expandedOnce: false
  };
  row.cells = [];
  row.children = [];
  const text = obj.name;
  row.cells.push({
    title: text,
    style: {
      fontWeight: 'normal',
      paddingLeft: margin + 10,
      textAlign: 'left'
    }
  });

  for (const period in reportData['periodName']) {
    row.cells.push({
      title: '',
      style: {
        fontWeight: 'normal',
        paddingLeft: margin,
        textAlign: 'left'
      }
    });
    row.children.push({
      cells: [],
      children: [],
      expanded: false,
      expandedOnce: false
    });
  }
  for (let i = 0; i < 5; i++) {
    let hasChild = false;
    if (obj['itemDto'] !== null) {
      hasChild = true;
    }
    if (obj['itemDto']?.length === 0) {
      hasChild = false;
    }
    if (i === 3) {
      let title = hasChild
        ? totalValues.collapsed[`Period_1_withChild`]
        : totalValues.expanded[`Period_1`];
      row.cells.push({
        title: hasChild
          ? getAmountBlockWithCurrency(
              totalValues.collapsed[`Period_1_withChild`]
            )
          : getAmountBlockWithCurrency(totalValues.expanded[`Period_1`]),
        style: {
          fontWeight: 'normal',
          paddingLeft: margin,
          textAlign: 'right',
          color: !isNaN(title) && title < 0 ? 'red' : 'black'
        }
      });
    } else {
      // row.cells.push({
      //   title: ' ',
      //   style: { fontWeight: 'normal', paddingLeft: margin, textAlign: 'left' }
      // });
      row.cells.push({
        title: '',
        style: {
          fontWeight: 'normal',
          paddingLeft: margin,
          textAlign: 'right'
        }
      });
    }
  }
  if (obj['itemDto'] !== null) {
    obj['itemDto'].forEach((item: any, index: number) => {
      row.children.push(
        getParsedChildPNLDetails(item, margin + 15, reportData)
      );
    });
  }

  row.children = row.children.sort(function (a: any, b: any) {
    if (a.cells.length) {
      return a.cells[0]?.title?.localeCompare(b.cells[0]?.title);
    }
  });

  return row;
};

export const getParsedChildPNL = (
  obj: any,
  margin: number,
  reportData: any,
  currency?: string
) => {
  let totalValues: any = { expanded: {}, collapsed: {} };
  for (const period in reportData['periodName']) {
    totalValues.expanded[period] = obj['periodMap'][period];
    totalValues.collapsed[`${period}_withChild`] =
      obj['periodMap'][`${period}_withChild`];
  }
  let row: any = { expanded: true, totalValues: totalValues };
  row.cells = [];
  row.children = [];
  const text = obj.name;
  row.cells.push({
    title: text,
    style: {
      fontWeight: 'normal',
      paddingLeft: margin,
      textAlign: 'left'
    }
  });

  for (const period in reportData['periodName']) {
    row.cells.push({
      title: obj['periodMap'][period]
        ? getAmountBlockWithCurrency(obj['periodMap'][period], currency)
        : getAmountBlockWithCurrency(0, currency),
      style: {
        fontWeight: 'normal',
        paddingLeft: margin,
        textAlign: 'right',
        color: obj['periodMap'][period]
          ? Number(obj['periodMap'][period]) < 0
            ? 'red'
            : 'black'
          : 'black'
      }
    });

    if (!isNaN(row.cells.title) && Number(row.cells.title) < 0) {
      row.cells.style = { ...row.cells.style, color: 'red' };
    }
  }
  if (obj['itemDto'] !== null) {
    obj['itemDto'].forEach((item: any, index: number) => {
      row.children.push(
        getParsedChildPNL(item, margin + 15, reportData, currency)
      );
    });
  }
  row.children = row.children.sort(function (a: any, b: any) {
    if (a.cells.length) {
      return a.cells[0]?.title?.localeCompare(b.cells[0]?.title);
    }
  });

  return row;
};

export const getParsedSortedChildPNL = (
  obj: any,
  margin: number,
  reportData: any,
  direction: string,
  currency?: string
) => {
  let totalValues: any = { expanded: {}, collapsed: {} };
  for (const period in reportData['periodName']) {
    totalValues.expanded[period] = obj['periodMap'][period];
    totalValues.collapsed[`${period}_withChild`] =
      obj['periodMap'][`${period}_withChild`];
  }
  let row: any = { expanded: true, totalValues: totalValues };
  row.cells = [];
  row.children = [];
  const text = obj.name;
  row.cells.push({
    title: text,
    style: {
      fontWeight: 'normal',
      paddingLeft: margin,
      textAlign: 'left'
    }
  });

  for (const period in reportData['periodName']) {
    row.cells.push({
      title: obj['periodMap'][period]
        ? getAmountBlockWithCurrency(obj['periodMap'][period], currency)
        : getAmountBlockWithCurrency(0, currency),
      style: {
        fontWeight: 'normal',
        paddingLeft: margin,
        textAlign: 'right',
        color: obj['periodMap'][period]
          ? Number(obj['periodMap'][period]) < 0
            ? 'red'
            : 'black'
          : 'black'
      },
      amount: !isNaN(obj['periodMap'][`${period}_withChild`])
        ? obj['periodMap'][`${period}_withChild`]
        : obj['periodMap'][period]
    });

    if (!isNaN(row.cells.title) && Number(row.cells.title) < 0) {
      row.cells.style = { ...row.cells.style, color: 'red' };
    }
  }
  if (obj['itemDto'] !== null) {
    obj['itemDto'].forEach((item: any, index: number) => {
      row.children.push(
        getParsedSortedChildPNL(
          item,
          margin + 15,
          reportData,
          direction,
          currency
        )
      );
    });
  }
  if (direction === 'ASC') {
    row.children = row.children.sort(function (a: any, b: any) {
      if (a.cells.length) {
        return a.cells[1].amount - b.cells[1]?.amount;
      }
    });
  } else {
    row.children = row.children.sort(function (a: any, b: any) {
      if (a.cells.length) {
        return b.cells[1].amount - a.cells[1]?.amount;
      }
    });
  }

  return row;
};

export const getParsedChildPNLInBothCurrency = (
  obj: any,
  margin: number,
  reportData: any,
  baseCurrency: string,
  primaryCurrency: string
) => {
  const getCurrency = (period: string) => {
    let col = parseInt(period.split('_')[1]);
    return col & 1 ? baseCurrency : primaryCurrency;
  };
  let totalValues: any = { expanded: {}, collapsed: {} };
  for (const period in reportData['periodName']) {
    totalValues.expanded[period] = obj['periodMap'][period];
    totalValues.collapsed[`${period}_withChild`] =
      obj['periodMap'][`${period}_withChild`];
  }
  let row: any = { expanded: true, totalValues: totalValues };
  row.cells = [];
  row.children = [];
  const text = obj.name;
  row.cells.push({
    title: text,
    style: {
      fontWeight: 'normal',
      paddingLeft: margin,
      textAlign: 'left'
    }
  });

  for (const period in reportData['periodName']) {
    row.cells.push({
      title: obj['periodMap'][period]
        ? getAmountBlockWithCurrency(
            obj['periodMap'][period],
            getCurrency(period)
          )
        : getAmountBlockWithCurrency(0, getCurrency(period)),
      style: {
        fontWeight: 'normal',
        paddingLeft: margin,
        textAlign: 'right',
        color: obj['periodMap'][period]
          ? Number(obj['periodMap'][period]) < 0
            ? 'red'
            : 'black'
          : 'black'
      }
    });

    if (!isNaN(row.cells.title) && Number(row.cells.title) < 0) {
      row.cells.style = { ...row.cells.style, color: 'red' };
    }
  }
  if (obj['itemDto'] !== null) {
    obj['itemDto'].forEach((item: any, index: number) => {
      row.children.push(
        getParsedChildPNLInBothCurrency(
          item,
          margin + 15,
          reportData,
          baseCurrency,
          primaryCurrency
        )
      );
    });
  }
  row.children = row.children.sort(function (a: any, b: any) {
    if (a.cells.length) {
      return a.cells[0]?.title?.localeCompare(b.cells[0]?.title);
    }
  });

  return row;
};

export const getParsedSortedChildPNLInBothCurrency = (
  obj: any,
  margin: number,
  reportData: any,
  direction: string,
  baseCurrency: string,
  primaryCurrency: string
) => {
  const getCurrency = (period: string) => {
    let col = parseInt(period.split('_')[1]);
    return col & 1 ? baseCurrency : primaryCurrency;
  };
  let totalValues: any = { expanded: {}, collapsed: {} };
  for (const period in reportData['periodName']) {
    totalValues.expanded[period] = obj['periodMap'][period];
    totalValues.collapsed[`${period}_withChild`] =
      obj['periodMap'][`${period}_withChild`];
  }
  let row: any = { expanded: true, totalValues: totalValues };
  row.cells = [];
  row.children = [];
  const text = obj.name;
  row.cells.push({
    title: text,
    style: {
      fontWeight: 'normal',
      paddingLeft: margin,
      textAlign: 'left'
    }
  });

  for (const period in reportData['periodName']) {
    row.cells.push({
      title: obj['periodMap'][period]
        ? getAmountBlockWithCurrency(
            obj['periodMap'][period],
            getCurrency(period)
          )
        : getAmountBlockWithCurrency(0, getCurrency(period)),
      style: {
        fontWeight: 'normal',
        paddingLeft: margin,
        textAlign: 'right',
        color: obj['periodMap'][period]
          ? Number(obj['periodMap'][period]) < 0
            ? 'red'
            : 'black'
          : 'black'
      },
      amount: !isNaN(obj['periodMap'][`${period}_withChild`])
        ? obj['periodMap'][`${period}_withChild`]
        : obj['periodMap'][period]
    });

    if (!isNaN(row.cells.title) && Number(row.cells.title) < 0) {
      row.cells.style = { ...row.cells.style, color: 'red' };
    }
  }
  if (obj['itemDto'] !== null) {
    obj['itemDto'].forEach((item: any, index: number) => {
      row.children.push(
        getParsedSortedChildPNLInBothCurrency(
          item,
          margin + 15,
          reportData,
          direction,
          baseCurrency,
          primaryCurrency
        )
      );
    });
  }
  if (direction === 'ASC') {
    row.children = row.children.sort(function (a: any, b: any) {
      if (a.cells.length) {
        return a.cells[1].amount - b.cells[1]?.amount;
      }
    });
  } else {
    row.children = row.children.sort(function (a: any, b: any) {
      if (a.cells.length) {
        return b.cells[1].amount - a.cells[1]?.amount;
      }
    });
  }

  return row;
};

export const makepayloadForDetails = (reportFilterState?: any) => {
  let payload: any = {};

  if (reportFilterState.Basis) {
    payload = { ...payload, basis: reportFilterState.Basis.value };
  }
  if (reportFilterState.StartDate && reportFilterState.EndDate) {
    payload = {
      ...payload,
      startDate: getDateStrFromDate(
        reportFilterState.StartDate,
        DATE_FORMATS['DD-MM-YYYY']
      ),
      endDate: getDateStrFromDate(
        reportFilterState.EndDate,
        DATE_FORMATS['DD-MM-YYYY']
      )
    };
  }

  return payload;
};

export const makepayload = (reportFilterState?: any) => {
  let payload: any = {};

  if (
    reportFilterState.ComparisonMode &&
    reportFilterState.ComparisonMode?.id !== 0
  ) {
    if (reportFilterState.ComparisonMode.vname === 'Off') {
      payload = {
        basis: reportFilterState.Basis.value,
        startDate: getDateStrFromDate(
          reportFilterState.StartDate,
          DATE_FORMATS['DD-MM-YYYY']
        ),
        endDate: getDateStrFromDate(
          reportFilterState.EndDate,
          DATE_FORMATS['DD-MM-YYYY']
        )
      };
      if (!isEmpty(reportFilterState.Contacts)) {
        payload = { ...payload, contacts: reportFilterState.Contacts };
      }
      if (!isEmpty(reportFilterState.Products)) {
        payload = { ...payload, products: reportFilterState.Products };
      }
      if (!isEmpty(reportFilterState.Contact)) {
        payload = { ...payload, columnBy: 'CONTACTS' };
      }
      if (!isEmpty(reportFilterState.Product)) {
        payload = { ...payload, columnBy: 'PRODUCTS' };
      }
      if (!isEmpty(reportFilterState.LOCATION)) {
        payload = { ...payload, columnBy: 'LOCATION' };
      } else if (!isEmpty(reportFilterState.CLASS)) {
        payload = { ...payload, columnBy: 'CLASS' };
      } else if (!isEmpty(reportFilterState.Weeks)) {
        payload = { ...payload, columnBy: 'WEEKS' };
      } else if (!isEmpty(reportFilterState.Days)) {
        payload = { ...payload, columnBy: 'DAYS' };
      }
    } else {
      payload = {
        basis: reportFilterState.Basis.value,
        vid: reportFilterState.ComparisonMode.id,
        vname: reportFilterState.ComparisonMode.vname
      };
      if (!isEmpty(reportFilterState.Contacts)) {
        payload = { ...payload, contacts: reportFilterState.Contacts };
      }
      if (!isEmpty(reportFilterState.Products)) {
        payload = { ...payload, products: reportFilterState.Products };
      }
    }
  } else if (!isEmpty(reportFilterState.PresetInfo)) {
    payload = {
      basis: reportFilterState.Basis.value,
      periods: reportFilterState.PresetInfo.Periods,
      save: reportFilterState.PresetInfo.SaveAsPreset
    };
    if (!isEmpty(reportFilterState.Contacts)) {
      payload = { ...payload, contacts: reportFilterState.Contacts };
    }
    if (!isEmpty(reportFilterState.Products)) {
      payload = { ...payload, products: reportFilterState.Products };
    }
    if (reportFilterState.PresetInfo.SaveAsPreset) {
      payload.vname = reportFilterState.PresetInfo.PresetName;
    }
  }
  if (reportFilterState.showCustomFilterPopup) {
    let customField: any = [];
    reportFilterState.showCustomFilterPopup?.data?.forEach((row: any) => {
      const modules = reportFilterState.showCustomFilterPopup.customFieldOptions
        ? reportFilterState.showCustomFilterPopup.customFieldOptions
            .filter((field: any) => field.key === row.key)
            .map((field: any) => {
              if (field.key === row.key) {
                return field.module;
              }
              return;
            })
        : [];

      let cf: any = {};
      if (Array.isArray(row.value)) {
        let val = '';
        reportFilterState.showCustomFilterPopup.options.forEach(
          (option: any) => {
            if (option.key === row.key) {
              option?.options.forEach((opt: any) => {
                if (opt.id === row.value[0]) {
                  val = opt.value;
                }
              });
            }
          }
        );

        cf.value = val;
        cf.code = row.key;
        cf.module = modules;
      } else {
        cf.value = row.value;
        cf.code = row.key;
        cf.module = modules;
      }
      if (cf.value !== '' && cf.module.length) customField.push(cf);
    });
    if (customField?.length) {
      payload = {
        ...payload,
        custom_field: JSON.stringify(customField)
      };
    }
  }
  return payload;
};

const getDay = (params: any) => {
  let now = new Date();
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const day = days[now.getDay()];
  const month = months[now.getMonth()];
  const dd = String(now.getDate()).padStart(2, '0');

  if (params === 'day') return day.toString();
  if (params === 'month') return month.toString();
  else return dd;
};

const formatAMPM = (date: Date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const minuteStr = minutes < 10 ? '0' + minutes : minutes.toString();
  const strTime = hours + ':' + minuteStr + ' ' + ampm;
  return strTime;
};

export const getTimeStamp = (accountingMethod: string) => {
  const text = `${accountingMethod} basis ${getDay('day')}, ${getDay(
    'month'
  )} ${getDay('date')}, 2022, ${formatAMPM(new Date())}`;
  return text;
};

export const columnLogic = (
  value: any,
  updatedState: any,
  comparisionModes: any[]
) => {
  updatedState.Contact = '';
  updatedState.Product = '';
  updatedState.LOCATION = '';
  updatedState.CLASS = '';
  updatedState.Days = '';
  updatedState.Weeks = '';
  if (
    value.id === 5 ||
    value.id === 6 ||
    value.id === 7 ||
    value.id === 8 ||
    value.id === 9 ||
    value.id === 10
  ) {
    if (value.id === 5) {
      updatedState.Contact = 'CONTACTS';
      updatedState.ComparisonMode = comparisionModes[0];
    } else if (value.id === 6) {
      updatedState.Product = 'PRODUCTS';
      updatedState.ComparisonMode = comparisionModes[0];
    } else if (value.id === 7) {
      updatedState.LOCATION = 'Location';
      updatedState.ComparisonMode = comparisionModes[0];
    } else if (value.id === 8) {
      updatedState.CLASS = 'Class';
      updatedState.ComparisonMode = comparisionModes[0];
    } else if (value.id === 9) {
      updatedState.Days = 'Days';
      updatedState.ComparisonMode = comparisionModes[0];
    } else if (value.id === 10) {
      updatedState.Weeks = 'Weeks';
      updatedState.ComparisonMode = comparisionModes[0];
    }

    return;
  }
  let comparisonMode: any = {};
  comparisonMode.id = value.id;
  comparisonMode.vname = value.vname;
  comparisonMode.custom = false;

  updatedState.ComparisonMode = { ...comparisonMode };
  updatedState.PresetInfo = null;
};

export const comparisionLog = (value: any, updatedState: any) => {
  updatedState.ComparisonMode = value;
  updatedState.PresetInfo = null;
};

export const dateFilterLogic = (dateInfo: any, updatedState: any) => {
  updatedState.StartDate = getDateFromString(
    dateInfo.startDate,
    DATE_FORMATS['DD-MM-YYYY']
  );
  updatedState.EndDate = getDateFromString(
    dateInfo.endDate,
    DATE_FORMATS['DD-MM-YYYY']
  );
  updatedState.PresetInfo = null;
};

export const accountingMethod = (
  value: any,
  label: string,
  updatedState: any
) => {
  updatedState.Basis.value = value;
  updatedState.Basis.label = label;
};

export const dateRangeOptions = [
  { id: 0, title: 'All Dates' },
  { id: 1, title: 'Today' },
  { id: 2, title: 'This Week' },
  { id: 3, title: 'This Month' },
  { id: 4, title: 'This Year' },
  { id: 9, title: 'This Quarter' },
  { id: 5, title: 'Last Week' },
  { id: 6, title: 'Last Month' },
  { id: 10, title: 'Last Quarter' },
  { id: 7, title: 'Last Year' },
  { id: 11, title: 'Since 30 days' },
  { id: 12, title: 'Since 60 days' },
  { id: 13, title: 'Since 90 days' },
  { id: 14, title: 'Since 360 days' },
  { id: 8, title: 'Customize' }
];

export const comparisionDataOptions = [
  { id: 1, title: 'Total Only', vname: 'Off', custom: false },

  { id: 3, title: 'Months', vname: '6 Month', custom: false },
  {
    id: 4,
    title: 'Quarters',
    vname: 'Quarterly',
    custom: false
  },
  { id: 2, title: 'Years', vname: '3 Years', custom: false },
  { id: 5, title: 'Contact', vname: '3 Years', custom: false },
  { id: 6, title: 'Product', vname: '3 Years', custom: false }
];

export const sortGridData = (
  data: any[],
  columnType: any,
  sortByColumn: string,
  sortDir: string,
  fromDateFormat: DATE_FORMATS = DATE_FORMATS['YYYY-MM-DD']
) => {
  if (columnType === INPUT_TYPE.TEXT) {
    if (sortDir === 'ASC') {
      data.sort((a: any, b: any) =>
        a[sortByColumn]
          ?.toLowerCase()
          ?.localeCompare(b[sortByColumn]?.toLowerCase())
      );
    } else {
      data.sort((a: any, b: any) =>
        b[sortByColumn]
          ?.toLowerCase()
          ?.localeCompare(a[sortByColumn]?.toLowerCase())
      );
    }
  } else if (columnType === INPUT_TYPE.NUMBER) {
    if (sortDir === 'ASC') {
      data.sort((a: any, b: any) => a[sortByColumn] - b[sortByColumn]);
    } else {
      data.sort((a: any, b: any) => b[sortByColumn] - a[sortByColumn]);
    }
  } else if (columnType === INPUT_TYPE.DATE) {
    if (sortDir === 'ASC') {
      data.sort(
        (a: any, b: any) =>
          getDateFromString(a[sortByColumn], fromDateFormat).getTime() -
          getDateFromString(b[sortByColumn], fromDateFormat).getTime()
      );
    } else {
      data.sort(
        (a: any, b: any) =>
          getDateFromString(b[sortByColumn], fromDateFormat).getTime() -
          getDateFromString(a[sortByColumn], fromDateFormat).getTime()
      );
    }
  }

  return data;
};

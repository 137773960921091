import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../../models/Filter';
import { getTenantSpecificApiCode } from './Product';
import http from '../';
import ApiConstants from '../../constants/ApiConstant';

const DEFAULT_PARAMS = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  IncludeOpeningAmounts: true,
  IncludeOweAmounts: true,
  QueryParam: ''
};

export default class ContactService {
  static async getContactsByPage(params: any) {
    const countryCode = getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.CONTACT
    );

    const url = ApiConstants.URL.CONTACTS.FETCH_CONTACTS + (countryCode || '');

    params = {
      ...DEFAULT_PARAMS,
      ...(params || {})
    };

    const finalEndPoint = `${url}?search=${
      params.SearchTerm
    }&includeOweAmounts=${params.IncludeOweAmounts}\
    &includeOpeningAmounts=${
      params.IncludeOpeningAmounts
    }&SKIP_REQUEST_INTERCEPTOR=true
    &limit=${params.Limit}&page=${params.Page}${
      params.Query ? '&query=' + params.Query : ''
    }${params.QueryParam ? params.QueryParam : ''}`;

    return http.get(finalEndPoint).catch((err: any) => {
      console.error('Error loading contacts: ', err);
      return Promise.reject(err);
    });
  }
}

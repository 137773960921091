import React, { useEffect, useState } from 'react';
import {
  DKButton,
  DKExpandableWrapper,
  DKIcon,
  DKIcons,
  DKLabel,
  INPUT_TYPE,
  DKInput
} from 'deskera-ui-library';
import { INPUT_VIEW_DIRECTION, STATUS_TYPE } from '../../constants/Enum';
import AccountingService from '../../services/reports/Accounting';

const MappingPopup = (props: any) => {
  const [data, setData] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const [accountList, setAccountList] = useState({ content: [] });

  let activeAccountOptions = (accountList.content || [])
    .filter((account: any) => account.status === STATUS_TYPE.ACTIVE)
    .map((data: any) => {
      return { label: data.name, value: data.code, used: false };
    });

  const noBankOption = (accountList.content || [])
    .filter(
      (account: any) =>
        account.status === STATUS_TYPE.ACTIVE &&
        account.accountGroup !== 'Bank' &&
        account.accountGroup !== 'Cash'
    )
    .map((data: any) => {
      return { label: data.name, value: data.code, used: false };
    });

  const loadMaps = () => {
    AccountingService.getInstance()
      .getCashflowMap()
      .then((data: any) => {
        let result = [...data];
        result.forEach((block: any, index: any) => {
          result[index].items.sort(function (a: any, b: any) {
            return a.itemOrder > b.itemOrder;
          });
        });
        setData(result);
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  const loadAccounts = () => {
    AccountingService.getInstance()
      .fetchAccounts()
      .then((data: any) => setAccountList(data))
      .catch((err: any) => {
        console.error(err);
      });
  };

  useEffect(() => {
    loadAccounts();
    loadMaps();
  }, []);

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel text={`Cashflow Statement Mapping`} className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={() => {
              props.onCancel();
            }}
          />
          <DKButton
            title={'Update'}
            className="bg-button text-white"
            onClick={() => {
              let payload: any = [];
              data.forEach((section: any) => {
                let obj: any = {
                  netCashFlow: section.netCashFlow,
                  sectionLabel: section.sectionLabel,
                  items: section.items
                };
                payload.push(obj);
              });
              AccountingService.getInstance()
                .updateMapping(payload)
                .then((res) => {
                  props.onCancel();
                })
                .catch((err: any) => {
                  console.error(err);
                });
            }}
          />
        </div>
      </div>
    );
  };

  const multiSelectDropdown = (
    index: any,
    rowIndex: any,
    value: number[],
    title: string,

    options: any
  ) => {
    return (
      <DKInput
        className={''}
        value={value}
        displayKey="label"
        title={title}
        type={INPUT_TYPE.DROPDOWN}
        required={false}
        canValidate={true}
        onChange={(value: number[]) => {
          let updatedData = [...data];
          let account: any = [];
          value.forEach((val: any) => {
            account.push({
              name: options[val].label,
              code: options[val].value
            });
          });
          updatedData[index].items[rowIndex].accounts = account;
          setData(updatedData);
        }}
        dropdownConfig={{
          className: '',
          title: title,
          style: { width: 400 },
          allowSearch: true,
          searchableKey: 'label',
          multiSelect: true,
          selectedIndexes: [],
          checkMarkColor: 'bg-blue',
          data: options,
          renderer: (index: number, obj: any) => {
            return <DKLabel text={obj.label} />;
          },
          onSelect: (index: number, value: any) => {},
          onClear: () => {
            let updatedData = [...data];
            let account: any = [];

            updatedData[index].items[rowIndex].accounts = account;
            setData(updatedData);
          }
        }}
      />
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 700,
          width: '90%',
          maxHeight: '90%',
          padding: 0,
          paddingBottom: 20,
          overflowY: 'auto'
        }}
      >
        {getHeader()}
        {data &&
          data.length > 0 &&
          data.map((block: any, index: any) => {
            let localOptions = [...activeAccountOptions];
            if (block.sectionOrder === 0) {
              localOptions = noBankOption;
              // localOptions = localOptions.filter(
              //   (options) => options.label !== 'Bank'
              // );
            }
            let useMap: any = {};
            return (
              <div>
                <DKExpandableWrapper
                  title={`${block.sectionLabel} (${block.items.length})`}
                  className="bg-white border-m mb-r p-1"
                  headerStyle={{
                    padding: '20px 20px'
                  }}
                  style={{ fontSize: 25, width: 700 }}
                  expanded={show}
                  onClick={(expanded: boolean) => {
                    setShow(expanded);
                  }}
                >
                  <div
                    className="flex flex-wrap bg-white p-h-l pb-l pt-r"
                    style={{ gap: '3%' }}
                  >
                    {block.items.map((row: any, rowIndex: any) => {
                      let value: any = [];
                      localOptions = localOptions.filter(
                        (options) => useMap[options.value] === undefined
                      );

                      // setData([...updatedData]);
                      row.accounts?.forEach((acc: any) => {
                        localOptions.forEach((option: any, index: any) => {
                          if (option.value === acc.code) {
                            value.push(index);
                            localOptions[index].used = true;
                            useMap[option.value] = option.label;
                          }
                        });
                      });
                      return (
                        <div className="row parent-width p-s">
                          {/* <DKLabel
                            text={row.itemLabel}
                            className="parent-width"
                          /> */}
                          <DKInput
                            title={''}
                            direction={INPUT_VIEW_DIRECTION.VERTICAL}
                            value={row.itemLabel}
                            type={INPUT_TYPE.TEXT}
                            onChange={(value: string) => {
                              let updatedData = [...data];
                              updatedData[index].items[rowIndex].itemLabel =
                                value;
                              setData([...updatedData]);
                            }}
                            required={false}
                          />
                          <div className=" parent-width ml-l">
                            {multiSelectDropdown(
                              index,
                              rowIndex,
                              value,
                              '',

                              localOptions
                            )}
                          </div>
                          {row?.deletable && (
                            <DKIcon
                              src={DKIcons.ic_delete}
                              className="ic-s ml-l cursor-pointer"
                              onClick={
                                () => {
                                  let updatedData = [...data];

                                  updatedData[index].items.splice(rowIndex, 1);
                                  setData([...updatedData]);
                                }
                                // removeAttachment(attachment.attachmentId)
                              }
                            />
                          )}
                          {!row.deletable && (
                            <div className="mr-4" style={{ width: 34 }}></div>
                          )}
                        </div>
                      );
                    })}
                    <DKButton
                      title={'Add more'}
                      className={`bg-white fs-m border-m ${
                        !props.isViewMode ? 'mr-r' : ''
                      }`}
                      onClick={() => {
                        let updatedData = [...data];

                        let obj: any = {
                          accounts: null,
                          deletable: true,
                          id: null,
                          itemAmount: null,
                          itemLabel: ' ',
                          itemOrder: updatedData[index].items.length
                        };
                        updatedData[index].items.push(obj);
                        setData([...updatedData]);
                      }}
                    />
                  </div>
                </DKExpandableWrapper>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MappingPopup;

import { supportedMimeTypes } from '../constants/Constant';
import { supportedFileTypes } from '../constants/Enum';

export function getFile(
  blobContent: BlobPart,
  fileName: string,
  fileType: supportedFileTypes
) {
  const file = new File(
    [blobContent],
    `${fileName}_${new Date().getTime()}.${fileType}`,
    { type: getMimeType(fileType) }
  );

  return file;
}

function getMimeType(fileType: supportedFileTypes) {
  return supportedMimeTypes[fileType] || 'text/csv';
}

export function triggerDownload(
  blobContent: Blob | null,
  fileName: string,
  altSrc?: string
) {
  const downloadAnchor = document.createElement('a');
  downloadAnchor.setAttribute('download', fileName);
  /* Checking If download feature supported in browser */
  if (typeof downloadAnchor.download !== 'undefined') {
    downloadAnchor.href = (
      blobContent ? URL.createObjectURL(blobContent) : altSrc
    ) as string;
    document.body.appendChild(downloadAnchor);
    downloadAnchor.click();
    document.body.removeChild(downloadAnchor);
  }
}

import React, { useEffect, useState } from 'react';
import ReportTable from './grid/ReportTable';
import {
  DKIcons,
  DKButton,
  DKLabel,
  DKSpinner,
  DKLine,
  DKListPicker
} from 'deskera-ui-library';
import { isEmpty } from '../../utilities/Common';
import { getDateStrFromDate } from '../../utilities/Date';
import PdfUtility from '../../utilities/PDFUtility';
import { isViewportMobile } from '../../utilities/ViewportSize';

export const initialReportBodyState = {
  reportData: null,
  reportName: '',
  footerText: '',
  isLoading: false
};

export interface ReportBodyProps {
  reportData: any;
  reportName: string;
  footerText: string;
  onEmailClick: () => void;
  onPrintClick: () => void;
  onExportClick: (data: any) => void;
  onRowExpand: (data: any) => void;
  onCellClick: (data: any) => void;
  showLoading: boolean;
  templateName?: string;
  isExportHidden?: boolean;
  tenantName?: any;
  onSortClick?: (data: any) => void;
  showSort?: boolean;
  filterDate?: any;
}

const AccountingReportBody: React.FC<ReportBodyProps> = (props) => {
  const [reportBodyState, setReportBodyState] = useState(
    initialReportBodyState
  );
  const [showExport, setShowExport] = useState<boolean>(false);
  const [showSort, setShowSort] = useState<boolean>(false);

  useEffect(() => {
    const updatedState = reportBodyState;
    if (props.reportName) {
      updatedState.reportName = props.reportName;
    }

    if (props.reportData) {
      updatedState.reportData = props.reportData;
    }

    if (props.footerText) {
      updatedState.footerText = props.footerText;
    }

    updatedState.isLoading = props.showLoading;

    setReportBodyState({ ...updatedState });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);
  const showSortOptions = () => {
    return (
      <DKListPicker
        data={[`Ascending`, `Descending`]}
        className="border-m border-radius-m shadow-m position-absolute"
        style={{ width: 100, top: 5, left: 0.5, zIndex: 10 }}
        onSelect={(index: any, value: any) => {
          if (index === 0) {
            if (props.onSortClick) props.onSortClick('ASC');
          }
          if (index === 1) {
            if (props.onSortClick) props.onSortClick('DESC');
          }
          setShowSort(false);
        }}
        onClose={() => {
          setShowSort(false);
        }}
      />
    );
  };

  const showOptions = () => {
    return (
      <DKListPicker
        data={[`XLS`, `PDF`]}
        className="border-m border-radius-s shadow-s position-absolute z-index-3"
        style={{ width: 88, right: 0, top: 0 }}
        onSelect={(index: any, value: any) => {
          if (index === 0) {
            props.onExportClick?.('XLS');
          }
          if (index === 1) {
            exportPDFDocument('');
          }

          setShowExport(false);
        }}
        onClose={() => {
          setShowExport(false);
        }}
      />
    );
  };

  const exportPDFDocument = async (type: string) => {
    const element = document.getElementById(
      props.templateName ? props.templateName : 'template'
    );
    return new PdfUtility().getPDFUrl(element, props.reportName);
  };

  const getFilterDates = () => {
    let startDate = getDateStrFromDate(props.filterDate.StartDate) || '';
    let endDate = getDateStrFromDate(props.filterDate.EndDate) || '';

    if (!isEmpty(startDate) && !isEmpty(endDate)) {
      return `${startDate} - ${endDate}`;
    } else {
      return '';
    }
  };

  return (
    <div className="parent-size">
      <div className="column border-box align-items-center mobile-parent-width">
        <div className="column align-items-center  position-relative  mobile-parent-width">
          {props.showSort && (
            <div
              className="row width-auto position-absolute"
              style={{ top: '1.75rem', left: '0.75rem' }}
            >
              <DKButton
                icon={DKIcons.ic_sort}
                style={{ opacity: 0.5 }}
                onClick={() => {
                  setShowSort(true);
                }}
              />
              {showSort && showSortOptions()}
            </div>
          )}
          <div
            className="row width-auto position-absolute mb-xs"
            style={{
              top: '1.75rem',
              right: '0.75rem'
            }}
          >
            <DKButton
              icon={DKIcons.ic_email}
              style={{ opacity: 0.5 }}
              onClick={() => {
                props.onEmailClick();
              }}
            />
            <DKButton
              icon={DKIcons.ic_printer}
              style={{ opacity: 0.5 }}
              onClick={() => {
                props.onPrintClick();
              }}
            />
            {props.isExportHidden ? null : (
              <>
                <DKButton
                  icon={DKIcons.ic_export}
                  onClick={() => setShowExport(true)}
                  style={{ opacity: 0.5 }}
                />
                {showExport && showOptions()}
              </>
            )}
          </div>
          <div
            className="column align-items-center border-m border-radius-s p-xl bg-white mt-xl unselectable mobile-parent-width"
            id="template"
          >
            <div className="column align-items-center  mobile-parent-width">
              <DKLabel
                text={props.tenantName}
                className="fs-xl text-dark-gray"
                style={{ fontWeight: 300 }}
              />
              <DKLabel
                text={props.reportName}
                className="mt-l fw-b fs-m mb-l"
              />
            </div>
            {!isEmpty(props.filterDate) && !reportBodyState.isLoading && (
              <DKLine className="bg-gray3" />
            )}

            {!isEmpty(props.filterDate) && !reportBodyState.isLoading && (
              <div className="parent-width align-text-right ">
                <DKLabel
                  text={getFilterDates()}
                  className="text-align-right fs-m fw-b  p-v-xs"
                />
              </div>
            )}
            {isEmpty(reportBodyState.reportData) ||
            reportBodyState.isLoading ? (
              <div style={{ width: 400 }} className="p-xxl">
                <DKSpinner />
              </div>
            ) : (
              <div
                className=" pb-l show-scroll-bar"
                style={{
                  maxWidth: isViewportMobile() ? "100%" :  window.innerWidth - 350,
                  overflowX: 'scroll'
                }}
              >
                {reportBodyState.reportData && (
                  <ReportTable
                    data={reportBodyState.reportData}
                    onExpandCollapse={(data: any) => {
                      props.onRowExpand(data);
                    }}
                    onCellClick={(data: any) => {
                      props.onCellClick(data);
                      // alert(JSON.stringify(data));
                    }}
                  />
                )}
              </div>
            )}

            <DKLine />
            <DKLine />
            {/* <DKLabel   //Report footer hid.
              text={reportBodyState.footerText}
              className="mt-xxl mb-xl text-gray"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountingReportBody;

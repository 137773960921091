import React, { Fragment, useEffect, useState } from 'react';
import { DKLabel, DKButton, DKIcon, DKIcons } from 'deskera-ui-library';
import ic_startup from "../../assets/icons/pricingSection/startup.svg";
import ic_essential from "../../assets/icons/pricingSection/essential.svg";
import ic_professional from "../../assets/icons/pricingSection/professional.svg";
import { useAppSelector } from '../../store/hooks';
import { getTenantInfo, selectLoggedInUser, selectLoggedInUserPlan, selectUserInfo } from '../../store/slices/tenantSlice';
import ApiConstants from '../../constants/ApiConstant';
import AuthManager from '../../managers/AuthManager';
import { PLANS } from '../../constants/Constant';
import ProfileOptionsSection from '../common/ProfileOptionsSection';
import ic_profile_pic from "../../assets/icons/ic_profile_pic.png";
import { getFullName } from '../../models/Tenant';
import { isMobileAppWebView } from '../../utilities/ViewportSize';
import SideBarService from '../../services/common/Sidebar';

export default function TopMenu() {
  const loggedInUserInfo = useAppSelector(selectLoggedInUser());
  const userPlanInfo = useAppSelector(selectLoggedInUserPlan());
  const tenantInfo = useAppSelector(getTenantInfo());
  const userInfo = useAppSelector(selectUserInfo());
  const [userPlan, setUserPlan] = useState<any>(null);
  const [showProfileSection, setShowProfileSection] = useState<boolean>(false);

  useEffect(() => {
    let newUserPlan = {
      name: "",
      icon: "",
      inTrial: AuthManager.isTrialPlanActive(),
      isActive: AuthManager.isPlanActive(),
      trialDays: 0,
    };
    if (newUserPlan.inTrial) {
      newUserPlan.trialDays = AuthManager.getTrialDays(true);
    } else if (newUserPlan.isActive) {
      newUserPlan.name = AuthManager.getUserPlanName();
      newUserPlan.icon = getPlanIcon(newUserPlan.name);
    }
    setUserPlan(newUserPlan);
  }, [userPlanInfo]);

  const getPlanBadgeStatus = (isPlanActive: boolean) => {
    return isPlanActive ? "Active" : "Expired";
  }
  const getPlanIcon = (planName = "") => {
    let iconSrc = null;
    switch (planName.toLowerCase()) {
      case PLANS.STARTUP:
        iconSrc = ic_startup;
        break;
      case PLANS.ESSENTIAL:
        iconSrc = ic_essential;
        break;
      case PLANS.PROFESSIONAL:
        iconSrc = ic_professional;
        break;
      default:
    }

    return iconSrc;
  }
  const getProfileOptionsSection = () => {
    return (
      <ProfileOptionsSection
        onClose={() => {
          setTimeout(() => {
            setShowProfileSection(false)
          }, 10);
        }}
      />
    );
  }
  const getProfileSection = () => {
    return (
      <div className=" position-relative ml-m">
        <div
          className="cursor-hand"
          onClick={() => {
            setShowProfileSection(true)
          }}
          style={{ opacity: 0.6 }}
        >
          <DKIcon src={ic_profile_pic} className="circle ic-r-3" />
        </div>

        {showProfileSection && getProfileOptionsSection()}
      </div>
    );
  }
  return (
    <div className="row bg-white p-h-r p-v-s shadow-m z-index-3">
      <div
        className="dk-sidebar-toggle p-v-s expandable-button cursor-hand z-index-4 column justify-content-center display-only-mobile"
        style={{
          visibility: 'visible'
        }}
        onClick={() => SideBarService.toggleSideBar()}
      >
        <div className="row">
          <DKIcon src={DKIcons.ic_menu} className={`ic-s shadow-s-2`} />
          {isMobileAppWebView() ? (
            <DKLabel
            className="fw-m fs-l p-h-m"
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              maxWidth: "160px",
            }}
            text={tenantInfo?.name || ""}
          />
          ) : null}
        </div>
      </div>
      <div className='row display-only-web'>{loggedInUserInfo && (
        <>
          <DKLabel text={"👋"} className="fs-l" />
          <DKLabel text={" Welcome "} />
          <DKLabel
            text={getFullName(userInfo)}
            className="fw-b"
          />
        </>
      )}
        {userPlan?.inTrial ? (
          <Fragment>
            <DKLabel
              text={`, Your trial will end ${userPlan?.trialDays ? `in ${userPlan?.trialDays} days` : `today`
                }, `}
              className="fw-m text-red"
            />
            <DKButton
              title={`subscribe now`}
              className="fw-m text-blue text-underline"
              style={{ padding: "0 2px" }}
              onClick={() => {
                window.open(ApiConstants.URL.BILLING_URL + "billing");
              }}
            />
          </Fragment>
        ) : userPlan && userPlan?.name ? (
          <Fragment>
            <DKLabel text=", you are on " />
            <DKButton
              title={`${userPlan?.name} Plan`}
              className="fw-m text-blue text-underline"
              style={{ padding: "0 2px" }}
              onClick={() => {
                window.open(ApiConstants.URL.BILLING_URL + "billing");
              }}
            />
            <DKIcon src={userPlan?.icon} className="ic-s-2 pl-xs" />
            <DKLabel
              text={getPlanBadgeStatus(userPlan?.isActive)}
              className={`${userPlan?.isActive
                ? "bg-chip-green border-green text-green"
                : "bg-chip-red border-red text-red"
                } fw-m border-radius-r ml-s p-h-s`}
            />
          </Fragment>
        ) : null}</div>
      <div className='ml-auto'>{getProfileSection()}</div>
    </div>
  );
}

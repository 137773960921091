import React, { useState, useEffect } from 'react';
import { showToast, TOAST_TYPE, showLoader, removeLoader } from 'deskera-ui-library';
import { DATE_FORMATS, REPORT_BASIS } from '../constants/Enum';
import {
  accountingMethod,
  comparisionDataOptions,
  dateFilterLogic,
  dateRangeOptions,
  getAmountBlockWithCurrency,
  getFinancialStartDate,
  getTimeStamp
} from '../helper/SharedParser';
import RouteManager from '../managers/RouteManager';
import { getDateStrFromDate } from '../utilities/Date';
import AccountingService from '../services/reports/Accounting';
import { deepCloneObject, isEmpty } from '../utilities/Common';
import {
  REPORT_HEADER_ITEMS,
  ReportHeaderState
} from '../models/AccountingReport';
import AccountingReportHeader from '../components/accounting/AccountingReportHeader';
import AccountingReportBody from '../components/accounting/AccountingReportBody';
import PdfUtility from '../utilities/PDFUtility';
import { getParsedDataForTrialBalanceDetails } from '../helper/TrialBalanceDetailParser';
import EmailComposerPopUp from '../components/common/EmailComposerPopup';

export default function TrialBalanceDetail(props: any) {
  const initialState = {
    Basis: { label: 'Accrual', value: REPORT_BASIS.ACCRUAL },
    StartDate: getFinancialStartDate(),
    EndDate: new Date(),
    ComparisonMode: { id: 1, vname: 'Off', custom: false },
    ColumnBy: null
  };
  const [reportFilterState, setReportFilterState] = useState<any>({
    ...initialState
  });
  const [showEmailPopUp, setShowEmailPopup] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [toggled, setToggled] = useState<boolean>(false);
  const [reportData, setReportData] = useState<any>(null);
  const [pdfUrl, setPdfUrl] = useState<any>();
  const [lengthOfCols, setLengthOfCols] = useState<any>(0);
  const [passer, setPasser] = useState<any>();
  const [tableLoader, setTableLoader] = useState(false);

  useEffect(() => {
    RouteManager.setPresenter({ props });
    const updatedState = reportFilterState;
    updatedState.CurrentDateRange = null;
    const payload = generatePayload(reportFilterState);
    fetchReportDetails(payload);
  }, []);

  useEffect(() => {
    parsedDataForTrialBalance();
  }, [reportData]);

  const getEmailPopUp = () => {
    return (
      <EmailComposerPopUp
        documentData={reportData}
        reportTitle={'Trial Balance Details'}
        removeEmailPopUp={() => {
          setShowEmailPopup(false);
        }}
        pdfURL={pdfUrl}
        isMobile={false}
        printingCompleted={() => {
          setShowEmailPopup(false);
        }}
      />
    );
  };

  const generatePayload = (updatedState: any) => {
    let payload: any = {
      basis: updatedState.Basis.value,
      startDate: getDateStrFromDate(
        updatedState.StartDate,
        DATE_FORMATS['DD-MM-YYYY']
      ),
      endDate: getDateStrFromDate(
        updatedState.EndDate,
        DATE_FORMATS['DD-MM-YYYY']
      )
    };
    if (updatedState.showCustomFilterPopup) {
      let customField: any = [];
      updatedState.showCustomFilterPopup?.data?.forEach((row: any) => {
        const modules = updatedState.showCustomFilterPopup.customFieldOptions
          ? updatedState.showCustomFilterPopup.customFieldOptions
              .filter((field: any) => field.key === row.key)
              .map((field: any) => {
                if (field.key === row.key) {
                  return field.module;
                }
                return '';
              })
          : [];

        let cf: any = {};
        if (Array.isArray(row.value)) {
          let val = '';
          updatedState.showCustomFilterPopup.options.forEach((option: any) => {
            if (option.key === row.key) {
              option?.options.forEach((opt: any) => {
                if (opt.id === row.value[0]) {
                  val = opt.value;
                }
              });
            }
          });

          cf.value = val;
          cf.code = row.key;
          cf.module = modules;
        } else {
          cf.value = row.value;
          cf.code = row.key;
          cf.module = modules;
        }
        if (cf.value !== '' && cf.module.length) customField.push(cf);
      });
      if (customField?.length) {
        payload = {
          ...payload,
          custom_field: JSON.stringify(customField)
        };
      }
    }
    return payload;
  };

  const exportDocument = (format: string) => {
    AccountingService.getInstance()
      .exportTrialBalance(format, {
        StartDate: getDateStrFromDate(
          reportFilterState.StartDate,
          DATE_FORMATS['DD-MM-YYYY']
        ),
        EndDate: getDateStrFromDate(
          reportFilterState.EndDate,
          DATE_FORMATS['DD-MM-YYYY']
        ),
        CustomField: reportFilterState.CustomField
      })
      .then((res: any) => setIsExporting(false))
      .catch((err: any) => {
        showToast('Could Not Export Report', TOAST_TYPE.FAILURE);
      });
  };

  const parsedDataForTrialBalance = () => {
    let style = {
      width: 190
    };
    const columns = [
      {
        title: 'Account Code',
        style: { ...style, width: 250 },
        type: 'bold-lines',
        id: 'accountCode'
      },
      {
        title: 'Account Name',
        style: { ...style, width: 250 },
        type: 'bold-lines',
        id: 'accountName'
      },
      {
        title: 'Account Group',
        style,
        type: 'bold-lines',
        id: 'accountGroup'
      },
      {
        title: 'Debit',
        style: { ...style, textAlign: 'right' },
        type: 'bold-lines',
        id: 'debitAmount'
      },
      {
        title: 'Credit',
        style: { ...style, textAlign: 'right' },
        type: 'bold-lines',
        id: 'creditAmount'
      }
    ];
    let data = getParsedDataForTrialBalanceDetails(
      reportData,
      reportFilterState,
      columns
    );

    if (data) {
      data['payload']['columns'] = columns;
      setPasser(data.payload);
      setLengthOfCols(data.length);
    }
  };

  const setZero = (rows: any) => {
    let newRow = deepCloneObject(rows);
    let indexesToRemove: any[] = [];

    rows.forEach((row: any, index: any) => {
      let toDelete = true;
      row.cells.forEach((cell: any, indexx: any) => {
        if (
          (cell.title === ' ' && indexx !== 0) ||
          (Number(cell.title.substring(1)) !== 0 && indexx !== 0)
        ) {
          toDelete = false;
          //return;
        }
      });
      rows?.children?.forEach((child: any) => {
        newRow.children = setZero(child);
      });
      if (toDelete === true && row.cells.length > 0) {
        // newRow.splice(index, 1);
        indexesToRemove.push(index);
      }
    });
    for (var i = indexesToRemove.length - 1; i >= 0; i--)
      newRow.splice(indexesToRemove[i], 1);

    return newRow;
  };
  const toggleZero = (state: any) => {
    if (state.toggle === false) {
      // setPasser(oldPasser);
      return;
    }
    const rows = setZero(passer.rows);
    let updatedState = passer;
    updatedState.rows = rows;
    setPasser({ ...updatedState });

    // for (let keys in data) {
    //   let rows = replaceNameState(passer.rows, keys, data[keys]);
    //   let updatedState = passer;
    //   updatedState.rows = rows;
    //   setPasser({ ...updatedState });
    // }
  };

  const fetchReportDetails = (payload: any) => {
    if (!isEmpty(payload)) {
      setTableLoader(true);

      let queryString: string = `basis=${payload.basis}&fromDate=${payload.startDate}&toDate=${payload.endDate}`;
      if (payload.custom_field && payload.custom_field.length > 0) {
        queryString += `&customfield=${encodeURI(payload.custom_field)}`;
      }
      AccountingService.getInstance()
        .getTrialBalanceDetailReport(queryString)
        .then((res: any) => {
          let data: any = { ...res };
          setReportData({ ...data });
          setTableLoader(false);
        })
        .catch((err: any) => {
          showToast('Error loading report data', TOAST_TYPE.FAILURE);
          setTableLoader(false);
        });
    }
  };

  const runReport = (state: ReportHeaderState) => {
    const updatedState = reportFilterState;

    if (state.date.currentValue) {
      dateFilterLogic(state.date, updatedState);
    }

    if (state.accountingMethod.currentValue) {
      if (state.accountingMethod.currentValue === REPORT_BASIS.CASH) {
        accountingMethod(
          state.accountingMethod.currentValue,
          'Cash',
          updatedState
        );
      } else {
        accountingMethod(
          state.accountingMethod.currentValue,
          'Accural',
          updatedState
        );
      }
    }
    if (state.showCustomFilterPopup) {
      updatedState.showCustomFilterPopup = state.showCustomFilterPopup;
    }

    updatedState.CurrentDateRange = state.date.currentValue;
    const payload = generatePayload(updatedState);
    setReportFilterState({ ...updatedState });
    fetchReportDetails(payload);
  };

  const newHeader = () => {
    return (
      <AccountingReportHeader
        hideToggle={true}
        toggle={(state: any) => {
          setToggled(state.toggle);
          if (state.toggle === false) {
            runReport(state);
          } else toggleZero(state);
        }}
        hasCustomField={true}
        columnBy={{
          name: '',
          isHidden: true,
          type: REPORT_HEADER_ITEMS.COLUMN_BY,
          dataOptions: [],
          currentValue: reportFilterState.ColumnBy
        }}
        taxAgency={{
          name: '',
          isHidden: true,
          type: REPORT_HEADER_ITEMS.TAX_AGENCY,
          dataOptions: [],
          currentValue: null
        }}
        comparisionMode={{
          name: '',
          isHidden: true,
          type: REPORT_HEADER_ITEMS.COMPARISION_MODE,
          dataOptions: [],
          currentValue: reportFilterState.ComparisonMode
        }}
        accountingMethod={{
          name: '',
          isHidden: false,
          type: REPORT_HEADER_ITEMS.ACCOUNTING_METHOD,
          dataOptions: [...comparisionDataOptions],
          currentValue: null
        }}
        dateRange={{
          startDate: '',
          isHidden: false,
          endDate: '',
          dataOptions: [...dateRangeOptions],
          currentValue: reportFilterState.CurrentDateRange
        }}
        showDirect={false}
        runReport={(state) => {
          runReport(state);
        }}
        reportType={'Trial Balance Details Report'}
        showFilter={false}
      />
    );
  };

  const newReportBody = () => {
    return (
      <AccountingReportBody
        reportData={passer}
        reportName="Trial Balance Details"
        templateName="template"
        filterDate={reportFilterState}
        footerText={getTimeStamp(reportFilterState.Basis.label)}
        onEmailClick={() => {
          if (!isLoading) {
            setIsLoading(true);
            showLoader("Generating PDF...");
            let pdf = new PdfUtility();
            const element = document.getElementById('template');
            pdf
              .getPDFUrlForEmail(element, 'Trail Balance Details')
              .then((res: any) => {
                setIsLoading(false);
                removeLoader();
                setShowEmailPopup(true);
                setPdfUrl(res);
              }).catch((err: any) => {
                removeLoader();
              });
          }
        }}
        onExportClick={() => {
          if (!isExporting) {
            setIsExporting(true);
            exportDocument('XLS');
          }
        }}
        onPrintClick={() => {
          const element = document.getElementById('template');
          if (lengthOfCols >= 4) {
            PdfUtility.printLandscapeHtml(element, true);
          } else {
            PdfUtility.printLandscapeHtml(element, false);
          }
        }}
        onCellClick={() => {}}
        onRowExpand={async (data: any) => {
          if (data.rowData.totalValues) {
            data.rowData.cells.forEach((cell: any, index: any) => {
              if (index == 3 && data.rowData.children.length > 0) {
                if (
                  isEmpty(data.rowData.totalValues.expanded) &&
                  data.rowData.expanded
                ) {
                  data.rowData.cells[index].title = ' ';
                } else if (
                  isEmpty(data.rowData.totalValues.collapsed) &&
                  data.rowData.collapsed
                ) {
                  data.rowData.cells[index].title = ' ';
                } else {
                  data.rowData.cells[index].title = data.rowData.expanded
                    ? data.rowData.totalValues.expanded['debitAmount'] !==
                      undefined
                      ? getAmountBlockWithCurrency(
                          data.rowData.totalValues.expanded[`debitAmount`]
                        )
                      : ' '
                    : data.rowData.totalValues.collapsed[
                        `debitAmountWithChilds`
                      ] !== undefined
                    ? getAmountBlockWithCurrency(
                        data.rowData.totalValues.collapsed[
                          `debitAmountWithChilds`
                        ]
                      )
                    : ' ';
                }
              }
              if (index == 4 && data.rowData.children.length > 0) {
                if (
                  isEmpty(data.rowData.totalValues.expanded) &&
                  data.rowData.expanded
                ) {
                  data.rowData.cells[index].title = ' ';
                } else if (
                  isEmpty(data.rowData.totalValues.collapsed) &&
                  data.rowData.collapsed
                ) {
                  data.rowData.cells[index].title = ' ';
                } else {
                  data.rowData.cells[index].title = data.rowData.expanded
                    ? data.rowData.totalValues.expanded['creditAmount'] !==
                      undefined
                      ? getAmountBlockWithCurrency(
                          data.rowData.totalValues.expanded[`creditAmount`]
                        )
                      : ' '
                    : data.rowData.totalValues.collapsed[
                        `creditAmountWithChilds`
                      ] !== undefined
                    ? getAmountBlockWithCurrency(
                        data.rowData.totalValues.collapsed[
                          `creditAmountWithChilds`
                        ]
                      )
                    : ' ';
                }
              }
            });
          }
        }}
        showLoading={tableLoader}
      />
    );
  };

  return (
    <div className="column full-screen-width parent-width flex-1 text-13  bg-gray1 main-holder-padding padding-bottom-50">
      <div className="row align-items-center justify-content-between">
        {showEmailPopUp && getEmailPopUp()}
      </div>
      {newHeader()}

      <div className="column parent-width align-items-start p-h-s">
        {newReportBody()}
      </div>
    </div>
  );
}

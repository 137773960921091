import React, { Component } from 'react';
import {
  DKLabel,
  DKIcon,
  DKIcons,
  DKSegmentControl,
  DKLine,
  DKSearchBar
} from 'deskera-ui-library';
import RouteManager from '../../managers/RouteManager';
import TemplateManager, {
  ITemplateGroup
} from '../../managers/TemplateManager';
import { PAGE_ROUTES, QUERY_PARAM_KEYS } from '../../constants/Constant';
import { REPORT_TYPE } from '../../constants/Enum';
import ic_no_data from '../../assets/ic_no_data_3.png';
import { deepClone, isEmpty } from '../../utilities/Common';
export default class Templates extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      selectedTypeIndex: 0,
      searchText: '',
      templatesToDisplay: TemplateManager.templateGroups
    };
  }
  componentDidMount() {
    RouteManager.setPresenter(this);
  }

  redirectToReport = (path: string, type?: REPORT_TYPE) => {
    if (!type) {
      RouteManager.navigateToPage(path);
      return;
    }

    let data = null;
    switch (type) {
      case REPORT_TYPE.BALANCE_SHEET_BY_MONTH:
      case REPORT_TYPE.PROFIT_AND_LOSS_BY_MONTH:
        data = { timePeriod: 1 };
        break;
      case REPORT_TYPE.BALANCE_SHEET_BY_QUARTER:
      case REPORT_TYPE.PROFIT_AND_LOSS_BY_QUARTER:
        data = { timePeriod: 4 };
        break;

      case REPORT_TYPE.BALANCE_SHEET_BY_YEAR:
      case REPORT_TYPE.PROFIT_AND_LOSS_BY_YEAR:
        data = { timePeriod: 12 };
        break;
      default:
        break;
    }
    if (data) {
      this.props.history.push(path, {
        reportDetails: data
      });
    } else {
      RouteManager.navigateToPage(path);
    }
  };
  onSearch = (searchText: any) => {
    this.setState({
      searchText: searchText
    });
  };
  getHeader() {
    return (
      <div className="row row-responsive mobile-flex-gap-m justify-content-between">
        <DKLabel text="Templates" className="fs-l fw-m" />
        <div className="row width-auto mobile-parent-width mobile-justify-content-center">
          <DKSearchBar
            className="report-search-bar"
            placeholder="Search by name..."
            onSearch={(searchText: any) => {
              this.onSearch(searchText);
            }}
            searchText={this.state.searchText}
          />
          <DKSegmentControl
            width={250}
            segments={['All', 'ERP', 'Accounts', 'CRM']}
            backgroundColor="bg-gray1"
            selectedColor="text-white"
            barColor="bg-button"
            selectedIndex={parseInt(this.state.selectedTypeIndex)}
            onSelect={(index: number) => {
              this.setState({
                selectedTypeIndex: index
              });
            }}
          />
        </div>
      </div>
    );
  }

  getReportSection(templateGroup: ITemplateGroup) {
    const { title, templates } = templateGroup;
    const searchResultArray = templates.filter((obj: any) =>
      Object.values(obj).some((val: any) =>
        val.toLowerCase().includes(this.state.searchText.toLowerCase())
      )
    );

    if (isEmpty(searchResultArray)) {
      return null;
    } else {
      return (
        <>
          {
            <div className="column parent-width p-h-l pt-l pb-xxl bg-white border-radius-m border-m mt-xl">
              <DKLabel text={title} className="fs-l fw-m" />
              <div className="row flex-wrap mt-s">
                {searchResultArray.map((template) => {
                  return (
                    <div
                      className="column listPickerBG mr-l mt-r mobile-parent-width cursor-hand"
                      style={{ width: '48%' }}
                      onClick={() => {
                        const defaultPath = `${PAGE_ROUTES.CREATE_REPORT}?${QUERY_PARAM_KEYS.TEMPLATE_GROUP}=${templateGroup.title}&${QUERY_PARAM_KEYS.TEMPLATE_NAME}=${template.templateName}`;
                        this.redirectToReport(
                          template.path || defaultPath,
                          template.type
                        );
                      }}
                    >
                      <DKLabel text={template.title} className="mt-r fw-r" />
                      {/* <DKLabel
                  text={template.description}
                  className="mt-xs text-gray"
                /> */}
                      <DKLine className="bg-gray2 mt-r" />
                    </div>
                  );
                })}
              </div>
            </div>
          }
        </>
      );
    }
  }
  getNoDataView = () => {
    return (
      <div
        className="column align-self-center align-items-center position-absolute"
        style={{ top: '30%', pointerEvents: 'none' }}
      >
        <DKIcon
          src={ic_no_data}
          className="ic-l"
          style={{ opacity: 0.2, marginTop: 70 }}
        />
        <DKLabel text="No template found" className="fw-m mt-l" />
      </div>
    );
  };
  renderAllTemplateGroups = () => {
    let templateGroups = TemplateManager.templateGroups;
    if (this.state.selectedTypeIndex !== 0) {
      templateGroups = [
        TemplateManager.templateGroups[this.state.selectedTypeIndex - 1]
      ];
    }
    const templateGroupRenderers = templateGroups
      .map((templateGroup) => this.getReportSection(templateGroup))
      .filter((renderer) => renderer !== null);
    if (isEmpty(templateGroupRenderers)) return this.getNoDataView();
    return <React.Fragment>{templateGroupRenderers}</React.Fragment>;
  };
  render() {
    let templateGroups = TemplateManager.templateGroups;
    if (this.state.selectedTypeIndex !== 0) {
      templateGroups = [
        TemplateManager.templateGroups[this.state.selectedTypeIndex - 1]
      ];
    }
    return (
      <div
        className="column parent-width p-xl align-self-center"
        style={{ maxWidth: 1400 }}
      >
        {this.getHeader()}
        {this.renderAllTemplateGroups()}
      </div>
    );
  }
}

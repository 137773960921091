import React, { useEffect, useState } from 'react';
import {
  DKInput,
  DKIcon,
  DKIcons,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  DKTooltipWrapper,
  DKLabel,
  TOAST_TYPE,
  showToast
} from 'deskera-ui-library';
import {
  CUSTOM_FIELD_TYPE,
  FINANCIAL_REPORT,
  REPORT_BASIS
} from '../../constants/Enum';
import { useAppSelector } from '../../store/hooks';
import { getTenantInfo, getUsers } from '../../store/slices/tenantSlice';
import AccountingService from '../../services/reports/Accounting';
import { isEmpty } from '../../utilities/Common';
import { getDateFormatForDateFns } from '../../utilities/Date';
import AddCustomComparisonMode from './AddCustomComparisonMode';
import useConfirm from '../../store/custom/confirm';

interface FilterState {
  Basis: any;
  StartDate: Date;
  EndDate: Date;
  ComparisonMode: any;
  PresetInfo?: any;
  CustomField?: any;
}
const getLastTen = () => {
  let firstDay = new Date(new Date().getFullYear(), 0, 1);
  firstDay = new Date(firstDay.setFullYear(firstDay.getFullYear() - 10));
  return firstDay;
};

export const initialState: FilterState = {
  Basis: { label: 'Accrual', value: REPORT_BASIS.ACCRUAL },
  StartDate: getLastTen(),
  EndDate: new Date(),
  ComparisonMode: null
};

interface FilterProps {
  reportType: FINANCIAL_REPORT;
  reloadModesOptions?: boolean;
  needDateRange?: boolean;
  isDataLoading?: boolean;
  onFilterUpdated: (filterState: any) => void;
}

export interface CustomFieldOption {
  name: string;
  type: string;
  columnCode: string;
  key: string;
  id: string;
  allowFilter: boolean;
  module: string;
  index?: number;
  options?: any[];
  required: boolean;
  width?: number;
  editable?: boolean;
  hidden?: boolean;
  uiVisible?: boolean;
  systemField?: boolean;
}

export function TrialBalanceFilter(props: FilterProps) {
  const { confirm } = useConfirm();
  const [showAddCustomModeBlock, setShowAddCustomModeBlock] = useState(false);
  const [reportFilterState, setReportFilterState] = useState(initialState);
  const [comparisionModes, setComparisionModes] = useState([
    { id: 0, vname: 'Customize', custom: false }
  ]);
  const [basisOptions, setBasisOptions] = useState([
    { label: 'Accrual', value: REPORT_BASIS.ACCRUAL },
    { label: 'Cash', value: REPORT_BASIS.CASH }
  ]);
  const [customFieldsOptions, setCustomFieldsOptions] = useState<any>(null);
  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState<any[]>([]);
  const [isDeletingViewOption, setIsDeletingViewOption] = useState(false);
  const tenantInfo = useAppSelector(getTenantInfo());
  const usersListInfo = useAppSelector(getUsers());

  const loadViewOptions = async (resetOption = false) => {
    if (props.reportType === FINANCIAL_REPORT.TRIAL_BALANCE) {
      return;
    }
    // if (
    //   props.reportType === NON_FINANCIAL_REPORT.AGED_PAYABLES ||
    //   props.reportType === NON_FINANCIAL_REPORT.AGED_RECEIVABLES
    // ) {
    //   return;
    // }

    try {
      const options =
        await AccountingService.getInstance().getComparisonModeOptions(
          props.reportType
        );
      setComparisionModes((prevState) => [
        ...options,
        { id: 0, vname: 'Customize', custom: false }
      ]);
      if (reportFilterState.PresetInfo?.PresetName) {
        setReportFilterState((prevState) => {
          return {
            ...prevState,
            PresetInfo: {
              ...reportFilterState.PresetInfo,
              PresetName: undefined,
              SaveAsPreset: false
            }
          };
        });
      } else if (resetOption) {
        setReportFilterState((prevState) => {
          return {
            ...prevState,
            ComparisonMode: options.filter(
              (option: any) => option.vname === 'Off'
            )[0]
          };
        });
      }
    } catch (err: any) {
      console.error('Error loading view options: ', err);
    }
  };

  const loadCustomFieldsByModules = async () => {
    // try {
    //   const customFields = await AccountingService.getInstance().getCustomFieldByModules();
    //   createCustomFieldOptions(customFields);
    // } catch (err: any) {
    //   console.error('Error loading custom fields by module: ', err);
    // }
  };

  const createCustomFieldOptions = (fields: any) => {
    let options: CustomFieldOption[] = [];
    Object.keys(fields).forEach((key) => {
      fields[key].forEach((field: any) => {
        options.push({
          id: field.code,
          type: field.fieldType.toLowerCase(),
          name: field.label,
          allowFilter: true,
          columnCode: field.code,
          key: field.code,
          required: true,
          module: field.module,
          options: field.attributes
        });
      });
    });
    setCustomFieldsOptions(options);
  };

  useEffect(() => {
    if (filters.length) {
      let customFields: any[] = [];
      filters.forEach((filter) => {
        if (filter?.obj?.options) {
          filter.obj.options.forEach((options: any) => {
            if (options.id === filter.value[0]) {
              filter.valueToBeSent = options.value;
            }
          });
        }
        const modules = customFieldsOptions
          .filter((field: any) => field.key === filter.key)
          .map((field: any) => {
            if (field.key === filter.key) {
              return field.module;
            }
            return;
          });
        customFields.push({
          value: filter.valueToBeSent ? filter.valueToBeSent : filter.value,
          code: filter.key,
          module: modules
        });
      });
      setReportFilterState((prevValue) => {
        return {
          ...prevValue,
          CustomField: JSON.stringify(customFields),
          customFieldObject: customFields
        };
      });
    } else {
      setReportFilterState((prevValue) => {
        return {
          ...prevValue,
          CustomField: null
        };
      });
    }
  }, [filters]);

  const handleNewPresetInfo = (presetInfo: any) => {
    setReportFilterState((prevState) => {
      return {
        ...prevState,
        PresetInfo: presetInfo,
        Basis: presetInfo.PresetName
          ? { label: 'Accrual', value: REPORT_BASIS.ACCRUAL }
          : reportFilterState.Basis
      };
    });
  };

  useEffect(() => {
    loadViewOptions(true);

    loadCustomFieldsByModules();
  }, []);

  useEffect(() => {
    loadViewOptions();
  }, [props.reloadModesOptions]);

  useEffect(() => {
    setReportFilterState((prevState) => {
      return {
        ...prevState,
        StartDate: getLastTen(),
        EndDate: new Date()
      };
    });
  }, [tenantInfo]);

  useEffect(() => {
    if (reportFilterState.Basis && !isEmpty(reportFilterState.PresetInfo)) {
      props.onFilterUpdated(reportFilterState);
    } else if (
      reportFilterState.EndDate > reportFilterState.StartDate &&
      reportFilterState.Basis
    ) {
      props.onFilterUpdated(reportFilterState);
    }
  }, [reportFilterState]);

  const handleDeleteViewOption = async (viewId: number) => {
    const isConfirmed = await confirm(
      'Are you sure you want to delete this preset?'
    );

    if (isConfirmed) {
      setIsDeletingViewOption(true);
      try {
        await AccountingService.getInstance().deleteFilterViewOption(viewId);
        loadViewOptions(viewId === reportFilterState.ComparisonMode.id);
        setIsDeletingViewOption(false);
        showToast('Successfully deleted preset.', TOAST_TYPE.SUCCESS);
      } catch (err: any) {
        console.error('Error deleting preset: ', err);
        setIsDeletingViewOption(false);
        showToast('Error deleting preset', TOAST_TYPE.FAILURE);
      }
    }
  };

  const getUniqueCustomFieldOptions = () => {
    let setObj = new Set<any>(); // create key value pair from array of array

    let results: any = [...customFieldsOptions]?.reduce((acc, item) => {
      if (!setObj.has(item.id)) {
        setObj.add(item.id);

        acc.push(item);
      }
      return acc;
    }, []); //converting back to array from mapobject

    results = results.map((res: any) => {
      const usersList: any[] = usersListInfo || [];
      if (res.type === INPUT_TYPE.DROPDOWN) {
        res.type = INPUT_TYPE.SELECT;
        res.options &&
          res.options.forEach((options: any) => {
            options['name'] = options['value'];
          });
      }
      if (res.type === CUSTOM_FIELD_TYPE.USER.toLowerCase()) {
        res = {
          ...res,
          type: INPUT_TYPE.SELECT,
          options: usersList.length
            ? usersList.map((user: any) => {
                let name = user.firstName?.trim();
                if (user.lastName?.trim()) {
                  name += ` ${user.lastName?.trim()}`;
                }

                return {
                  id: user.iamUserId?.toString(),
                  name: name,
                  value: user.iamUserId?.toString()
                };
              })
            : []
        };
      }
      return res;
    });

    return results;
  };

  return (
    <div className="column ">
      <div className="row align-items-start  row-responsive">
        <div className="row align-items-start row-responsive mobile-flex-gap-m">
          {props.reportType !== FINANCIAL_REPORT.TRIAL_BALANCE && (
            // props.reportType !== NON_FINANCIAL_REPORT.AGED_PAYABLES &&
            // props.reportType !== NON_FINANCIAL_REPORT.AGED_RECEIVABLES &&
            <div className="mr-m mobile-m-0" style={{ width: 150 }}>
              <DKInput
                titleStyle={{ color: 'gray', fontSize: 12 }}
                className={'text-gray-800'}
                readOnly={props.isDataLoading}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                value={reportFilterState.ComparisonMode}
                formatter={(obj: any) => {
                  return obj.vname;
                }}
                title="Comparison Mode"
                valueStyle={{ backgroundColor: 'white' }}
                type={INPUT_TYPE.DROPDOWN}
                required={false}
                canValidate={false}
                onChange={(value: any) => {
                  setReportFilterState((prevState: any) => {
                    return {
                      ...prevState,
                      ComparisonMode: value,
                      PresetInfo: undefined
                    };
                  });
                  if (value.id === 0) {
                    setShowAddCustomModeBlock(true);
                  }
                }}
                dropdownConfig={{
                  className: '',
                  style: {},
                  allowSearch: false,
                  searchableKey: 'vname',
                  data: comparisionModes,
                  renderer: (index: number, obj: any) => {
                    return (
                      <div
                        className="row align-items-center justify-content-between"
                        style={{
                          width: 150
                        }}
                      >
                        <DKLabel
                          text={`${obj.vname}`}
                          className={`${
                            obj.id === reportFilterState.ComparisonMode?.id
                              ? 'text-blue fw-b'
                              : ''
                          }`}
                        />
                        {obj.custom && (
                          <DKIcon
                            src={DKIcons.ic_delete}
                            className={`ic-s ml-r ${
                              isDeletingViewOption ? 'opacity-30' : 'opacity-60'
                            }`}
                            onClick={(e: any) => {
                              if (!isDeletingViewOption) {
                                handleDeleteViewOption(obj.id);
                              }
                              e.stopPropagation();
                            }}
                          />
                        )}
                      </div>
                    );
                  }
                }}
              />
            </div>
          )}
          {
            // props.reportType !== NON_FINANCIAL_REPORT.AGED_PAYABLES &&
            //   props.reportType !== NON_FINANCIAL_REPORT.AGED_RECEIVABLES &&
            <div className="position-relative " style={{ width: 230 }}>
              <DKInput
                titleStyle={{ color: 'gray', fontSize: 12 }}
                className={'text-gray-800'}
                readOnly={props.isDataLoading}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                value={reportFilterState.Basis}
                formatter={(obj: any) => {
                  return obj.label;
                }}
                title="Accounting Basis"
                type={INPUT_TYPE.DROPDOWN}
                required={false}
                canValidate={false}
                onChange={(obj: any) => {
                  if (obj.value !== reportFilterState.Basis.value) {
                    setReportFilterState((prevState: any) => {
                      return {
                        ...prevState,
                        Basis: obj
                      };
                    });
                  }
                }}
                dropdownConfig={{
                  className: '',
                  style: {},
                  allowSearch: false,
                  searchableKey: 'label',
                  data: basisOptions,
                  renderer: (index: number, obj: any) => {
                    return (
                      <DKLabel
                        text={`${obj.label}`}
                        className={`${
                          obj.value === reportFilterState.Basis.value
                            ? 'text-blue fw-b'
                            : ''
                        }`}
                      />
                    );
                  }
                }}
              />
              <div className="position-absolute" style={{
                top: 1,
                right: 1
              }}>
                <DKTooltipWrapper
                  content="<div class='fw-b'>Cash or Accrual</div><div> Accrual Basis recognizes income and expenses at the time of invoicing, while Cash method only reports income and expenses when cash changes hands.</div>"
                  tooltipClassName=""
                >
                  <DKIcon
                    src={DKIcons.ic_info}
                    className="ic-xs opacity-40"
                    onClick={() => {}}
                  />
                </DKTooltipWrapper>
              </div>
            </div>
          }

          {!isEmpty(tenantInfo) &&
            props.reportType === FINANCIAL_REPORT.PNL && (
              <div className="ml-m mobile-m-0" style={{ width: 182 }}>
                <DKInput
                  titleStyle={{ color: 'gray', fontSize: 12 }}
                  title="Date Range"
                  className={' text-gray-800'}
                  readOnly={props.isDataLoading}
                  errorMessage={''}
                  canValidate={
                    reportFilterState.EndDate < reportFilterState.StartDate
                  }
                  type={INPUT_TYPE.DATE}
                  dateFormat={getDateFormatForDateFns(tenantInfo.dateFormat)}
                  onChange={(startDate: any, endDate: any) => {
                    setReportFilterState((prevState) => {
                      return {
                        ...prevState,
                        StartDate: startDate,
                        EndDate: endDate,
                        PresetInfo: null,
                        ComparisonMode: comparisionModes.filter(
                          (mode) => mode.vname === 'Off'
                        )[0]
                      };
                    });
                  }}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  required={true}
                  datePickerConfig={{
                    isDateRange: true,
                    startDate: reportFilterState.StartDate,
                    endDate: reportFilterState.EndDate
                  }}
                />
              </div>
            )}
          {!isEmpty(tenantInfo) &&
            (props.reportType === FINANCIAL_REPORT.BALANCE_SHEET ||
              props.reportType === FINANCIAL_REPORT.TRIAL_BALANCE) && (
              <div className="ml-m mr-0  mobile-m-0" style={{ width: 210 }}>
                <DKInput
                  titleStyle={{ color: 'gray', fontSize: 12 }}
                  title="As of"
                  className={'text-gray-800'}
                  value={reportFilterState.EndDate}
                  readOnly={props.isDataLoading}
                  errorMessage={'Must be greater than beginning date'}
                  validator={(val: Date) => val > reportFilterState.StartDate}
                  canValidate={
                    reportFilterState.EndDate < reportFilterState.StartDate
                  }
                  type={INPUT_TYPE.DATE}
                  dateFormat={getDateFormatForDateFns(tenantInfo.dateFormat)}
                  onChange={(endDate: any) => {
                    setReportFilterState((prevState) => {
                      return {
                        ...prevState,
                        EndDate: endDate,
                        PresetInfo: null,
                        ComparisonMode: comparisionModes.filter(
                          (mode) => mode.vname === 'Off'
                        )[0]
                      };
                    });
                  }}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  required={true}
                  datePickerConfig={{
                    isDateRange: false
                  }}
                />
              </div>
            )}
        </div>
        {/* <div className="position-relative mt-5 ml-m">
          <div className="position-relative">
            {filters && filters.length > 0 && (
              <div
                className="position-absolute bg-red"
                style={{
                  width: '8px',
                  height: '8px',
                  right: '0',
                  borderRadius: '4px'
                }}
              ></div>
            )}
            <DKButton
              icon={DKIcons.ic_filter}
              className="border-radius-s border bg-gray1"
              style={{
                borderColor: 'rgb(235, 235, 235)'
              }}
              title=""
              onClick={() => {
                setShowFilter((prevState) => !prevState);
              }}
            />
          </div>

          <div
            className="position-absolute z-index-2"
            style={{ width: 480, left: 0, top: 5 }}
          >
            {showFilter && customFieldsOptions && (
              <FilterPopup
                headerTitle="Custom Field Filters"
                filterData={[...filters]}
                headers={getUniqueCustomFieldOptions()}
                maxRows={10}
                onSubmit={(data: any) => {
                  setFilters([...data]);
                  setShowFilter(false);
                }}
                onCancel={() => {
                  setShowFilter(false);
                }}
              />
            )}
          </div>
        </div> */}
      </div>
      {showAddCustomModeBlock && (
        <AddCustomComparisonMode
          reportType={props.reportType}
          onCustomComparisonModeChange={(presetInfo: any) => {
            handleNewPresetInfo(presetInfo);
            setShowAddCustomModeBlock(false);
          }}
          onAddCustomComparisonModeClose={() => {
            setShowAddCustomModeBlock(false);
          }}
        />
      )}
    </div>
  );
}

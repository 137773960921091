import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../../models/Filter';
import { isEmpty } from '../../utilities/Common';
import http from '../';
import ApiConstants from '../../constants/ApiConstant';
import { COUNTRY_CODES, COUNTRY_SPECIFIC_URL_CODE } from '../../constants/Enum';
import AuthManager from '../../managers/AuthManager';
import { getTenantInfo } from '../../helper/SharedParser';

export interface ProductAPIConfig {
  SearchTerm?: string;
  Query?: string;
  Limit?: number;
  Page?: number;
  IsVariant?: boolean;
  type?: string;
  QueryParam?: any;
  isOB?: boolean;
  barcode?: string;
}

export const defaultConfig: ProductAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  IsVariant: false,
  type: '',
  QueryParam: ''
};

export function getTenantSpecificApiCode(
  moduleName: COMPLIANCE_SPECIFIC_FIELD_NAME
): string {
  let countrySpecificURL = '';

  const countryCode = getTenantInfo()?.country;

  switch (countryCode) {
    case COUNTRY_CODES.IN:
      if (
        moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.CONTACT ||
        moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.FETCH_TENANT_SETTINGS ||
        moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.PATCH_TENANT_SETTINGS ||
        moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.RECEIVE_PAYMENT ||
        moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.MAKE_PAYMENT ||
        moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.PRODUCT ||
        moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.INVOICE ||
        moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.QUOTATION ||
        moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.PURCHASE_ORDER ||
        moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.BILL ||
        moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.SALES_ORDER ||
        moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.JOB_WORK_OUT
      ) {
        countrySpecificURL = COUNTRY_SPECIFIC_URL_CODE.IN;
      }
      break;
    case COUNTRY_CODES.US:
      break;

    case COUNTRY_CODES.SG:
      break;

    case COUNTRY_CODES.ID:
      if (
        moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.CONTACT ||
        moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.FETCH_TENANT_SETTINGS ||
        moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.PATCH_TENANT_SETTINGS
      ) {
        countrySpecificURL = COUNTRY_SPECIFIC_URL_CODE.INDONESIA;
      }
      break;
    case COUNTRY_CODES.MY:
      if (
        moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.CONTACT ||
        moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.FETCH_TENANT_SETTINGS ||
        moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.PATCH_TENANT_SETTINGS ||
        moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.PRODUCT
      ) {
        countrySpecificURL = COUNTRY_SPECIFIC_URL_CODE.MALAYSIA;
      }
      break;

    case COUNTRY_CODES.PH:
      if (
        moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.FETCH_TENANT_SETTINGS ||
        moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.PATCH_TENANT_SETTINGS
      ) {
        countrySpecificURL = COUNTRY_SPECIFIC_URL_CODE.PHILIPPINES;
      }
      break;

    case COUNTRY_CODES.NZ:
      break;

    case COUNTRY_CODES.DE:
      break;

    case COUNTRY_CODES.AE:
      if (
        moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.CONTACT ||
        moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.FETCH_TENANT_SETTINGS ||
        moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.PATCH_TENANT_SETTINGS
      ) {
        countrySpecificURL = COUNTRY_SPECIFIC_URL_CODE.UAE;
      }
      break;
    case COUNTRY_CODES.UK:
      break;
    case COUNTRY_CODES.BE:
      break;
    case COUNTRY_CODES.AU:
      break;
    case COUNTRY_CODES.CA:
      if (
        moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.FETCH_TENANT_SETTINGS ||
        moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.PATCH_TENANT_SETTINGS
      ) {
        countrySpecificURL = COUNTRY_SPECIFIC_URL_CODE.CANADA;
      }
      break;

    default:
      break;
  }
  return countrySpecificURL;
}

export default class ProductService {
  static apiConfig: ProductAPIConfig;
  static moduleName: COMPLIANCE_SPECIFIC_FIELD_NAME =
    COMPLIANCE_SPECIFIC_FIELD_NAME.PRODUCT;

  static getProductEndPoint(isVariant = false) {
    if (isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
      if (
        !isVariant &&
        !this.apiConfig.Query?.toString().includes('isVariant=false')
      ) {
        this.apiConfig.Query = this.apiConfig.Query + `isVariant=false`;
      }
    }
    let queryString: string = `?search=${this.apiConfig.SearchTerm}&limit=${
      this.apiConfig.Limit
    }&page=${this.apiConfig.Page}${
      this.apiConfig.Query ? '&query=' + this.apiConfig.Query : ''
    }${this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''}`;
    queryString = this.apiConfig.barcode
      ? queryString + '&barcode=' + this.apiConfig.barcode
      : queryString;
    const countryCode = getTenantSpecificApiCode(ProductService.moduleName);
    const finalEndpoint: string =
      ApiConstants.URL.PRODUCT.FETCH_PRODUCTS +
      (countryCode || '') +
      queryString;
    return finalEndpoint;
  }

  static getProductsByPage(country = '', isVariant = false) {
    return http.get(ProductService.getProductEndPoint(isVariant));
  }
}

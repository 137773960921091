import { DropdownSelectionType, FORM_ELEMENTS } from '../constants/Enum';

export interface MulitSelectDropdown {
  selectionType: DropdownSelectionType;
  type: FORM_ELEMENTS;
  defaultValue?: number[];
  hidden: boolean;
  value: number[];
  key?: string;
  options?: ReactSelectOptionsType<string, string>[];
  originalData?: any;
}

export interface ReactSelectOptionsType<K, T> {
  value: T;
  label: K;
  index?: any;
  isDisabled?: boolean;
  status?: string;
}

export enum COMPLIANCE_SPECIFIC_FIELD_NAME {
  CONTACT = 'CONTACT',
  PRODUCT = 'PRODUCT',
  INVOICE = 'INVOICE',
  BILL = 'BILL',
  QUOTATION = 'QUOTATION',
  PURCHASE_ORDER = 'PURCHASE ORDER',
  REQUISITION = 'REQUISITION',
  PATCH_TENANT_SETTINGS = 'PATCH_TENANT_SETTINGS',
  FETCH_TENANT_SETTINGS = 'FETCH_TENANT_SETTINGS',
  QUOTE = 'QUOTE',
  RECEIVE_PAYMENT = 'RECEIVE_PAYMENT',
  MAKE_PAYMENT = 'MAKE_PAYMENT',
  SALES_ORDER = 'SALES_ORDER',
  JOB_WORK_OUT = 'JOB_WORK_OUT',
  QUALITY_CHECK = 'QUALITY_CHECK'
}

import React, { useEffect, useState } from 'react';
import { DKButton, showLoader, removeLoader } from 'deskera-ui-library';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getQueryParam } from '../../utilities/Common';
import AppIntegrationService from '../../services/appIntegration';
import { fetchAppIntegrationMapping } from '../../store/slices/appIntegrationSlice';

interface OneDriveButtonProps {
  redirectURL?: string;
  needDisconnectButton?: boolean;
  buttonClassName?: string;
  styles?: { [key: string]: any };
  needDarkIcon?: boolean;
  onSuccess: (files: any[]) => void;
  onCancel: () => void;
  onError: (err: any) => void;
}

export default function OneDriveButton(props: OneDriveButtonProps) {
  const redirectUrl =
    props.redirectURL || `${window.location.origin}/settings/connect`;
  const login_url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
client_id=${process.env.REACT_APP_ONEDRIVE_CLIENT_ID}&
scope=https%3A%2F%2Fgraph.microsoft.com%2FFiles.Read.All offline_access user.read&
redirect_uri=${redirectUrl}&
state=one-drive&
response_type=code&
prompt=login`;

  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const appIntegrationMapping = useAppSelector(
    (state) => state.appIntegration.connections
  );

  useEffect(() => {
    const authCode = getQueryParam('code');
    const state = getQueryParam('state');
    if (authCode && state === 'one-drive') {
      setLoading(true);
      const payload = {
        code: authCode,
        realmId: process.env.REACT_APP_ONEDRIVE_CLIENT_ID,
        state: state,
        appName: 'ONEDRIVE',
        redirectUrl: redirectUrl
      };
      AppIntegrationService.connectToApp(payload).then((data) => {
        dispatch(fetchAppIntegrationMapping());
      }).catch((err) => {
        console.error('Error while connecting app: ', err);
      }).finally(() => {
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* ************** HANDLERS *************** */
  const onDisconnectAccount = (oneDriveApp: any) => {
    showLoader('Disconnecting...');
    const payload = {
      app: oneDriveApp.appName,
      username: oneDriveApp.username
    };
    AppIntegrationService.disconnectApp(payload).then(
      (data) => {
        dispatch(fetchAppIntegrationMapping());
        removeLoader();
      },
      (err) => {
        console.error('Error while disconnecting app: ', err);
        removeLoader();
      }
    );
  };

  const triggerBrowseFileDialogue = () => {
    try {
      if (!(window as any)['OneDrive']) return;

      let odOptions = {
        clientId: process.env.REACT_APP_ONEDRIVE_CLIENT_ID,
        action: 'query',
        multiSelect: false,
        advanced: {
          endpointHint: 'api.onedrive.com'
        },
        success: (files: any) => {
          if (props.onSuccess) {
            props.onSuccess(files);
          }
        },
        cancel: () => {
          if (props.onCancel) {
            props.onCancel();
          }
        },
        error: (error: any) => {
          console.error('Error loading picker: ', error);
          if (props.onError) {
            props.onError(error);
          }
        }
      };

      (window as any)['OneDrive'].open(odOptions);
    } catch (err) {
      console.error('Error launching OneDrive picker: ', err);
    }
  };

  const getConnectedAccountData = () => {
    try {
      const oneDriveAppList =
        appIntegrationMapping?.filter(
          (app: any) => app.appName === 'ONEDRIVE'
        ) || [];

      const oneDriveApp =
        oneDriveAppList.find((app) => app.connected) || oneDriveAppList[0];
      return oneDriveApp;
    } catch (err) {
      return null;
    }
  };

  /* ************** RENDERERS *************** */
  const getOneDriveBtn = () => {
    const oneDriveApp = getConnectedAccountData();
    return !oneDriveApp?.['connected'] ? (
      <DKButton
        title={loading ? "Connecting..." : "Connect to Onedrive"}
        className={
          props.buttonClassName || 'border-m bg-button text-white border-blue'
        }
        style={props.styles || {}}
        onClick={() => {
          window.location.href = login_url;
        }}
      />
    ) : props.needDisconnectButton ? (
      <DKButton
        title="Disconnect Onedrive"
        className="border-m bg-button text-white border-blue"
        onClick={() => onDisconnectAccount(oneDriveApp)}
      />
    ) : (
      <DKButton
        title="Browse Onedrive"
        className={
          props.buttonClassName || 'border-m bg-button text-white border-blue'
        }
        style={props.styles || {}}
        onClick={() => triggerBrowseFileDialogue()}
      />
    );
  };

  return getOneDriveBtn();
}

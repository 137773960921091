import { connect } from "react-redux";
import React, { Component } from "react";
import { DKLabel, DKLine, DKIcon } from "deskera-ui-library";
import ApiConstants from "../../constants/ApiConstant";
import RouteManager from "../../managers/RouteManager";
import { PAGE_ROUTES } from "../../constants/Constant";
import AppManager from "../../managers/AppManager";
import ic_profile_pic from "../../assets/icons/ic_profile_pic.png";

class ProfileOptionsSection extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        if (this.props.onClose) {
            document.addEventListener("mouseup", this.onMouseUp);
        }
    }
    componentWillUnmount() {
        if (this.props.onClose) {
            document.removeEventListener("mouseup", this.onMouseUp);
        }
    }
    onMouseUp = (e) => {
        if (this.props.onClose) {
            const target = e.target;
            const editingContainer = target.closest(
                "#profile-options-section, #dk-alert, #dk-alert-background"
            );

            if (!editingContainer) {
                this.props.onClose();
            }
        }
    };

    render() {
        const options = [
            {
                title: "View or Edit Profile",
                url: ApiConstants.URL.GO_UI_URL,
            },
            {
                title: "Organization Info",
                url: ApiConstants + "settings",
            },
            {
                title: "Subscription & Billing",
                url: ApiConstants.URL.BILLING_URL,
            },
            {
                title: "Settings",
                url: "settings",
            },
        ];

        return (
            <div
                id="profile-options-section"
                className="column position-absolute bg-white shadow-l border-radius-m border-m pb-m"
                style={{ top: 40, right: 0, width: 300 }}
            >
                {this.getProfileSection(true)}
                <DKLine className="mb-r" />
                {options.map((option) => (
                    <div
                        className="cursor-hand parent-width listPickerBG"
                        onClick={
                            option.url === "settings"
                                ? () => {
                                    this.props.onClose();
                                    RouteManager.navigateToPage(PAGE_ROUTES.SETTINGS, option.queryParams || "");
                                }
                                : () => {
                                    this.props.onClose();
                                    window.open(option.url);
                                }
                        }
                    >
                        <DKLabel text={option.title} className="p-h-r p-v-s" />
                    </div>
                ))}

                <DKLine className="mb-r mt-r" />
                <div
                    className="cursor-hand parent-width listPickerBG"
                    onClick={() => {
                        this.props.onClose();
                        AppManager.showLogoutConfirmation();
                    }}
                >
                    <DKLabel text="Sign Out" className="p-h-r p-v-s text-red" />
                </div>
            </div>
        );
    }

    getProfileSection(detailedView = false) {
        return (
            <div
                className={
                    "row border-radius-m p-m " + (!detailedView ? "cursor-hand" : "")
                }
                style={{ backgroundColor: "rgba(255, 255, 255, 0.08)" }}
                onClick={
                    detailedView
                        ? null
                        : () => {
                            this.setState({ needProfileOptionsSection: true });
                        }
                }
            >
                <DKIcon
                    src={ic_profile_pic}
                    className="circle ic-m shadow-s"
                    style={{ opacity: 0.7 }}
                />
                <div className={" ml-r " + (!detailedView ? "text-white" : "")}>
                    <DKLabel
                        text={`${this.props.userInfo?.firstName + ' ' +this.props.userInfo?.lastName || ""}`}
                        style={{ opacity: !detailedView ? 0.9 : 1 }}
                    />
                    <DKLabel
                        text={this.props.userInfo?.email || ''}
                        className="mt-xs"
                        style={{ opacity: 0.5, wordBreak: "break-all" }}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    userInfo: state.tenant.userInfo
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileOptionsSection);

import { parse, format } from 'date-fns';
import { DATE_FN_DATE_FORMATS, DATE_FORMATS } from '../constants/Enum';

/**
 * @param dateString - date string in the format specified for date-fns
 * @param format - format of the date string from DATE_FORMATS
 * @returns - date object
 */
export function getDateFromString(
  dateString: string,
  format: DATE_FORMATS = DATE_FORMATS['DD-MM-YYYY']
): Date {
  return parse(dateString, getDateFormatForDateFns(format), new Date());
}

/**
 * Converts Date object to corresponding date string
 * @param date Date object to be formatted as string
 * @param toFormat format of the date string from DATE_FORMATS
 * @returns formatted date string as per tenant's date format, or, in a default date format dd-mm-yyyy
 */
export function getDateStrFromDate(
  date: Date,
  toFormat: DATE_FORMATS = DATE_FORMATS['DD-MM-YYYY']
): string {
  try {
    if (typeof date === 'string') {
      date = new Date(date);
    }
    const formattedDate = format(date, getDateFormatForDateFns(toFormat));
    return formattedDate;
  } catch (e: any) {
    console.error('Error formatting Date: ', date);
    return '';
  }
}

/**
 * Convert date string from one format to another
 * @param dateStr Date string to be formatted
 * @param fromFormat Any one of the date formats
 * @param toFormat (Optional) Any one of the date formats
 * @returns formatted date string as per provided date format or dd-mm-yyyy format
 */
export function getFormattedDateString(
  dateStr: string,
  fromFormat: DATE_FORMATS,
  toFormat: DATE_FORMATS = DATE_FORMATS['DD-MM-YYYY']
): string {
  try {
    return format(
      parse(dateStr, getDateFormatForDateFns(fromFormat), new Date()),
      getDateFormatForDateFns(toFormat)
    );
  } catch (e: any) {
    console.error('Error formatting Date: ', dateStr);
    return '';
  }
}

/**
 *
 * @param format - format of the date string from DATE_FORMATS
 * @returns - date string in the format specified for date-fns
 */
export function getDateFormatForDateFns(format: DATE_FORMATS): string {
  return DATE_FN_DATE_FORMATS[format];
}
